<template>
    <section :class="mini? 'ml-3' : 'position-absolute w-100 h-100'" class=" load d-flex flex-column align-items-center justify-center">
        <div class="position-relative d-flex">
            <div :class="mini? 'bar-mini' : 'bar'" class="p-2 position-absolute" style="" />
            <div class="h-100 d-middle-center rounded-circle">
                <img :src="$config.load_web" class="p-1" :class="mini? 'bell-loading-mini' : 'bell-loading'" />
            </div>
        </div>
        <!-- <p class="mt-3">Cargando...</p> -->
    </section>
</template>
<script>
export default {
    props: {
        mini: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            url: '/img/icons/bell_loading.svg'
        }
    }
}
</script>

<style lang="scss" scoped>
.load{
    z-index:500;
    opacity: 0.8;
    left: 0px;
    background-color: #ffffff;
}

.bell-loading{
    object-fit: cover;
    height: 100px;
    width: 100px;
}

.bar{
    border: 10px solid var(--color-db);
    left:-25px;top:-25px;
    border-top: 10px solid var(--color-general);
    width:150px;
    height: 150px;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.bell-loading-mini{
    object-fit: cover;
    height: 50px;
    width: 50px;
}
.bar-mini{
    border: 8px solid var(--color-db);
    left:-15px;top:-15px;
    border-top: 8px solid var(--color-general);
    width:80px;
    height: 80px;
    border-radius: 50%;
    animation: spin-mini 2s linear infinite;    
}
@keyframes spin-mini {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
