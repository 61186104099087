export default [
    {
        path: '/tendero/creditos',
        name: 'tendero.creditos',
        meta: { nombre: 'Creditos' },
        redirect: {name: 'tendero.creditos.dashboard' },
        component: () => import('~/pages/creditos').then(m => m.default || m),
        children: [
            {
                path: 'dashboard',
                name: 'tendero.creditos.dashboard',
                meta: { nombre: 'Creditos' },
                component: () => import('~/pages/creditos/dashboard').then(m => m.default || m),
            },
            {
                path: 'clientes',
                name: 'tendero.creditos.clientes',
                meta: { nombre: 'Creditos' },
                component: () => import('~/pages/creditos/clientes').then(m => m.default || m),
            },
            {
                path: 'clientes/ver/:id_user',
                name: 'tendero-vip.creditos.cliente.ver',
                meta: { nombre: 'Creditos' },
                component: () => import('~/pages/creditos/clienteVer').then(m => m.default || m),
            },
            {
                path: 'clientes/ver/:id_user/deuda/ver/:id_pedido',
                name: 'tendero-vip.creditos.cliente.ver.deuda.ver',
                component: () => import('~/pages/creditos/deudaVer').then(m => m.default || m),
            },
            {
                path: 'cuotas',
                name: 'tendero.creditos.cuotas',
                meta: { nombre: 'Creditos' },
                component: () => import('~/pages/creditos/cuotas').then(m => m.default || m),
            },
            {
                path: 'pedido-cliente/:id_pedido',
                name: 'tendero.almacen.creditos.detalle.pedido.cliente',
                meta: { nombre: 'Creditos' },
                component: () => import('~/pages/almacen/tendero/creditos/detallePedido').then(m => m.default || m),
            }
        ]

    }
]
