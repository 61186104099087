export default [
    {
        path: '/admin/servicios-paqueteria',
        name: 'servicios.paqueteria',
        meta: { nombre: 'Servicios de paquetacion' },
        component: () => import('../../pages/serviciosPaqueteria/index.vue').then(m => m.default || m)
    },
	{
		path: '/admin/servicios-paqueteria/:id/detalle',
        name: 'servicios.paqueteria.detalles',
        meta: { nombre: 'Servicios de paquetacion - Detalle' },
        component: () => import('../../pages/serviciosPaqueteria/serviciosDetalles.vue').then(m => m.default || m)
	}

]
