import direcciones from "~/services/tiendas/direcciones";

export const state = {
    direcciones: []
};

export const getters = {
    direcciones: ({ direcciones }) => direcciones,
    principal: ({ direcciones }) => direcciones.find(el => el.principal)
};

export const mutations = {
    setDirecciones: (state, payload) => state.direcciones = payload,
};

export const actions = {
    async fetchDirecciones({ commit, rootGetters }){
        const idUser = rootGetters["auth/obtenerUsuario"].id
        const { data } = await direcciones.listar(idUser);
        const datos = data.data.lista.map(el => ({...el, editando: false}))
        commit("setDirecciones", datos);
    },
};
