export default [
    {
        path: '/admin/retiros',
        meta: { nombre: 'Gestión de administrador' },
        name: 'admin.retiros',
        component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/retiros').then(m => m.default || m),
        redirect: {name: 'admin.retiros.cedis'},
        children: [
            {
                path: 'productos',
                meta: { nombre: 'Gestión de administrador' },
                name: 'admin.retiros.productos',
                component: () => import(/* webpackChunkName: "gestion-administrador.productos" */ '../../pages/retiros/productos').then(m => m.default || m),
                redirect: {name: 'admin.retiros.cedis'},
                children: [
                    {
                        path: 'cedis',
                        meta: { nombre: 'Gestión de administrador' },
                        name: 'admin.retiros.cedis',
                        component: () => import(/* webpackChunkName: "gestion-administrador.productos.cedis" */ '../../pages/retiros/cedis').then(m => m.default || m),
                    },
                    {
                        path: 'leecheros',
                        meta: { nombre: 'Gestión de administrador' },
                        name: 'admin.retiros.leecheros',
                        component: () => import(/* webpackChunkName: "gestion-administrador.productos.leecheros" */ '../../pages/retiros/leecheros').then(m => m.default || m),
                    },
                    {
                        path: 'aprobados',
                        meta: { nombre: 'Gestión de administrador' },
                        name: 'admin.retiros.aprobados',
                        component: () => import(/* webpackChunkName: "gestion-administrador.productos.aprobados" */ '../../pages/retiros/aprobados').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'estadisticas-eliminacion',
                meta: { nombre: 'Gestión de administrador' },
                name: 'admin.retiros.estadisticas-eliminacion',
                component: () => import(/* webpackChunkName: "gestion-administrador.estadisticas-eliminacion" */ '../../pages/retiros/estadisticasEliminacion').then(m => m.default || m),
            },
            {
                path: 'estadisticas-devolucion',
                meta: { nombre: 'Gestión de administrador' },
                name: 'admin.retiros.estadisticas-devolucion',
                component: () => import(/* webpackChunkName: "gestion-administrador.estadisticas-eliminacion" */ '../../pages/retiros/estadisticasEliminacion').then(m => m.default || m),
            },
            {
                path: 'cambio-precio',
                meta: { nombre: 'Gestión de administrador' },
                name: 'admin.retiros.cambio-precio',
                component: () => import(/* webpackChunkName: "gestion-administrador.productos.cedis" */ '../../pages/retiros/cambioPrecio/index').then(m => m.default || m),
                redirect: {name: 'admin.retiros.cambio-precio.lista'},
                children: [
                    {
                        path: 'lista',
                        meta: { nombre: 'Gestión de administrador' },
                        name: 'admin.retiros.cambio-precio.lista',
                        component: () => import(/* webpackChunkName: "gestion-administrador.productos.cedis" */ '../../pages/retiros/cambioPrecio/cambioPrecio').then(m => m.default || m),
                    },
                    {
                        path: 'dashboard',
                        meta: { nombre: 'Gestión de administrador' },
                        name: 'admin.retiros.cambio-precio.dashboard',
                        component: () => import(/* webpackChunkName: "gestion-administrador.productos.leecheros" */ '../../pages/retiros/cambioPrecio/dashboard').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'eliminacion-productos',
                meta: { nombre: 'Gestión de administrador' },
                name: 'admin.retiros.eliminacion-productos',
                component: () => import(/* webpackChunkName: "gestion-administrador.estadisticas-eliminacion" */ '../../pages/retiros/eliminacionProductos').then(m => m.default || m),
            },
            {
                path: 'terminos-condiciones',
                meta: { nombre: 'Gestión de administrador' },
                name: 'admin.retiros.terminos-condiciones',
                component: () => import(/* webpackChunkName: "gestion-administrador.estadisticas-eliminacion" */ '../../pages/retiros/terminosCondiciones').then(m => m.default || m),
            },
            {
                path: 'confirmacion-precio',
                meta: { nombre: 'Gestión de administrador' },
                name: 'admin.retiros.confirmacion-precio',
                component: () => import(/* webpackChunkName: "gestion-administrador.estadisticas-eliminacion" */ '../../pages/retiros/confirmacionPrecio').then(m => m.default || m),
            },

        ]
    },
]
