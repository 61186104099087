export default [
    {
        path: '/admin/tiendas',
        name: 'admin.tiendas',
        redirect: { name: 'admin.tiendas.produccion' },
        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/index-admin').then(m => m.default || m),
        children: [
            {
                path: 'produccion',
                name: 'admin.tiendas.produccion',
                meta: { nombre: 'Vendedores' },
                redirect: { name: 'admin.tiendas.produccion.lista' },
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/index').then(m => m.default || m),
                children: [
                    {
                        path: 'mapa',
                        name: 'admin.tiendas.produccion.mapa',
                        meta: { nombre: 'Vendedores' },
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/mapa').then(m => m.default || m),
                    },
                    {
                        path: 'lista',
                        name: 'admin.tiendas.produccion.lista',
                        meta: { nombre: 'Vendedores' },
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/lista').then(m => m.default || m),
                    },
                    {
                        path: 'geolocalizacion',
                        name: 'admin.tiendas.produccion.geolocalizacion',
                        meta: { nombre: 'Vendedores' },
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/geolocalizacion').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'postulaciones',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.postulaciones',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/postulaciones').then(m => m.default || m)
            },
            {
                path: 'aprobados',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.aprobados',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/aprobados').then(m => m.default || m)
            },
            {
                path: 'aprobados/:id_tienda',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.aprobados.tabs',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/tabs-aprobados').then(m => m.default || m),
                children: [
                    {
                        path: 'informacion',
                        meta: { nombre: 'Vendedores' },
                        name: 'admin.tiendas.aprobados.informacion',
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/informacion').then(m => m.default || m)
                    },
                    {
                        path: 'productos',
                        meta: { nombre: 'Vendedores' },
                        name: 'admin.tiendas.aprobados.productos',
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/productos').then(m => m.default || m)
                    },
                    {
                        path: 'clientes',
                        meta: { nombre: 'Vendedores' },
                        name: 'admin.tiendas.aprobados.clientes',
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/clientes').then(m => m.default || m)
                    },
                ]
            },
            {
                path: 'cambio-datos',
                meta: { nombre: 'Vendedores' },
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.cambio-datos',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/cambioDatos').then(m => m.default || m)
            },
            {
                path: 'inactivos',
                meta: { nombre: 'Vendedores' },
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.inactivos',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/inactivos').then(m => m.default || m)
            },
            
            {
                path: 'denegados',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.denegados',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/denegados').then(m => m.default || m)
            }
        ]
    },
    {
        path: '/produccion/:id_tienda',
        name: 'admin.tiendas.produccion.tabs',
        meta: { nombre: 'Vendedores' },
        redirect: { name: 'admin.tiendas.produccion.informacion' },
        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/tabs').then(m => m.default || m),
        children: [
            {
                path: 'informacion',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.informacion',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/informacion').then(m => m.default || m),
                /* redirect: { name: 'admin.tiendas.produccion.informacion.parametros' }, */
                /* children: [
                ] */
            },
            {
                path: 'parametros',
                name: 'admin.tiendas.produccion.parametros',
                meta: { nombre: 'Vendedores' },
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/parametros').then(m => m.default || m),
            },
            {
                path: 'identificadores',
                name: 'admin.tiendas.produccion.identificadores',
                meta: { nombre: 'Vendedores' },
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/identificadores').then(m => m.default || m),
            },
            {
                path: 'estado-leechero',
                name: 'admin.tiendas.produccion.estado-leechero',
                meta: { nombre: 'Vendedores' },
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/estadoLeechero').then(m => m.default || m),
            },
            {
                path: 'productos',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.productos',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/productos').then(m => m.default || m)
            },
            {
                path: 'metricas',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.metricas',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/metricas').then(m => m.default || m)
            },
            {
                path: 'abastecimiento',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.abastecimiento',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/estadisticas').then(m => m.default || m)
            },
            {
                path: 'calificaciones',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.calificaciones',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/calificaciones').then(m => m.default || m)
            },
            {
                path: 'clientes',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.clientes',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/clientes').then(m => m.default || m)
            },
            {
                path: 'comisiones',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.comisiones',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/comisiones/components/informeLeechero').then(m => m.default || m)
            },
            {
                path: 'pedidos',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.pedidos',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/pedidos').then(m => m.default || m)
            },
            {
                path: 'alianzas',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.alianzas',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/alianzasLeechero/components/informeLeechero').then(m => m.default || m)
            },
            {
                path: 'historial',
                meta: { nombre: 'Leecheros' },
                name: 'admin.tiendas.produccion.historial',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/historial').then(m => m.default || m)
            },
            {
                path: 'creditos',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.produccion.creditos',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/creditos/index').then(m => m.default || m),
                redirect: {name: 'admin.tiendas.produccion.creditos.tenderoVer'},
                children: [
                    {
                        path: 'dashboard',
                        meta: { nombre: 'Vendedores' },
                        name: 'admin.tiendas.produccion.creditos.tenderoVer',
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/almacen/creditos/tenderoVer').then(m => m.default || m),
                    },
                    {
                        path: 'entregas',
                        meta: { nombre: 'Vendedores' },
                        name: 'admin.tiendas.produccion.creditos.entregas',
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/creditos/entregas').then(m => m.default || m),
                    },
                    {
                        path: 'creditos',
                        meta: { nombre: 'Vendedores' },
                        name: 'admin.tiendas.produccion.creditos.creditos',
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/creditos/creditos').then(m => m.default || m),
                    },
                    {
                        path: 'historial-pagos',
                        meta: { nombre: 'Vendedores' },
                        name: 'admin.tiendas.produccion.creditos.historial',
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/creditos/historial').then(m => m.default || m),
                    },
                ]
            },
        ]
    },
    {
        path: '/inactivos/:id_tienda',
        meta: { nombre: 'Vendedores' },
        name: 'admin.tiendas.inactivos.tabs',
        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/tabs-inactivos').then(m => m.default || m),
        children: [
            {
                path: 'informacion',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.inactivos.informacion',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/informacion').then(m => m.default || m),
                redirect: { name: 'admin.tiendas.inactivos.informacion.parametros' },
                children: [
                    {
                        path: 'parametros',
                        name: 'admin.tiendas.inactivos.informacion.parametros',
                        meta: { nombre: 'Vendedores' },
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/parametros').then(m => m.default || m),
                    },
                    {
                        path: 'identificadores',
                        name: 'admin.tiendas.inactivos.informacion.identificadores',
                        meta: { nombre: 'Vendedores' },
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/identificadores').then(m => m.default || m),
                    },
                    {
                        path: 'estado-leechero',
                        name: 'admin.tiendas.inactivos.informacion.estado-leechero',
                        meta: { nombre: 'Vendedores' },
                        component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/estadoLeechero').then(m => m.default || m),
                    },

                ]
            },
            {
                path: 'productos',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.inactivos.productos',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/productos').then(m => m.default || m)
            },
            {
                path: 'metricas',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.inactivos.metricas',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/metricas').then(m => m.default || m)
            },
            {
                path: 'abastecimiento',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.inactivos.abastecimiento',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/estadisticas').then(m => m.default || m)
            },
            {
                path: 'calificaciones',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.inactivos.calificaciones',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/calificaciones').then(m => m.default || m)
            },
            {
                path: 'clientes',
                meta: { nombre: 'Vendedores' },
                name: 'admin.tiendas.inactivos.clientes',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/clientes').then(m => m.default || m)
            },
            {
                path: 'estado-leechero',
                name: 'admin.tiendas.inactivos.estado-leechero',
                meta: { nombre: 'Vendedores' },
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/tiendas/admin/produccion/estadoLeechero').then(m => m.default || m),
            },
        ]
    },
]
