<template>
    <section>
        <div class="row mx-0 mb-4">
            <div class="col-6 d-middle-center" :class="activoFilter === 0 ? 'text-gr-red border-bottom-active' : '' " style="width:180px;height:56px;" @click=" activoFilter = 0">
                Todos
            </div>
            <div class="col-6 d-middle-center" :class="activoFilter === 1 ? 'text-gr-red border-bottom-active' : '' " style="width:180px;height:56px;" @click=" activoFilter = 1">
                Pagos
            </div>
        </div>
        <ValidationObserver ref="validacion">
            <div v-if="activoFilter === 0" class="row mx-0 text-general px-2">
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required|max:500" name="comentario">
                        <el-input
                        v-model="form.comentario"
                        placeholder="Por favor, escriba su comentario"
                        type="textarea"
                        :rows="3"
                        :maxlength="caracteres"
                        show-word-limit
                        />
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
        <div v-if="activoFilter === 1" class="row mx-0 justify-center">
            <div class="bg-grr-red text-white cr-pointer d-middle-center br-12 py-1 mb-3" style="width:200px;" @click="AbrirReportarPago(id_tienda)">
                Reportar Pago
            </div>
        </div>
        <div v-if="activoFilter === 0" class="row mx-0 px-3 justify-content-end my-2">
            <button slot="reference" type="button" class="btn btn-sm br-20 text-white bg-gr-general" name="button" @click="crearComentario">
                Comentar
            </button>
        </div>
        <div class="overflow-auto custom-scroll" style="max-height:calc(100vh - 200px)">
            <div v-for="(info, idx) in listaFiltrada" :key="idx" class="row mx-0 px-2 my-3 no-gutters">
                <img
                class="rounded-circle obj-cover"
                height="32"
                width="32"
                :src="_.defaultTo(info.foto_miniatura, '/img/no-imagen/sin_user.png')"
                />
                <div class="col chat-bubble ml-3" :class="info.tipo == 1 ? 'chat-bubble-pay' : '' ">
                    <div class="row mx-0 my-1 f-13 text-muted">
                        <div class="col-6 f-600">
                            <span class="f-16" v-text="info.nombre" />
                        </div>
                        <div v-if="info.tipo == 1" class="col-12">
                            <p class="f-15 f-600" style="color:#0892DD;">
                                Plan: {{ info.tipo_plan }} - {{ separadorNumero(info.valor_plan) }}
                            </p>
                            <p class="f-15 f-600" style="color:#0892DD;">
                                Válido hasta  {{ info.fecha_valido }}
                            </p>
                        </div>
                        <div class="col-12">
                            <!-- eslint-disable-next-line vue/no-v-html -->
                            <p class="f-15" v-html="formatearTextoHtml(info.comentario)" />
                        </div>
                        <div class="col-12 text-right">
                            <i class="text-muted" v-text="info.fecha" />
                        </div>
                    </div>
                    <div class="chat-bubble-arrow-left" />
                </div>
            </div>
            <div v-if="!listaFiltrada.lenght" class="d-middle-center">
                <div class="text-center f-13" style="width:160px;">
                    <div class="bg-whitesmoke p-2">
                        <img height="60" src="/img/no-imagen/comment.svg" alt="" />
                    </div>
                    <p>No se han hecho comentarios de pago</p>
                </div>
            </div>
        </div>
        <pagosModalCobrar ref="cobrar" @actualizar="recargar" />
    </section>
</template>
​
<script>
import Comentarios from '~/services/comentarios/comentarios'

export default {
    props: {
        titulo: {
            type: String,
            default: 'Comentarios'
        },
        id: {
            type: Number,
            default: 0
        },
        modelo: {
            type: Number,
            default: 0
        },
        caracteres: {
            type: Number,
            default: 500
        },
        contador: {
            type: Number,
            default: 0
        }
    },
    data(){
        return {
            id_tienda: parseInt(this.$route.params.id_tienda),
            activoFilter: 0,
            todos: 1,
            pagos: 2,
            form: {
                comentario: null,
                tipo: 2
            },
            listado: []
        }
    },
    computed: {
        listaFiltrada(){
            if (this.activoFilter === 0){
                return this.listado
            } else {
                return this.listado.filter(e => e.tipo === this.activoFilter)
            }
        }
    },
    watch: {
        id(val){
            this.id = val
            this.listarComentarios()
        }
    },
    mounted(){
        this.listarComentarios()
    },
    methods: {
        toggle(){
            this.$refs.comentarios.toggle()
        },
        async crearComentario(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const { data } = await Comentarios.PostCrearComentario(this.id, this.modelo, this.form)
                this.notificacion('Éxito', data.mensaje, 'success')
                this.limpiar()
                this.listarComentarios()
            } catch (error){
                this.error_catch(error)
            }
        },
        async listarComentarios(){
            try {
                this.cargando = true
                const { data } = await Comentarios.GetListarComentario(this.id, this.modelo)
                this.listado = data.lista
            } catch (error){
                this.error_catch(error)
            } finally {
                this.cargando = false

            }
        },
        AbrirReportarPago(id_tienda){
            this.$refs.cobrar.toggle(id_tienda);
        },
        recargar(){
            this.listarComentarios()
            this.$emit('recargar')
        },
        limpiar(){
            this.$refs.validacion.reset()
            this.form.comentario = null
        }
    }
}
</script>
​
<style lang="scss" scoped>
.chat-bubble{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 1px solid #F8FCFF;
}
.chat-bubble-pay{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 2px solid #0892DD;
}
.chat-bubble-right{
    background: #f9f9f9;
    border: 1px solid red;
}
.border-bottom-active{
    border-bottom: 3px solid #FF2525;
}
</style>
