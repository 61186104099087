<template>
    <div class="row mx-3 justify-content-md-center justify-content-sm-center f-15 px-3 bg-white br-b-12">
        <div v-if="datos.length>0" class="content-box-left mt-3" style="width:170px;">
            <div class="br-10 p-2 text-general my-2 text-center" style="width:140px;background-color:#F6F9FB;">
                <p>{{ datos[0].nombre }}</p>
                <p class="f-17 f-600"> {{ formatear(datos[0].valor, datos[0].formato) }} </p>
                <p v-show="datos[0].fecha">{{ datos[0].fecha }}</p>
            </div>
            <div class="br-10 p-2 text-general my-2 text-center" style="width:140px;background-color:#F6F9FB;">
                <p>{{ datos[1].nombre }}</p>
                <p class="f-17 f-600">{{ formatear(datos[1].valor, datos[1].formato) }}</p>
                <p v-show="datos[1].fecha">{{ datos[1].fecha }}</p>
            </div>
            <div v-if="datos.length==3" class="br-10 p-2 text-general my-2 text-center" style="width:140px;background-color:#F6F9FB;">
                <p>{{ datos[2].nombre }}</p>
                <p class=" f-600">{{ datos[2].fecha }}</p>
                <p>{{ formatear(datos[2].valor, datos[2].formato) }}</p>
            </div>
        </div>
        <div class="col-xl col-lg col-md-12 col-sm-12 col-12 content-box-center position-relative">
            <!-- <p>{{chart.dataset.source.length}}</p> -->
            <!-- <echart v-if="chart.dataset.source.length" ref="dentroChart" :options="chart" /> -->
            <echart ref="dentroChart" :options="chart" />
            <div
            v-if="chart.dataset.source.length == 0"
            class="position-absolute h-100 w-100"
            style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;"
            />
            <div v-if="chart.dataset.source.length == 0" class="img-sindatos text-center">
                <img class="obj-cover" src="/img/otros/no_datos.svg" />
            </div>
            <!-- <p class="mensaje">{{ mensaje }}</p> -->
        </div>
        <div v-if="datos.length>3" class="content-box-right mt-3" style="width:170px;">
            <div class="br-10 p-2 my-2 text-center text-general" style="width:140px;background-color:#F6F9FB;">
                <p>{{ datos[2].nombre }}</p>
                <p class="f-600">{{ formatear(datos[2].valor, datos[2].formato) }}</p>
                <p v-show="datos[2].fecha" class="f-600">{{ datos[2].fecha }}</p>
            </div>
            <div class="br-10 p-2 my-2 text-center text-general" style="width:140px;background-color:#F6F9FB;">
                <p>{{ datos[3].nombre }}</p>
                <p class="f-600">{{ formatear(datos[3].valor, datos[3].formato) }}</p>
                <p v-show="datos[3].fecha" class="f-600">{{ datos[3].fecha }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        datos: {
            type: Array,
            default: () => {
                return []
            }
        },
        chart: {
            type: Object,
            default: () => {
                return {}
            }
        },
        mensaje:{
            type: String,
            default: 'Aún no se han registrado datos'
        },
        handleResize:{
            type: Boolean,
            default: false,
        }
    },
    watch: {
        handleResize(val){
            console.log(val);
            this.$refs.dentroChart.handleResize()
        }
    },
    methods: {
        formatear(valor, formato){
            switch (formato){
            case 1:
                return this.separadorNumero(valor)
                break;
            case 2:
                return this.agregarSeparadoresNumero(valor)
                break;
            case 3:
                return valor
                break;
            default:
                return valor
                break;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }


    @media only screen and (min-width : 768px) and (max-width : 1000px) {
        .content-box-left{
            width: auto !important;
            order: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            div{
                height: fit-content;
                margin-left: 8px;
                margin-right: 8px;
            }
        }
        .content-box-center{
            order: 3;
            /* width: 140px !important; */
        }
        .content-box-right{
            width: auto !important;
            order: 2;
            display: flex;
            justify-content: center;
            div{
                margin-left: 8px;
                margin-right: 8px;
            }
        }
    }

    @media (min-width : 320px) and (max-width : 480px) {
        .img-sindatos img{
            max-width: 100% !important;
        }
    }

    @media (max-width:768px){
        .content-box-left{
            width: auto !important;
            order: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            div{
                margin-left: 8px;
                margin-right: 8px;
            }
        }
        .content-box-center{
            order: 3;
        }
        .content-box-right{
            width: auto !important;
            order: 2;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            div{
                margin-left: 8px;
                margin-right: 8px;
            }
        }
    }

</style>
