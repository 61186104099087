export default [
    {
        path: '/recompensas',
        meta: { nombre: 'Reclamos' },
        name: 'vendedor.recompensas',
        component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/recompensas').then(m => m.default || m),
    },
    {
        path: '/recompensas/misiones-mes',
        meta: { nombre: 'Reclamos' },
        name: 'vendedor.recompensas.misiones-mes',
        component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/recompensas/misionesMes').then(m => m.default || m),
    },
]