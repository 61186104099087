export const state = {
    proveedor: {
        id: null,
        ciudad: null,
        created_at: null,
        created_by: null,
        direccion: null,
        estado: null,
        id_pais: null,
        idm_ciudad: null,
        imagen: null,
        pais: null,
        departamento: null,
        logo: null,
        nit: null,
        nombre: null,
        observaciones: null,
        updated_at: null,
        updated_by: null,
    },
    filtroProveedorAplicado: false,
};

export const getters = {
    proveedor: state => state.proveedor,
    filtroProveedorAplicado: state => state.filtroProveedorAplicado,
};

export const mutations = {
    setProveedor: (state, payload) => {
        state.proveedor = payload;
    },
    setFiltroProveedorAplicado: (state, payload) => {
        state.filtroProveedorAplicado = payload;
    },
};

export const actions = {};
