<template>
    <div class="main-layout">
        <menuLateral ref="menuLateral" class="menu-lateral" />
        <navbar @click="lateralMobile" />
        <div class="position-relative app-wrapper px-4 pt-4">
            <child />
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
export default {
    name: 'MainLayout',
    computed: {
        toolbar: {
            get(){
                return this.$store.state.tema.dark
            },
            set(val){
                this.$store.commit('tema/setDark', val)
            }
        }
    },
    methods: {
        color(data){
            const root = document.documentElement
            root.style.setProperty('--general-color', data)
        },
        lateralMobile(){
            this.$refs.menuLateral.showMobileMenu;
            console.log(this.$refs.menuLateral);
        }
    }
}
</script>
<style lang="scss" scoped>

main{
    padding-left: 65px !important
}
.app-wrapper {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    background: #F6F9FB;
    color: #707070;
    height: calc(100vh - 61px);
    width: calc(100vw - 61px);
    left: 60px;
    top: 60px;
    // z-index: 1;
    /* box-shadow: 0 3px 6px 0 #00000029; */
    /* padding-top: 10px;
    padding-left: 13px;
    padding-right: 13px; */
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
/* @media (min-width : 768px) and (max-width : 979px){ */
@media (max-width : 979px){
    .app-wrapper{
        width: 100vw;
        left: 0px !important; 
    }
}
</style>
