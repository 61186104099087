
export const state = {
    cant_novedades: 0,
    id_pedido: 0,
}

export const getters = {
    cant_novedades: state => state.cant_novedades,
    id_pedido: state => state.id_pedido,
}

export const mutations = {
    set_cant_novedades: (state,payload) => {
        state.cant_novedades = payload
    },
    set_id_pedido: (state,payload) => {
        state.id_pedido = payload
    },
}

