import { mapGetters } from 'vuex'
import Helper from '~/services/helper'
import Aws from '~/services/aws'
import moment from 'moment'
import store from '~/store'


export default {
    computed: {
        ...mapGetters({
            $usuario: 'auth/obtenerUsuario',
            $tienda: 'auth/obtenerTienda',
            monedas: 'cedis/monedas',
            cedis_calculo: 'cedis/cedis',
            $config:'x_parametros/configuracion',
        })
    },
    data(){
        return{
            dialogObj: {},
            tipo_chat : null,
            cant_caracteres: 86
        }
    },
    methods: {
        funImagenGeneral(num=0){
            let imagenes = store.getters['x_parametros/imgGenerales']
            const ruta = imagenes[num]
            if(ruta){
                return ruta
            }else{
                return '/img/no-imagen/default.jpg'
            }
        },
        $validar(a){
            Helper.validar(a)
        },
        error_catch(e,referenciaValidador = null){
            Helper.error_catch(e)
            const estadosWarning= [400, 422]
            console.log(e.response);
            if(e.response && estadosWarning.includes(e.response.data.status)){
                this.notificacion(
                    _.get(e.response,'data.titulo',''),
                    _.get(e.response,'data.mensaje','Ha ocurrido un error al realizar la consulta'),
                    'warning'
                )
            } else if (_.defaultTo(e.response.status, false) != 401){
                this.notificacion(
                    _.get(e.response,'data.titulo','Error'),
                    _.get(e.response,'data.mensaje','Ha ocurrido un error al realizar la consulta'),
                    'error'
                )
            }



            if(this.existenValidaciones(e) && referenciaValidador){
                this.$refs[referenciaValidador].setErrors(e.response.data.validaciones)
            }
        },
        existenValidaciones: excepcion => !_.isEmpty(excepcion?.response?.data?.validaciones),
        confirmar(message,title,callback = false,callback2 = false){
            this.$confirm(message,title,{
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                type: 'warning',
                center: true,
                dangerouslyUseHTMLString: true
            }).then(() => {
                if(callback) callback()
            }).catch(() => {
                if(callback2) callback2()
            })
        },
        aceptar(message,title,callback = false){
            this.$confirm(message,title,{
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                // cancelButtonText: 'No',
                type: 'warning'
                // center: true,
            }).then(() => {
                if(callback) callback()
            })
        },
        notificacion(title,message,type = 'info',isHtml = true){
            Helper.notificacion(title,message,type,isHtml)
        },
        crear_formData(data){
            const fd = new FormData()
            for(var key in data){
                if(Array.isArray(data[key])){
                    for(var key2 in Object.entries(data[key])){
                        fd.append(`${key}[${key2}]`,data[key][key2])
                    }
                } else {
                    fd.append(key,data[key])
                }
            }
            return fd
        },
        icon_extension(fileName){
            const fileTypes = {
                image: {
                    extension: ['bmp','gif','jpg','jpeg','png','svg','webp'],
                    icon: 'icon-picture color-IcoImagen'
                },
                pdf: {
                    extension: ['pdf'],
                    icon: 'icon-file-pdf-box color-IcoPdf'
                },
                video: {
                    extension: ['avi','mp4','mpg','mpeg','wmv','div'],
                    icon: 'icon-video color-IcoVideo'
                },
                other: {
                    icon: 'icon-doc-inv color-dark'
                }
            }
            const fileType = fileName.split('.').pop()
            const isImage = fileTypes.image.extension.includes(fileType.toLowerCase())
            const isPdf = fileTypes.pdf.extension.includes(fileType.toLowerCase())
            const isVideo = fileTypes.video.extension.includes(fileType.toLowerCase())
            if(isImage){
                return fileTypes.image.icon
            } else if(isPdf){
                return fileTypes.pdf.icon
            } else if(isVideo){
                return fileTypes.video.icon
            } else {
                return fileTypes.other.icon
            }
        },
        file_type(fileName){
            const fileTypes = {
                image: {
                    extension: ['bmp','gif','jpg','jpeg','png','svg','webp']
                },
                pdf: {
                    extension: ['pdf']
                },
                video: {
                    extension: ['mp4']
                    // extension: ['avi','mp4', 'mpg', 'mpeg', 'wmv', 'div'],
                }
            }
            const fileType = fileName.split('.').pop()
            const isImage = fileTypes.image.extension.includes(fileType.toLowerCase())
            const isPdf = fileTypes.pdf.extension.includes(fileType.toLowerCase())
            const isVideo = fileTypes.video.extension.includes(fileType.toLowerCase())
            if(isImage){
                return 'image/*'
            } else if(isPdf){
                return 'application/pdf'
            } else if(isVideo){
                return 'video/*'
            } else {
                return '*'
            }
        },

        /*
            Otorga el focus al input indicado por referencia dentro de un popover

            @Params:
            * referencia = String (nombre de la referencia del input)
            * index = Entero (índice de la iteración, en caso de estar en un v-for)
        */
        autofocus_popover(referencia,index = 0){
            setImmediate(x => {
                this.$nextTick(() => {
                    const input = this.$refs[referencia]

                    if(input.$el){this.$refs[referencia].$el.children[0].focus()}

                    if(input.length > 0) input[index].focus()
                    else input.focus()
                })
            })
        },

        async uploadCropperImage(file, path = 'img'){
            if(file === null){
                return null
            }
            if(file.image === null){
                return null
            }
            const archivo = Aws.b64toBlob(file.image)
            const name = file.name
            let ruta = `Market/${path}/${Aws.getHash()}.${name.split('.').pop()}`
            const resp = await Aws.getInstance().upload({
                Key: ruta,
                ContentType: archivo.type,
                Body: archivo
            }).on('httpUploadProgress',uploadEvent=>{
                let porcentaje = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                this.mensaje = porcentaje
            }).promise()

            return resp.Key
        },
        /**
         * Función para generar un delay
         * usada en un input tipo buscador
         * @Params:
            * callback = funcion
            * ms = duración en milisengundos del retraso
         */
        delay(callback,ms = 600){
            if(window.retraso) clearInterval(window.retraso)
            window.retraso = setTimeout(callback,ms)
        },

        rutaImagenMapa: () => '/img/otros/mapa-preview.png',

        /** Notificaciones para Chat
         * Tipo:
         *      1 Soporte cliente
         *          casos:
         *          -/admin/soporte/soporte-general (Tab soporte general caht iniciado desde móvil),
         *      2 Pedidos,
         *      3 Tiendas(soporte de un tendero a admin)
        */
        notifiChat(tipo=2, chat={}, info={}){
            try {
                this.tipo_chat = tipo
                let icon = 'icon-message-text'
                let id = null
                let myMensaje = null
                let tituloSpan = null
                let msg = ''
                if(chat.tipo == 1){
                    msg = chat.mensaje
                } else if(chat.tipo == 2){
                    msg = '(Imágen)'
                } else {
                    msg = '(Pdf)'
                }
                switch(tipo){
                case 1:
                    icon = 'icon-message-text'
                    if(msg.length > this.cant_caracteres){
                        msg = msg.slice(0,this.cant_caracteres) + '...';
                    }
                    tituloSpan = `Mensaje soporte cliente`
                    break;
                case 2:
                    icon = 'icon-message-text'
                    id = info.id

                    myMensaje = `${info.nombre ? info.nombre : ''}: ${msg}`
                    if(myMensaje.length > this.cant_caracteres){
                        myMensaje = myMensaje.slice(0,this.cant_caracteres) + '...';
                    }
                    tituloSpan = `Nuevo mensaje -ID ${id}`
                    break;
                case 3:
                    icon = 'icon-lifebuoy'
                    if(msg.length > this.cant_caracteres){
                        msg = myMensaje.slice(0,this.cant_caracteres) + '...';
                    }
                    tituloSpan = `Nuevo mensaje`
                    break;
                default:
                    break;
                }
                const h = this.$createElement
                this.$notify({
                    title: '',
                    dangerouslyUseHTMLString: true,
                    message: h('div',{class: ''},[
                        h('p',{class: ''},[
                            h(
                                'i',
                                {
                                    class: `${icon} text-gr-general cr-pointer`,
                                    on: {
                                        click: this.doSomeThing
                                    }
                                },
                            ),
                            h(
                                'span',
                                {
                                    class: `f-600`,
                                },
                                `${tituloSpan}`
                            ),
                            h(
                                'br',
                            ),
                            h(
                                'p',
                                `${msg}`
                            ),
                        ]),
                    ]),
                    // position: 'bottom-left'
                });

            } catch (e){
                this.error_catch(e)
            }

        },
        doSomeThing(){
            // console.log('doSomeThing',this.tipo_chat);
        },
        calcularMonedaCedis(id_cedis, returnDecimales= false){
            let cedis = this.cedis_calculo.find(e => e.id == id_cedis)
            var id_moneda_cedis = 0
            if(cedis){
                let moneda = this.monedas.find(e => e.id === cedis.idm_moneda)
                if(moneda){
                    return returnDecimales ? moneda.decimales: moneda.id
                }
            }
            return id_moneda_cedis
        },
        calcularSiglaActual(id_cedis){

            let cedis = this.cedis_calculo.find(e => e.id == id_cedis)
            var sigla = '-'
            if(cedis){
                let moneda = this.monedas.find(e => e.id === cedis.idm_moneda)
                if(moneda){
                    return moneda.sigla
                }
            }
            return sigla
        },
        getDecimales(id_cedis){

            let cedis = this.cedis_calculo.find(e => e.id == id_cedis)
            var decimales = 0
            if(cedis){
                let moneda = this.monedas.find(e => e.id === cedis.idm_moneda)
                if(moneda){
                    return moneda.decimales
                }
            }
            return decimales
        },
        calcularSiglaMoneda(idMoneda){

            let sigla = '-'
            let moneda = this.monedas.find(e => e.id === idMoneda)
            if(moneda){
                return moneda.sigla
            }
            return sigla
        },

        toPromise(fn){
            return new Promise(async(resolve) => {
                await fn()
                resolve()
            })
        },

        diaSemana(dia){
            return moment().weekday(1).day(dia+ 1).format('dddd')
        },
        diffMomento(fecha){
            return moment(fecha).fromNow()
        }

    }
}
