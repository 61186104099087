import axios from "axios"
export default {
    getPais: params => axios.get('pais', { params }),
    getEstados: (idPais, params) => axios.get(`${idPais}/estado`, { params }),
    getCiudades: (idEstado, params) => axios.get(`${idEstado}/ciudad`, { params }),
    async getPlaces(google, string){
        if (!string) return
        return new Promise((resolve, reject) => {
            let parse = string.replaceAll("#", " ");
            const autocomplete = new google.places.AutocompleteService();
            autocomplete.getQueryPredictions(
                { input: parse },
                (sugestions, status) => {
                    let arr = [];
                    if (status != google.GeocoderStatus.OK) reject('error');
                    if (status == google.GeocoderStatus.OK) arr = sugestions.map(({ description, place_id }) => ({description,place_id}));
                    resolve(arr);
                }
            );
        });
        // console.log(autocomplete);

        // return axios.get(
        //     `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${parse}&key=AIzaSyCNGYJuggtoQAtYyH5eSXs4GJuXHEA_nQo`
        // );
    },
    getLocationFromBrowser(google){
        return new Promise((resolve, reject) => {
            if (navigator.geolocation){
                navigator.geolocation.getCurrentPosition(async(position) => {
                    let { coords: { latitude: lat, longitude: lng } } = position
                    let obj = await this.getLocationFromLatLng(google, lat, lng);
                    resolve(obj)
                }, (error) => {
                    reject(error)
                })
            }
        })
    },
    getLocationFromLatLng: (google, lat, lng) => {
        return new Promise((resolve, reject) => {
            let city = '', state = '', country = '', postalCode = '', direction ;
            let geocoder = new google.Geocoder();
            geocoder.geocode({ 'latLng': { lat, lng } }, (results, status) => {
            // geocoder.geocode({ 'latLng': { lat: 7.0670476, lng: -73.1097443 } }, (results, status) => { //floridablanca
            // geocoder.geocode({ 'latLng': { lat: 25.686323,  lng: -100.321645 } }, (results, status) => { //mexico
                if (status == google.GeocoderStatus.OK){
                    for (var i = 0; i < results[0].address_components.length; i++){
                        for (var b = 0; b < results[0].address_components[i].types.length; b++){
                            if (results[0].address_components[i].types[0] == "country"){
                                country = results[0].address_components[i].long_name;
                            }
                        }
                        direction = results[0].formatted_address;
                    }
                    console.log({results});
                    let identCity = country == "Mexico" ? 'locality' : 'administrative_area_level_2'
                    // let identState = country == "Mexico" ? 'administrative_area_level_1' : 'administrative_area_level_1'
                    for (var i = 0; i < results[0].address_components.length; i++){
                        for (var b = 0; b < results[0].address_components[i].types.length; b++){
                            if (results[0].address_components[i].types[b] == identCity){
                                city = results[0].address_components[i].long_name;
                                break;
                            }
                        }
                    }

                    for (var i = 0; i < results[0].address_components.length; i++){
                        for (var b = 0; b < results[0].address_components[i].types.length; b++){
                            if (results[0].address_components[i].types[b] == "administrative_area_level_1"){
                                state = results[0].address_components[i].long_name;
                                break;
                            }
                        }
                    }
                    for (var i = 0; i < results[0].address_components.length; i++){
                        for (var b = 0; b < results[0].address_components[i].types.length; b++){
                            if (results[0].address_components[i].types[b] == "postal_code"){
                                postalCode = results[0].address_components[i].long_name;
                                break;
                            }
                        }
                    }

                    // https://maps.googleapis.com/maps/api/place/details/json?place_id=$placeId&fields=geometry&key=AIzaSyCNGYJuggtoQAtYyH5eSXs4GJuXHEA_nQo
                    let obj = {
                        city,
                        state,
                        country,
                        direction,
                        postalCode
                    };
                    resolve(obj)
                } else {
                    reject('No encontrado')
                }
            });
        })
    }
}
