var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"vh-100 bg-white"},[_vm._m(0),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mx-0",class:_vm.error ? 'align-items-center' : 'align-items-end',staticStyle:{"min-height":"calc(100vh - 5px)"}},[(_vm.error)?[_vm._m(1),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"box mt-2"},[_vm._m(2),_c('ValidationObserver',{ref:"validation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"custom-scroll overflow-auto",staticStyle:{"height":"calc(100% - 155px)"}},[_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-12 text-center mb-4"},[_vm._v(" Seleccione un Avatar ")]),_c('div',{staticClass:"avatar-box rounded-circle cr-pointer"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm._.get(_vm.currentAvatar, 'imagen_firmada', '/img/modales/tear_face.svg'),"width":"100%","height":"100%","alt":""},on:{"click":function($event){return _vm.seleccionarAvatar()}}})])]),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-auto"}),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row mx-0 mt-3"},[_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ValidationProvider',[_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" ¿Como quieres que te llamemos? ")]),_c('el-input',{staticClass:"w-100",model:{value:(_vm.model.datos.nombre),callback:function ($$v) {_vm.$set(_vm.model.datos, "nombre", $$v)},expression:"model.datos.nombre"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-12 my-2"},[_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" ¿Cuándo naciste? ")]),_c('ValidationProvider',{attrs:{"rules":"required","vid":"fecha","name":"fecha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","format":"dd - MM - yyyy","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},model:{value:(_vm.model.datos.nacimiento),callback:function ($$v) {_vm.$set(_vm.model.datos, "nacimiento", $$v)},expression:"model.datos.nacimiento"}}),_c('p',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-12 text-center mt-3"},[_vm._v(" Selecciona el género con el que te identificas ")]),_c('div',{staticClass:"col-12 d-middle-center my-3"},[_c('el-radio-group',{model:{value:(_vm.model.datos.genero),callback:function ($$v) {_vm.$set(_vm.model.datos, "genero", $$v)},expression:"model.datos.genero"}},[_c('el-radio',{staticClass:"radio-red",attrs:{"label":1}},[_vm._v(" Mujer ")]),_c('el-radio',{staticClass:"radio-red",attrs:{"label":2}},[_vm._v(" Hombre ")]),_c('el-radio',{staticClass:"radio-red",attrs:{"label":3}},[_vm._v(" Otro ")])],1)],1),_c('div',{staticClass:"col-12 my-2"},[_vm._v(" ¿Donde Vives? ")]),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"pais","name":"país"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" Selecciona el país ")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","remote":"","reserve-keyword":"","remote-method":_vm.getPaises},on:{"change":function($event){return _vm.getEstados()}},model:{value:(_vm.idPais),callback:function ($$v) {_vm.idPais=$$v},expression:"idPais"}},_vm._l((_vm.paises),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.pais,"value":item.id}})}),1),_c('p',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"estado","name":"estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" Selecciona el estado ")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","remote":"","reserve-keyword":"","remote-method":_vm.getEstados},on:{"change":function($event){return _vm.getCiudades()}},model:{value:(_vm.idEstado),callback:function ($$v) {_vm.idEstado=$$v},expression:"idEstado"}},_vm._l((_vm.estados),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.estado,"value":item.id}})}),1),_c('p',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"ciudad","name":"ciudad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" Selecciona la ciudad ")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","remote":"","reserve-keyword":"","remote-method":_vm.getCiudades},on:{"change":_vm.onChangeCity},model:{value:(_vm.model.datos.idm_ciudad),callback:function ($$v) {_vm.$set(_vm.model.datos, "idm_ciudad", $$v)},expression:"model.datos.idm_ciudad"}},_vm._l((_vm.ciudades),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.ciudad,"value":item.id}})}),1),_c('p',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"direccion","name":"dirección"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" Dirección ")]),_c('el-input',{staticClass:"w-100 br-12",model:{value:(_vm.model.direccion.direccion),callback:function ($$v) {_vm.$set(_vm.model.direccion, "direccion", $$v)},expression:"model.direccion.direccion"}}),_c('p',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-12 my-2"},[_c('img',{staticClass:"w-100 cr-pointer br-12 border",attrs:{"src":_vm.rutaImagenMapa()},on:{"click":_vm.abrirModalMapa}})]),_c('div',{staticClass:"col-12 my-2"},[_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" Anexo ")]),_c('el-input',{staticClass:"w-100 br-12",attrs:{"placeholder":"Apto, casa, ofc..."},model:{value:(_vm.model.direccion.direccion_anexo),callback:function ($$v) {_vm.$set(_vm.model.direccion, "direccion_anexo", $$v)},expression:"model.direccion.direccion_anexo"}})],1),_c('div',{staticClass:"col-12 my-2"},[_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" Guardar como ")]),_c('el-input',{staticClass:"w-100 br-12",attrs:{"placeholder":"Apto, casa, ofc..."},model:{value:(_vm.model.direccion.nombre),callback:function ($$v) {_vm.$set(_vm.model.direccion, "nombre", $$v)},expression:"model.direccion.nombre"}})],1),_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"my-3"},[_vm._v(" Crea tus datos de acceso ")]),_c('p',{staticClass:"my-3"},[_vm._v(" Crea tu contraseña ")])]),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"password","name":"contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 f-15"},[_vm._v(" Contraseña ")]),_c('el-input',{staticClass:"w-100 br-12 f-20 password-edit",attrs:{"show-password":""},model:{value:(_vm.model.datos.password),callback:function ($$v) {_vm.$set(_vm.model.datos, "password", $$v)},expression:"model.datos.password"}}),_c('p',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required_if:password|confirmed:password","name":"confirmar contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 f-15"},[_vm._v(" Confirmar Contraseña ")]),_c('el-input',{staticClass:"w-100 br-12",attrs:{"show-password":""},model:{value:(_vm.input3),callback:function ($$v) {_vm.input3=$$v},expression:"input3"}}),_c('p',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-12 my-4"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-auto px-0"},[_c('el-checkbox',{staticClass:"check-red",model:{value:(_vm.terminosCondiciones),callback:function ($$v) {_vm.terminosCondiciones=$$v},expression:"terminosCondiciones"}})],1),_c('div',{staticClass:"col px-0 text-center"},[_vm._v(" He leído y acepto los "),_c('span',{staticClass:"text-gr-general"},[_vm._v("términos y condiciones y Política de privacidad")])])])])])]),_c('div',{staticClass:"col-auto"})])]),_c('div',{staticClass:"row mx-0 justify-center py-2"},[_c('div',{staticClass:"col-8"},[_c('button',{staticClass:"btn btn-block br-12 p-1 py-2 d-flex d-middle-center text-white bg-leeche border-0",attrs:{"disabled":invalid || !_vm.terminosCondiciones},on:{"click":_vm.crear}},[_vm._v(" Crear Perfil ")])])])]}}],null,false,1124149793)})],1)])]:[_c('error-registro',{attrs:{"estado-vista":_vm.error}})],_c('div',{staticClass:"col-auto"}),_c('div',{staticClass:"col-auto"})],2)]),_c('modal-seleccionar-avatar',{ref:"SelectAvatar",on:{"avatar":_vm.avatarSelected}}),_c('mapa-google-modal',{ref:"modalMapaGoogle",staticClass:"centrar-modal",attrs:{"coordenadas-entrada":_vm.coordenadasCiudad},on:{"actualizar":_vm.actualizarCoordenadasCiudad}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-100 position-absolute container-img"},[_c('div',{staticClass:"img-background"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col text-left px-5"},[_c('p',{staticClass:"text-leeche text-center f-30 f-600 my-4 mt-5"},[_vm._v(" Tu registro se ha realizado exitosamente ")]),_c('p',{staticClass:"text-muted2 text-center f-30 my-2 mb-5"},[_vm._v(" Accede a "),_c('span',{staticClass:"f-600"},[_vm._v(" la App ")]),_vm._v(" y disfuta de nuestros descuentos y promociones. ")]),_c('img',{staticClass:"obj-cover ml-4 img-height",staticStyle:{"max-width":"100%","height":"350px"},attrs:{"src":"/img/icons/Grupo 19404.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mx-0 justify-center mt-3"},[_c('div',{staticClass:"col-auto text-center"},[_c('img',{staticClass:"obj-cover",staticStyle:{"max-width":"200px","max-height":"50px"},attrs:{"src":"/img/auth/logo_rojo.svg"}}),_c('p',{staticClass:"text-muted2 f-600 mt-3"},[_vm._v(" En la App queremos saber de ti ")])])])}]

export { render, staticRenderFns }