export default [
    {
        path: '/admin-vip/configurar',
        meta: { nombre: 'Configuración de leecheros' },
        name: 'admin-vip.conf',
        redirect: { name: 'admin-vip.conf.tipoTiendas' },
        component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin-vip').then(m => m.default || m),
        children: [
            {
                path: 'tipo-tiendas',
                name: 'admin-vip.conf.tipoTiendas',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/tipoTiendas').then(m => m.default || m),
            },
            {
                path: 'motivo-inactivo',
                name: 'admin-vip.conf.motInactivo',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motInactivo').then(m => m.default || m),
            },
        ]
    }
]
