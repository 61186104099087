import axios from 'axios'

export const state = {
    tienda:{},
    satisfaccion:0
};

export const getters = {
    tienda: state => state.tienda,
    satisfaccion: state => state.satisfaccion
};

export const mutations = {
    set_navbar_tendero: (state, payload) => {
        state.tienda = payload;
    },
    set_navbar_admin: (state, payload) => {
        state.satisfaccion = payload;
    }
};

export const actions = {
    async navbar_tendero({ commit }){
        const { data } = await axios(`admin-tiendas/navbar-tendero`)
        commit("set_navbar_tendero", data.tienda);
    },
    async navbar_admin({ commit }){
        const { data } = await axios(`admin-tiendas/navbar-admin`)
        commit("set_navbar_admin", data.satisfaccion);
    },
    async abrir_tienda({ commit },id_tienda){
        const { data } = await axios.put(`admin-tiendas/${id_tienda}/abrir-cerrar-tienda`)

    },
};
