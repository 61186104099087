import axios from 'axios'

const parametros = {
    get_parametros: () => axios.get("x-parametros/get-parametros"),
    get_parametros_publico: () => axios.get("x-parametros/get-parametros-publico"),
    crear_parametro: datos => axios.post("x-parametros/crear-parametro", datos),
    eliminar_parametro: (id) => axios.delete(`x-parametros/eliminar-parametro/${id}`),
    getParamsGenerales: () => axios.get("parametros/generales"),
};
export default parametros
