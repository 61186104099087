import axios from 'axios'

const API_PREFIX = 'cedis'

const ENDPOINTS = {
    get_cedis: params => axios(`${API_PREFIX}/get-cedis`, { params }),
    get_cedis_disponibles: params => axios(`${API_PREFIX}/get-cedis-disponibles`),
    info_cedis: (params) => axios(`${API_PREFIX}/${params.id_cedis}/cedis`, { params }),
    get_responsables: () => axios(`${API_PREFIX}/get-responsables`),
    crear_cedis: params => axios.post(`${API_PREFIX}/cedis`, params),
    editar_cedis: params => axios.put(`${API_PREFIX}/${params.id}/cedis`, params),
    delete_cedis: id => axios.delete(`${API_PREFIX}/${id}/cedis`),
    getFormaPagos: () => axios(`${API_PREFIX}/get-forma-pagos`),
    getInfoCedis: (idCedis) => axios(`${API_PREFIX}/${idCedis}/editar`),
    actualizarParametroCedi(idCedis, payload){
        return axios.put(`${API_PREFIX}/${idCedis}/parametros/actualizar`, payload);
    },
    getClasificaciones: () => axios(`${API_PREFIX}/get-clasificaciones`),
};

export default ENDPOINTS
