<template>
    <section>
        <navbar-auth />
        <div id="sign-in-button" />
        <div
        class="container-fluid px-0 position-relative"
        style="height: calc(100vh - 70px);background-color:#F5F5F6;"
        >
            <div
            class="position-absolute w-100 h-100 image-background"
            :style="`background-image: url('${ _.isEmpty(urlBackground) ? '/img/no-imagen/sin_imagen.png' : urlBackground }');`"
            />
            <div class="d-middle justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center mx-0 h-100">
                <!-- Box -->
                <div class="col-auto px-0 d-flex flex-column justify-content-center mr-4">
                    <ValidationObserver ref="validation" tag="div" :class="`box ${seccionIngreso ? '' : 'codigo'} custom-scroll`" style="background-color:white">
                        <!-- <div class="box custom-scroll"> -->
                        <template v-if="ingresoLeechero">
                            <template v-if="seccionIngreso">
                                <div class="row mx-0 mb-4 mt-3 pt-xl-4 pt-lg-4 pt-md-3 pt-sm-2 pt-0">
                                    <div class="col-12 text-general text-center f-600" style="font-size:25px;">
                                        Ser un nuevo {{ $config.vendedor }}
                                    </div>
                                </div>
                                <div v-loading="loading" class="row mx-0 mt-xl-4 mt-lg-4 mt-md-2 mt-sm-2 mt-1">
                                    <div class="col px-xl-5 px-lg-4 px-md-3 px-sm-2">
                                        <div class="border d-middle text-general location-box mb-2 cr-pointer" @click="toggleModalUbicacion()">
                                            <img
                                            :src="getBandera"
                                            width="33"
                                            style="max-height:100%;"
                                            class="rounded-circle flag mx-2"
                                            />
                                            <p v-show="!_.isEmpty(localizacionConcat)" class="mr-2  tres-puntos text-position">
                                                Estás en {{ localizacionConcat }}
                                            </p>
                                            <i class="icon-angle-down f-18 text-general2 ml-auto mr-2" />
                                        </div>
                                        <div class="row mx-0 my-3">
                                            <ValidationProvider
                                            v-slot="{ errors }"
                                            tag="div"
                                            class="col-xl-6 col-lg-12 col-md-12 col-sm-12 pl-0 pr-xl-3 pr-lg-0 pr-md-0 pr-sm-0 pr-0 mb-xl-0 mb-lg-2 mb-md-2 mb-sm-2 mb-2"
                                            rules="required"
                                            name="nombres"
                                            >
                                                <el-input v-model="nombre" placeholder="Nombres" class="br-6" />
                                                <span
                                                class="text-danger f-11 ml-3"
                                                >{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            <!-- <div class="col-xl-6 col-lg-10 col-md-12 col-sm-12">
                                            </div> -->
                                            <ValidationProvider
                                            v-slot="{ errors }"
                                            tag="div"
                                            class="col-xl-6 col-lg-12 col-md-12 col-sm-12 pr-0  pl-xl-3 pl-lg-0 pl-md-0 pl-sm-0 pl-0 mt-xl-0 mt-lg-0 mt-md-2 mt-sm-2 mt-2"
                                            rules="required"
                                            name="apellidos"
                                            >
                                                <el-input v-model="apellido" placeholder="Apellidos" class="br-6" />
                                                <span
                                                class="text-danger f-11 ml-3"
                                                >{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            <!-- <div class="col-xl-6 col-lg-10 col-md-12 col-sm-12">
                                            </div> -->
                                        </div>
                                        <div v-if="[1, 2].includes(tipo_ingreso)" class="row mx-0 my-4">
                                            <div class="col px-0">
                                                <ValidationProvider v-slot="{ errors }" :rules="`required|${tipo_ingreso == 1? 'numeric|min:10': 'email'}`" vid="usuario" :name="tipo_ingreso == 1? 'teléfono': 'correo'">
                                                    <el-input
                                                    v-model="input"
                                                    class="w-100 br-6 input-registro"
                                                    placeholder="Número celular"
                                                    :class="{'input-login-edit': tipo_ingreso == 1}"
                                                    >
                                                        <template v-if="tipo_ingreso == 1" slot="prepend">
                                                            <div class="d-flex justify-content-around d-middle">
                                                                <img :src="getBandera" alt width="25" height="25" class="rounded-circle" />
                                                                <span>
                                                                    +{{ getPrefijo }}
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </el-input>
                                                    <span
                                                    class="text-danger f-11"
                                                    >{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>

                                        <div class="row mx-0 justify-center mt-4">
                                            <div class="col-auto">
                                                <el-checkbox
                                                v-model="checked"
                                                class="check-red"
                                                />
                                            </div>
                                            <div class="col-auto f-15 text-general">
                                                Aceptar
                                                <router-link
                                                target="_blank"
                                                :to="{ name: 'login.leechero.terminos-condiciones', }"
                                                class="text-general"
                                                >
                                                    <span class="text-green">
                                                        Términos & condiciones
                                                    </span>
                                                    y
                                                    <br />
                                                    <span class="text-green">Políticas de privacidad</span>
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="row mx-0 justify-center mt-4 mb-3">
                                            <div class="col-12 px-0">
                                                <!-- <errorAlert /> -->
                                                <error-alert v-if="showErrorAlert" :tipo="errorAlertType" />

                                                <button
                                                :disabled="!checked"
                                                class="btn-general w-100"
                                                style="height:40px"
                                                @click="registrar()"
                                                >
                                                    Enviar
                                                </button>
                                            </div>
                                            <div class="col-12 px-0 mt-3">
                                                <div class="btn-outline" style="height:40px;" @click="ingresoLeechero = !ingresoLeechero">
                                                    Ya soy {{ $config.vendedor }}, <span class="text-green ml-2">Ingresar</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <verificar-codigo v-else />
                        </template>
                        <form-normal v-else />
                        <!-- </div> -->
                    </ValidationObserver>
                </div>
                <!-- Box -->
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalUbicacion" titulo="Tu ubicación" icon="location" center-icon @guardar="onAccept()">
            <ValidationObserver ref="formUbi">
                <div class="row mx-0 justify-center">
                    <div class="col-auto" />
                    <div class="col">
                        <div class="text-center">
                            Selecciona tu ubicación
                        </div>
                        <div class="row justify-center mx-0">
                            <div class="col-10 px-0 position-relative">
                                <div class="row mx-3 text-general f-15">
                                    País
                                </div>
                                <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                                name="país"
                                >
                                    <el-select
                                    v-model="idm_pais"
                                    placeholder="Seleccione un país"
                                    filterable
                                    class="w-100"
                                    @change="consultarEstadosProyecto"
                                    >
                                        <el-option
                                        v-for="(item, i) in proyecto.paises"
                                        :key="i"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-danger f-11">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-10 px-0 position-relative mt-2">
                                <div
                                class="row mx-3 text-general text-general f-15"
                                >
                                    Estado
                                </div>
                                <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                                name="estado"
                                >
                                    <el-select
                                    v-model="idm_estado"
                                    placeholder="Seleccione un estado"
                                    class="w-100"
                                    filterable
                                    @change="consultarCiudadesProyecto"
                                    >
                                        <el-option
                                        v-for="(item, i) in proyecto.estados"
                                        :key="i"
                                        :label="item.estado"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-danger f-11 pl-3">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-10 px-0 position-relative mt-2">
                                <div class="row mx-3 text-general f-15">
                                    Ciudad
                                </div>
                                <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                                vid="idm_ciudad"
                                name="ciudad"
                                >
                                    <el-select
                                    v-model="idm_ciudad"
                                    placeholder="Seleccione una ciudad"
                                    class="w-100"
                                    filterable
                                    >
                                        <el-option
                                        v-for="(item, i) in proyecto.ciudades"
                                        :key="i"
                                        :label="item.ciudad"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-danger f-11 pl-3">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto" />
                </div>
            </ValidationObserver>
        </modal>
        <modal-codigo-verificacion ref="CodigoVerificacion" />
    </section>
</template>

<script>
import authService from "~/services/auth";
import firebase from "~/library/firebase";
import { mapGetters, mapActions, mapMutations } from "vuex";
import localizacion from "~/services/localizacion";
import ServicioApi from "~/services/tiendas/postulacion";
import registroService from '~/services/registro/registro';

export default {
    layout: "login",
    components: {
        modalCodigoVerificacion: () => import("./partials/modalCodigoVerificacion"),
        formNormal: () => import("./partials/form"),
        errorAlert: () => import("./partials/errorAlert"),
        verificarCodigo: () => import("./partials/verificarCodigo"),
    },
    data(){
        return {
            input: "",
            tipo_ingreso: 1,
            checked: false,
            estado_validacion: false,
            ingresoLeechero: true,
            seccionIngreso: true,
            loading: false,
            idPais: "",
            paises: [],
            nombre: "",
            apellido: "",
            location: {},
            proyecto: {
                datos: {},
                paises: [],
                estados: [],
                ciudades: [],
                seleccion: {
                    pais: "",
                    estado: "",
                    ciudad: "",
                },
            },
            idm_pais: "",
            idm_estado: "",
            idm_ciudad: "",
            localizacionConcat: "",
            indice: 0,
            showErrorAlert: false,
            errorAlertType: 1
        };
    },
    computed: {
        ...mapGetters({
            imgVendedor: 'x_parametros/imgVendedor'
        }),
        leecheroPeople(){
            if(!this.seccionIngreso){
                return this.imgVendedor.verificacion; 
            }
            let direction = this.ingresoLeechero ? this.imgVendedor.registro : this.imgVendedor.login;
            return direction
        },
        urlBackground(){
            return this.leecheroPeople[this.indice];
        },
        getBandera(){
            const { bandera_firmada = "/img/no-imagen/default.jpg" } = this.proyecto.paises.find((el) => el.id == this.idm_pais) ?? {};
            return bandera_firmada;
        },
        getPrefijo(){
            const { indicativo = "00" } = this.proyecto.paises.find((el) => el.id == this.idm_pais) ?? {};
            return indicativo;
        },
        
    },
    watch: {
        "proyecto.paises"(valores){
            this.tomarPrimerRegistroArray(valores, "pais", this.idm_pais);
        },
        "proyecto.estados"(valores){
            this.tomarPrimerRegistroArray(valores, "estado", this.idm_estado);
        },
        "proyecto.ciudades"(valores){
            this.tomarPrimerRegistroArray(valores, "ciudad", this.idm_ciudad);
        },
        "proyecto.seleccion.ciudad"(ciudad){
            // this.model.idm_ciudad = ciudad
        },
        tipo_ingreso(){
            this.input = ""
        }
    },
    async mounted(){
        this.ingresoLeechero = this.$route.params.ingresoLeechero ?? true;

        setInterval(() => {
            this.indice++;
            let cantidad = this.leecheroPeople.length - 1;
            if(this.indice > cantidad ){
                this.indice = 0;
            }
        }, 5000);

        await this.getPaises();
        await this.getLocationFromBrowser();
        await this.consultarProyecto();
        await this.consultarPaisesProyecto(this.proyecto.datos.id);
        this.localizacion();
        await this.consultarEstadosProyecto(this.idm_pais);
        this.localizacion();
        await this.consultarCiudadesProyecto(this.idm_estado);
        this.localizacion();
    },
    methods: {
        ...mapActions({
            intentarIniciarSesion: `auth/intentarIniciarSesion`,
            intentarIniciarSesionSocial: `auth/intentarIniciarSesionSocial`,
            registerWithGoogle: "register/registerWithGoogle",
            registerWithFacebook: "register/registerWithFacebook",
        }),
        ...mapMutations({
            setIdUser: "register/setIdUser",
            setIndicativo: "register/setIndicativo",
            setUsuarioModel: "register/setUsuarioModel",
            setPhoneNumber: "register/setPhoneNumber",
        }),
        toggleModalUbicacion(){
            this.localizacion();
            this.$refs.modalUbicacion.toggle();
        },
        async getPaises(query = ""){
            try {
                let params = {
                    query,
                };
                const { data } = await localizacion.getPais(params);
                this.paises = data;
            } catch (e){
                this.error_catch(e);
            }
        },
        async registrar(){
            try {

                const valid = await this.$refs.validation.validate();

                if (!valid){
                    let errors = Object.keys(this.$refs.validation.errors)
                        .map((el) => `El campo ${el} es requerido <br />`)
                        .join(" ");
                    this.notificacion("", errors, "error");
                    return;
                }

                const validUbi = await this.$refs.formUbi.validate();

                if (!validUbi)
                    return this.notificacion(
                        "",
                        "Debes completar tus datos de ubicación",
                        "error"
                    );

                const { data } = await authService.noExistsUser({usuario: this.input})
                if(data.exists){
                    this.showErrorAlert = true
                    if (data.user.tipo == 5){
                        this.errorAlertType = 2
                        return
                    }

                    if (data.user.tipo == 1){
                        this.errorAlertType = 1
                        return
                    }
                    this.errorAlertType = 3
                    return
                }


                const phoneNumber = `+${this.getPrefijo}${this.input}`
                this.setPhoneNumber(phoneNumber)

                const datos = await registroService.registrar(phoneNumber)
                if(datos){

                    let obj = {
                        usuario: this.input,
                        nombre: this.nombre,
                        apellido: this.apellido,
                        ciudad: this.idm_ciudad,
                        pais: this.idm_pais,
                    }
                    this.setUsuarioModel(obj)
                    this.seccionIngreso = false
                    // this.$refs.CodigoVerificacion.toggle()
                }

                /*const params = {
                    nombre: this.nombre,
                    apellido: this.apellido,
                    usuario: this.input,
                    indicativo: this.getPrefijo,
                    ciudad: this.idm_ciudad,
                    pais: this.idm_pais,
                };
                const { data } = await authService.generarCodigoTienda(params);
                this.setIdUser(data.data.id);
                this.setIndicativo(this.getPrefijo)
                this.$refs.CodigoVerificacion.toggle(true,this.tipo_ingreso == 1); */
                // this.estado_validacion = !this.estado_validacion;

                // console.log(data);
            } catch (error){
                this.estado_validacion = true;
                this.error_catch(error, "validation");
            }
        },
        async onRegisterWithGoogle(){
            const validUbi = await this.$refs.formUbi.validate();
            if (!validUbi) return this.notificacion("", "Debes completar tus datos de ubicación", "error");


            if (!this.nombre || !this.apellido){
                let errors = ["nombre", "apellido"].map((el) => `El campo ${el} es requerido <br />`)
                    .join(" ");
                this.notificacion("", errors, "error");
                return;
            }

            const proveedor = new firebase.auth.GoogleAuthProvider();
            proveedor.addScope("email");
            this.abrirPopoverConexion(proveedor);
        },
        async onRegisterWithFacebook(){
            const validUbi = await this.$refs.formUbi.validate();
            if (!validUbi) return this.notificacion("", "Debes completar tus datos de ubicación", "error");

            const proveedor = new firebase.auth.FacebookAuthProvider();
            proveedor.addScope("email");
            this.abrirPopoverConexion(proveedor);
        },
        async abrirPopoverConexion(proveedor){
            try {
                this.toggleLoading();
                let canContinue;
                const { credential: credenciales, user,} = await firebase.auth().signInWithPopup(proveedor);

                if (!credenciales) return;

                const { nombre, apellido, idm_ciudad, idm_pais } = this

                const payload = {
                    token: credenciales.accessToken,
                    nombre,
                    apellido,
                    idm_ciudad,
                    idm_pais,

                };
                let isGoogle = /google.com/.test(proveedor.providerId);
                if (isGoogle){
                    canContinue = await this.registerWithGoogle(payload);
                } else {
                    canContinue = await this.registerWithFacebook(payload);
                }

                if (canContinue){
                    this.$router.push({ name: 'registrado' })
                }
            } catch (error){
                console.error(error);
            } finally {
                this.toggleLoading();
            }
        },
        goToLogin(){
            this.$router.push({ name: "login.form.admin" });
        },
        toggleLoading(){
            this.loading = !this.loading;
        },
        async getLocationFromBrowser(){
            try {
                let googleMaps = await this.$iniciarGoogleMaps();
                this.location = await localizacion.getLocationFromBrowser(googleMaps);
            } catch (error){
                this.location = {}
            }
        },
        async consultarProyecto(){
            try {
                const { data: { data }, } = await ServicioApi.consultarProyecto();
                this.proyecto.datos = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        async consultarPaisesProyecto(proyecto){
            try {
                const { data: { data }, } = await ServicioApi.consultarPaises(proyecto);
                this.proyecto.paises = data;
                this.selectDefault("paises", "idm_pais", "country");
            } catch (error){
                this.error_catch(error);
            }
        },
        async consultarEstadosProyecto(pais = null){
            if (!pais) return;
            try {
                const { data: { data }, } = await ServicioApi.consultarEstados({
                    proyecto: this.proyecto.datos.id,
                    pais,
                });
                this.proyecto.estados = data;
                this.selectDefault("estados", "idm_estado", "state");
            } catch (error){
                this.error_catch(error);
            }
        },
        async consultarCiudadesProyecto(estado = null){
            if (!estado) return;
            try {
                const { data: { data }, } = await ServicioApi.consultarCiudades({
                    proyecto: this.proyecto.datos.id,
                    estado,
                });
                this.proyecto.ciudades = data;
                this.selectDefault("ciudades", "idm_ciudad", "city");
            } catch (error){
                this.error_catch(error);
            }
        },
        selectDefault(array, variable, location){
            if (!this.location[location]) return "";
            let find = this.proyecto[array].find((el) => el.nombre.toLowerCase() == this.location[location].toLowerCase());
            this[variable] = find ? find.id : "";
        },
        tomarPrimerRegistroArray(valores, objetivo, defecto = null){
            if (!_.isEmpty(valores))
                this.proyecto.seleccion[objetivo] = defecto ?? valores[0].id;
        },
        localizacion(){
            const findFun = (el, type, idDefault) =>{
                return !_.isNil(idDefault) ? el.id == idDefault :  _.isEmpty(this.location) || !this.location[type] ? true : el.nombre.toLowerCase().match(this.location[type].toLowerCase())
            };

            const countryDefault = (this.proyecto.paises.length && this.proyecto.paises[0]) || { nombre: "", id: "" };
            const stateDefault = (this.proyecto.estados.length && this.proyecto.estados[0]) || { nombre: "", id: "" };
            const cityDefault = (this.proyecto.ciudades.length && this.proyecto.ciudades[0]) || { nombre: "", id: "" };
            const country = this.proyecto.paises.find((el) => findFun(el, "country", this.idm_pais),) ?? countryDefault;
            const state = this.proyecto.estados.find((el) => findFun(el, "state", this.idm_estado),) ?? stateDefault;
            const city = this.proyecto.ciudades.find((el) => findFun(el, "city", this.idm_ciudad),) ?? cityDefault;
            this.idm_pais = this.idm_pais || country.id;
            this.idm_estado = this.idm_estado || state.id;
            this.idm_ciudad = this.idm_ciudad || city.id;

            this.localizacionConcat = [city.ciudad, state.estado, country.nombre, ].filter((el) => el).join();
        },
        async onAccept(){
            let valid = await this.$refs.formUbi.validate();
            if (valid){
                this.$refs.modalUbicacion.toggle();
                let country = this.proyecto.paises.find((el) => el.id == this.idm_pais);
                let state = this.proyecto.estados.find((el) => el.id == this.idm_estado);
                let city = this.proyecto.ciudades.find((el) => el.id == this.idm_ciudad);
                this.location.country = country.nombre;
                this.location.state = state.nombre;
                this.location.city = city.nombre;
                this.localizacion();
            }
        },

    },
};
</script>

<style lang="scss" scoped>
a{text-decoration: none !important;}
.image-background{
    z-index:0;
    background-size:cover;
    background-position: left center;
    background-attachment: fixed;
    background-repeat:no-repeat;
    transition: all 1s ease-out !important;
}


.term-movil{display: none;}
.navbar-postulacion{
    top: 0px;
    left: 0px;
    width: calc(100vw);
    height: 70px;
    display: flex;
    align-items: center;
    background-color: var(--color-general);
    box-shadow: 0px 2px 8px #00000029;
}
.box{
    width: 423px;
    box-shadow: 0px 1px 4px #00000014;
    min-height: 500px;
    border-radius: 12px;
    padding-top: 0px !important;
    transition: height 1s ease;
    &.codigo{
        height: 404px !important;
    }
}
.social-box {
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000080;
    width: 175px;
    height: 35px;
}
.location-box {
    border-radius: 6px;
    background-color: #F6F9FB;
    height: 44px;
}

.tab {
    color: #5d5d5d;
    font-weight: 500px;
    border-bottom: 4px solid #f5f5f5;
    cursor: pointer;
}

.arrow-oh {
    border-left: 25px solid transparent;
    width: 0px;
    height: 0px;
    border-top: 25px solid transparent;
    border-right: 25px solid white;
    left: -46px;
    top: 85px;
    z-index: 1;
    border-bottom: 25px solid transparent;
}

.tab-active {
    border-bottom: 4px solid #ff3737;
    cursor: pointer;
}
.br-12 {
    border-radius: 12px !important;
}
.space-flag{
    width:50px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.text-green{
    color: #28D07B !important;
}


/* -*-*-*-*- Mobile -*-*-*-*- */
@media only screen and (min-device-width:320px) and (max-device-width:480px){
    .navbar-home{
        justify-content: center;
        .space-navbar{
            display: none !important;
        }
    }
    .location-box{
        text-align: center;
        height: 50px !important;
        display: flex !important;
        .text-position{
            white-space: initial !important;
            text-overflow: initial !important;
            overflow: initial !important;
        }
    }
    .container-ilustration{
        display: none !important;
    }
    .box{
        width: 100%;
        background-color: inherit !important;
        box-shadow: inherit !important;
    }
    .grid-login{
        grid-template-columns: 1fr;
    }
}




@media (max-width : 1224px) {
    .box{
        width: 423px;
        height: auto !important;
        .box-logo{
            display: none !important;
        }
    }
    .space{
        display: none !important;
    }
    .image-background{
        display: none !important;
    }
    .space-image{
        display: none !important;
    }
    .grid-login{
        grid-template-columns: 1fr 1fr;
    }
}

/* iPads */
@media (min-width : 768px) and (max-width : 1024px) {
    .container-fluid .row{
        justify-content: center;
    }
    .navbar-home{
        justify-content: center !important;
        .space-navbar{
            display: none;
        }
    }
    .box{
        width: 374px;
    }
}
</style>
