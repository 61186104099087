import axios from 'axios'

const API = 'soporte'

const SOPORTE = {
    listarTiendas(payload = {}){
        return axios(`${API}/listar-tiendas`,{params: payload})
    },
    acticarInactivarChat(payload = {}){
        return axios.put(`${API}/activar-inactivar-chat`, payload)
    },
    listarUsersClientes(payload = {}){
        return axios(`${API}/listar-users-cleintes`,{params: payload})
    },
    finalizarChat(payload = {}){
        return axios.post(`${API}/finalizar-chat`,payload)
    },


}

export default SOPORTE
