import axios from 'axios'
const api = "cedis/predefinidos"

const cedisDefecto = {
    getData: () => axios.get(`${api}/index`),
    fetchCedis: () => axios.get(`${api}/get-cedis`),
    buscarCiudad: ( params ) => axios.get(`${api}/buscar-ciudad`, params),
    buscarPaises: ( params ) => axios.get(`${api}/buscar-paises`, params),
    saveCedis: ( data ) => axios.post(`${api}/save-cedis`, data),
    savePais: ( data ) => axios.post(`${api}/save-pais`, data),
    changeDefault: ( data ) => axios.post(`${api}/change-default`, data),
    updateCedi: ( params ) => axios.put(`${api}/update-cedis`, params),
    eliminar: ( id ) => axios.delete(`${api}/${id}/eliminar`),
}
export default cedisDefecto
