import store from '../store'

const VUEX_RUTA = 'auth'
const paginaDefecto = { name: 'home' }
const componer = (...fns) => x => fns.reduce((y, f) => f(y), x);

// const necesitaConsultarRoles = componer(tomarDatosSesion, algunDatoFalla)
// const tomarDatosSesion = () => [
//     !store.getters[`${VUEX_RUTA}/existenRoles`],
//     store.getters[`${VUEX_RUTA}/existeToken`]
// ]
// const algunDatoFalla = () => !validaciones.includes(false)

const redireccionaLogin = ({ name }) => name === 'login'

const obtenerInicioRuta = ({ path }) => path.slipt[1]
const rutaIncluidaEnPermisos = inicioRuta => store.getters[`${VUEX_RUTA}/obtenerRoles`].includes(inicioRuta)
const rutaEsPublica = componer(obtenerInicioRuta, rutaIncluidaEnPermisos)


export default async(to, from, next) => {
    try {
        //if (necesitaConsultarRoles()) 
        await store.dispatch[`${VUEX}/consultarRoles`]

        if (redireccionaLogin(to) || rutaEsPublica(to)) return next()

        const rolUsuario = store.getters['auth/obtenerUsuario'].rol_nombre

        if (puedeContinuar(inicioRuta)(rolUsuario)) return next()
    } catch (error){
        console.error(error)
    }

    next(paginaDefecto)
}
