export default [
    {
        path: '/admin/almacen',
        name: 'admin.almacen',
        meta: { nombre: 'Abastecimiento' },
        redirect: { name: 'admin.almacen.pedidos' },
        component: () => import('../../pages/almacen').then(m => m.default || m),
        children: [
            {
                path: 'pedidos/:id_pedido?',
                meta: { nombre: 'Abastecimiento' },
                name: 'admin.almacen.pedidos',
                component: () => import('../../pages/almacen/pedidos/index').then(m => m.default || m),
            },
            {
                path: 'productos',
                meta: { nombre: 'Abastecimiento' },
                name: 'admin.almacen.productos',
                component: () => import('../../pages/almacen/productos/index').then(m => m.default || m),
                redirect: {name: 'admin.almacen.productos.estado'},
                children: [
                    {
                        path: 'estado',
                        meta: { nombre: 'Abastecimiento' },
                        name: 'admin.almacen.productos.estado',
                        component: () => import('../../pages/almacen/productos/estado').then(m => m.default || m),
                    },
                    {
                        path: 'desconocidos',
                        meta: { nombre: 'Abastecimiento' },
                        name: 'admin.almacen.productos.desconocidos',
                        component: () => import('../../pages/almacen/productos/desconocidos').then(m => m.default || m),
                    },
                    {
                        path: 'pedido',
                        meta: { nombre: 'Abastecimiento' },
                        name: 'admin.almacen.productos.pedidos',
                        component: () => import('../../pages/almacen/productos/pedido').then(m => m.default || m),
                    },      
                ]
            },
            {
                path: 'logistica-despacho',
                meta: { nombre: 'Abastecimiento' },
                name: 'admin.almacen.logistica_despacho',
                component: () => import('../../pages/almacen/logisticaDespacho/index').then(m => m.default || m),
            },
            {
                path: 'tablero',
                meta: { nombre: 'Abastecimiento' },
                name: 'admin.almacen.tablero',
                component: () => import('../../pages/almacen/tablero/index').then(m => m.default || m),
            },
            {
                path: 'historial-pedidos',
                meta: { nombre: 'Abastecimiento' },
                name: 'admin.almacen.historial',
                component: () => import('../../pages/almacen/pedidos/historial/index').then(m => m.default || m),
            },
            {
                path: 'pedidos-x-entregar',
                meta: { nombre: 'Abastecimiento' },
                name: 'admin.almacen.pedidos-x-entregar',
                component: () => import('../../pages/almacen/pedidosEntregar/index').then(m => m.default || m),
            },
            // {
            //     path: 'estadisticas',
            //     meta: { nombre: 'Abastecimiento' },
            //     name: 'admin.almacen.estadisticas',
            //     component: () => import('../../pages/almacen/estadisticas/index').then(m => m.default || m),
            // },
            {
                path: 'calificaciones',
                meta: { nombre: 'Abastecimiento' },
                name: 'admin.almacen.calificaciones',
                component: () => import('../../pages/calificaciones').then(m => m.default || m),
            },
            {
                path: 'cancelados',
                meta: { nombre: 'Abastecimiento' },
                name: 'admin.almacen.cancelados',
                component: () => import('../../pages/almacen/pedidos/historial/cancelados').then(m => m.default || m),
            },
            // {
            //     path: 'creditos',
            //     name: 'admin.almacen.creditos',
            //     meta: { nombre: 'Abastecimiento' },
            //     redirect: { name: 'admin.almacen.creditos.dashboard' },
            //     component: () => import('../../pages/almacen/creditos').then(m => m.default || m),
            //     children: [
            //         {
            //             path: 'dashboard',
            //             meta: { nombre: 'Abastecimiento' },
            //             name: 'admin.almacen.creditos.dashboard',
            //             component: () => import('../../pages/almacen/creditos/dashboard').then(m => m.default || m),
            //         },
            //         {
            //             path: 'tenderos',
            //             meta: { nombre: 'Abastecimiento' },
            //             name: 'admin.almacen.creditos.tenderos',
            //             component: () => import('../../pages/almacen/creditos/tenderos').then(m => m.default || m),
            //         },
            //         {
            //             path: 'tenderos/ver/:id_tienda',
            //             meta: { nombre: 'Abastecimiento' },
            //             name: 'admin.almacen.creditos.tenderos.ver',
            //             component: () => import('../../pages/almacen/creditos/tenderoVer').then(m => m.default || m),
            //         },
            //         {
            //             path: 'cuotas',
            //             meta: { nombre: 'Abastecimiento' },
            //             name: 'admin.almacen.creditos.cuotas',
            //             component: () => import('../../pages/almacen/creditos/cuotas').then(m => m.default || m),
            //         },
            //     ]
            // },
            /* {
                path: 'pedidos-admin/:id_pedido_admin',
                name: 'admin.almacen.creditos.detalle.pedido',
                component: () => import('../../pages/almacen/tendero/creditos/detallePedidoAdmin').then(m => m.default || m),
            }, */
            // {
            //     path: 'comisiones',
            //     meta: { nombre: 'Abastecimiento' },
            //     name: 'admin.almacen.comisiones',
            //     component: () => import('../../pages/comisiones').then(m => m.default || m),
            // },
        ]
    }
]
