export default [
    {
        path: '/tendero-vip/configurar',
        meta: { nombre: 'Configuraciones' },
        name: 'tendero-vip.conf',
        redirect: { name: 'tendero-vip.conf.datosTienda' },
        component: () => import(/* webpackChunkName: "configurarTenderoVip" */ '../../pages/configurar/tendero-vip').then(m => m.default || m),
        children: [
            {
                path: 'datos-tienda',
                name: 'tendero-vip.conf.datosTienda',
                component: () => import(/* webpackChunkName: "configurarTenderoVip" */ '../../pages/configurar/tendero-vip/datosTienda').then(m => m.default || m),
            },
            {
                path: 'domicilios',
                name: 'tendero-vip.conf.domicilios',
                component: () => import(/* webpackChunkName: "configurarTenderoVip" */ '../../pages/configurar/tendero-vip/domiciliosVip').then(m => m.default || m),
            },
            {
                path: 'pedidos',
                name: 'tendero-vip.conf.pedidos',
                component: () => import(/* webpackChunkName: "configurarTenderoVip" */ '../../pages/configurar/tendero/pedidos').then(m => m.default || m),
            },
            {
                path: 'unidades-medida',
                name: 'tendero-vip.conf.unidades-medida',
                component: () => import(/* webpackChunkName: "configurarTenderoVip" */ '../../pages/configurar/tendero-vip/unidadesMedida').then(m => m.default || m),
            },
            {
                path: 'categorias',
                name: 'tendero-vip.conf.categorias',
                component: () => import(/* webpackChunkName: "configurarTenderoVip" */ '../../pages/configurar/tendero-vip/categorias').then(m => m.default || m),
            },
            {
                path: 'etiquetas',
                name: 'tendero-vip.conf.etiquetas',
                component: () => import(/* webpackChunkName: "configurarTenderoVip" */ '../../pages/configurar/tendero-vip/etiquetas').then(m => m.default || m),
            },
            {
                path: 'tiempos',
                name: 'tendero-vip.conf.tiempos',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/tiempos').then(m => m.default || m),
            },
            {
                path: 'criterios-calificacion',
                name: 'tendero-vip.conf.criterios',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/criterios').then(m => m.default || m),
            },
            {
                path: 'motivo-rechazo',
                name: 'tendero-vip.conf.motRechazo',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motRechazo').then(m => m.default || m),
            },
            {
                path: 'motivo-cancelacion-tendero',
                name: 'tendero-vip.conf.motCanTendero',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motCanTendero').then(m => m.default || m),
            },
            {
                path: 'motivo-cancelacion-cliente',
                name: 'tendero-vip.conf.motCanCliente',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motCanCliente').then(m => m.default || m),
            },
            {
                path: 'motivo-reclamo',
                name: 'tendero-vip.conf.motReclamo',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motReclamo').then(m => m.default || m),
            },
            {
                path: 'datos-acceso',
                name: 'tendero-vip.conf.datos-acceso',
                component: () => import(/* webpackChunkName: "configurarTendero" */ '../../pages/configurar/tendero/datosAcceso').then(m => m.default || m),
            },
        ]
    }
]
