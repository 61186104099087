import axios from 'axios'

const API_PREFIX = 'cedis-kits'

const cediskits = {
    GetKits: (id_cedis) => axios.get(`${API_PREFIX}/${id_cedis}/listar-kits`),
    GetKitsCategorias: (id_cedis) => axios.get(`${API_PREFIX}/${id_cedis}/listar-kits-categorias`),
    getKitsPorCategoria: (id_cedis,id_categoria) => axios.get(`${API_PREFIX}/${id_cedis}/${id_categoria}/listar-kits-por-categoria`),
    getProductosPorKit: (id_cedis,id_kit) => axios.get(`${API_PREFIX}/${id_cedis}/${id_kit}/listar-productos-por-kit`),
    GuardarKit: (data) => axios.post(`${API_PREFIX}/${data.id_cedis}/guardar-kit`,data),
    ActualizarCantidad: (data) => axios.put(`${API_PREFIX}/${data.id_kit_producto}/actualizar-cantidad`,data),
    CambiarEstado: (data) => axios.put(`${API_PREFIX}/${data.id_kit}/cambiar-estado-kit`,data),
    EliminarKitProducto: (id_kit_producto) => axios.delete(`${API_PREFIX}/${id_kit_producto}/eliminar-kit-producto`),
    BuscarCedisProductos: (id_cedis,data) => axios.get(`${API_PREFIX}/${id_cedis}/buscar-cedis-productos`,{params:data}),
    AgregarProducto: (data) => axios.post(`${API_PREFIX}/agregar-productos-kits`,data),
    BuscarNoDisponibles: (id_cedis,data) => axios.get(`${API_PREFIX}/${id_cedis}/buscar-no-disponibles`,{params:data}),
    DuplicarKit: (data) => axios.post(`${API_PREFIX}/duplicar-kit`,data),
};

export default cediskits