<template>
    <section class="position-relative">
        <navbar-auth />
        <div class="container-fluid px-0">
            <div class="d-middle justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center position-relative h-100" style="background-color:#F5F5F6;">
                <div
                class="position-absolute w-100 h-100 image-background"
                :style="`background-image: url('${ _.isEmpty(ulrBackground) ? '/img/no-imagen/sin_imagen.png' : ulrBackground } ');`"
                />
                <div class="col-auto mr-xl-3 mr-lg-3 mr-md-0 mr-sm-0 mr-0">
                    <div class="row mx-0 justify-center mt-1 mb-1">
                        <div class="col-xl-auto col-lg-auto col-md-auto col-sm-12 px-0 cr-pointer" @click="goRegister">
                            <div class="p-3 align-items-end position-relative">
                                <img :src="imgBotonRegistro" class="br-12 obj-fit img-right" width="100%" height="100%" style="left:0px" />
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center mt-1 mb-1">
                        <div class="col-xl-auto col-lg-auto col-md-auto col-sm-12 px-0 cr-pointer" @click="goLogin">
                            <div class="p-2 position-relative">
                                <img :src="imgBotonLogin" class="br-12 obj-fit img-right" width="100%" height="100%" style="left:0px" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    layout: 'login',
    name: 'IngresoVendedor',

    data(){
        return {
            indice: 0,
            intervalID:null,
        }
    },

    computed: {
        ...mapGetters({
            imgsVendedor: 'x_parametros/imgVendedor',
        }),

        ulrBackground(){
            return this.imgsVendedor.ingreso[this.indice];
        },

        imgBotonLogin(){
            return this.imgsVendedor.boton_login.length > 0 ? this.imgsVendedor.boton_login[0] : '/img/no-imagen/tengo_una_cuenta.svg';
        },

        imgBotonRegistro(){
            return this.imgsVendedor.boton_registro.length > 0 ? this.imgsVendedor.boton_registro[0] : '/img/no-imagen/soy_nuevo.svg';
        },

        spaceWindow(){
            return window.innerWidth <= 1366 ? false : true
        }
    },

    mounted(){
        this.intervalID = setInterval(() => {
            let cantidad = this.imgsVendedor.ingreso.length - 1;
            this.indice++;
            if(this.indice > cantidad ){
                this.indice = 0;
            }
        }, 5000);
    },

    destroyed(){
        clearInterval(this.intervalID)
    },

    methods: {
        goRegister(){
            this.$router.push({name:'login.form.leechero'});
        },

        goLogin(){
            this.$router.push({name:'login.form.leechero', params: { ingresoLeechero: false }});
        },
    }
}
</script>
<style lang="scss" scoped>

a{ text-decoration: none !important;}

.box-login{
    width: 423px;
}
.social-divisor{ background: #FFF;opacity: .5; }

.navbar-postulacion{
    top: 0px;
    left: 0px;
    width: calc(100vw);
    height: 70px;
    display: flex;
    align-items: center;
    background-color: var(--color-general) !important;
}
.container-fluid{
    height: calc(100vh - 70px);
}
.none-router{
    color: white;
    outline: none;
    text-decoration: none;
    &:hover{
        color:#523a3a;
    }
}

.social-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 25px;
    border-radius: 50%;
    background: #FFF;
    color: var(--color-general);
    text-align: center;
    cursor: pointer;
}
.img-background{
    background-image: url('/img/auth/onda_postulate.svg');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}
.image-background{
    z-index:0;
    background-size:cover;
    background-position: left center;
    background-attachment: fixed;
    background-repeat:no-repeat;
    transition: all 0.5s ease-out !important;
}

.arrow-oh{
    border-left: 25px solid transparent;
    width: 0px;
    height: 0px;
    border-top: 25px solid transparent;
    border-right: 25px solid white;
    left: -46px;
    top: 85px;
    z-index: 1;
    border-bottom: 25px solid transparent;
}



/* -*-*-*-*- Media Queries -*-*-*-*- */
@media (min-width:320px) and (max-width:480px){
    .navbar-postulacion{
        justify-content: center !important;
        margin: 0px !important;
    }
    .grid-login{
        grid-template-columns: 1fr !important;
    }

}

@media (max-width : 1200px) {
    .faqs-image{
        display: none !important;
    }
    .navbar-postulacion{
        justify-content: center;
    }
    .image-background{
        display: none !important;
    }
    .space-image{
        display: none !important;
    }
    .grid-login{
        grid-template-columns: 1fr 1fr;
    }
}

.img-right{
    @media (max-width: 1366px) {
       // width: 400px !important;
        height: 150px !important;
    }
     @media (min-width: 1400px) {
       // width: 400px !important;
        height: 200px !important;
    }
}
</style>
