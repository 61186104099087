<template>
    <modal ref="modalCedisNavbar" titulo="Selección de cedis" :cargado-modal="cargando" icon="bodega" no-aceptar adicional="Aplicar" @adicional="crearCedisNavbar">
        <div class="row mx-0 mb-3">
            <div class="col-12 text-center text-general f-15">
                Seleccione los CEDIS que desea analizar
            </div>
        </div>
        <div v-for="(data,d) in cedis" :key="d" class="col-8 px-4 mb-3 mt-2">
            <el-checkbox v-model="data.checked" class="check-red">
                {{ data.nombre }}
            </el-checkbox>
        </div>
    </modal>
</template>

<script>
import Navbar from "~/services/cedis";
export default {
    data(){
        return {
            cargando: false,
            cedis: [],
        }
    },
    methods: {
        toggle(cedis){
            this.cedis = _.cloneDeep(cedis)
            this.$refs.modalCedisNavbar.toggle();
        },
        async crearCedisNavbar(){
            try {
                let params = {}

                if(this.cedis.length == 1){
                    params.cedis = [this.cedis[0].id]
                }else{
                    let cedis = this.cedis.filter(d => d.checked).map(({id}) => id)
                    params.cedis = cedis
                }

                this.cargando = true
                const {data} = await Navbar.crearCedisNavbar(params)
                this.$store.commit('cedis/set_cedis_nabvar', data.cedisNavbar)
                this.$store.commit('cedis/set_id_cedis_navbar')
                this.$store.commit('cedis/set_id_cedis')
                this.$refs.modalCedisNavbar.toggle()
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
