export default [
    {
        path: '/picking',
        name: 'admin.picking',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/picking').then(m => m.default || m)
    },
    {
        path: '/picking/:id_picking',
        name: 'admin.picking.ver',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/picking/verPicking').then(m => m.default || m)
    },
]
