export default [
    {
        path: '/admin/nomina',
        meta: { nombre: 'Nomina' },
        name: 'admin.nomina',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina').then(m => m.default || m),
        redirect: {name: 'admin.nomina.estadisticas'},
        children: [
            {
                path: 'estadisticas',
                meta: { nombre: 'Nomina' },
                name: 'admin.nomina.estadisticas',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/estadisticas').then(m => m.default || m),
            },
            {
                path: 'empresas',
                meta: { nombre: 'Nomina' },
                name: 'admin.nomina.empresas-asociadas',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/empresasAsociadas').then(m => m.default || m),
            },
            {
                path: 'liquidaciones',
                meta: { nombre: 'Nomina' },
                name: 'admin.nomina.liquidaciones',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/liquidaciones').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/admin/nomina/empresas/:id_convenio',
        meta: { nombre: 'Nomina' },
        name: 'admin.nomina.ver.empresa',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/index').then(m => m.default || m),
        redirect: {name: 'admin.nomina.ver.empresa.afiliados'},
        children: [
            {
                path: 'estadisticas',
                meta: { nombre: 'Nomina' },
                name: 'admin.nomina.ver.empresa.estadisticas',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/estadisticas/index').then(m => m.default || m),
            },
            {
                path: 'afiliados',
                meta: { nombre: 'Nomina' },
                name: 'admin.nomina.ver.empresa.afiliados',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/afiliados').then(m => m.default || m),
            },
            {
                path: 'solicitud-afiliacion',
                meta: { nombre: 'Nomina' },
                name: 'admin.nomina.ver.solicitud-afiliacion',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/solicitudAfiliacion').then(m => m.default || m),
            },
            {
                path: 'historial-liquidacion',
                meta: { nombre: 'Nomina' },
                name: 'admin.nomina.ver.historial-liquidacion',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/historialLiquidacion').then(m => m.default || m),
            },
            {
                path: 'liquidadores',
                meta: { nombre: 'Nomina' },
                name: 'admin.nomina.ver.liquidadores',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/liquidaciones').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/admin/nomina/empresas/:id_convenio/liquidar',
        meta: { nombre: 'Nomina' },
        name: 'admin.nomina.empresas.liquidar',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/liquidar').then(m => m.default || m),
    },
    {
        path: '/admin/nomina/empresas/:id_convenio/afiliados/:id_cliente',
        meta: { nombre: 'Nomina' },
        name: 'admin.nomina.empresas.afiliados',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/verAfiliado').then(m => m.default || m),
    },
    {
        path: '/admin/nomina/empresas/:id_convenio/liquidacion/:id_liquidacion',
        meta: { nombre: 'Nomina' },
        name: 'admin.nomina.empresas.liquidacion',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/verHistorial').then(m => m.default || m),
    },
    {
        path: '/admin/nomina/empresas/crear',
        meta: { nombre: 'Nomina' },
        name: 'admin.nomina.empresas.crear',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/crearEmpresa').then(m => m.default || m),
    },
    {
        path: '/admin/nomina/empresas/:id_convenio/editar',
        meta: {nombre: 'Nomina'},
        name: 'admin.nomina.empresas.editar',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/editarEmpresa').then(m => m.default || m),
    },
    {
        path: '/admin/nomina/:id_convenio/liquidaciones/:id_liquidacion',
        meta: { nombre: 'Nomina' },
        name: 'admin.nomina.liquidacion',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/nomina/ver/verLiquidaciones').then(m => m.default || m),
    },
]
