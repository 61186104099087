<template>
    <modal ref="imprimirRecibo" titulo="Imprimir Factura" adicional="Imprimir" no-aceptar @adicional="imprimirFactura">
        <div class="row mx-0">
            <div class="col-12 text-general text-center f-14">
                Desea imprimir la factura de:
            </div>
        </div>
        <div class="row mx-0 justify-center align-items-center my-4">
            <div class="col-auto d-middle">
                <input v-model="imprimirTipo" :value="1" type="radio" class="option-input black radio mx-1" />
                {{ $config.vendedor }}
            </div>
            <div class="col-auto d-middle">
                <input v-model="imprimirTipo" :value="2" type="radio" class="option-input black radio mx-1 ml-3" />
                {{ $config.cliente }}
            </div>
        </div>
    </modal>
</template>

<script>
import PdfPedidos from '~/services/pdf/pdf'
export default {
    data(){
        return{
            imprimirTipo: 1,
            id_pedido: 1,
            id_pedido_cliente: 1,
        }
    },
    methods: {
        toggle(id_pedido, id_pedido_cliente){
            this.id_pedido = id_pedido
            this.id_pedido_cliente = id_pedido_cliente
            this.$refs.imprimirRecibo.toggle()
        },
        imprimir(){
            this.$emit('imprimir', this.imprimirTipo)
        },
        async imprimirFactura(){
            try {
                if (this.imprimirTipo == 1){
                    const {data} = await PdfPedidos.generar_pdf_ticket_admin_pedidos({id_pedido: this.id_pedido})
                    const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                    const pdfWindow = window.open(url);
                    pdfWindow.print();
                } else {
                    const {data} = await PdfPedidos.generar_pdf_ticket_pedidos_cliente({id_pedido: this.id_pedido_cliente})
                    const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                    const pdfWindow = window.open(url);
                    pdfWindow.print();
                }
                this.$refs.imprimirRecibo.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },

    }
}
</script>
