export default [
    {
        path: '/cupones',
        name: 'admin.cupones',
        meta: { nombre: 'Cupones' },
        redirect: {name: 'admin.cupones.clientes.lista'},
        component: () => import('~/pages/cupones').then(m => m.default || m),
        children: [
            {
                path: 'clientes/lista-cupones',
                name: 'admin.cupones.clientes.lista',
                component: () => import('~/pages/cupones/listaCupones').then(m => m.default || m),
            },
            {
                path: 'clientes/dashboard',
                name: 'admin.cupones.clientes.dashboard',
                component: () => import('~/pages/cupones/estadisticasClientes').then(m => m.default || m),
            },
            {
                path: 'lechero/lista-cupones',
                name: 'admin.cupones.lechero.lista',
                component: () => import('~/pages/cupones/listaCupones').then(m => m.default || m),
            },
            {
                path: 'lechero/dashboard',
                name: 'admin.cupones.lechero.dashboard',
                component: () => import('~/pages/cupones/estadisticasLeecheros').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/cupones/ver/:id_cupon',
        name: 'admin.cupones.ver',
        meta: { nombre: 'Cupones' },
        //redirect: {name: 'admin.cupones.ver.informacion'},
        component: () => import('~/pages/cupones/ver').then(m => m.default || m),
        children: [
            {
                path: 'informacion/cupon',
                name: 'admin.cupones.ver.informacion1',
                component: () => import('~/pages/cupones/ver/informacionCupon').then(m => m.default || m),
            },
            {
                path: 'informacion/bono',
                name: 'admin.cupones.ver.informacion2',
                component: () => import('~/pages/cupones/ver/informacionBono').then(m => m.default || m),
            },
            {
                path: 'clientes-especificos',
                name: 'admin.cupones.ver.clientes-especificos',
                component: () => import('~/pages/cupones/ver/clientesEspecificos').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/cupones/crear',
        name: 'admin.cupones.crear',
        meta: { nombre: 'Cupones' },
        redirect: {name: 'admin.cupones.crear.informacion'},
        component: () => import('~/pages/cupones/crear').then(m => m.default || m),
        children: [
            {
                path: 'informacion',
                name: 'admin.cupones.crear.informacion',
                meta: { nombre: 'Cupones' },
                component: () => import('~/pages/cupones/crear/informacion').then(m => m.default || m),
            }

        ]
    },
    {
        path: '/cupones/:id_cupon/editar',
        name: 'admin.cupones.editar',
        meta: { nombre: 'Cupones' },
        redirect: {name: 'admin.cupones.editar.informacion'},
        component: () => import('~/pages/cupones/editar').then(m => m.default || m),
        children: [
            {
                path: 'informacion',
                name: 'admin.cupones.editar.informacion',
                meta: { nombre: 'Cupones' },
                component: () => import('~/pages/cupones/editar/informacion').then(m => m.default || m),
            },
            {
                path: 'clientes-especificos',
                name: 'admin.cupones.editar.clientes-especificos',
                meta: { nombre: 'Cupones' },
                component: () => import('~/pages/cupones/editar/clientesEspecificos').then(m => m.default || m),
            },


        ]
    },
    {
        path: '/cupones/:id_cupon/editar/buscar-clientes',
        name: 'admin.cupones.editar.buscar-clientes',
        component: () => import('~/pages/cupones/editar/buscarClientes').then(m => m.default || m),
    },
    {
        path: '/cupones/:id_cupon/editar/buscar-clientes2',
        name: 'admin.cupones.editar.buscar-clientes2',
        component: () => import('~/pages/cupones/editar/buscarClientes2').then(m => m.default || m),
    },
	{
        path: '/cupones/vendedor',
        meta: { nombre: 'Cupones' },
        name: 'tendero.cupones',
        component: () => import('~/pages/cupones/vendedor').then(m => m.default || m)
    },
	{
        path: '/cupones/:idCupon/vendedor/editar',
        meta: { nombre: 'Cupones' },
        name: 'tendero.cupones.editar',
        component: () => import('~/pages/cupones/crearCuponVendedor').then(m => m.default || m)
    },
	{
        path: '/cupones/:idCarpeta/vendedor/crear',
        meta: { nombre: 'Cupones' },
        name: 'tendero.cupones.crear',
        component: () => import('~/pages/cupones/crearCuponVendedor').then(m => m.default || m)
    },
	{
        path: '/cupones/vendedor/:idCupon',
        meta: { nombre: 'Cupones' },
        name: 'tendero.cupones.detalles',
        component: () => import('~/pages/cupones/detalleCuponVendedor').then(m => m.default || m)
    },


]
