import axios from 'axios'

const API = 'almacen-creditos'
const API_TENDERO = 'tendero-creditos'

const Creditos = {
    // Desde Menu ALmacen - creditos como Admin
    listarCreditosGraficaDosMeses(params){
        return axios(`${API}/listar-creditos-grafica-dos-meses`,{params})
    },
    listarCreditosTabTenderos(params){
        return axios(`${API}/creditos-tab-tendero`,{params})
    },
    listarCreditosTabCuotas(params){
        return axios(`${API}/creditos-tab-cuotas`,{params})
    },
    registrarPago(payload){
        return axios.post(`${API}/registrar-pago`,payload)
    },
    detalleCreditosTienda(id_pedidoatom){
        return axios(`${API}/${id_pedidoatom}/detalle-creditos-tienda`)
    },
    anularPagoAdminPedidos(payload){
        return axios.post(`${API}/anular-pago-admin-pedidos`,payload)
    },
    verCredito(params){
        return axios(`${API}/${params.id_tienda}/ver-credito`, { params });
    },
    graficaBalanceCredito(params={}){
        return axios(`${API}/${params.id_tienda}/balance-credito`,{params})
    },
    graficaCalendarioCredito(params){
        return axios(`${API}/${params.id_tienda}/grafica-calendario`,{ params })
    },
    historialCreditosLeechero(params){
        return axios(`${API}/${params.id_tienda}/historial-creditos-leecheros`,{ params })
    },
    historialPagosLeechero(params){
        return axios(`${API}/${params.id_tienda}/historial-pagos-leecheros`,{ params })
    },
    // Módulo Créditos en el menú para tendero y tendero vip
    listarCreditosGraficaDosMesesTendero(params){
        return axios(`${API_TENDERO}/grafica-dos-meses-tendero`,{params})
    },
    listarCreditosTabClientes(){
        return axios(`${API_TENDERO}/creditos-tab-clientes`)
    },
    listarCreditosTabCuotasTendero(params){
        return axios(`${API_TENDERO}/creditos-tab-cuotas`,{params})
    },
    detalleCreditosCliente(id_pedido){
        return axios(`${API_TENDERO}/${id_pedido}/detalle-creditos-cliente`)
    },
    registrarPagoCliente(payload){
        return axios.post(`${API_TENDERO}/registrar-pago-cliente`,payload)
    },
    verCreditoCliente(id_user){
        return axios(`${API_TENDERO}/${id_user}/ver-credito-cliente`)
    },
    graficaBalanceCreditoCliente(id_user){
        return axios(`${API_TENDERO}/${id_user}/balance-credito-cliente`)
    },
    graficaCalendarioCreditoCliente(id_user){
        return axios(`${API_TENDERO}/${id_user}/grafica-calendario-cliente`)
    },
    anularPagoClientePedidos(payload){
        return axios.post(`${API_TENDERO}/anular-pago-cliente-pedido`,payload)
    },
    listarCreditos: params => axios.get(`${API}/listar-creditos`, { params })


}

export default Creditos
