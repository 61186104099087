
export const state = {
    cant_tiendas: 0,
    id_tienda: 0,
    cant_users_soportes: 0,
    cant_reclamos: 0,
}

export const getters = {
    cant_tiendas: state => state.cant_tiendas,
    id_tienda: state => state.id_tienda,
    cant_users_soportes: state => state.cant_users_soportes,
    cant_reclamos: state => state.cant_reclamos,
}

export const mutations = {
    set_cant_tiendas: (state,payload) => {
        state.cant_tiendas = payload
    },
    set_id_tienda: (state,payload) => {
        state.id_tienda = payload
    },
    set_cant_users_soportes: (state,payload) => {
        state.cant_users_soportes = payload
    },
    set_cant_reclamos: (state,payload) => {
        state.cant_reclamos = payload
    },

}

