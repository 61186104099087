export default [
    {
        path: '/tendero/configurar',
        meta: { nombre: 'Configuración' },
        name: 'tendero.conf',
        redirect: { name: 'tendero.conf.datosTienda' },
        component: () => import(/* webpackChunkName: "configurarTendero" */ '../../pages/configurar/tendero').then(m => m.default || m),
        children: [
            {
                path: 'datos-tienda',
                meta: { nombre: 'Configuración' },
                name: 'tendero.conf.datosTienda',
                component: () => import(/* webpackChunkName: "configurarTendero" */ '../../pages/configurar/tendero/datosTienda').then(m => m.default || m),
            },
            {
                path: 'editar/datos-tienda',
                meta: { nombre: 'Configuración' },
                name: 'tendero.conf.datosTienda.editar',
                /* redirect: { name: 'tendero.conf.datosTienda' }, */
                component: () => import(/* webpackChunkName: "configurarTendero" */ '../../pages/configurar/tendero/datosTienda/editarDatos').then(m => m.default || m),
            },
            {
                path: 'domicilios',
                meta: { nombre: 'Configuración' },
                name: 'tendero.conf.domicilios',
                component: () => import(/* webpackChunkName: "configurarTendero" */ '../../pages/configurar/tendero/domicilios').then(m => m.default || m),
            },
            {
                path: 'pedidos',
                meta: { nombre: 'Configuración' },
                name: 'tendero.conf.pedidos',
                component: () => import(/* webpackChunkName: "configurarTendero" */ '../../pages/configurar/tendero/pedidos').then(m => m.default || m),
            },
            {
                path: 'datos-acceso',
                meta: { nombre: 'Configuración' },
                name: 'tendero.conf.datos-acceso',
                component: () => import(/* webpackChunkName: "configurarTendero" */ '../../pages/configurar/tendero/datosAcceso').then(m => m.default || m),
            },
            {
                path: 'galeria',
                meta: { nombre: 'Configuración' },
                name: 'tendero.conf.galeria',
                component: () => import(/* webpackChunkName: "configurarTendero" */ '../../pages/configurar/tendero/galeria').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/registro-completado',
        meta: { nombre: 'Configuración' },
        name: 'tendero.conf.registro-completado',
        component: () => import(/* webpackChunkName: "configurarTendero" */ '../../pages/configurar/tendero/datosTienda/registroCompletado').then(m => m.default || m),
    }
]
