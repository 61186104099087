<template>
    <section>
        <modal ref="modalImagnes" titulo="Imagenes del Producto" tamano="modal-lg" no-cancelar no-aceptar cerrar @close="cerrarModal">
            <div class="row mx-5">
                <div class="col">
                    <vueper-slides
                    ref="vueperslides1"
                    class="no-shadow text-muted2 h-vueper2"
                    :dragging-distance="50"
                    :touchable="false"
                    >
                        <vueper-slide v-for="(i,key) in imagenes" :key="key">
                            <template v-slot:content>
                                <div class="row mx-0 justify-center">
                                    <div class="br-12 d-middle-center shadow border content-slider" style="height:209px;width:209px;">
                                        <img :src="i.imagen" class="w-100 h-100 br-12 obj-cover" />
                                    </div>
                                </div>
                            </template>
                        </vueper-slide>
                    </vueper-slides>
                    <vueper-slides
                    ref="vueperslides2"
                    class="no-shadow mt-3 mb-0"
                    :visible-slides="5"
                    fixed-height="134px"
                    :bullets="false"
                    :touchable="false"
                    :gap="10"
                    :arrows="true"
                    @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                    >
                        <template #arrow-left>
                            <div class="d-middle-center bg-white rounded-circle shadow" style="width:40px;height:40px;">
                                <i class="icon-left-open text-general-red" />
                            </div>
                        </template>
                        <template #arrow-right>
                            <div class="d-middle-center bg-white rounded-circle shadow" style="width:40px;height:40px;">
                                <i class="icon-right-open text-general-red" />
                            </div>
                        </template>
                        <vueper-slide v-for="(i, key) in imagenes" :key="key" @click.native="$refs.vueperslides2.goToSlide(key); imagen_activa = key">
                            <template v-slot:content>
                                <div class="row mx-0 h-100 align-items-center">
                                    <div :class="`br-12 d-middle-center shadow content-slider ${ imagen_activa == key ? 'border-general p-1' : ''} `" style="height:99px;width:99px;">
                                        <img :src="i.imagen" class="cr-pointer w-100 h-100 br-12 obj-cover" />
                                    </div>
                                </div>
                            </template>
                        </vueper-slide>
                    </vueper-slides>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
export default {
    data(){
        return {
            imagenes: [],
            imagen_activa: null,
        }
    },
    methods: {
        toggle(images){
            this.imagenes = images
            this.$refs.modalImagnes.toggle()
        },
        cerrarModal(){
            this.$emit('cerrar')
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-ver-imagen{
    .imagen{
        max-height: 44vh;
        max-width: 100%;
        object-fit: cover;
    }

}
</style>
