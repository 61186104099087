export default [
    {
        path: '/tendero/comision',
        meta: { nombre: 'Comisión' },
        name: 'tendero.comision',
        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/comisiones/components/informeLeechero').then(m => m.default || m),
    },
    {
        path: '/tendero/alianzas',
        meta: { nombre: 'Comisión' },
        name: 'tendero.alianzas',
        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/alianzasLeechero/components/informeLeechero').then(m => m.default || m),
    },
]
