import vueGlobalMixin from '~/mixins/general'
import authService from '~/services/auth'
import moment from 'moment'
import _ from 'lodash'

export const state = {
    idUser: 0,
    login: 0,
    usuario: '',
    indicativo: '',
    phoneNumber: '',
    usuarioModel: {
        usuario: '',
        nombre: '',
        apellido: '',
        ciudad: null,
        pais: null,
    },
    model: {
        idm_ciudad: null,
        login: 2,
        tipo: 2,
        imagenes: [],
        propietario_nombre: '',
        direccion: '',
        direccion_anexo: '',
        barrio: '',
        telefono: '',
        correo: '',
        descripcion: '',
        nombre: '',
        nit: '',
        password: '',
        propietario_genero: 1,
        propietario_nacimiento: moment().format('YYYY-MM-DD'),
        idm_pais: null,
        idm_estado: null,
        longitud: 0,
        latitud: 0,
        imagen: ''

    },
};

export const getters = {
    idUser: ({idUser}) => idUser,
    getLogin: ({login}) => login,
    getUsuario: ({usuario}) => usuario,
    getIndicativo: ({indicativo}) => indicativo,
    getModel: ({model}) => model,
    getUsuarioModel: ({usuarioModel}) => usuarioModel,
    getPhoneNumber: ({phoneNumber}) => phoneNumber,
};

export const mutations = {
    setIdUser: (state, payload) =>  state.idUser = payload,
    setLogin: (state, payload) =>  state.login = payload,
    setUsuario: (state, payload) =>  state.usuario = payload,
    setIndicativo: (state, payload) =>  state.indicativo = payload,
    setUsuarioModel: (state, payload) =>  state.usuarioModel = payload,
    setPhoneNumber: (state, payload) =>  state.phoneNumber = payload,
    setTienda: (state, payload) => {
        if(_.isEmpty(payload)) return
        state.model.propietario_nombre = payload.propietario_nombre
        state.model.propietario_genero = payload.propietario_genero
        state.model.propietario_nacimiento = moment(payload.propietario_nacimiento).format('YYYY-MM-DD')
        state.model.telefono = payload.telefono_celular
        state.model.barrio = payload.barrio
        state.model.nit = payload.nit
        state.model.direccion = payload.direccion
        state.model.direccion_anexo = payload.direccion_anexo
        state.model.idm_ciudad = payload.idm_ciudad
        state.model.nombre = payload.nombre
        state.model.descripcion = payload.descripcion
        state.model.idm_pais = payload.idm_pais
        state.model.idm_estado = payload.idm_estado
        state.model.longitud = payload.longitud
        state.model.latitud = payload.latitud
        state.model.imagen = payload.imagen
    }
};

export const actions = {
    async registerWithGoogle(obj, payload){
        try {

            const { data } = await authService.registerWithGoogle(payload)
            this.commit('auth/ALMACENAR_TOKEN', data.token)
            this.commit('auth/ALMACENAR_USUARIO', data.usuario)
            await this.dispatch('auth/consultarRoles')
            return true;
        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
            return false;
        }
    },
    async registerWithFacebook({ commit }, payload){
        try {
            const { data } = await authService.registerWithFacebook(payload)
            this.commit('auth/ALMACENAR_TOKEN', data.token)
            this.commit('auth/ALMACENAR_USUARIO', data.usuario)
            await this.dispatch('auth/consultarRoles')
            return true;

        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
            return false;
        }
    }
}
