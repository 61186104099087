import store from '~/store';
import axios from 'axios';

const VUEX_RUTA = 'auth';
const RUTA_VALIDAR_HOME = '/admin-tiendas/validar-home';
const HOME_SEGUN_ROL = {
    tendero: '',
    'tendero-vip': ''
}


const puedeConsultarUsuario = validaciones => !validaciones.includes(false);
const redireccionaLogin = ({ name }) => name === 'login';

export default async(to, from, next) => {
    const datosSesion = [
        !store.getters[`${VUEX_RUTA}/existeUsuario`],
        store.getters[`${VUEX_RUTA}/existeToken`]
    ];

    try {
        const seDirigeAlLogin = redireccionaLogin(to);
        const necesitaConsultarUsuario = puedeConsultarUsuario(datosSesion);

        if (seDirigeAlLogin && !necesitaConsultarUsuario) return next();

        if (necesitaConsultarUsuario) await store.dispatch(`${VUEX_RUTA}/consultarUsuario`);

        if (! seDirigeAlLogin) return next();

        const { data } = await axios(RUTA_VALIDAR_HOME)
        
        const usuario = store.getters[`auth/obtenerUsuario`];

        if (data.home) return next({
            name: `${usuario.rol_nombre}.home`
        });

        return next({name:`${usuario.rol_nombre}.pedidos`});
    } catch (error){
        console.error(error);
    }

    next();
}
