const helper_storage = {}

const convertir = (texto, tipo) => {
  // console.log(texto, tipo);
  // tipos
  // 0=>usuario
  // 1=>cliente
  if (texto) {
    return `/api/storage/${texto}`;
  } else {
    var arr = ['sin_user.png', 'sin_cliente.png']
    return `/img/sin_foto/${arr[tipo]}`
  }
}

helper_storage.install = function(Vue) {
  Vue.filter('helper-storage', (val, tipo = 0) => {
    return convertir(val, tipo);
  })
}

export default helper_storage;
