export default [
    {
        path: '/admin/clientes',
        meta: { nombre: 'Clientes' },
        name: 'admin.clientes',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/clientes').then(m => m.default || m),
    },
    {
        path: '/admin-vip/clientes',
        meta: { nombre: 'Clientes' },
        name: 'admin-vip.clientes',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/clientes').then(m => m.default || m),
    },
    {
        path: '/tendero-vip/clientes',
        meta: { nombre: 'Clientes' },
        name: 'tendero-vip.clientes',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/clientes').then(m => m.default || m),
    }
]
