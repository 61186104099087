
export const state = {
    dia: null,
    fecha: null,
    caja: {

    },
}

export const getters = {    
    dia: state => state.dia,
    fecha: state => state.fecha,
    caja: state => state.caja,
}

export const mutations = {    
    setDia: (state,payload) => {
        state.dia = payload
    },
    setFecha: (state,payload) => {
        state.fecha = payload
    },
    setCaja: (state,payload) => {
        state.caja = payload
    },
}

