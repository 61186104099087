import axios from 'axios'

const API = 'almacen-creditos-tendero'

const Creditos = {
    listarCreditosGraficaCuentaPorPagar(){
        return axios(`${API}/creditos-grafica-cuentas-por-pagar`)
    },
    // listarCreditosTabTenderos(){
    //     return axios(`${API}/creditos-tab-tendero`)
    // },
    // listarCreditosTabCuotas(){
    //     return axios(`${API}/creditos-tab-cuotas`)
    // },
    // registrarPago(payload){
    //     return axios.post(`${API}/registrar-pago`,payload)
    // },
    // detalleCreditosTienda(id_pedidoatom){
    //     return axios(`${API}/${id_pedidoatom}/detalle-creditos-tienda`)
    // },
    // anularPagoAdminPedidos(payload){
    //     return axios.post(`${API}/anular-pago-admin-pedidos`,payload)
    // },
    verCreditoPorPagarTabla(){
        return axios(`${API}/ver-credito-por-pagar-tabla`)
    },
    graficaBalanceCredito(id_tienda){
        return axios(`${API}/${id_tienda}/balance-credito`)
    },
    graficaCalendarioCreditoPorPagar(id_tienda){
        return axios(`${API}/${id_tienda}/grafica-calendario`)
    },


}

export default Creditos
