export default [
    {
        path: '/admin-vip/satisfaccion',
        meta: { nombre: 'Satisfacción' },
        name: 'admin.vip.satisfaccion',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/satisfaccion').then(m => m.default || m),
    },
    {
        path: '/admin-vip/clientes',
        meta: { nombre: 'Clientes' },
        name: 'admin.vip.clientes',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/clientes').then(m => m.default || m),
    }
]
