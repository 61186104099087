import Cupones from '~/services/cupones/cupones'
import Helper from '~/services/helper'
export const state = {
    datosBasicos:{},
    datosBasicosFuturo:{},
    cedisCupon:[],
    idCarpeta:null,
    datosDetalle:{pais:{}},
    datosBono:{}
}

export const getters = {
    datosBasicos: state => state.datosBasicos,
    cedisCupon: state => state.cedisCupon,
    idCarpeta: state => state.idCarpeta,
    datosBasicosFuturo: state => state.datosBasicosFuturo,
    datosDetalle: state => state.datosDetalle,
    datosBono: state => state.datosBono,




}

export const mutations = {
    set_datos_basicos: (state,payload) => {
        Helper.notificacion('','Datos del cupón actualizados','success')
        state.datosBasicos = payload;
    },
    set_cedis_cupon: (state,payload) => {
        Helper.notificacion('','Datos del cupón actualizados','success')
        state.cedisCupon = payload;
    },
    set_id_carpeta: (state,payload) => {
        state.idCarpeta = payload;
    },
    set_datos_basicos_futuro: (state,payload) => {
        Helper.notificacion('','Datos del cupón actualizados','success')
        state.datosBasicosFuturo = payload;
    },
    set_detalle: (state,payload) => {
        state.datosDetalle = payload;
    },
    set_bono: (state,payload) => {
        state.datosBono = payload;
    },



}

export const actions = {
    async getDatosBasicos({commit},id){
        const {data} = await Cupones.getDatosBasicos(id)
        commit('set_datos_basicos',data.cupon)
    },
    async getCuponesCedis({commit},id){
        const {data} = await Cupones.getCuponesCedis(id)
        commit('set_cedis_cupon',data.data)
    },
    async getDatosBasicosFuturo({commit},id_padre){
        const {data} = await Cupones.getDatosBasicosFututo(id_padre)
        if(data.cupon === null) return
        commit('set_datos_basicos_futuro',data.cupon)
    },
    async getDetalleCupon({commit},id){
        const {data} = await Cupones.getDetalleCupon(id)
        commit('set_detalle',data.cupon)
        commit('set_bono',data.bono)

    },
}
