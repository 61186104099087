import axios from 'axios'

const API = 'pedido-manual'

const Pedidos = {
    datos_pedido_manual(id_pedido){
        return axios(`${API}/${id_pedido}/datos_basicos`)
    },
    productos_agregados(id_pedido){
        return axios(`${API}/${id_pedido}/productos`)
    },
    busqueda_productos(params){
        return axios(`${API}/buscar-productos`,{params})
    },
    agregar_producto(model){
        return axios.post(`${API}/agregar-producto`,model)
    },
    cambiar_cantidad_producto(model){
        return axios.put(`${API}/cambio-cantidad`,model)
    },
    delete_producto(id){
        return axios.delete(`${API}/${id}/delete-producto`)
    },
    agregar_pedido_manual(model){
        return axios.post(`${API}/crear-manual-web`,model)
    },
    editar_pedido_manual(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/editar-manual-web`,model)
    },
    calcular_domicilio(params){
        return axios(`carrito/calcular-domicilio`,{params})
    },
    descartar_pedido_manual(id_pedido){
        return axios.delete(`${API}/${id_pedido}/eliminar-pedido`)
    },

}

export default Pedidos
