import Parametros from "~/services/x_parametros";
import moment from "moment";

export const state = {
    parametros: {
        nombre_proyecto: '',
        pagina_web: '',
        direccion: '',
        telefono: '',
        idm_pais: null,
        idm_estado: null,
        idm_ciudad: null,
        logo_color: '',
        header: '',
        logo_blanco: '',
        logo_app: '',
        facebook: '',
        youtube: '',
        instagram: '',
        twitter: '',
        whatsapp: '',
    },
    parametros_publicos: {
        nombre_proyecto: '',
        pagina_web: '',
        direccion: '',
        telefono: '',
        idm_pais: null,
        idm_estado: null,
        idm_ciudad: null,
        logo_color: '',
        header: '',
        logo_blanco: '',
        logo_app: '',
        facebook: '',
        youtube: '',
        instagram: '',
        twitter: '',
        whatsapp: '',
    },
    configuracion: localStorage.configuracion ? JSON.parse(localStorage.configuracion) : {},
    imgGenerales:localStorage.imgGenerales ? JSON.parse(localStorage.imgGenerales) : {},
    imgAdmin:localStorage.imgAdmin ? JSON.parse(localStorage.imgAdmin) : {},
    imgVendedor:localStorage.imgVendedor ? JSON.parse(localStorage.imgVendedor) : {},
};

export const getters = {
    parametros: state => state.parametros,
    parametros_publicos: state => state.parametros_publicos,
    configuracion: state => state.configuracion,
    imgGenerales: state => state.imgGenerales,
    imgAdmin: state => state.imgAdmin,
    imgVendedor: state => state.imgVendedor,
};

export const mutations = {
    set_parametros: (state, payload) => {
        state.parametros = payload;
    },
    set_parametros_publicos: (state, payload) => {
        state.parametros_publicos = payload;
    },
    setParamsGenerales: (state, payload) => {
        payload.dia = moment().day();
        state.configuracion = payload;
        localStorage.configuracion = JSON.stringify(payload)
    },
    setImagenesGenerales: (state, payload) => {
        state.imgGenerales = payload;
        localStorage.imgGenerales = JSON.stringify(payload)
    },
    setImagenesAdmin: (state, payload) => {
        state.imgAdmin = payload;
        localStorage.imgAdmin = JSON.stringify(payload)
    },
    setImagenesVendedor: (state, payload) => {
        state.imgVendedor = payload;
        localStorage.imgVendedor = JSON.stringify(payload)
    }
};

export const actions = {
    async action_get_parametros_publico({ commit }){
        const { data } = await Parametros.get_parametros_publico();
        commit("set_parametros_publicos", data.parametros_publicos);

    },
    async action_get_parametros({ commit }){
        const { data } = await Parametros.get_parametros();
        commit("set_parametros", data.parametros);
    },
    async getParamsGenerales({ commit }){
        const { data } = await Parametros.getParamsGenerales();
        commit("setParamsGenerales", data.parametros);
        commit("setImagenesGenerales", data.imagenes);
        commit("setImagenesAdmin", data.admin);
        commit("setImagenesVendedor", data.vendedor);

    },
};
