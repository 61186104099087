export default [
    {
        path: '/tendero/pedidos',
        meta: { nombre: 'Pedidos de mis clientes' },
        name: 'tendero.pedidos',
        component: () => import('../../pages/pedidos').then(m => m.default || m),
        redirect: {name: 'tendero.pedidos.activos'},
        children: [
            {
                path: 'activos/:id_pedido?',
                meta: { nombre: 'Pedidos de mis clientes' },
                name: 'tendero.pedidos.activos',
                component: () => import('../../pages/pedidos/tendero').then(m => m.default || m),
            },
            {
                path: 'historial-pedidos',
                meta: { nombre: 'Pedidos de mis clientes' },
                name: 'tendero.historial-pedidos',
                component: () => import('../../pages/pedidos/historial/historialTendero').then(m => m.default || m),
            },
            
        ]
    },

]
