// States
export const state = {
    listenerUpdater: { // Bandera que hará la función de escuchar cuando halla nueva información que requiera actualizarse en vista
        value: null,
        type: null,
        idSection: null,
    }
}
// Getters
export const getters = {
    getListenerUpdater: state => state.listenerUpdater,
}
// Mutations
export const mutations = {
    setListenerUpdater(state, payload){
        state.listenerUpdater = payload
    }
}
// Actions
export const actions = {}