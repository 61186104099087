export default [
    {
        path: '/admin-vip/soporte',
        meta: { nombre: 'Soporte' },
        name: 'admin-vip.soporte',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/admin').then(m => m.default || m),
        redirect: {name: 'admin-vip.soporte.tiendas'},
        children: [
            {
                path: 'tiendas',
                meta: { nombre: 'Soporte - Leecheros' },
                name: 'admin-vip.soporte.tiendas',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/tiendas').then(m => m.default || m),
            },
            {
                path: 'clientes',
                meta: { nombre: 'Soporte - clientes' },
                name: 'admin-vip.soporte.general',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/soporteGeneral').then(m => m.default || m),
            },
        ]
    }
]
