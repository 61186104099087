<template>
    <money
    :value="+value"
    class="form-control br-10"
    v-bind="moneyOptions"
    @input="emitir($event)"
    />
</template>
<script>
import {Money} from 'v-money'
import {mapGetters} from 'vuex'
export default {
    name: 'InputMiles',
    components:{ Money },
    props: {
        value: {
            type: [Number, String],
            default: 0
        },
        moneyOptions: {
            type: Object,
            default: () => {
                return {
                    decimal: ',',
                    thousands: '.',
                    prefix: '$ ',
                    suffix: '',
                    precision: 0,
                    masked: false
                }
            }
        }
    },
    data(){
        return {
            valor: this.value,
        }
    },
    watch: {
        valor(val){
            this.$emit('change',val)
        }
    },
    methods:{
        emitir(value){
            this.valor = value
            this.$emit('input',value)
        },
        // debouncer: _.debounce(function(){
        //     this.$emit('change', this.valor)
        // }, 200)
    }

}
</script>
