<template>
    <div class="card-producto d-middle m-2 p-1" :class="border[info.promocion]" @click="$emit('accion',info)">
        <div class="position-relative">
            <i v-show="info.promocion != 0" class="icon-bookmark f-20 position-absolute" :class="text[info.promocion]" style="top:-6px;right:4px;" />
            <img class="br-10 obj-cover" height="90" width="90" :src="info.producto.imagen" alt="" />
            <div
            class="position-absolute text-white w-100 f-10 text-center"
            :class="colores[info.promocion]"
            style="bottom:0px;border-radius: 0 0 10px 10px;"
            >
                <span v-if="info.promocion === 1">Destacado</span>
                <span v-else-if="info.promocion === 2">2x1</span>
                <span v-else-if="info.promocion ===3">Descuento</span>
            </div>
        </div>
        <div class="col px-2">
            <div v-if="info.desconocido === 0" class="">
                <p class="nombre lh-18 f-600">{{ info.producto.nombre }}</p>
                <p>{{ info.producto.presentacion }}</p>
                <div class="row mx-0 align-items-center pb-1 justify-content-between">
                    <span class="f-600" :class="text[info.promocion]">{{ convertMoney(info.total_final,info.idm_moneda) }}</span>
                    <p>
                        <b>{{ agregarSeparadoresNumero(info.cantidad,2) }}</b>
                        {{ info.producto.unidad }}
                    </p>
                </div>
            </div>
            <div v-else class="">
                <p class="nombre lh-18 f-600">Producto Desconocido</p>
                <p>{{ info.producto.nombre }}</p>
            </div>
        </div>
        <div class="d-flex flex-column">
            <i v-show="info.estado === 21" class="icon-swap-horizontal-bold f-22 my-auto cr-pointer text-gr-general" />
            <i v-show="info.estado === 22" class="icon-plus f-22 my-auto cr-pointer text-gr-red" />
            <i v-show="info.estado === 23" class="icon-minus f-22 my-auto cr-pointer text-gr-red" />
            <i v-show="info.estado === 4" class="icon-down-circle f-22 my-auto cr-pointer text-gr-general" />
            <i v-show="info.estado === 51 || info.estado === 52" class="icon-trash-empty f-22 my-auto cr-pointer text-dark" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {
                    nombre: 'Malteada de vainilla Francesa con palitos de chocolate',
                    foto_mini: 'https://img.culturacolectiva.com/content_image/2020/1/21/1579624512515-receta-de-frappe-de-chocolate-con-vino-tinto-en-5-minutos.003.jpeg',
                    presentacion:  '600',
                    sigla: 'ml',
                    precio: 14000,
                    cant_vendido: 51,
                    id_promocion: null,
                    tipo: 1,
                    color: 0,
                    borde: true,
                    posicion: 0,
                    texto: 'MÁS BARATO',
                    descuento: 0,
                    estado: ''


                }
            }
        }
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            // agregar: false,
            colores: [ null, 'bg-gr-red','bg-gr-general','bg-gr-purple'],
            text: [ null,'text-gr-red','text-gr-general', 'text-gr-purple' ],
            border: [ 'border','border-orange', 'border-blue','border-purple'],
        }
    },
    methods: {
        disenoCard(idx){
            this.diseno.color = this.colores[idx]
            this.borde_activo = this.border[idx]
        },

    }
}
</script>

<style lang="css" scoped>
.card-producto{
    width: 400px;
    border-radius: 16px;
}
.nombre{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
