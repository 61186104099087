export default [
    {
        path: "/tendero/metricas",
        meta: { nombre: "Estadisticas" },
        name: "tendero.metricas",
        redirect: {name: 'tendero.metricas.estadisticas'},
        component: () =>
            import(
                /* webpackChunkName: "informacion" */ "../../pages/metricas/leecheroIndex"
            ).then(m => m.default || m),
        children: [
            {
                path: "estadisticas",
                meta: { nombre: "Estadisticas" },
                name: "tendero.metricas.estadisticas",
                component: () =>
                    import(
                        /* webpackChunkName: "informacion" */ "../../pages/metricas"
                    ).then(m => m.default || m)
            },
            {
                path: "estadisticas-equipo",
                meta: { nombre: "Estadisticas" },
                name: "tendero.metricas.estadisticas-equipo",
                component: () =>
                    import(
                        /* webpackChunkName: "informacion" */ "../../pages/equipos/ver"
                    ).then(m => m.default || m)
            }
        ]
    }
];
