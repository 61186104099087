import axios from 'axios'

const API = 'pedido-historial'

const Historial = {

    listar_pedidos(params){
        return axios(`${API}/listado`,{params})
    },
    
}

export default Historial
