
import Creditos from '~/services/almacen/creditos'

export const state = {
    graficaDosMeses: [],
    creditosVencidos: {
        cant_tenderos: 0,
        cant_cuotas: 0,
        cant_pedidos: 0,
        total: 0,
        deuda:[]
    },
    creditosVigentes: {
        cant_tenderos: 0,
        cant_cuotas: 0,
        cant_pedidos: 0,
        total: 0,
        deuda: []
    },
    tenderosMorosos: [],
    tenderosActivos: [],
    datosGenerales: {
        sumaActivos: 0,
        sumaMorosos: 0
    },
    tablaCuotas: [],
    infoTabCuotas: {
        cuotas_vencidas: 0,
        deuda_por_cobrar: 0,
        deuda_vencidas: 0,
        proxi_cuotas: 0,
        deuda_total: 0
    },

}

export const getters = {
    graficaDosMeses: state => state.graficaDosMeses,
    creditosVencidos: state => state.creditosVencidos,
    creditosVigentes: state => state.creditosVigentes,
    tenderosMorosos: state => state.tenderosMorosos,
    tenderosActivos: state => state.tenderosActivos,
    datosGenerales: state => state.datosGenerales,
    tablaCuotas: state => state.tablaCuotas,
    infoTabCuotas: state => state.infoTabCuotas,
}

export const mutations = {
    set_grafica_dos_meses: (state,payload) => {
        state.graficaDosMeses = payload
    },
    set_creditos_vencidos: (state,payload) => {
        state.creditosVencidos = payload
    },
    set_creditos_vigentes: (state,payload) => {
        state.creditosVigentes = payload
    },
    set_tenderos_morosos: (state,payload) => {
        state.tenderosMorosos = payload
    },
    set_tenderos_activos: (state,payload) => {
        state.tenderosActivos = payload
    },
    set_datos_generales: (state,payload) => {
        state.datosGenerales = payload
    },
    set_tabla_cuotas: (state,payload) => {
        state.tablaCuotas = payload
    },
    set_info_Tab_cuotas: (state,payload) => {
        state.infoTabCuotas = payload
    },

}

export const actions = {
    async action_listar_creditos_grafica_dos_meses({commit}, params={ tendero:1}){
        if(params.tendero===1){
            var {data} = await Creditos.listarCreditosGraficaDosMeses(params)
        }else{
            var {data} = await Creditos.listarCreditosGraficaDosMesesTendero(params)
        }
        commit('set_grafica_dos_meses',data.grafica)
        commit('set_creditos_vencidos',data.creditosVencidos)
        commit('set_creditos_vigentes',data.creditosVigentes)
        commit('set_tenderos_morosos',data.tenderosMorosos)
        commit('set_tenderos_activos',data.tenderosActivos)
        commit('set_datos_generales',data.datosGenerales)
    },
    async action_listar_cuotas({commit},params = {tendero: 1}){
        if(params.tendero === 1){
            var {data} = await Creditos.listarCreditosTabCuotas(params)
        } else {
            var {data} = await Creditos.listarCreditosTabCuotasTendero(params)
        }
        commit('set_tabla_cuotas',data.cuotas)
        commit('set_info_Tab_cuotas',data.info)


    }

}
