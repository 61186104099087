
import vueGlobalMixin from '~/mixins/general'
import cedisDefecto from '~/services/configurar/admin/cedisDefecto'

export const state = {
    datos: [],
    cedis: [],
    idPais: 0,
    defecto: {}
}

export const getters = {
    getCedis: ({cedis}) => cedis,
    getDatos: ({datos}) => datos,
    getDefecto: ({defecto}) => defecto,
    getIdPais: ({idPais}) => idPais,
}

export const mutations = {
    setCedis: (state, payload) => state.cedis = payload,
    setDatos: (state, payload) => state.datos = payload,
    setDefecto: (state, payload) => state.defecto = payload,
    setIdPais: (state, payload) => state.idPais = payload,
}

export const actions = {
    async fetchCedis({commit}){
        try {
            const { data } = await cedisDefecto.fetchCedis();
            commit('setCedis', data)
        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
        }
    },
    async fetchDatos({commit}){
        try {
            const { data } = await cedisDefecto.getData();
            commit('setDatos', data.datos)
            commit('setDefecto', data.defecto)
        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
        }
    },

}
