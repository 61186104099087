export default [
    {
        path: '/inventario-tienda',
        name: 'inventario-leechero',
        meta: { nombre: 'Inventario' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/inventarioLeecheros').then(m => m.default || m),

    },
    {
        path: '/tendero/inventario',
        name: 'tendero.inventario',
        meta: { nombre: 'Inventario Temporal' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/inventarioLeecheros/tendero').then(m => m.default || m),

    }
]
