<template>
    <i class="icon-star f-22 cr-pointer" :class="estadoColor(row.estrella)" @click.stop="cambiar_estrella(row)" />
</template>

<script>
import Produccion from "~/services/tiendas/produccion";

export default {
    props:{
        row: {
            type: Object,
            default: () => {}
        }
    },

    data(){
        return {
        }
    },
    methods: {
        async cambiar_estrella(row){
            try {
                const model = {
                    id: row.id_estrella,
                    id_user: row.created_by,
                    color: row.estrella == 6 ? null : row.estrella + 1,
                }
                const { data } = await Produccion.cambiar_estrella(model)
                if(data.dato){
                    row.estrella = data.dato.color
                    row.id_estrella = data.dato.id
                }else{
                    row.estrella = null
                    row.id_estrella = null
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        estadoColor(estado){
            switch (estado){
            case null:
                return 'icon-star-empty'
            case 1:
                return 'red-star'
            case 2:
                return 'blue-star'
            case 3:
                return 'green-star'
            case 4:
                return 'orange-star'
            case 5:
                return 'violet-star'
            case 6:
                return 'black-star'
            default:
                return 'icon-star-empty'
            }
        },
    }
}
</script>


<style lang="css" scoped>
.br-15{
    border-radius: 15px;
}
.black-star{
    color: #050005;
}
.orange-star{
    color: #FF9D32;
}
.blue-star{
    color: #03D6BC;
}
.violet-star{
    color: #9303d6;
}
.green-star{
    color: #03d649;
}
.red-star{
    color: #FF2525;
}

</style>
