<template>
    <div id="googleMap" ref="googleMap" :style="{height}" class="googleMap" />
</template>

<script>
import { debounce } from 'lodash'
import { log } from 'util'
import MarkerClusterer from '@googlemaps/markerclustererplus';
import Helper from '~/services/helper'


const OPCIONES_MAPA_DESACTIVADO = {
    panControl: false,
    zoomControl: false,
    scaleControl: false,
    fullscreenControl: false,
    draggable: false,
}

const COORDENADAS_DEFECTO = { lat: 0, lng: 0 }
const EVENTO_CAMBIO_CENTRO = 'center_changed'
const ZOOM_INICIAL_MAPA = 15
const ICONO_MARCADOR_CENTRAL = '/img/icons/tienda-mapa.svg'

export default {
    name: 'GoogleMaps',
    props: {
        coordenadasEntrada: {
            type: Object,
            default: () => (COORDENADAS_DEFECTO)
        },
        coordenadas: {
            type: Object,
            default: () => (COORDENADAS_DEFECTO)
        },
        desactivarMapa: {
            type: Boolean,
            default: false
        },
        iconoCentral:{
            type:String,
            default:'/img/icons/tendero-point-map.svg'
        },
        dragable:{
            type:Boolean,
            default:false
        },
        buscar:{
            type:String,
            default:''
        },
        height: {
            type:String,
            default:'53vh'
        }
    },
    data(){
        return {
            coordenadasCentro: {},
            googleMaps: null,
            mapa: null,
            geocoder:null,
            marcadores: [],
            mapsMarkerClustering: null,
            markerClusterer: null,
            polygon:null
        }
    },
    watch: {
        coordenadasEntrada: {
            deep: true,
            handler(){
                console.log('coordenadas mapa', this.coordenadasEntrada)
                if(! this.desactivarMapa && (this.coordenadasCentro.lat !== 0 && this.coordenadasCentro.lng !== 0)) this.dibujarMapa()
            }
        },
        buscar: {
            inmmedite: true,
            handler(val){
                console.log(val);
            }
        },
    },
    methods: {
        async dibujarMapa(){
            //console.log(this.$refs.googleMap.style = 'none','Hola ');
            this.calcularPosicionInicial()
            await this.inicializarGoogleMaps()
            this.agregarMarcadorCentral()
            this.agregarGeolocalizacion()
            this.agregarEventoMapa()
            this.setNewPostition()
        },
        calcularPosicionInicial(){
            let that = this;
            navigator.permissions.query({name: 'geolocation'})
                .then(res => {
                    if (res.state === 'granted'){
                        navigator.geolocation.getCurrentPosition(that.capturarCoordenadasNavegador, that.capturarCoordenadasDefecto, {})
                    } else {
                        that.capturarCoordenadasDefecto();
                    }
                });
        },
        capturarCoordenadasNavegador(coordenadas){
            const { latitude: lat, longitude: lng } = coordenadas.coords
            this.coordenadasCentro = { lat, lng }
        },
        capturarCoordenadasDefecto(){
            //console.log('rechazó')
            this.coordenadasCentro = Object.entries(this.coordenadasEntrada)
                .reduce((obj, [llave, valor]) => ({ ...obj, [llave]: +valor }), COORDENADAS_DEFECTO)
            ?? COORDENADAS_DEFECTO
        },
        async inicializarGoogleMaps(){
            this.googleMaps = await this.$iniciarGoogleMaps()
            //console.log('va bien', this.coordenadasCentro);

            var cordenadas = {
                lat:7.12389,
                lng:-73.11542
            }
            //Si no se obtinen codenadas inicialmente, se coloca por defecto la ubicacion central de bucaramanga
            if(this.coordenadasCentro.lat){
                cordenadas = this.coordenadasCentro
            }

            this.mapa = new this.googleMaps.Map(
                this.$refs.googleMap, {
                    zoom: ZOOM_INICIAL_MAPA,
                    center: cordenadas,
                    scrollwheel: true, // scroll del mouse se activa si draggable esta en true
                    ...(this.desactivarMapa && { ...OPCIONES_MAPA_DESACTIVADO })
                })
            // console.log('inicializarGoogleMaps -desactivarMapa ', this.desactivarMapa, 'this.googleMaps', this.googleMaps, this.mapa);
        },
        agregarMarcadorCentral(){
            this.marcadores[0]= new this.googleMaps.Marker({
                position: this.coordenadasCentro,
                map: this.mapa,
                icon: this.iconoCentral,
                draggable: this.dragable
            })
        },
        agregarGeolocalizacion(){
            this.geocoder = new this.googleMaps.Geocoder();
        },
        agregarEventoMapa(){
            if(this.dragable){
                this.googleMaps.event.addListener(this.marcadores[0], 'drag', () => this.rebotador2());
            }

            if(!this.dragable){
                if (!_.isEmpty(this.marcadores)) this.googleMaps.event.addListener(this.mapa, EVENTO_CAMBIO_CENTRO, () => this.rebotador())
            }
        },
        rebotador2: debounce(function(){
            this.actualizarPosicionMarcador2()
        }, 200),
        rebotador: debounce(function(){
            this.actualizarPosicionMarcador()
        }, 200),
        async actualizarPosicionMarcador2(){
            let that = this
            let datos = {}

            this.geocoder.geocode({'latLng': that.marcadores[0].getPosition()}, function(results, status){
                if (status == that.googleMaps.GeocoderStatus.OK){
                    datos.direccion = results[0].formatted_address;
                    datos.lat = that.marcadores[0].getPosition().lat();
                    datos.lng = that.marcadores[0].getPosition().lng();
                }
            });
            console.log('Se arrastro en marcador');

            //this.marcadores[0].setPosition(nuevaPosicion)
            //console.log(datos,'datosssssssssss');
            this.$emit('posicion', datos)
        },
        async actualizarPosicionMarcador(){

            /* TODO: Quitar cuando ya el mapa esté estable */
            console.log('cambió');
            const nuevaPosicion = await this.obtenerPosicionCentralActual()

            this.marcadores[0].setPosition(nuevaPosicion)
            this.$emit('actualizar', nuevaPosicion)
        },
        setNewPostition(){
            setTimeout(() => {
                this.mapa.setCenter(this.coordenadas)
                this.marcadores[0].setPosition(this.coordenadas)
            }, 500);
        },
        buscarDireccion(query){
            let that = this
            let datos = {}

            this.geocoder.geocode({'address': query}, function(results, status){
                if(_.isEmpty(results)){
                    Helper.notificacion('', 'La busqueda no ha arrojado resultado, por favor especifique más la dirección o verifique el código postal', 'warning')
                }
                if (status == that.googleMaps.GeocoderStatus.OK){
                    datos.direccion = results[0].formatted_address,
                    datos.lat = results[0].geometry.location.lat(),
                    datos.lng =  results[0].geometry.location.lng()
                    that.mapa.setCenter({lat: datos.lat, lng:datos.lng})
                    that.marcadores[0].setPosition({lat:datos.lat,lng:datos.lng})
                }
            });


            this.$emit('posicion', datos)
        },

        buscarCodigoPostal(address, codigo){
            const that = this
            let datos = {}
            this.geocoder.geocode({
                address,
                componentRestrictions: {
                    postalCode: codigo
                }}, function(results, status){
                if(_.isEmpty(results)){
                    Helper.notificacion('', 'La busqueda no ha arrojado resultado, por favor especifique más la dirección o verifique el código postal', 'warning')
                }
                if (status == that.googleMaps.GeocoderStatus.OK){
                    // console.log(results,'resultados');
                    datos.direccion = results[0].formatted_address,
                    datos.lat = results[0].geometry.location.lat(),
                    datos.lng =  results[0].geometry.location.lng()
                    that.mapa.setCenter({lat: datos.lat, lng:datos.lng})
                    that.marcadores[0].setPosition(datos)
                }
            });
            this.$emit('posicion', datos)
        },
        async obtenerPosicionCentralActual(){
            const centro = await this.mapa.getCenter()

            return {
                lat: this.formatearCifraCoordenadas(centro.lat()),
                lng: this.formatearCifraCoordenadas(centro.lng())
            }
        },
        /**
         * https://developers.google.com/maps/documentation/javascript/marker-clustering#maps_marker_clustering-javascript
         * https://stackoverflow.com/questions/40047210/integrate-google-maps-markerclusterer-with-infowindow
         *
         */
        async initMapMarkerClusterer(locations, group = false, tooltip = false){
            let self = this
            // const id_map = document.getElementById("googleMap");
            // this.googleMaps = await this.$iniciarGoogleMaps()

            let misCoordenadasCentro = this.coordenadasCentro
            if(this.coordenadasCentro.length === undefined){
                if(locations.length  > 0){
                    const primeraLocalizacion = locations[0]
                    misCoordenadasCentro = {lat:primeraLocalizacion.lat,lng: primeraLocalizacion.lng }
                }else{
                    misCoordenadasCentro = {lat:7.120959508258036,lng: -73.13525244855165 } // si no encuentra cordenadas de Bucaramanga
                }
            }
            this.coordenadasCentro = misCoordenadasCentro

            await this.inicializarGoogleMaps()

            let bounds = new this.googleMaps.LatLngBounds();
            let infoWin = new this.googleMaps.InfoWindow();
            this.marcadores = locations.map((location, i) => {
                // bounds.extend(location);
                // this.mapa.fitBounds(bounds);
                let marker = new  this.googleMaps.Marker({
                    position: location,
                    label: location.label? location.label : null,
                    icon: location.icon?location.icon: null,
                    map: this.mapa
                });
                if(location.info){
                    if(!tooltip){
                        this.googleMaps.event.addListener(marker, 'click', () => {
                            infoWin.setContent(location.info);
                            infoWin.open(this.mapa, marker);
                        })
                    } else {
                        this.googleMaps.event.addListener(marker, 'mouseover', () => {
                            infoWin.setContent(location.info);
                            infoWin.open(this.mapa, marker);
                        })
                        this.googleMaps.event.addListener(marker, 'mouseout', () => infoWin.close());
                    }
                }
                return marker;
            });
            this.mapa.addListener('dragend', () => {
                let centro = this.mapa.getCenter();
                let coordenadas = {
                    lat: this.formatearCifraCoordenadas(centro.lat()),
                    lng: this.formatearCifraCoordenadas(centro.lng())
                }
                this.$emit('actualizar', coordenadas)
            });

            if (group){
                this.markerClusterer = new MarkerClusterer(this.mapa, this.marcadores, {
                    imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                });
            }
        },
        addNewMarkers(locations, group = false, tooltip = false){
            setTimeout(() => {
                this.deleteMarkers()
                if(!locations.length) return
                let misCoordenadasCentro = {lat:locations[0].lat,lng: locations[0].lng }

                this.coordenadasCentro = misCoordenadasCentro
                this.mapa.setCenter(this.obtenerPosicionCentralActual())

                // this.markerClusterer.clearMarkers();
                let bounds = new this.googleMaps.LatLngBounds();
                let infoWin = new this.googleMaps.InfoWindow();

                this.marcadores = locations.map((location, i) => {
                    // bounds.extend(location);
                    // this.mapa.fitBounds(bounds);
                    let marker = new  this.googleMaps.Marker({
                        position: location,
                        label: location.label? location.label : null,
                        icon: location.icon?location.icon: null,
                        map: this.mapa
                    });
                    if(location.info){
                        if(!tooltip){
                            this.googleMaps.event.addListener(marker, 'click', () => {
                                infoWin.setContent(location.info);
                                infoWin.open(this.mapa, marker);
                            })
                        } else {
                            this.googleMaps.event.addListener(marker, 'mouseover', () => {
                                infoWin.setContent(location.info);
                                infoWin.open(this.mapa, marker);
                            })
                            this.googleMaps.event.addListener(marker, 'mouseout', () => infoWin.close());
                        }
                    }
                    return marker;
                });

                if (group){
                    this.markerClusterer = new MarkerClusterer(this.mapa, this.marcadores, {
                        imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                    });
                }
            }, 100);

        },

        formatearCifraCoordenadas: numero => +numero.toFixed(6),
        deleteMarkers(){
            this.marcadores = this.marcadores.reduce((acc, el) => {
                el.setMap(null)
                return acc
            }, [])
            this.markerClusterer && this.markerClusterer.clearMarkers()
        },
        async createGeocerca(){
            this.calcularPosicionInicial()
            await this.inicializarGoogleMaps()

            this.polygon = new this.googleMaps.Polygon({
                strokeColor: "#000000",
                strokeOpacity: 1,
                strokeWeight: 3,
                editable:true,
                draggable:true,
                map: this.mapa
            });

            this.googleMaps.event.addListener(this.mapa, "click", (event) => {
                this.addLatLngToPoly(event.latLng, this.polygon);
            });
        },
        addLatLngToPoly(latLng,poly){
            const path = poly.getPath();
            path.push(latLng);

        },
        getCordenadasPoligono(){
            const vertices = this.polygon.getPath();

            let  conten = []
            vertices.forEach(function(xy, i){
                conten.push({lat:xy.lat(), lng:xy.lng()})
            });
            return conten
        },
        async drawGeocercas(geocercas){

            //console.log('construyendo',geocercas);
            for (const geo of geocercas){
                let puntos = geo.puntos.map(function(p){
                    p.lat = parseFloat(p.lat)
                    p.lng = parseFloat(p.lng)
                    return p
                })
                //console.log(puntos);
                let geocerca = new this.googleMaps.Polygon({
                    paths: puntos,
                    strokeColor: "#000000",
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: "#6F6E6E",
                    fillOpacity: 0.35,
                    map: this.mapa
                });

                let infoWindow = new this.googleMaps.InfoWindow();
                geocerca.addListener("click", (event) => {
                    let contentString =
                        `<b>${geo.nombre}</b><br>` +
                        "Descripcion: <br>" +
                        geo.descripcion;
                    infoWindow.setContent(contentString);
                    infoWindow.setPosition(event.latLng);
                    infoWindow.open(this.mapa);
                });
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.googleMap{
    position: relative;
    width: 100%;
}
</style>
