<template>
    <div class="postulacion">
        <!-- <navbar /> -->
        <div class="position-relative app-wrapper">
            <child />
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
export default {
    name: 'Postulacion',
    computed: {
        toolbar: {
            get(){
                return this.$store.state.tema.dark
            },
            set(val){
                this.$store.commit('tema/setDark', val)
            }
        }
    },
    methods: {
        color(data){
            const root = document.documentElement
            root.style.setProperty('--general-color', data)
        }
    }
}
</script>
<style lang="scss" scoped>

.app-wrapper {
    color: #5D5D5D;
    min-height: calc(100vh);
    box-shadow: 0 3px 6px 0 #00000029;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
</style>
