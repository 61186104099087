import axios from 'axios'

const API = 'productos-admin'

const Productos = {
    selects_admin(){
        return axios(`${API}/selects`)
    },
    agregar(model){
        return axios.post(`${API}/agregar-producto`,model)
    },
    agregarProductoConCedisProducto(model){
        return axios.post(`${API}/agregar-producto-cedis`,model)
    },
    duplicarProductoConTodosCedisProducto(model){
        return axios.post(`${API}/duplicar-producto-cedis`,model)
    },
    actualizar(id_producto, model){
        return axios.post(`${API}/${id_producto}/editar-producto`,model)
    },
    eliminar(id_producto){
        return axios.delete(`${API}/${id_producto}/delete`)
    },
    categorias(params){
        return axios(`categorias-admin/lista`,{params:params})
    },
    categorias_bajas_stock(params){
        return axios(`categorias-admin/lista-stock-bajo`,{ params })
    },
    listar(params){
        return axios(`categorias-admin/productos`,{params: params})
    },
    // para tendero
    // listarCategoriasTendero(params){
    //     return axios(`categorias-cliente/lista-categorias-tendero`,{params:params})
    // },
    cambio_estado(id_producto){
        return axios.put(`${API}/${id_producto}/change-estado`)
    },
    eliminarStock(leechero){
        return axios(`${API}/${leechero}/eliminar-stock`)
    },
    detalle(id, params={}){
        return axios(`${API}/${id}/detalle`,{params})
    },
    inventario_productos(params){
        return axios('productos/inventario', { params } )
    },
    info_producto(params){
        return axios('productos/info-producto', { params } )
    },
    buscadorProductoConPresentacion(params){
        return axios(`productos/buscar`,{params: params})
    },
    getCoincidencias(params){
        return axios(`productos/sugerencias`,{params: params})
    },
    informacionProductoSeleccinado(payload){
        return axios(`productos/${payload.id_producto}/informacion`)
    },
    fusionarProductos(payload){
        return axios.put(`productos/fusionar`, payload)
    },
    obtenerFiltrados(params){
        return axios.get(`productos/filtrados`, { params })
    },
    productosDelKit(id_producto){
        return axios.get(`productos/${id_producto}/productos-del-kit`)
    },
    AgregarProductoKit(payload){
        return axios.post(`productos/agregar-producto-kit`,payload)
    },
    sumarRestarCantidad(payload){
        return axios.post(`productos/sumar-restar-producto-kit`,payload)
    },
    estadisticas(id_producto,id_cedis, params = {}){
        return axios.get(`productos/${id_producto}/${id_cedis}/estadisticas`, { params })
    },
    eliminarProductoDelkit(payload){
        return axios.post(`productos/eliminar-del-kit`,payload)
    },
    getImages(id){
        return axios.get(`productos/${id}/images`)
    },
    getProductosKit(id,idCedis = 0){
        const params = {id_cedis: idCedis}
        return axios.get(`productos/${id}/kit`, {params})
    },
    porcentajeGanancia(form){
        return axios.post(`categorias-cliente/porcentaje-ganancia`,form)
    },

}

export default Productos

