export default [
    {
        path: '/proveedores',
        meta: { nombre: 'Proveedores' },
        name: 'admin.proveedores',
        component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/proveedores').then(m => m.default || m),
        redirect: {name: 'admin.proveedores.lista'},
        children: [
            {
                path: 'lista',
                meta: { nombre: 'Proveedores' },
                name: 'admin.proveedores.lista',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/proveedores/lista').then(m => m.default || m),
            },
            {
                path: 'estadisticas',
                meta: { nombre: 'Proveedores' },
                name: 'admin.proveedores.estadisticas',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/proveedores/estadisticas').then(m => m.default || m),
            },
            {
                path: 'compras',
                meta: { nombre: 'Proveedores' },
                name: 'admin.proveedores.compras',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/proveedores/compras').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/proveedores/ver/:id_proveedor',
        meta: { nombre: 'Proveedores' },
        name: 'admin.proveedores.ver',
        component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/proveedores/ver').then(m => m.default || m),
        redirect: {name: 'admin.proveedores.ver.informacion-proveedor'},
        children: [
            {
                path: 'informacion-proveedor',
                meta: { nombre: 'Proveedores' },
                name: 'admin.proveedores.ver.informacion-proveedor',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/proveedores/ver/informacionProveedor').then(m => m.default || m),
            },
            {
                path: 'productos',
                meta: { nombre: 'Proveedores' },
                name: 'admin.proveedores.ver.productos',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/proveedores/ver/productos').then(m => m.default || m),
            },
            {
                path: 'estadisticas',
                meta: { nombre: 'Proveedores' },
                name: 'admin.proveedores.ver.estadisticas',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/proveedores/ver/estadisticas').then(m => m.default || m),
            },
            {
                path: 'comentarios',
                meta: { nombre: 'Proveedores' },
                name: 'admin.proveedores.ver.comentarios',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/proveedores/ver/comentarios').then(m => m.default || m),
            }
            
        ]
    },
]
