import axios from "axios"

const api = "notificaciones"

const notificaciones = {
    listarCarpetas: params => axios.get(`${api}/carpetas`, { params }),
    crearCarpetas: data => axios.post(`${api}/carpetas`, data ),
    actualizarCarpeta: (data, id) => axios.put(`${api}/carpetas/${id}`, data ),
    eliminarCarpeta: id => axios.delete(`${api}/carpetas/${id}`, ),
    listarCedis: () => axios.get(`${api}/listar-cedis`,),
    listarCampana: idCarpeta => axios.get(`${api}/${idCarpeta}/campanas`,),
    crearCampana: data => axios.post(`${api}/campanas`, data ),
    verCampana: id => axios.get(`${api}/campanas/${id}` ),
    verCampanaBasico: id => axios.get(`${api}/campanas-basico/${id}` ),
    eliminarCampana: id => axios.delete(`${api}/campanas/${id}` ),
    usuariosCampana: id => axios.get(`${api}/usuarios-campana/${id}` ),
    editarCampana: (data, id) => axios.put(`${api}/campanas/${id}`, data ),
    modificarCampana: (data, id) => axios.put(`${api}/modificar-campana/${id}`, data ),
    promociones: () => axios.get(`${api}/promociones`),
    categorias: () => axios.get(`${api}/categorias`),
    crearLanzamiento: data => axios.post(`${api}/lanzamientos`, data ),
    verLanzamiento: (id, params) => axios.get(`${api}/lanzamientos/${id}`, { params }),
    eliminarLanzamiento: id => axios.delete(`${api}/lanzamientos/${id}` ),
    programarLanzamiento: (data, id) => axios.put(`${api}/programar-lanzamiento/${id}`, data ),
    editarLanzamiento: (data, id) => axios.put(`${api}/editar-lanzamiento/${id}`, data ),
    cambiarEstadoLanzamiento: (data, id) => axios.put(`${api}/cambiar-estado-lanzamiento/${id}`, data),
    eliminarUsuarioLanzamiento: data => axios.post(`${api}/eliminar-usuario-lanzamiento`, data ),
    copiarUsuariosLanzamientos: data => axios.post(`${api}/copiar-usuario-lanzamiento`, data ),
    agregarUsuariosLanzamiento: data => axios.post(`${api}/agregar-usuarios-lanzamiento`, data ),
    agregarUsuariosLanzamientoMultiple: data => axios.post(`${api}/agregar-usuarios-lanzamiento-multiple`, data ),
    listarCarpetasCopiar: params => axios.get(`${api}/carpetas-copiar`, { params }),
    listarCampanasCopiar: idCarpeta => axios.get(`${api}/${idCarpeta}/campanas-copiar`),
    listarLanzamientoCopiar: idCampana => axios.get(`${api}/${idCampana}/lanzamientos-copiar`),
    buscarClientes: (params, idLanzamiento) => axios.get(`${api}/lanzamiento/${idLanzamiento}/buscar-clientes`, { params }),
    checkAllClientes: (params, idLanzamiento) => axios.get(`${api}/lanzamiento/${idLanzamiento}/buscar-clientes/seleccion`, { params }),
    buscarLeecheros: params => axios.get(`${api}/buscar-leecheros`, { params }),
    grafica: params => axios.get(`${api}/grafica`, { params }),
    cambiarGraficaCampanas: params => axios.get(`${api}/cambiar-grafica-campanas`, { params }),
    cambiarGraficaCedis: params => axios.get(`${api}/cambiar-grafica-cedis`, { params }),
    verLanzamientosEstadistica: params => axios.get(`${api}/lanzamientos-estadisticas`, { params }),


}

export default notificaciones