<template>
    <div id="app">
        <loading ref="loading" />
        <transition name="page" mode="out-in">
            <component :is="layout" v-if="layout" />
        </transition>
    </div>
</template>

<script>
import Loading from './components/Loading'
import moment from "moment";
// Load layout components dynamically.
const requireContext = require.context('~/layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
    .reduce((components, [name, component]) => {
        components[name] = component.default || component
        return components
    }, {})

export default {
    el: '#app',
    components: {
        Loading
    },
    data: () => ({
        layout: null,
        defaultLayout: 'default'
    }),
    metaInfo(){
        const  appName  = this.$config.proyecto
        return {
            title: appName,
            titleTemplate: `%s · ${appName}`
        }
    },
    async mounted(){
        if(localStorage.configuracion){
            if(this.$config.dia < moment().day()){
                await this.getParamsGenerales()
            }
        }else{
            await this.getParamsGenerales()
        }
        let favIcon = document.getElementById("favicon");
        favIcon.href = this.$config.favicon;
        this.$loading = this.$refs.loading
        // Setting variable color app
        //console.log(this.$config);
        let colorApp = '#' + this.$config.color;
        document.documentElement.style.setProperty('--color-general', colorApp, "important");
    },
    methods: {
        /**
         * Set the application layout.
         *
         * @param {String} layout
         */
        setLayout(layout){
            if (!layout || !layouts[layout]){
                layout = this.defaultLayout
            }
            this.layout = layouts[layout]
        },
        async getParamsGenerales(){
            await this.$store.dispatch("x_parametros/getParamsGenerales")
        }
    }
}
</script>
