<template>
    <el-drawer :visible.sync="drawer" title="Filtro" class="bg-lateral" size="30%">
        <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 104px)">
            <div class="row mx-0 px-4">
                <div class="pr-1 d-flex align-items-end pb-2 col-auto">
                    <input v-model="tipo" :value="1" type="radio" class="option-input radio" />
                </div>
                <div class="col pl-0">
                    <p class="text-general pl-3 f-14">Fecha de Pedido</p>
                    <el-date-picker
                    v-model="fecha_pedido"
                    class="w-100 br-12"
                    type="daterange"
                    :disabled="tipo == 2"
                    range-separator="-"
                    start-placeholder="Inicio"
                    end-placeholder="Fin"
                    value-format="yyyy-MM-dd"
                    format="dd-MMM-yyyy"
                    />
                </div>
            </div>
            <div class="row mx-0 mt-3 px-4">
                <div class="pr-1 d-flex align-items-end pb-2 col-auto">
                    <input v-model="tipo" :value="2" type="radio" class="option-input radio" />
                </div>
                <div class="col pl-0">
                    <p class="text-general pl-3 f-14">Fecha de Entrega</p>
                    <el-date-picker
                    v-model="fecha_entrega"
                    class="w-100 br-12"
                    type="daterange"
                    :disabled="tipo == 1"
                    range-separator="-"
                    start-placeholder="Inicio"
                    end-placeholder="Fin"
                    value-format="yyyy-MM-dd"
                    format="dd-MMM-yyyy"
                    />
                </div>
            </div>

            <div v-show="opcion == 2" class="row mx-5 mt-3">
                <p class="my-3">Mostar Por Estado</p>
                <el-checkbox-group v-model="filtro_estados_admin">
                    <el-checkbox class="my-1 w-100 check-red" :label="1">
                        Entregados
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="2">
                        Cancelados
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="3">
                        Rechazados
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="4">
                        Cancelados Por Mi
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="5">
                        Reclamo Finalizado
                    </el-checkbox>
                </el-checkbox-group>
            </div>

            <div v-show="opcion == 3" class="row mx-5 mt-3">
                <p class="my-3">Mostar Por Estado</p>
                <el-checkbox-group v-model="filtro_estados_almacen_tendero">
                    <el-checkbox class="my-1 w-100 check-red" :label="1">
                        En Proceso
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="2">
                        Entregados
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="3">
                        Cancelados Por Mi
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="4">
                        Cancelados Por El Cedis
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="5">
                        En Reclamo
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="6">
                        Reclamo Finalizado
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <!--
            <div v-show="opcion == 5" class="row mx-5 mt-3">
                <p class="my-3">Mostar Por Estado</p>
                <el-checkbox-group v-model="filtro_estados_historial_tendero">
                    <el-checkbox class="my-1 w-100 check-red" :label="1">
                        Entregados
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="2">
                        Cancelados
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="3">
                        Rechazados
                    </el-checkbox>
                    <el-checkbox class="my-1 w-100 check-red" :label="4">
                        Cancelados Por Mi
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            -->
        </div>
        <div class="row mx-0 justify-center">
            <button type="button" class="btn-grey px-3 mr-3" @click="limpiar">
                Limpiar
            </button>
            <button type="button" class="btn-general px-3" @click="filtrar">
                Filtrar
            </button>
        </div>
    </el-drawer>
</template>
<script>
export default {
    props: {
        opcion:{
            type:Number,
            default:1 // 1 Admin Pedidos - 2 Historial Admin Pedidos -
        }
    },
    data(){
        return {
            drawer: false,
            tipo:1, // 1 Creacion - 2 Entrega
            fecha_pedido:[],
            fecha_entrega:[],
            filtro_estados_admin : [1,2,3,4,5],
            filtro_estados_almacen_tendero : [1,2,3,4,5,6],
            filtro_estados_historial_tendero : [1,2,3,4],
        };
    },
    methods:{
        open(){
            this.drawer = !this.drawer
        },

        filtrar(){
            switch (this.opcion){
            case 1:
                this.filtro_admin_pedidos()
                break;
            case 2:
                this.filtro_admin_historial()
                break;
            case 3:
                this.filtro_almacen_tendero()
                break;
            case 4:
                this.filtro_pedidos_tendero()
                break;
            case 5:
                this.filtro_tendero_historial()
                break;

            default:
                break;
            }

        },
        filtro_admin_pedidos(){

            let obj = {}
            if(this.tipo == 1){
                obj.tipo = this.tipo,
                obj.fecha =this.fecha_pedido
            }else{
                obj.tipo = this.tipo,
                obj.fecha = this.fecha_entrega
            }


            this.$emit('filtrar',obj)
            this.drawer = false
        },
        filtro_admin_historial(){

            let estados = []
            for (var i = 1; i <= 5; i++){

                if(this.filtro_estados_admin.includes(i)){
                    switch (i){
                    case 1:
                        estados.push(4)
                        break;
                    case 2:
                        estados.push(26)
                        break;
                    case 3:
                        estados.push(25)
                        break;
                    case 4:
                        estados.push(27)
                        break;
                    case 5:
                        estados.push(32)
                        break;
                    default:

                    }
                }
            }

            let obj = {}
            if(this.tipo == 1){
                obj.tipo = this.tipo,
                obj.fecha =this.fecha_pedido
            }else{
                obj.tipo = this.tipo,
                obj.fecha = this.fecha_entrega
            }

            obj.estados = estados

            this.$emit('filtrar',obj)
            this.drawer = false
        },
        filtro_almacen_tendero(){
            let estados = []
            for (var i = 1; i <= 6; i++){

                if(this.filtro_estados_almacen_tendero.includes(i)){
                    switch (i){
                    case 1:
                        estados.push(1,2,201,202,3,31)
                        break;
                    case 2:
                        estados.push(4)
                        break;
                    case 3:
                        estados.push(26)
                        break;
                    case 4:
                        estados.push(25,27)
                        break;
                    case 5:
                        estados.push(31)
                        break;
                    case 6:
                        estados.push(32)
                        break;
                    default:

                    }
                }
            }

            let obj = {}
            if(this.tipo == 1){
                obj.tipo = this.tipo,
                obj.fecha =this.fecha_pedido
            }else{
                obj.tipo = this.tipo,
                obj.fecha = this.fecha_entrega
            }

            obj.estados = estados

            this.$emit('filtrar',obj)
            this.drawer = false
        },
        filtro_pedidos_tendero(){

            let obj = {}
            if(this.tipo == 1){
                obj.tipo = this.tipo,
                obj.fecha =this.fecha_pedido
            }else{
                obj.tipo = this.tipo,
                obj.fecha = this.fecha_entrega
            }


            this.$emit('filtrar',obj)
            this.drawer = false
        },
        filtro_tendero_historial(){

            let estados = []
            for (var i = 1; i <= 4; i++){

                if(this.filtro_estados_historial_tendero.includes(i)){
                    switch (i){
                    case 1:
                        estados.push(4,32,33,34)
                        break;
                    case 2:
                        estados.push(27,21,22,23,24)
                        break;
                    case 3:
                        estados.push(25)
                        break;
                    case 4:
                        estados.push(26)
                        break;
                    default:

                    }
                }
            }

            let obj = {}
            if(this.tipo == 1){
                obj.tipo = this.tipo,
                obj.fecha =this.fecha_pedido
            }else{
                obj.tipo = this.tipo,
                obj.fecha = this.fecha_entrega
            }

            obj.estados = estados

            this.$emit('filtrar',obj)
            this.drawer = false
        },
        limpiar(){

            if(this.opcion == 2){
                this.limpiar_admin_historial()
                return
            }
            if(this.opcion == 3){
                this.limpiar_almacen_tendero()
                return
            }
            if(this.opcion == 5){
                this.limpiar_tendero_historial()
                return
            }

            this.fecha_pedido = []
            this.fecha_entrega = []
            this.tipo = 1

            let obj = {
                tipo:0,
                fecha:[]
            }

            this.$emit('filtrar',obj)
            this.drawer = false
        },
        limpiar_admin_historial(){
            this.fecha_pedido = []
            this.fecha_entrega = []
            this.tipo = 1
            this.filtro_estados_admin = [1,2,3,4]

            let obj = {
                tipo:0,
                fecha:[],
                estados:[]
            }

            this.$emit('filtrar',obj)
            this.drawer = false
        },
        limpiar_almacen_tendero(){
            this.fecha_pedido = []
            this.fecha_entrega = []
            this.tipo = 1
            this.filtro_estados_almacen_tendero = [1,2,3,4]

            let obj = {
                tipo:0,
                fecha:[],
                estados:[]
            }

            this.$emit('filtrar',obj)
            this.drawer = false
        },
        limpiar_tendero_historial(){
            this.fecha_pedido = []
            this.fecha_entrega = []
            this.tipo = 1
            this.filtro_estados_historial_tendero = [1,2,3,4]

            let obj = {
                tipo:0,
                fecha:[],
                estados:[]
            }

            this.$emit('filtrar',obj)
            this.drawer = false
        },
    }
}
</script>
