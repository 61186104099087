<template>
    <section>
        <div class="row mx-0 justify-center mt-3">
            <div v-show="true" class="border text-general f-14 px-3  br-8 mx-2 cr-pointer" @click="$emit('initPago')">
                <img src="/img/icons/boton_payu.png" alt="" width="250" height="80" />
            </div>
            <div id="idPayuButtonContainer" />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        async initBotonPayu(infoPago){
            try {

                var html_button="<form id='formPago' method='post' action='https://checkout.payulatam.com/ppp-web-gateway-payu/'>\
              <input name='merchantId'    type='hidden'  value='"+infoPago.merchantId+"'   >\
              <input name='accountId'     type='hidden'  value='"+infoPago.accountId+"'   >\
              <input name='extra1'     type='hidden'  value='"+infoPago.extra1+"'   >\
              <input name='extra2'     type='hidden'  value='"+infoPago.dataBase+"'   >\
              <input name='description'   type='hidden'  value='"+infoPago.description+"'   >\
              <input name='referenceCode' type='hidden'  value='"+infoPago.referenceCode+"'   >\
              <input name='amount'        type='hidden'  value='"+infoPago.amount+"'   >\
              <input name='tax'           type='hidden'  value='"+infoPago.tax+"'   >\
              <input name='taxReturnBase' type='hidden'  value='"+infoPago.taxReturnBase+"'   >\
              <input name='currency'      type='hidden'  value='"+infoPago.currency+"'   >\
              <input name='signature'     type='hidden'  value='"+infoPago.signature+"'   >\
              <input name='test'          type='hidden'  value='"+infoPago.test+"'   >\
              <input name='buyerEmail'    type='hidden'  value='"+infoPago.buyerEmail+"'   >\
              <input name='buyerFullName'    type='hidden'  value='"+infoPago.buyerFullName+"'   >\
              <input name='responseUrl'    type='hidden'  value='"+infoPago.responseUrl+"'   >\
              <input name='confirmationUrl'    type='hidden'  value='"+infoPago.confirmationUrl+"'   >\
            </form>";

                var elem = document.getElementById('idPayuButtonContainer');
                elem.innerHTML = html_button;
                document.getElementById('formPago').submit();
            } catch (e){
                this.error_catch(e)
            }

        },

    }
}
</script>
