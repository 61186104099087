<template>
    <ValidationObserver ref="validacion" class="h-100">
        <div class="overflow-auto custom-scroll" :style="alto_input">
            <div v-for="(data, indice) in listado" :key="indice" class="d-flex px-2 my-3 no-gutters">
                <img class="rounded-circle obj-cover" height="32" width="32" :src="_.defaultTo(data.foto_miniatura, '/img/no-imagen/sin_user.png')" />
                <div class="col chat-bubble text-general ml-3" :class="data.estado == 'pagado' ? 'chat-bubble-pay' : '' ">
                    <div class="row mx-0 my-1 f-13 text-muted">
                        <div class="col-12 f-600 f-15">
                            <p v-if="!data.nombre" class="ml-2 text-general">Sin nombre</p>
                            <p v-text="data.nombre" />
                        </div>
                        <div class="col-12">
                            <p class="f-15" style="word-break:break-all;" v-html="formatearTextoHtml(data.comentario)" />
                        </div>
                        <div class="col-12 text-right">
                            <i v-show="data.icono" :class="`${fun_icono(parseInt(data.icono))} pr-2`" />
                            <i class="text-muted" v-text="data.fecha" />
                        </div>
                    </div>
                    <div class="chat-bubble-arrow-left" />
                </div>
            </div>
            <div v-show="listado.length <= 0" class="row mx-0 justify-center h-100 align-items-center">
                <div class="">
                    <div class="br-12 border d-middle-center" style="width:162px;height:162px;">
                        <img src="/img/no-imagen/comment.svg" alt="" />
                    </div>
                    <p class="text-center">
                        No hay comentarios
                    </p>
                </div>
            </div>
        </div>
        <div id="cont_input" class="row mx-0 mt-2 text-general px-2">
            <div class="col pr-textarea position-relative">
                <ValidationProvider v-slot="{errors}" rules="required" name="comentario" class="position-relative">
                    <el-input
                    v-model="form.comentario"
                    placeholder="Por favor, escriba su comentario"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    :maxlength="caracteres"
                    show-word-limit
                    />
                    <span class="text-danger f-10">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>
        <div class="row mx-0 px-3 justify-content-end my-2">
            <el-dropdown size="mini" placement="bottom" type="primary" @command="handleCommand">
                <span class="el-dropdown-link">
                    <i :class="`${fun_icono(form.icono)} f-20`" />
                </span>
                <el-dropdown-menu slot="dropdown" class="br-10" style="width:45px;">
                    <el-dropdown-item :command="null" :disabled="form.icono === null">
                        <i class="icon-sticker-emoji f-20" />
                    </el-dropdown-item>
                    <el-dropdown-item :command="1" :disabled="form.icono === 1">
                        <i class="icon-currency-usd-circle text-aqua f-20" />
                    </el-dropdown-item>
                    <el-dropdown-item :command="2" :disabled="form.icono === 2">
                        <i class="icon-attention-alt f-20" />
                    </el-dropdown-item>
                    <el-dropdown-item :command="3" :disabled="form.icono === 3">
                        <i class="icon-star text-warning f-20" />
                    </el-dropdown-item>
                    <el-dropdown-item :command="4" :disabled="form.icono === 4">
                        <i class="icon-attention-outline text-orange f-20" />
                    </el-dropdown-item>
                    <el-dropdown-item :command="5" :disabled="form.icono === 5">
                        <i class="icon-thumb-up text-primary f-20" />
                    </el-dropdown-item>
                    <el-dropdown-item :command="6" :disabled="form.icono === 6">
                        <i class="icon-thumb-down text-general f-20" />
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <button slot="reference" type="button" class="btn border-0 btn-sm br-20 text-white bg-general" name="button" @click="crearComentario">
                Comentar
            </button>
        </div>
    </ValidationObserver>
</template>
<script>
import Comentarios from '~/services/comentarios/comentarios'

export default {
    props: {
        titulo: {
            type: String,
            default: 'Comentarios'
        },
        id: {
            type: Number,
            default: 0
        },
        modelo:{
            type: Number,
            required: false,
            default: 0
        },
        caracteres: {
            type: Number,
            default: 500
        },
        contador: {
            type: Number,
            default: 0
        },
        tendero: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            form: {
                comentario: null,
                icono: null
            },
            fileList: [],
            listado: [

            ],
            alto_input: 'height:calc(100% - 107px)'
        }
    },
    watch: {
        id(val){
            this.id = val
            this.listarComentarios()
        },
        'form.comentario'(val){
            this.altoMess()
        }
    },
    mounted(){

        this.limpiar()
        this.listarComentarios()
    },
    created(){
        this.altoMess()
    },
    methods:{
        altoMess(){
            // var inp = document.getElementById('cont_input');
            var inp = document.querySelector("#cont_input");
            let alto = 'height:calc(100% - ' + ((inp?inp.clientHeight:40) + 120) +'px)'
            this.alto_input = alto
        },
        async listarComentarios(){
            try {
                if(this.id && this.modelo){
                    this.cargando = true
                    const params ={
                        tendero: this.tendero,
                        id_user_tienda: this.$usuario.rol_nombre==='tendero'? this.$usuario.id: null
                    }
                    const { data } = await Comentarios.GetListarComentario(this.id, this.modelo, params)
                    this.listado = data.lista
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.cargando = false

            }
        },
        async crearComentario(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const { data } = await Comentarios.PostCrearComentario(this.id, this.modelo, this.form)
                this.notificacion('Éxito', data.mensaje, 'success')
                this.limpiar()
                this.listarComentarios()
            } catch (error){
                this.error_catch(error)
            }
        },
        fun_icono(icon){
            switch (icon){
            case null:
                return 'icon-sticker-emoji'
            case 1:
                return 'icon-currency-usd-circle text-aqua'
            case 2:
                return 'icon-attention-alt'
            case 3:
                return 'icon-star text-warning'
            case 4:
                return 'icon-attention-outline text-orange'
            case 5:
                return 'icon-thumb-up text-primary'
            case 6:
                return 'icon-thumb-down text-general'
            default:
                return 'icon-sticker-emoji'
            }
        },
        handleCommand(e){
            this.form.icono = e
        },
        limpiar(){
            this.$refs.validacion.reset()
            this.form.comentario = null
            this.form.icono = null
        }
    }
}
</script>
<style lang="scss" scoped>
.chat-bubble{
    background: #f5f5f5;
    /* border: 1px solid var(--color-general); */
    border: 1px solid #F8FCFF;
}
.chat-bubble-pay{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 2px solid #0892DD;
}
.chat-bubble-right{
    background: #f5f5f5;
    border: 1px solid red;
}
.border-bottom-active{
    border-bottom: 3px solid #FF2525;
}
.text-green{
    color: #28D07B;
}
.text-yellow{
    color: #FF9D32;
}
.text-gr-black{
    background: -webkit-linear-gradient(90deg, #ffffff, #000000 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.pos-sticker{
    position: absolute;
    top: 5px;
    right: 50px;
    z-index: 1;
}
.upload-demo{
    position: absolute;
    top: 7px;
    right: 25px;
    z-index: 1;
}
</style>
