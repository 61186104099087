export const state = {
    productos: [],
    id_producto:null,
    is_kit:null,
    query:''
}

export const getters = {
    productos: state => state.productos,
    id_producto: state => state.id_producto,
    is_kit: state => state.is_kit,
    query: state => state.query,

}

export const mutations = {
    set_productos: (state,payload) => {
        state.productos.push(...payload);
    },
    vaciar_productos: (state,payload) => {

        state.productos = [];
    },
    set_query: (state,payload) => {

        state.query = payload;
    },
    set_id_producto: (state,payload) => {
        state.id_producto = payload
    },
    set_is_kit: (state,payload) => {
        state.is_kit = payload
    },
    update_productos: (state,payload) => {

        //Procedemos a buscar el indice del producto para despues alterarlo
        let idx = state.productos.findIndex(p=>p.id === payload.id)
        state.productos[idx] = payload

        //Hacemos un copia de los productos
        let backup_productos = state.productos

        //Vaciamos la data para que la vista reaccione
        state.productos = []

        // recargamos la informacion
        state.productos = backup_productos

    },
    alter_producto(state,payload){

        let index = state.productos.findIndex(p=>p.id === payload.id);


        for (let [key,value] of Object.entries(payload)){
            state.productos[index][key] = value
        }

    },
    setImagen(state,payload){
        let index = state.productos.findIndex(p=>p.id === payload.id);
        state.productos[index].imagen = payload.imagen

        // for (let [key,value] of Object.entries(payload)){
        //     state.productos[index][key] = value
        // }
    },
    delete_producto: (state,payload) => {

        //Procedemos a buscar el indice del producto para despues eliminarlo
        let idx = state.productos.findIndex(p=>p.id === payload)
        console.log(idx);
        state.productos.splice(idx, 1);

        //Hacemos un copia de los productos
        let backup_productos = state.productos

        //Vaciamos la data para que la vista reaccione
        state.productos = []

        // recargamos la informacion
        state.productos = backup_productos

    },
    push_producto_nuevo(state,payload){
        state.productos.unshift(payload)
    }


}

export const actions = {

}
