import axios from "axios";

const API = "notify";

const Notificaciones = {
    listar(page){
        return axios(`${API}/listar`,{params:{page}});
    },
    lectura(){
        return axios(`${API}/nueva-lectura`);
    }

};

export default Notificaciones;
