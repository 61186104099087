
import Promociones from '~/services/promociones/promociones'

export const state = {
    promociones: [],
    id_promocion: null,
    detalle:{
        id: null,
        nombre: '',
        imagen: null,
        imagen_show: null,
        tipo: '',
        condiciones: '',
        categorias:1,
        visible: 1,
        promo_categorias:[]
    },
    cedis: [],
    productos: [],
    tipo_promocion: null,
    loading_general: false,
    detalle_promo:{
        nombre: '',
        condiciones: '',
        pro_agregados: 0,
        pro_permitidos: 0,
        promo_categorias:[],
        imagen_show: null
    }


}

export const getters = {
    promociones: state => state.promociones,
    id_promocion: state => state.id_promocion,
    detalle: state => state.detalle,
    cedis: state => state.cedis,
    productos: state => state.productos,
    tipo_promocion: state => state.tipo_promocion,
    loading_general: state => state.loading_general,
    detalle_promo: state => state.detalle_promo
}

export const mutations = {
    set_promociones: (state,payload) => {
        state.promociones = payload
    },
    set_id_promocion: (state,payload) => {
        state.id_promocion = payload
    },
    set_detalle: (state,payload) => {
        state.detalle = payload
    },
    set_cedis: (state,payload) => {
        state.cedis = payload
    },
    set_productos: (state,payload) => {
        state.productos = payload
    },
    set_tipo_promocion: (state,payload) => {
        state.tipo_promocion = payload
    },
    set_loading_general: (state,payload) => {
        state.loading_general = payload
    },
    set_detalle_promo: (state,payload) => {
        state.detalle_promo = payload
    }

}

export const actions = {
    async action_listar_promociones({commit},payload = null){
        const {data} = await Promociones.listarPromociones(payload)
        commit('set_promociones',data.promociones)
    },
    async action_listar_detalle_promocion({commit},payload = null){
        const {data} = await Promociones.detallePromocion(payload)
        commit('set_detalle',data.promocion)
        commit('set_cedis',data.cedis)
    },
    async action_listar_productos({commit},payload = null){
        const {data} = await Promociones.listarProductos(payload)
        commit('set_productos', data.productos)
    },
    // Tendero
    async action_listar_tendero_promociones({commit},payload = null){
        const {data} = await Promociones.listarTenderoPromociones(payload)
        commit('set_cedis',data.data.promociones)
    },
    async action_detalle_promo({commit},payload = null){
        const {data} = await Promociones.detallePromoTendero(payload)
        commit('set_detalle_promo',data.promocion)
    },
    // Alamcen
    async action_listar_productos_admin_almacen({commit},payload = null){
        const {data} = await Promociones.listarProductosAdminAlmacen(payload)
        commit('set_productos',data.productos)
    },
    async action_detalle_promo_almacen({commit},payload = null){
        const {data} = await Promociones.detallePromoAdminAlmacen(payload)
        commit('set_detalle',data.promocion)
    },
    async action_listar_promociones_almacen({commit},payload = null){
        const {data} = await Promociones.listarPromocionesAlmacen(payload)
        commit('set_promociones',data.promociones)
    },

}
