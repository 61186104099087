<template>
    <el-drawer :visible.sync="drawer" :with-header="false">
        <div class="bg-fondo h-100">
            <div v-if="doble" class="row mx-0 border-bottom">
                <div class="col text-center pt-2 px-2 pb-2 cr-pointer" :class="tab == 1 ? 'active-tab' : 'text-gris2'" @click="mensajeT('Hola1',1)">
                    {{ $usuario.rol_nombre == 'admin' ? `Chat Con ${$config.vendedor}` : 'Chat con cedis' }}
                </div>
                <div class="col text-center pt-2 px-2 pb-2 cr-pointer" :class="tab == 2 ? 'active-tab' : 'text-gris2'" @click="mensajeT('Hola2',2)">
                    {{ $usuario.rol_nombre == 'admin' ? `Chat con ${$config.cliente}` : `Chat cedis-${$config.cliente}` }}
                </div>
            </div>
            <div v-if="tipo==2 || (tipo==6 && momento==1)" class="row mx-0 align-items-center p-2 bg-general3 text-white shadow-16">
                <i class="icon-cancel f-20 cr-pointer" @click="cerrarModal" />
                <img class="rounded-circle mx-2 border bg-white" width="38" height="38" :src="cliente.imagen_firmada" alt="" />
                <p class="f-600 tres-puntos">{{ cliente.nombre }}</p>
                <p class="ml-auto">Chat</p>
                <!-- <i class="icon-star mx-2 text-aqua" /> -->
            </div>
            <div v-else-if="tipo==3" class="row mx-0 align-items-center p-2 py-3 bg-general3 text-white shadow-16">
                <template v-if="$usuario.rol == 1">
                    <i class="icon-cancel mr-2 f-20 cr-pointer" @click="cerrarModal" />
                    <b>Soporte</b>
                    <p class="ml-auto cr-pointer" @click="modalActivarInactivarChat">{{ tienda.chat_admin===1 ?'Inactivar':'Activar' }} chat</p>
                </template>
                <template v-else>
                    <i class="icon-cancel mr-2 f-20 cr-pointer" @click="cerrarModal" />
                    <b>Asesor de leeche</b>
                </template>
            </div>
            <div v-else-if="(tipo==6 || tipo == 5)&& momento==2" class="row mx-0 align-items-center p-2 bg-general3 text-white shadow-16">
                <i class="icon-cancel f-20 cr-pointer" @click="cerrarModal" />
                <img class="rounded-circle mx-2 border bg-white" width="38" height="38" :src="tienda.logo_mini" alt="" />
                <p class="f-600 tres-puntos">{{ tienda.nombre }}</p>
                <p class="ml-auto">Chat</p>
                <estrellas :row="tienda" />
                <!-- <i class="icon-star mx-2 text-aqua" /> -->
            </div>
            <div v-else-if="tipo == 5" class="row mx-0 align-items-center p-2 bg-general3 text-white shadow-16">
                <template v-if="$usuario.rol_nombre == 'admin'">
                    <i class="icon-cancel f-20 cr-pointer" @click="cerrarModal" />
                    <img class="rounded-circle mx-2 border bg-white" width="38" height="38" :src="tienda.logo_mini" alt="" />
                    <p class="f-600 tres-puntos">{{ tienda.nombre }}</p>
                </template>
                <template v-else>
                    <i class="icon-cancel f-20 cr-pointer" @click="cerrarModal" />
                    <i class="icon-warehouse f-22 cr-pointer mx-2" />
                    <!-- <img class="rounded-circle mx-2 border bg-white" width="38" height="38" :src="tienda.logo_mini" alt="" /> -->
                    <p class="f-600 tres-puntos">Chat con cedis</p>
                </template>
                <!-- <p class="ml-auto">Chat</p> -->
                <!-- <estrellas :row="tienda" /> -->
                <!-- <i class="icon-star mx-2 text-aqua" /> -->
            </div>
            <div v-else-if="tipo==6 && momento==3" class="row mx-0 align-items-center p-2 bg-general3 text-white shadow-16">
                <p class="text-white">Chat con cedis</p>
                <!-- <i class="icon-star mx-2 text-aqua" /> -->
            </div>
            <div v-else class="row mx-0 align-items-center p-2 bg-general3 text-white shadow-16">
                <i class="icon-cancel f-20 cr-pointer" @click="cerrarModal" />
            </div>
            <div class="px-2" style="height:calc(100vh - 135px)">
                <chat ref="mychat" :user="id" :limitar-mensajes="limitarMensajes" :tipo="tipo" :momento="momento" :finalizado="finalizado" :fecha-finalizado="fecha_finalizado_chat" :height="doble" :tab="tab" :cliente="cliente" />
                <!-- <chat :user="id" :tipo="tipo" :momento="momento" @actualizar-lectura="actualizarUltimaLectura" /> -->
            </div>
        </div>
        <slot />
        <modalConfirmar
        ref="modalActivarChat"
        :titulo="tituloModal"
        :mensaje="mensaje" icon="enviar-pedido"
        @guardar="confirmar_cambios"
        />
    </el-drawer>
</template>

<script>
/*
 this.$refs.modalChat.toggle(this.id_pedido, 2, false, momento) // 2 = tipo pedido

 1 parametro = id principal
 2 parametro = tipo
    1 =
    2 = pedido
 3 parametro = finalizado(defecto false)
 4 parametro = momento, cuando es de tipo pedido
*/
import Chat from '~/services/chat/chat'

export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        leechero: {
            type: Object,
            default(){
                return {}
            }
        },
        pedido: {
            type: Object,
            default(){
                return {}
            }
        },
        estado: {
            type: Boolean,
            default: false
        },
        limitarMensajes: {
            type: Boolean,
            default: true
        },

    },
    data(){
        return {
            drawer: false,
            id: 0,
            tipo: 2,
            tipoOriginal: 2,
            tab: null,
            momento: 2,
            finalizado: false,
            fecha_finalizado_chat: '',
            cliente: {
                id: null,
                nombre: '',
                imagen_firmada: null
            },
            tienda:{
                id: null,
                chat_admin: 1,
                logo_mini: null,
                id_estrella:null,
                estrella: null,
                created_by: null
            },
            tituloModal: 'Inactivar',
            mensaje: '',
            doble: false
        };
    },
    watch:{
        drawer(val){
            if(!val){
                this.$refs.mychat.unsubscribe()
            }
        },
        tab(val){
            // if (val != null) return
            if (val == null) return
            if (this.tipoOriginal == 5){
                this.tipo = val == 1 ? 5 : 2
                this.id = this.tipo == 2 ? this.pedido.id_pedido : this.pedido.id
                if(this.tipo==2){
                    this.obtenerDatosUsuario()
                }else if(this.tipo == 5){
                    this.obtenerInfoTienda()
                }
            } else if (this.tipoOriginal == 2){
                this.tipo = val == 1 ? 5 : 2
                this.id = this.tipo == 2 ? this.pedido.id : this.pedido.id_admin_pedido
                if(this.tipo==2){
                    this.obtenerDatosUsuario()
                // }else if(this.tipo == 5){
                    // this.obtenerInfoTienda()
                }
            }
            if(this.$refs.mychat) this.$refs.mychat.resetear()
            if(this.$refs.mychat) this.$refs.mychat.unsubscribe()
        },
        // tipo(val){
        //     if (val == 2 || val == 5){
        //     }
        // }
    },
    methods:{
        // tipo = 6 chat_creditos y el momneto se usa solo para saber a donde consulta información del cliente o tendero ó chat  con proveedor
        toggle(id, tipo=2, finalizado=false, momento=2){
            this.limpiar()
            this.tipo = tipo
            this.tipoOriginal = tipo
            this.id = id
            this.momento = momento
            this.finalizado = finalizado
            this.actualizarUltimaLectura()

            if (tipo == 5){
                this.fecha_finalizado_chat = this.pedido.fecha_finalizado_chat
                this.doble = this.pedido.id_pedido != null
                this.tab = this.doble ? 1 : null
                this.tipo = this.doble ? this.tab != 1 ? 2 : 5 : tipo
                this.id = this.tipo == 2 ? this.pedido.id_pedido : this.pedido.id
                this.obtenerInfoTienda()
            }
            if (tipo == 2){
                this.doble = this.pedido.directo == 1
                this.tab = this.doble ? 1 : null
                this.tipo = this.doble ? this.tab != 1 ? 2 : 5 : tipo
                console.log('aqui',this.tipo);
                this.id = this.tipo == 2 ? this.pedido.id : this.pedido.id_admin_pedido
                // this.obtenerInfoTienda()
            }
            if(this.tipo==2){
                this.obtenerDatosUsuario()
            }else if(this.tipo==3){
                this.obtenerInfoTienda()
            }else if(this.tipo==6){
                if(this.momento===1){
                    this.obtenerDatosUserClienteDesdeCreditos()
                }else{
                    this.obtenerInfoTiendaChatCredito()
                }
            }
            if(this.$refs.mychat) this.$refs.mychat.resetear()


            this.drawer = !this.drawer
        },
        mensajeT(msg,tab){
            this.tab = tab
            console.log(msg);
        },
        modalActivarInactivarChat(){
            this.tituloModal=  this.tienda.chat_admin===1?'Inactivar chat':'Activar chat'
            if(this.tienda.chat_admin===1){
                this.tituloModal= 'Inactivar chat'
                this.mensaje = `¿Desea inactivar el chat con el ${this.$config.vendedor}? Él solo podrá enviar 3 mensajes máximo, hasta que reciba una respuesta no pondrá un nuevo mensaje`
            }else{
                this.tituloModal= 'Activar chat'
                this.mensaje = `¿Desea activar el chat con el ${this.$config.vendedor}? Él no tendrá limite de mensajes`

            }
            this.$refs.modalActivarChat.toggle()
        },
        async confirmar_cambios(){
            try {
                const enviar ={
                    id_tienda: this.id,
                    chat_admin: this.tienda.chat_admin ===1 ? 2 : 1
                }
                const {data} = await Chat.activarInactivarTienda(enviar)
                this.tienda = data.tienda
                let text = data.tienda.chat_admin ==1 ? 'activo': 'Inactivo'
                this.notificacion('Mensaje', `Chat ${text}`, 'success' )
                this.$refs.modalActivarChat.toggle()
                this.cerrarModal()


            } catch (e){
                this.error_catch(e)
            }

        },
        async obtenerDatosUsuario(){
            try {
                const {data} = await Chat.obtenerDatosUserCliente({id: this.id, tipo:2})// 2 es pedido
                this.cliente = data.cliente
                if(this.tipo==2 && this.doble == false){
                    this.fecha_finalizado_chat = this.momento==2? data.pedido.fecha_finalizado_momento_2 : data.pedido.fecha_finalizado_momento_3
                } else if(this.doble == true){
                    // let estados = [4, 31, 32, 33, 34, 25, 26, 27]
                    // this.finalizado = estados.includes(this.pedido.datos_pedido.estado)

                    this.fecha_finalizado_chat = this.pedido.fecha_finalizado_chat_cliente
                }

            } catch (e){
                this.error_catch(e)
            }

        },
        async obtenerDatosUserClienteDesdeCreditos(){
            try {
                const {data} = await Chat.obtenerDatosUserClienteDesdeCreditos({id: this.id})// id_user
                this.cliente = data.cliente
            } catch (e){
                this.error_catch(e)
            }

        },
        async actualizarUltimaLectura(){
            try {
                const enviar ={
                    id_dinamico: this.id,
                    tipo: this.tipo
                }
                const {data} = await Chat.actualizarUltimaLecturaChat(enviar)
                this.$emit('actualizarIcono')


            } catch (e){
                this.error_catch(e)
            }
        },
        async obtenerInfoTienda(){
            try {
                let idTienda = 0
                idTienda = this.tipo == 5 ? this.leechero.id : this.id
                const {data} = await Chat.obtenerInfoTienda(idTienda)
                this.tienda = data.tienda

            } catch (e){
                this.error_catch(e)
            }
        },
        async obtenerInfoTiendaChatCredito(){
            try {
                const {data} = await Chat.obtenerInfoTiendaCreditoChat(this.id)
                this.tienda = data.tienda

            } catch (e){
                this.error_catch(e)
            }
        },
        cerrarModal(){
            this.drawer=false
        },
        limpiar(){
            this.drawer= false
            this.id = 0
            this.tab = null
            this.tipo = 2
            this.momento = 2
            this.finalizado = false
            this.fecha_finalizado_chat = ''
            this.cliente = {
                id: null,
                nombre: '',
                imagen_firmada: null
            }
        }
    }
}
</script>
<style lang="scss">
.text-aqua{ color:#03D6BC;}
.active-tab{
    color: var(--color-general);
    border-bottom: 3px solid var(--color-general);
}
.disabled-div{
    pointer-events: none;
    opacity: 0.4;
}
@media (min-width : 998px) {
    .el-drawer.rtl{
        width: 30% !important;
    }
}
@media (max-width : 998px) {
    .el-drawer.rtl{
        width: 40% !important;
    }
}
@media (min-width : 320px) and (max-width : 480px) {
    .el-drawer.rtl{
        width: 65% !important;
    }
}
</style>
