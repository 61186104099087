export default [
    {
        path: '/admin/parametrizar/productos',
        name: 'admin.parametrizar.productos',
        meta: { nombre: 'Productos' },
        component: () => import('../../pages/configurar/admin/productos').then(m => m.default || m),
        children: [
            {
                path: 'unidades',
                name: 'admin.parametrizar.productos.unidades',
                component: () => import('../../pages/configurar/admin/productos/unidadesMedida').then(m => m.default || m),
            },
            {
                path: 'categorias',
                name: 'admin.parametrizar.productos.categorias',
                component: () => import('../../pages/configurar/admin/productos/categorias/index').then(m => m.default || m),
            },
            {
                path: 'etiquetas',
                name: 'admin.parametrizar.productos.etiquetas',
                component: () => import('../../pages/configurar/admin/productos/etiquetas').then(m => m.default || m),
            }
        ]
    }
]
