export default [
    {
        path: '/tendero/reclamos',
        meta: { nombre: 'Reclamos' },
        name: 'tendero.reclamos',
        component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/reclamos').then(m => m.default || m),
        redirect: {name: 'tendero.reclamos.reclamos'},
        children: [
            {
                path: 'index',
                meta: { nombre: 'Reclamos' },
                name: 'tendero.reclamos.reclamos',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/soporte/reclamo').then(m => m.default || m),
            },
            {
                path: 'terminados',
                meta: { nombre: 'Reclamos' },
                name: 'tendero.reclamos.terminados',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/soporte/reclamosTerminados').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/tendero-vip/reclamos',
        meta: { nombre: 'Reclamos' },
        name: 'tendero-vip.reclamos',
        component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/reclamos').then(m => m.default || m),
        children: [
            {
                path: 'index',
                meta: { nombre: 'Reclamos' },
                name: 'tendero-vip.reclamos.reclamos',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/soporte/reclamo').then(m => m.default || m),
            },
            {
                path: 'terminados',
                meta: { nombre: 'Reclamos' },
                name: 'tendero-vip.reclamos.terminados',
                component: () => import(/* webpackChunkName: "reclamos" */ '../../pages/soporte/reclamosTerminados').then(m => m.default || m),
            }
        ]
    }
]
