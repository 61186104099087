<template>
    <section class="vh-100 bg-white">
        <div class="w-100 h-100 position-absolute container-img">
            <div class="img-background" />
        </div>
        <div class="container-fluid">
            <div class="row mx-0" :class="error ? 'align-items-center' : 'align-items-end'" style="min-height:calc(100vh - 5px);">
                <template v-if="error">
                    <div class="col text-left px-5">
                        <p class="text-leeche text-center f-30 f-600 my-4 mt-5">
                            Tu registro se ha realizado exitosamente
                        </p>
                        <p class="text-muted2 text-center f-30 my-2 mb-5">
                            Accede a <span class="f-600"> la App </span>  y disfuta de nuestros descuentos y promociones.
                        </p>
                        <img src="/img/icons/Grupo 19404.svg" style="max-width:100%;height:350px" class="obj-cover ml-4 img-height" />
                    </div>
                    <div class="col-auto">
                        <div class="box mt-2">
                            <div class="row mx-0 justify-center mt-3">
                                <div class="col-auto text-center">
                                    <img src="/img/auth/logo_rojo.svg" class="obj-cover" style="max-width:200px;max-height:50px;" />
                                    <p class="text-muted2 f-600 mt-3">
                                        En la App queremos saber de ti
                                    </p>
                                </div>
                            </div>
                            <ValidationObserver v-slot="{ invalid }" ref="validation">
                                <div class="custom-scroll overflow-auto" style="height:calc(100% - 155px);">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-12 text-center mb-4">
                                            Seleccione un Avatar
                                        </div>
                                        <div class="avatar-box rounded-circle cr-pointer">
                                            <img :src="_.get(currentAvatar, 'imagen_firmada', '/img/modales/tear_face.svg')" width="100%" height="100%" alt="" class="rounded-circle" @click="seleccionarAvatar()" />
                                        </div>
                                    </div>
                                    <div class="row mx-0">
                                        <div class="col-auto" />
                                        <!-- Form -->
                                        <div class="col">
                                            <div class="row mx-0 mt-3">
                                                <div class="col-12 my-2">
                                                    <ValidationProvider v-slot="{ errors }" rules="required" name="nombre">
                                                        <ValidationProvider>
                                                            <label class="f-15 pl-3"> ¿Como quieres que te llamemos? </label>
                                                            <el-input v-model="model.datos.nombre" class="w-100" />
                                                            <span class="text-danger f-11"> {{ errors[0] }} </span>
                                                        </ValidationProvider>
                                                    </validationprovider>
                                                </div>
                                                <div class="col-12 my-2">
                                                    <label class="f-15 pl-3"> ¿Cuándo naciste? </label>
                                                    <ValidationProvider v-slot="{errors}" rules="required" vid="fecha" name="fecha">

                                                        <el-date-picker v-model="model.datos.nacimiento" type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100" :picker-options="pickerOptions" />

                                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 text-center mt-3">
                                                    Selecciona el género con el que te identificas
                                                </div>
                                                <div class="col-12 d-middle-center my-3">
                                                    <el-radio-group v-model="model.datos.genero">
                                                        <el-radio class="radio-red" :label="1">
                                                            Mujer
                                                        </el-radio>
                                                        <el-radio class="radio-red" :label="2">
                                                            Hombre
                                                        </el-radio>
                                                        <el-radio class="radio-red" :label="3">
                                                            Otro
                                                        </el-radio>
                                                    </el-radio-group>
                                                </div>
                                                <div class="col-12 my-2">
                                                    ¿Donde Vives?
                                                </div>
                                                <div class="col-12 my-2">
                                                    <ValidationProvider v-slot="{errors}" rules="required" vid="pais" name="país">
                                                        <label class="f-15 pl-3"> Selecciona el país </label>
                                                        <el-select
                                                        v-model="idPais"
                                                        class="w-100"
                                                        filterable
                                                        remote
                                                        reserve-keyword
                                                        :remote-method="getPaises"
                                                        @change="getEstados()"
                                                        >
                                                            <el-option
                                                            v-for="(item, i) in paises"
                                                            :key="i"
                                                            :label="item.pais"
                                                            :value="item.id"
                                                            />
                                                        </el-select>

                                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 my-2">
                                                    <ValidationProvider v-slot="{errors}" rules="required" vid="estado" name="estado">
                                                        <label class="f-15 pl-3"> Selecciona el estado </label>
                                                        <el-select
                                                        v-model="idEstado"
                                                        class="w-100" filterable
                                                        remote
                                                        reserve-keyword
                                                        :remote-method="getEstados"
                                                        @change="getCiudades()"
                                                        >
                                                            <el-option
                                                            v-for="(item, i) in estados"
                                                            :key="i"
                                                            :label="item.estado"
                                                            :value="item.id"
                                                            />
                                                        </el-select>
                                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 my-2">
                                                    <ValidationProvider v-slot="{errors}" rules="required" vid="ciudad" name="ciudad">
                                                        <label class="f-15 pl-3"> Selecciona la ciudad </label>
                                                        <el-select
                                                        v-model="model.datos.idm_ciudad"
                                                        class="w-100"
                                                        filterable
                                                        remote
                                                        reserve-keyword
                                                        :remote-method="getCiudades"
                                                        @change="onChangeCity"
                                                        >
                                                            <el-option
                                                            v-for="(item, i) in ciudades"
                                                            :key="i"
                                                            :label="item.ciudad"
                                                            :value="item.id"
                                                            />
                                                        </el-select>
                                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 my-2">
                                                    <ValidationProvider v-slot="{errors}" rules="required" vid="direccion" name="dirección">
                                                        <label class="f-15 pl-3"> Dirección </label>
                                                        <el-input v-model="model.direccion.direccion" class="w-100 br-12" />
                                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 my-2">
                                                    <img :src="rutaImagenMapa()" class="w-100 cr-pointer br-12 border" @click="abrirModalMapa" />
                                                </div>
                                                <div class="col-12 my-2">
                                                    <label class="f-15 pl-3"> Anexo </label>
                                                    <el-input v-model="model.direccion.direccion_anexo" class="w-100 br-12" placeholder="Apto, casa, ofc..." />
                                                </div>
                                                <div class="col-12 my-2">
                                                    <label class="f-15 pl-3"> Guardar como </label>
                                                    <el-input v-model="model.direccion.nombre" class="w-100 br-12" placeholder="Apto, casa, ofc..." />
                                                </div>
                                                <div class="col-12">
                                                    <p class="my-3">
                                                        Crea tus datos de acceso
                                                    </p>
                                                    <p class="my-3">
                                                        Crea tu contraseña
                                                    </p>
                                                </div>
                                                <div class="col-12 my-2">
                                                    <ValidationProvider v-slot="{errors}" rules="required" vid="password" name="contraseña">
                                                        <label class="pl-3 f-15"> Contraseña </label>
                                                        <el-input v-model="model.datos.password" class="w-100 br-12 f-20 password-edit" show-password />
                                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 my-2">
                                                    <ValidationProvider v-slot="{ errors }" rules="required_if:password|confirmed:password" name="confirmar contraseña">
                                                        <label class="pl-3 f-15"> Confirmar Contraseña </label>
                                                        <el-input
                                                        v-model="input3"
                                                        class="w-100 br-12"
                                                        show-password
                                                        />
                                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 my-4">
                                                    <div class="row mx-0">
                                                        <div class="col-auto px-0">
                                                            <el-checkbox v-model="terminosCondiciones" class="check-red" />
                                                        </div>
                                                        <div class="col px-0 text-center">
                                                            He leído y acepto los <span class="text-gr-general">términos y condiciones y Política de privacidad</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto" />
                                    </div>
                                </div>
                                <div class="row mx-0 justify-center py-2">
                                    <div class="col-8">
                                        <button
                                        :disabled="invalid || !terminosCondiciones"
                                        class="btn btn-block br-12 p-1 py-2 d-flex d-middle-center text-white bg-leeche border-0"
                                        @click="crear"
                                        >
                                            Crear Perfil
                                        </button>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <error-registro :estado-vista="error" />
                </template>
                <div class="col-auto" />
                <div class="col-auto" />
            </div>
        </div>
        <!-- Partials -->
        <modal-seleccionar-avatar ref="SelectAvatar" @avatar="avatarSelected" />
        <mapa-google-modal
        ref="modalMapaGoogle"
        class="centrar-modal"
        :coordenadas-entrada="coordenadasCiudad"
        @actualizar="actualizarCoordenadasCiudad"
        />
    </section>
    <!-- Partials -->
</template>

<script>
import Localizaciones from '~/services/localizacion'
import authService from '~/services/auth'
export default {
    layout: 'postulacion',
    components: {
        errorRegistro: () => import('./components/errorRegistro'),
        modalSeleccionarAvatar: () => import('./partials/modalSeleccionarAvatar')
    },
    data(){
        return {
            nombre: '',
            nacimiento: '',
            error: true,
            genero: 1,
            value: '',
            direccion: '',
            password: '',
            input3: '',
            terminosCondiciones: false,
            date: '',
            idPais: '',
            idEstado: '',
            idCiudad: '',
            paises: [],
            estados: [],
            ciudades: [],
            model: {
                datos: {
                    nombre: '',
                    genero: 1,
                    nacimiento: '',
                    id_avatar: null,
                    idm_ciudad: '',
                },
                direccion: {
                    nombre: '',
                    idm_ciudad: '',
                    direccion: '',
                    direccion_anexo: '',
                    longitud: '',
                    latitud: '',
                },
            },
            coordenadasCiudad: { lng: 0, lat: 0 },
            currentAvatar: {},
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() > Date.now()
                },
            },
        }
    },
    computed: {
        idUser(){
            return this.$store.getters['register/idUser']
        }
    },
    async mounted(){
        await this.getPaises()
    },
    methods: {
        seleccionarAvatar(){
            this.$refs.SelectAvatar.toggle(this.model.id_avatar)
        },
        avatarSelected(avatar){
            this.model.datos.id_avatar = avatar.id
            this.currentAvatar = avatar
        },
        async getPaises(query = ''){
            try {
                let params = {
                    query
                }
                const {data} = await Localizaciones.getPais(params)
                this.paises = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async getEstados(query = ''){
            try {
                let params = {
                    query
                }
                const {data} = await Localizaciones.getEstados(this.idPais, params)
                this.estados = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async getCiudades(query){
            try {
                let params = {
                    query
                }
                const {data} = await Localizaciones.getCiudades(this.idEstado, params)
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        actualizarCoordenadasCiudad({ latitud, longitud }){
            this.model.direccion.latitud = latitud
            this.model.direccion.longitud = longitud
        },
        async crear(){
            try {

                this.model.datos.id_user = this.idUser;
                this.model.direccion.id_user = this.idUser;
                this.model.direccion.idm_ciudad = this.model.datos.idm_ciudad
                const { data } = await authService.completeRegister(this.model)
                if (data.data == 'exito'){
                    this.notificacion('', 'Guardado Correctamente', 'success')
                    this.$router.push({name: 'login.form.admin'})
                }

            } catch (error){
                this.error_catch(error, 'validation')
            }

        },
        onChangeCity(id){
            let city = this.ciudades.find(el => el.id == id)

            this.coordenadasCiudad = { lng: city.longitude, lat: city.latitude },
            this.model.direccion.latitud = city.latitude
            this.model.direccion.longitud = city.longitude
        }
    }
}
</script>

<style lang="scss" scoped>
.box{
    width:487px;
    box-shadow:0px 3px 6px #00000080;
    height:calc(100vh - 311px);
    border-radius: 12px;
}
@media (max-height: 720px){
    .img-height{
        height: auto !important;
    }
    .box{
        height: calc(100vh - 90px) !important;
    }
}
.img-background{
    background-image: url('/img/auth/onda_postulate.svg');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}
.br-12{
    border-radius: 12px;
}
.avatar-box{
    width: 116px;
    height: 116px;
    border: 1px solid #707070;
    background-color: #F5F5F5;
}
</style>
