import Promociones from '~/services/promociones/promociones_tendero'
import PromocionesCedis from '~/services/promociones/promociones_cedis_admin'

export const state = {
    promociones: [],
    id_promocion: null,
    detalle:{
        id: null,
        nombre: '',
        imagen: null,
        imagen_show: null,
        tipo: '',
        condiciones: '',
        categorias:1,
        visible: 1,
        promo_categorias:[]
    },
    productos: [],
    promocion: {
        estado: 1,
        fin: null,
        id_cedi_promocion: null,
        id_promocion: null,
        imagen: null,
        inicio: null,
        nombre: null,
        pro_agregados: 0,
        text_estado: "",
        tipo: null,
        tipo_show: null,
        vencido: false
    }
}

export const getters = {
    promociones: state => state.promociones,
    id_promocion: state => state.id_promocion,
    detalle: state => state.detalle,
    productos: state => state.productos,
    promocion: state => state.promocion
}

export const mutations = {
    set_promociones: (state,payload) => {
        state.promociones = payload
    },
    set_id_promocion: (state,payload) => {
        state.id_promocion = payload
    },
    set_detalle: (state,payload) => {
        state.detalle = payload
    },
    set_productos: (state,payload) => {
        state.productos = payload
    },

    set_promocion: (state,payload) => {
        state.promocion = payload
    }

}

export const actions = {
    async action_listar_promociones({commit},payload = null){
        const {data} = await Promociones.listarPromociones(payload)
        commit('set_promociones',data.promociones)
    },
    async action_listar_detalle_promocion({commit},payload = null){
        const {data} = await PromocionesCedis.detallePromoDesdeCedis(payload)
        commit('set_detalle',data.promocion)
    },
    async action_listar_productos({commit},payload = {}){
        const {data} = await PromocionesCedis.listarProductosAgregados(payload)
        commit('set_productos', data.productos)
    }

}
