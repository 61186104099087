
import vueGlobalMixin from '~/mixins/general'
import leecherosDefecto from '~/services/configurar/admin/leecherosDefecto'

export const state = {
    datos: [],
    tiendas: [],
    idPais: 0,
    defecto: {}
}

export const getters = {
    getTiendas: ({tiendas}) => tiendas,
    getDatos: ({datos}) => datos,
    getDefecto: ({defecto}) => defecto,
    getIdPais: ({idPais}) => idPais,
}

export const mutations = {
    setTiendas: (state, payload) => state.tiendas = payload,
    setDatos: (state, payload) => state.datos = payload,
    setDefecto: (state, payload) => state.defecto = payload,
    setIdPais: (state, payload) => state.idPais = payload,
}

export const actions = {
    async fetchTiendas({commit}){
        try {
            const { data } = await leecherosDefecto.fetchTiendas();
            commit('setTiendas', data)
        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
        }
    },
    async fetchDatos({commit}){
        try {
            const { data } = await leecherosDefecto.getData();
            commit('setDatos', data.datos)
            commit('setDefecto', data.defecto)
        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
        }
    },

}
