<template>
    <div class="col-xl-5 col-lg-12 mx-0 p-1 d-middle cr-pointer card-prod2" style="height:110px;" @click="$emit('accion', producto.id)">
        <div :class="`mx-2 px-1 h-80 br-5 ${producto.id==activo?'bg-gr-red':''}`" />
        <img class="br-10 obj-cover" height="90" width="90" :src="producto.imagen" alt="" />
        <div class="col-7 pr-0 tres-puntos f-15">
            <div class="row mx-0 f-600">
                <p class="nombre">{{ producto.nombre }} </p>
            </div>
            <div class="row mx-0 align-items-center">
                <p class="tres-puntos">{{ producto.presentacion }}</p>
            </div>
            <div v-if="caso===1" class="row mx-0 justify-content-between pr-1">
                <b>{{ convertMoney(producto.precio, producto.idm_moneda) }}</b>
                <p :class="{'text-danger':Boolean(producto.stock_bajo)}">
                    <b>
                        {{ agregarSeparadoresNumero(producto.stock_actual,2) }}
                    </b>{{ producto.unidad }}. Stock
                </p>
            </div>
            <div v-else-if="caso===2" class="row mx-0 justify-content-end align-items-center text-danger  pr-1">
                <el-tooltip placement="bottom" content="CEDIS que tienen este producto en promocion" effect="light">
                    <div class="col-auto px-0">
                        {{ producto.cant_cedis_productos_activos }}
                        <i v-if="producto.has_promocion" class="icon-sale f-22 my-auto " />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="d-flex flex-column">
            <i v-show="parseFloat(producto.cant_carrito) > 0" class="icon-cart-outline f-20 text-gr-red" />
            <i class="icon-eye cr-pointer f-20 mt-auto" :class="producto.estado?'text-gr-general':'text-gris'" @click.stop="$emit('visible',producto.id)" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        diseno: {
            type: Object,
            default: () => {
                return {
                    color: 0,
                    border: true,
                    posicion: 0,
                    texto: ''
                }
            }
        },
        producto: {
            type: Object,
            default: () => {
                return {}
            }
        },
        activo: {
            type: Number,
            default: 0
        },
        caso: {
            type: Number,
            default: 1
        },
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            agregar: false,
            colores: [ 'bg-gr-general', 'bg-gr-red', 'bg-gr-purple', 'bg-gr-pink' ],
            text: [ 'text-gr-general', 'text-gr-red', 'text-gr-purple', 'text-gr-pink' ],
            border: [ 'border-blue', 'border-red', 'border-purple', 'border-pink' ],
        }
    },
    methods: {
        disenoCard(idx){
            this.diseno.color = this.colores[idx]
            this.borde_activo = this.border[idx]
        }
    }
}
</script>

<style lang="scss" scoped>
.card-prod2{
    width: 355px;
    border-radius: 16px;
}
.nombre{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;

}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
