export default [
    {
        path: '/admin/informacion',
        meta: { nombre: 'información para los usuario' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/admin').then(m => m.default || m),
        children: [
            {
                path: 'preguntas-frecuentes',
                meta: { nombre: 'Información para los usuarios' },
                name: 'admin.info.preguntas',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/preguntas').then(m => m.default || m),
            },
            {
                path: 'terminos',
                meta: { nombre: 'Información para los usuarios' },
                name: 'admin.info.terminos',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/terminos').then(m => m.default || m),
            },
            {
                path: 'politica',
                meta: { nombre: 'Información para los usuarios' },
                name: 'admin.info.politica',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/politica').then(m => m.default || m),
            }
        ]
    }
]
