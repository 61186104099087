import Cookies from 'js-cookie'
import ServicioApi from '~/services/auth'
import store from '~/store';
import Aws from '~/services/aws'

const ROLES_LOCAL = 'roles'
const TOKEN = 'token'

const componer = (...fns) => x => fns.reduce((y, f) => f(y), x)

const convertirObjetoEnArray = obj => Object.values(obj).map(x => x)
const generarJson = array => JSON.stringify(array)
const generarJsonParaGuardar = componer(convertirObjetoEnArray, generarJson)

const obtenerJsonLocal = item => localStorage[item]
const traducirJson = json => json === undefined ? [] : JSON.parse(json, true)
const obtenerDatoLocal = componer(obtenerJsonLocal, traducirJson)

export const state = {
    token: Cookies.get(TOKEN),
    usuario: {},
    tienda: {},
    roles: obtenerDatoLocal(ROLES_LOCAL),
    error: {}
}

export const getters = {
    obtenerUsuario: ({ usuario })  => usuario,
    obtenerTienda: ({ tienda })  => tienda,
    obtenerToken: ({ token }) => token,
    obtenerError: ({ error }) => error,
    obtenerRoles: ({ roles }) => roles,
    existeToken: ({ token }) => !_.isEmpty(token),
    existeUsuario: ({ usuario }) => !_.isEmpty(usuario) && !!usuario.id,
    existenRoles: ({ roles }) => !_.isEmpty(roles),
}

export const mutations = {
    ALMACENAR_TOKEN: (state, payload) => {
        state.token = payload
        Cookies.set(TOKEN, payload)
    },
    ALMACENAR_TOKEN_GOOGLE: (state, payload) => {
        state.tokenGoogle = payload
        Cookies.set(TOKEN_GOOGLE, payload)
    },
    ALMACENAR_USUARIO: (state, payload) => {
        state.usuario = payload
        if(payload.tienda){
            state.tienda = payload.tienda
        }
        store.dispatch('cedis/action_get_monedas')
        store.dispatch('cedis/action_get_cedis')
    },
    ALMACENAR_ROLES: (state, payload) => {
        state.roles = convertirObjetoEnArray(payload)
        localStorage[ROLES_LOCAL] = generarJsonParaGuardar(payload)
    },
    ALMACENAR_ERROR: (state, { response: { data } } ) => {
        state.error = {
            ...data,
            tipo: data.codigo ? 400 : 500
        }
    },
    CERRAR_SESION: state => {
        localStorage.clear()
        Cookies.remove(TOKEN)
        state.usuario = {}
        state.token = '',
        state.tokenGoogle = {}
        state.error = {}
    },
    REMOVER_ERRORES: state => {
        state.error = {}
    }
}

export const actions = {
    async intentarIniciarSesion({ commit, dispatch }, payload){
        commit('REMOVER_ERRORES')
        const { data: { token, usuario } } = await ServicioApi.iniciarSesion(payload)

        dispatch('consultarRoles')

        commit('ALMACENAR_TOKEN', token)
        commit('ALMACENAR_USUARIO', usuario)
    },
    async checkCodigoTemporalLogin({ commit, dispatch }, payload){
        commit('REMOVER_ERRORES')
        const { data: { token, usuario } } = await ServicioApi.checkCodigoTemporalLogin(payload)

        dispatch('consultarRoles')

        commit('ALMACENAR_TOKEN', token)
        commit('ALMACENAR_USUARIO', usuario)
    },
    async intentarIniciarSesionSocial({ commit, dispatch }, payload){
        commit('REMOVER_ERRORES')
        const { data: { token, usuario } } = await ServicioApi.iniciarSesionSocial(payload)

        dispatch('consultarRoles')

        commit('ALMACENAR_TOKEN', token)
        commit('ALMACENAR_USUARIO', usuario)
    },
    async consultarUsuario({ commit }){
        const { data: { usuario } } = await ServicioApi.obtenerUsuario()
        commit('ALMACENAR_USUARIO', usuario)
        // Aws.setBucket(usuario.bucket)
    },
    async consultarRoles({ commit }){
        const { data: { roles } } = await ServicioApi.obtenerRoles()
        commit('ALMACENAR_ROLES', roles)
    },
    async intentarCerrarSesion({ commit }){
        await ServicioApi.cerrarSesion()
        commit('CERRAR_SESION')
        commit('REMOVER_ERRORES')
    }
}
