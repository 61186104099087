
import Promociones from '~/services/promociones/promociones_cedis_admin'

export const state = {
    promo_cedis: [],
    id_promocion: null,
    promocion:{
        id: null,
        nombre: '',
        imagen: null,
        imagen_show: null,
        tipo: '',
        condiciones: '',
        categorias:1,
        visible: 1,
        promo_categorias:[]
    },
    // cedis: [],
    productos: [],
    tipo_promocion: null,
    loading_general: false,
    detalle_promo:{
        nombre: '',
        condiciones: '',
        pro_agregados: 0,
        pro_permitidos: 0,
        promo_categorias:[],
        imagen_show: null,
        tipoShow:null
    }


}

export const getters = {
    promo_cedis: state => state.promo_cedis,
    id_promocion: state => state.id_promocion,
    promocion: state => state.promocion,
    // cedis: state => state.cedis,
    productos: state => state.productos,
    tipo_promocion: state => state.tipo_promocion,
    loading_general: state => state.loading_general,
    detalle_promo: state => state.detalle_promo
}

export const mutations = {
    set_promo_cedis: (state,payload) => {
        state.promo_cedis = payload
    },
    set_id_promocion: (state,payload) => {
        state.id_promocion = payload
    },
    set_promocion: (state,payload) => {
        state.promocion = payload
    },
    // set_cedis: (state,payload) => {
    //     state.cedis = payload
    // },
    set_productos: (state,payload) => {
        state.productos = payload
    },
    set_tipo_promocion: (state,payload) => {
        state.tipo_promocion = payload
    },
    set_loading_general: (state,payload) => {
        state.loading_general = payload
    },
    set_detalle_promo: (state,payload) => {
        state.detalle_promo = payload
    }

}

export const actions = {
    async action_listar_admin_promociones({commit},payload = null){
        const {data} = await Promociones.listarPromocionesDelCedis(payload)
        commit('set_promo_cedis',data.cedisPromociones)
    },
    async action_listar_productos_agregados({commit},payload = {}){
        const {data} = await Promociones.listarProductosAgregados(payload)
        commit('set_productos', data.productos)
    },
    async action_detalle_promo({commit},payload = null){
        const {data} = await Promociones.detallePromoDesdeCedis(payload)
        commit('set_detalle_promo',data.promocion)
    }


}
