import Pedidos from '~/services/pedidos/pedidos-tienda'

export const state = {
    indicadores:{
        recibidos:0,
        cancelados_tiempo:0,
        cancelados:0,
        entregados:0
    },
    pedidos:[],
    id_pedido:null,
    productos:[],
    pedido:{},
    cliente:{},
    tienda:{
        chat_clientes: 0
    },
    historial:[],
    resumenes:{inicial:{},final:{}},
    productos_stock_bajo:[{producto:{nombre:'',imagen:null}}],
    cuotas:[],
    pagos:[],

    fecha_filtro:[],
    tipo_filtro:0,
    lecheros:[],
    convenio: {},
    pagoOnline: {},

}

export const getters = {
    indicadores: state => state.indicadores,
    pedidos: state => state.pedidos,
    id_pedido: state => state.id_pedido,
    productos: state => state.productos,
    pedido: state => state.pedido,
    cliente: state => state.cliente,
    tienda: state => state.tienda,
    historial: state => state.historial,
    resumenes: state => state.resumenes,
    productos_stock_bajo: state => state.productos_stock_bajo,
    cuotas: state => state.cuotas,
    pagos: state => state.pagos,
    lecheros: state => state.lecheros,
    convenio: state => state.convenio,
    pagoOnline: state => state.pagoOnline

}

export const mutations = {
    set_filtro:(state,payload) => {
        state.fecha_filtro = payload.fecha
        state.tipo_filtro = payload.tipo
    },
    set_indicadores: (state,payload) => {
        state.indicadores = {
            recibidos:payload.recibidos,
            cancelados_tiempo:payload.cancelados_tiempo,
            cancelados:payload.cancelados,
            entregados:payload.entregados
        }
    },
    set_pedidos: (state,payload) => {
        state.pedidos = payload
    },
    set_lecheros: (state,payload) => {
        state.lecheros = payload
    },




    set_id_pedido: (state,payload) => {
        state.id_pedido = null
        state.id_pedido = payload
    },
    set_productos: (state,payload) => {
        state.productos = payload
    },
    set_productos_stock_bajo: (state,payload) => {
        state.productos_stock_bajo = payload
    },
    set_pedido: (state,payload) => {

        state.pedido = payload
    },
    set_cliente: (state,payload) => {
        state.cliente = payload
    },
    set_resumenes: (state,payload) => {
        state.resumenes = payload
    },
    set_convenio: (state,payload) => {
        state.convenio = payload
    },
    set_historial: (state,payload) => {
        let mensajes = [
            'Un cliente ha solicitado un pedido',
            'Pedido Confirmado',
            'Pedido con cambios',
            'Pedido En Alistamiento',
            'Pedido En Empaque',
            'Pedido Enviado',
            'Pedido Entregado',
            'Pedido Cancelado',
            'Reclamo',
        ]

        let iconos = [
            'icon-receipt',
            'icon-ok-circled-outline',
            'icon-account-edit',
            'icon-cart-arrow-down',
            'icon-shopping-outline',
            'icon-truck-outline',
            'icon-shopping',
            'icon-attention-outline',
        ]

        let history = []
        for (let idx in payload){
            if(idx == 2 || idx == 7 || idx == 8){

                if(payload[idx] !== null){
                    let estado = state.pedido.estado
                    // console.log(estado);
                    let icon = ''
                    switch (estado){
                    case 25:
                        icon = 'icon-playlist-remove'
                        break;
                    case 26:
                        icon = 'icon-cancel-circled-outline'
                        break;
                    case 27:
                        icon = 'icon-account-remove-outline'
                        break;
                    default:
                        icon ='icon-attention-outline'
                    }

                    history.push({ content:mensajes[idx], hora:payload[idx], activo: payload[idx] !== null, icon:icon })
                }
            }else{
                history.push({ content:mensajes[idx], hora:payload[idx], activo: payload[idx] !== null, icon:iconos[idx] })
            }
        }
        state.historial = history
    },
    // Esta mutacion pasa un pedido de sin confirma a confirmado
    pasar_a_confirmado: (state,payload) => {

        let idx = state.pedidos.findIndex(p=>p.id === payload.id)
        state.pedidos[idx].estado = 2
        state.pedido = payload
    },
    //Esta mutacion cambia el estado de pedido a 110 edicion tendero
    pasar_edicion_tendero: (state,payload) => {

        let idx = state.pedidos.findIndex(p=>p.id === payload)
        state.pedidos[idx].estado = 110;
        state.pedido.estado = 110
    },
    rechazar_pedido: (state,payload) => {

        let idx = state.pedidos.findIndex(p=>p.id === payload)
        state.pedidos.splice(idx, 1);

        state.id_pedido = null
        state.pedido = {}
        state.indicadores.cancelados++
        state.productos = []

    },
    cancelar_pedido: (state,payload) => {

        let idx = state.pedidos.findIndex(p=>p.id === payload)
        state.pedidos.splice(idx, 1);

        state.id_pedido = null
        state.pedido = {}
        state.indicadores.cancelados++
        state.productos = []

    },
    set_tienda: (state,payload) => {
        state.tienda = payload
    },
    push_producto: (state,payload) => {
        state.productos.unshift(payload)
    },
    alter_producto: (state,payload) => {
        let index = state.productos.findIndex(p=>p.id === payload.id);

        for (let [key,value] of Object.entries(payload)){
            state.productos[index][key] = value
        }
    },
    delete_producto(state,payload){
        let idx = state.productos.findIndex(p=>p.id === payload) // id_pedido_producto
        state.productos.splice(idx, 1);
    },
    cambio_estado_pedido(state,payload){
        let idx = state.pedidos.findIndex(p=>p.id === payload.id) // id_pedido
        state.pedidos[idx].estado = payload.estado
    },
    set_credito: (state,payload) => {
        state.cuotas = payload.cuotas
        state.pagos = payload.pagos
    },
    set_pagoOnline: (state,payload) => {
        state.pagoOnline = payload
    },


}

export const actions = {
    async pedidos_indicadores({commit}){
        const {data} = await Pedidos.pedidos_indicadores()
        commit('set_indicadores',data.indicadores)
    },
    async listar_pedidos({state,commit}){

        let params = {
            fecha:state.fecha_filtro,
            tipo:state.tipo_filtro // 0 Sin filtro - 1 Creacion -  2 Entrega
        }

        const {data} = await Pedidos.listar_pedidos(params)
        commit('set_pedidos',data.pedidos)

        if(data.lecheros.length > 0){
            let todos = [{lechero:"Todos",id_tienda:0}]
            commit('set_lecheros',[...todos,...data.lecheros])
        }
    },
    async pedidos_productos({commit},id){
        const {data} = await Pedidos.pedidos_productos(id)
        commit('set_productos',data.productos)
    },
    async get_datos({commit},id){
        const {data} = await Pedidos.get_datos(id)
        if(data.error){
            return data
        }
        commit('set_pedido',data.pedido)
        commit('set_cliente',data.cliente)
        commit('set_tienda',data.tienda) // agrague para validar icono chat
        commit('set_convenio',data.convenio)
        commit('set_pagoOnline',data.pago_online)
        return {exito:'Todo bien'}
    },
    async pedidos_historial({commit},id){
        const {data} = await Pedidos.historial_estados(id)
        commit('set_historial',data)
    },
    async pedidos_resumenes({commit},id){
        const {data} = await Pedidos.resumenes(id)
        commit('set_resumenes',data)
    },
    async pedido_credito({commit},id){
        const {data} = await Pedidos.get_credito(id)
        commit('set_credito',data)
    },
    async ver_pedido({commit},id){
        const {data} = await Pedidos.verPedido(id)

        commit('set_historial',data.historial)
        commit('set_resumenes',data.resumen)
    },


}
