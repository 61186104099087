<template>
    <div class="row mx-0 tabs">
        <template v-for="(item,index) in tabs">
            <router-link
            v-if="$can(item.can)"
            :key="index"
            :data-xd="item.ruta"
            :to="{ name: `${item.ruta}`, params: item.params || {} }"
            :class="{'router-link-active': subIsActive(item.active)}"
            class="d-middle position-relative"
            >
                <el-tooltip :disabled="item.component_name==$route.ruta" class="item" effect="light" :content="item.titulo" placement="top">
                    <i v-if="item.icono" :class="`${item.icono}`" class="mdi f-20" />
                </el-tooltip>
                <p class="nombre-tab">{{ item.titulo }}</p>
                <div v-if="item.num !== undefined" class="item-tab f-14 br-20 ml-2 py-1 d-middle-center">
                    {{ item.num }}
                </div>
            </router-link>
        </template>
        <slot name="btnAgregar" />
    </div>
</template>
<script>
export default {
    name: 'TabsIcons',
    props: {
        tabs: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        isActive(){
            return this.$route.name
        }
    },
    methods: {
        subIsActive(input){
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.tabs{
    .item-tab{
        min-width: 40px;
        height: 22px;
        background: #707070;
        color: #ffffff;
    }
    a{
        padding: 5px 12px 5px 12px !important;
        color: #637381;
        font-size: 14px;
        &.router-link-active{
            padding: 12px 12px 5px 12px;
            /* border-width: 3px;
            border-style: solid; */
            /* border-image: var(--text-gr-general) 0 0 100%; */
            border-bottom: 3px solid var(--color-general) !important;
            color: var(--color-general) !important;
            .nombre-tab{
                display: inline-block;
            }
            .item-tab{
                background: var(--color-general);
                color: white;
            }
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>
