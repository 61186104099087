<template>
    <section class="row justify-center footer text-white" style="height:88px;">
        <div class="col-xl-auto col-lg-auto  d-middle-center f-600 cr-pointer">
            <router-link :to="{name: 'login.form.admin'}" class="none-router text-white">
                <i class="icon-house f-21" /> Home
            </router-link>
        </div>
        <div class="col-xl-auto col-lg-auto d-middle-center f-600 cr-pointer">
            <router-link :to="{name: esLeechero ? 'login.leechero.terminos-condiciones' : 'login.cliente.terminos-condiciones' }" class="none-router text-white">
                <i class="icon-book-open-variant f-20" /> Términos y Condiciones
            </router-link>
        </div>
        <div class="col-xl-auto col-lg-auto d-middle-center f-600 cr-pointer">
            <router-link :to="{name: esLeechero ? 'login.leechero.politica-privacidad' : 'login.cliente.politica-privacidad' }" class="none-router text-white">
                <i class="icon-lock f-20" /> Políticas de Privacidad
            </router-link>
        </div>
        <div class="col-xl-auto col-lg-auto d-middle-center f-600 cr-pointer">
            <router-link :to="{name: esLeechero ? 'login.leechero.preguntas-frecuentes' : 'login.cliente.preguntas-frecuentes'}" class="none-router text-white">
                <i class="icon-help f-20" /> Preguntas Frecuentes
            </router-link>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Footer',
    computed: {
        esLeechero(){
            return this.$route.name.match('leechero')
        }
    }
}
</script>
<style lang="scss" scoped>
a{ text-decoration: none !important; }
</style>
