export default [
    {
        path: '/admin/equipos',
        meta: { nombre: 'Equipos' },
        name: 'admin.equipos',
        component: () => import('../../pages/equipos/index').then(m => m.default || m),
        redirect: {name: 'admin.equipos.listar'},
        children: [
            { 
                path: 'listar',
                meta: { nombre: 'Equipos' },
                name: 'admin.equipos.listar',
                component: () => import('../../pages/equipos/listar').then(m => m.default || m),
            },
            { 
                path: 'comparacion-equipos',
                meta: { nombre: 'Equipos' },
                name: 'admin.equipos.comparacion-equipos',
                component: () => import('../../pages/equipos/comparacion').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/admin/equipos/ver/:id_equipo',
        meta: { nombre: 'Equipos' },
        name: 'admin.equipos.ver',
        component: () => import('../../pages/equipos/ver').then(m => m.default || m),
    }
]
