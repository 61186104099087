<template>
    <section class="vh-100 bg-login position-relative">
        <div class="w-100 h-100 position-absolute container-img" style="">
            <div class="img-background" />
        </div>
        <div class="navbar-home">
            <!-- <div class="col-auto px-5 d-none d-xl-block d-lg-block" /> -->
            <div class="col text-xl-left text-lg-center text-md-center text-sm-center text-center">
                <img src="/img/auth/logo_rojo.svg" style="max-width:150px;max-height:80px;" />
            </div>
            <div class="col d-lg-block d-md-block d-none d-sm-block">
                <!-- <img src="/img/auth/logo_rojo12.svg" style="max-height:75px;max-width:175px;" /> -->
                <div class="row mx-0 justify-content-end">
                    <router-link :to="{name: 'login'}">
                        <div class="bg-login br-12 text-white shadow f-600 py-2 px-5 cr-pointer">
                            iniciar sesión
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="container-fluid position-relative" style="padding-top:100px;height:calc(100% - 5px);">
            <div class="row mx-0 h-100 container-l">
                <div id="img-container" class="col-sm-12 order-sm-2 order-xl-1 col-xl d-flex align-items-center justify-center">
                    <img
                    v-if="url_image == ''"
                    id="img-loginNuevo"
                    :src="`/img/icons/Grupo 19404.svg`"
                    style="max-width:100%;"
                    class="obj-cover img-init"
                    />
                    <img
                    v-else
                    :src="`/img/auth/${url_image}.svg`"
                    style="max-width:100%;"
                    class="obj-cover img-init"
                    />
                </div>
                <div class="container-text col order-sm-1 order-xl-2 text-center d-flex flex-column justify-content-xl-center justify-content-sm-around justify-content-lg-start pt-3">
                    <div class="row mx-0 justify-center">
                        <img src="/img/auth/Logo leeche blanco.svg" style="max-width:100%;height:125px;" class="obj-cover logo" />
                    </div>
                    <p class="text-white text-welcome my-4">
                        Únete a la comunidad. <br />
                        La <span class="f-600"> nueva forma de vender y comprar </span> <br />
                        productos de la canasta familiar.
                    </p>
                    <div id="contenedor-btn" class="row mx-0 mt-xl-5 mt-lg-0">
                        <div class="col d-flex leechero">
                            <router-link :to="{name: 'login.form.leechero'}">
                                <div
                                class="btn-leechero bg-white f-18 text-general-red br-12 py-xl-2 py-lg-3 cr-pointer text-center shadow"
                                @mouseenter="url_image = 'leechero'"
                                @mouseleave="setDefaultImage()"
                                >
                                    Quiero ser Vendedor
                                </div>
                            </router-link>
                        </div>
                        <div class="col iniciar my-2">
                            <router-link :to="{name: 'login'}">
                                <div
                                class="btn-iniciar bg-white f-18 text-general-red br-12 py-xl-2 py-lg-3 cr-pointer text-center px-2 shadow"
                                @mouseenter="url_image = 'clientes_app'"
                                @mouseleave="setDefaultImage()"
                                >
                                    iniciar sesión
                                </div>
                            </router-link>
                        </div>
                        <div class="col d-flex cliente">
                            <router-link :to="{name: 'login.cliente'}">
                                <div
                                class="btn-cliente bg-white f-18 text-leeche br-12 py-xl-2 py-lg-3 cr-pointer text-center shadow"
                                @mouseenter="url_image = 'clientes_app'"
                                @mouseleave="setDefaultImage()"
                                >
                                    Quiero comprar
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    layout: 'login',
    data(){
        return{
            url_image: ''
        }
    },
    methods: {
        setDefaultImage(){
            this.url_image = '';
        },
    }
}
</script>

<style lang="scss" scoped>
a{text-decoration: none !important;}
.br-12{ border-radius: 12px; }
.bg-login{  background: var(--color-general); }
.text-leeche{ color: var(--color-general); }
.text-welcome{ font-size: 35px; }
.img-init{height: calc(100vh - 400px) !important;}
.iniciar{display: none;}

.img-background{
    background-image: url('/img/auth/Mancha blanca.svg');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 65vw;
    height: 100vh;
}

.navbar-home{
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    z-index: 5;
    align-items: center;
    width: calc(100vw);
    height: 88px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
}

.btn-leechero{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 180px !important;
}
.btn-iniciar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 180px !important;
}

.btn-cliente{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 180px !important;
}

/* -*-*-*-*- Mobile -*-*-*-*- */
@media (min-width:320px) and (max-width:480px){
    .img-init{
        height:calc(100vh - 500px) !important;
    }
    .container-img{
        .img-background{
            width: 58vw !important;
        }
    }
    .container-text{
        padding: 0px !important;
        .logo{ height: 55px !important; display: none !important; }
        .text-welcome{ font-size: 21px !important; margin-top: 12px; margin-bottom: 12px !important; }
        #contenedor-btn{
            .iniciar{
                display: flex !important;
                justify-content: center !important;
                a{
                    .btn-iniciar{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 44px;
                        width: 180px !important;
                    }
                }
            }
            .leechero{
                display: flex !important;
                justify-content: center !important;
            }
            .cliente{
                display: flex !important;
                justify-content: center !important;
            }
            /* .cliente{ display: none !important;  margin-top: 8px; a{width:  100%  !important; height: 44px !important; }    } */
        }
    }
}
/* -*-*-*-*- Desktops and laptops -*-*-*-*- */
@media (max-width : 1224px) {
    .logo{height: 95px;}
    .img-init{ height:calc(100vh - 466px) !important; }
    .text-welcome{ font-size: 28px !important; }
    .img-background{ width: 55vw !important; }
    .container-text{
        #contenedor-btn{
            .leechero{
                display: flex;
                justify-content: flex-end;
            }
            .cliente{
                display: flex;
                justify-content: flex-start;
            }
        }
    }
}
@media (min-width: 1224px){
    .container-text{
        #contenedor-btn{
            .leechero{
                display: flex;
                justify-content: flex-end;
            }
            .cliente{
                display: flex;
                justify-content: flex-start;
            }
        }
    }
}

</style>
