import axios from 'axios'

const cedis = {
    get_cedis: () => axios.get("cedis/cedis-user"),
    get_monedas: () => axios.get("monedas"),
    cedis: params => axios.get("cedis/cedis", params),
    crearCedisNavbar: params => axios.post(`cedis/crear-cedis-navbar`, params),
    postAgregarUserCedi: params => axios.post(`cedis/post-user-cedi`,params),
    deleteRemoverUserCedi(id_cedi,id_user){
        return axios.delete(`cedis/${id_cedi}/delete-user/${id_user}/cedi`)
    },
    changeFormaPago: params => axios.post(`cedis/post-forma-pago`,params),
    changeParametrosCedis(idCedis, payload){
        return axios.put(`cedis/${idCedis}/change-parametros`, payload)
    }
};
export default cedis
