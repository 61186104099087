export default [
    {
        path: '/geocercas',
        name: 'admin.geocercas',
        meta: { nombre: 'Geocercas' },
        component: () => import(/* webpackChunkName: "geocercas" */ '../../pages/geocercas').then(m => m.default || m),
        redirect: ({name: 'admin.geocercas.grupos'}),
        children: [
            {
                path: 'grupos',
                name: 'admin.geocercas.grupos',
                meta: { nombre: 'Geocercas' },
                component: () => import(/* webpackChunkName: "geocercas" */ '../../pages/geocercas/geocercasGrupos').then(m => m.default || m),
            },
            {
                path: 'grupos/:idGrupo',
                name: 'admin.geocercas.grupos.ver',
                meta: { nombre: 'Geocercas' },
                component: () => import(/* webpackChunkName: "geocercas" */ '../../pages/geocercas/geocercasGrupoDetalle').then(m => m.default || m),
            },
            {
                path: 'leecheros',
                name: 'admin.geocercas.leecheros',
                meta: { nombre: 'Geocercas' },
                component: () => import(/* webpackChunkName: "geocercas" */ '../../pages/geocercas/geocercasLeecheros').then(m => m.default || m),
            }
        ]
    },
]

