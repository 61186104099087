
export const state = {
    convenio: {},
}

export const getters = {
    convenio: ({convenio}) => convenio,
}

export const mutations = {
    setConvenio: (state, payload) => state.convenio = payload,
}
