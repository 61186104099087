export default [
    {
        path: '/entregas',
        name: 'admin.entregas',
        meta: { nombre: 'Entregas' },
        component: () => import('~/pages/entregas').then(m => m.default || m),
        redirect: {name: 'admin.entregas.leecheros'},
        children: [
            {
                path: 'entregas-tienda',
                name: 'admin.entregas.leecheros',
                component: () => import('~/pages/entregas/aLeecheros').then(m => m.default || m),
            },
            {
                path: 'mapa-entregas-tienda',
                name: 'admin.entregas.mapa-entregas-leecheros',
                component: () => import('~/pages/entregas/mapaALeecheros').then(m => m.default || m),
            },
            {
                path: 'mapa-entregas-clientes',
                name: 'admin.entregas.mapa-entregas-clientes',
                component: () => import('~/pages/entregas/MapaEntregasClientes').then(m => m.default || m),
            }
        ]
    }
]
