<template>
    <div>
        <el-select v-model="seleccion" multiple placeholder="Seleccionar" class="w-100">
            <el-option
            v-for="item in etiquetas"
            :key="item.id"
            :label="item.nombre"
            :value="item.id">
            </el-option>
        </el-select>
    </div>
    
</template>
<script>
import SEtiquetas from '~/services/etiquetas'
export default {    
    props: {
        idsetiquetas: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data(){
        return{
            seleccion: null,
            etiquetas: []
        }
    },
    mounted(){
        this.getEtiquetas()
    },
    methods:{
        async getEtiquetas(){
            try {
                const { data } = await SEtiquetas.GetEtiquetas()
                this.etiquetas = data
                if(this.idsetiquetas){
                    this.setSeleccionInicial()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        getSeleccion(){
            return this.seleccion
        },
        setSeleccion(etiquetas){
            this.seleccion = etiquetas
        },
        setSeleccionInicial(){
            this.seleccion = this.idsetiquetas           
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
