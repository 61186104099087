<template>
    <div class="row mx-0 tabs">
        <template v-for="(item,index) in tabs">
            <router-link
            v-if="$can(item.can)"
            :key="index"
            :data-xd="item.ruta"
            :to="{ name: `${item.ruta}`, params: item.params || {} }"
            :class="{'router-link-exact-active': subIsActive(item.active)}"
            class="d-middle br-50 position-relative"
            >
                <p class="nombre-tab">{{ item.titulo }}</p>
                <div 
                v-if="item.num !== undefined" 
                :class="{'router-link-exact-active': subIsActive(item.active)}" 
                class="item-tab br-20 ml-2 py-1 d-middle-center"
                >
                    {{ item.num }}
                </div>
            </router-link>
        </template>
        <slot name="btnAgregar" />
    </div>
</template>
<script>
export default {
    name: 'TabsIcons',
    props: {
        tabs: {
            type: Array,
            default: () => []
        }
    },
    /* computed: {
        isActive(){
            return this.$route.name
        }
    }, */
    methods: {
        subIsActive(input){
            return this.$route.name.match(input)
            /*
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
            */
        }
    }
}
</script>
<style lang="scss" scoped>
.tabs{
    // padding-bottom: 6px;
    // border-bottom: 2px solid #32A3FD;
    .item-tab{
        min-width: 40px;
        background: #707070;
        color: #ffffff;
    }
    a{
        padding: 5px 12px 5px 12px !important;
        color: #6C6B6B ;
        font-size: 14px;
        &.router-link-exact-active.router-link-active{
            padding: 5px 10px 5px 10px;
            background: var(--text-general);
            color: #fff;
            .nombre-tab{
                display: inline-block;
            }
            &:hover{
                color: #fff !important;
            }
            .item-tab{
                background: white;
                color: var(--text-general);
            }
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>
