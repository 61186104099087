export default [
    {
        path: '/gaming',
        name: 'admin.gaming',
        meta: { nombre: 'Gaming' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming').then(m => m.default || m),
        redirect: {name: 'admin.gaming.eventos'},
        children: [
            {
                path: 'pedidos-especiales/:id_gaming_pedido?',
                name: 'admin.gaming.pedidos-especiales',
                meta: { nombre: 'Gaming' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/pedidosEspeciales').then(m => m.default || m),
            },
            {
                path: 'historial-pedidos',
                name: 'admin.gaming.historial-pedidos',
                meta: { nombre: 'Gaming' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/historialPedidos').then(m => m.default || m),
            },
            {
                path: 'premios',
                name: 'admin.gaming.premios',
                meta: { nombre: 'Gaming' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/premios/index').then(m => m.default || m),
                children: [
                    {
                        path: 'registro',
                        name: 'admin.gaming.premios.registro',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/premios/registro').then(m => m.default || m),
                    },
                    {
                        path: 'cedis',
                        name: 'admin.gaming.premios.cedis',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/premios/cedis').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'eventos',
                name: 'admin.gaming.eventos',
                meta: { nombre: 'Gaming' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/eventos').then(m => m.default || m),
            },
            {
                path: 'usuarios',
                name: 'admin.gaming.usuarios',
                meta: { nombre: 'Gaming' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/usuarios').then(m => m.default || m),
                redirect: {name: 'admin.gaming.usuarios.cliente'},
                children: [
                    { 
                        path: 'cliente',
                        name: 'admin.gaming.usuarios.cliente',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/usuarios/clientes').then(m => m.default || m),
                    },
                    {
                        path: 'leecheros',
                        name: 'admin.gaming.usuarios.leecheros',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/usuarios/leecheros').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'recompensas',
                name: 'admin.gaming.recompensas',
                meta: { nombre: 'Gaming' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/index').then(m => m.default || m),
                children: [
                    {
                        path: 'valor-monedas-gemas',
                        name: 'admin.gaming.valor-monedas-gemas',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/valorMonedasGemas').then(m => m.default || m),
                    },
                    {
                        path: 'registro-propio',
                        name: 'admin.gaming.registro-propio',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/registroPropio').then(m => m.default || m),
                    },
                    {
                        path: 'completar-registro',
                        name: 'admin.gaming.completar-registro',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/completarRegistro').then(m => m.default || m),
                    },
                    {
                        path: 'verificar-cuentas',
                        name: 'admin.gaming.verificar-cuentas',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/verificarCuentas').then(m => m.default || m),
                    },
                    {
                        path: 'primer-compra',
                        name: 'admin.gaming.primer-compra',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/primerCompra').then(m => m.default || m),
                    },
                    {
                        path: 'compras-referidos',
                        name: 'admin.gaming.compras-referidos',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/comprasMias').then(m => m.default || m),
                    },
                    {
                        path: 'metas-dinero',
                        name: 'admin.gaming.metas-dinero',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/metasDinero').then(m => m.default || m),
                    },
                    {
                        path: 'metas-venta-dinero',
                        name: 'admin.gaming.metas-venta-dinero',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/metasVentaDinero').then(m => m.default || m),
                    },
                    {
                        path: 'metas-cantidad',
                        name: 'admin.gaming.metas-cantidad',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/metasCantidad').then(m => m.default || m),
                    },
                    {
                        path: 'metas-venta',
                        name: 'admin.gaming.metas-venta',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/metasVenta').then(m => m.default || m),
                    },
                    {
                        path: 'visitas-diarias',
                        name: 'admin.gaming.visitas-diarias',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/visitasDiarias').then(m => m.default || m),
                    },
                    {
                        path: 'nuevo-referido',
                        name: 'admin.gaming.nuevo-referido',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/nuevoReferido').then(m => m.default || m),
                    },
                    {
                        path: 'primera-compra',
                        name: 'admin.gaming.primera-compra',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/primeraCompra').then(m => m.default || m),
                    },
                    {
                        path: 'perdida-recompensa',
                        name: 'admin.gaming.perdida-recompensa',
                        meta: { nombre: 'Gaming' },
                        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/recompensas/perdidaRecompensa').then(m => m.default || m),
                    }
                ]
            }
        ]
    },
    {
        path: '/crear-evento/:id_carpeta',
        name: 'admin.gaming.crear-evento',
        meta: { nombre: 'Gaming' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/crearEvento').then(m => m.default || m),
    },
    {
        path: '/editar-evento/:id_evento',
        name: 'admin.gaming.editar-evento',
        meta: { nombre: 'Gaming' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/editarEvento').then(m => m.default || m),
    },
    {
        path: '/ver-evento/:id_evento',
        name: 'admin.gaming.ver-evento',
        meta: { nombre: 'Gaming' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/gaming/verEvento').then(m => m.default || m),
    }
]
