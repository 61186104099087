<template>
    <div
    ref="el-visor-pdf"
    class="_df_custom"
    webgl="false"
    download="false"
    :source="source"
    />
</template>

<script>
export default {
    data(){
        return {
            source: ''
        }
    },
    methods:{
        set_pdf(source){
            this.source = source
            this.$nextTick(()=>{
                this.$refs['el-visor-pdf'].click()
            })
        }
    }
}
</script>
