import axios from 'axios'
import store from '~/store'
export default async(to, from, next) =>{
    const {data} = await axios(`/admin-tiendas/validar-home`)
    let logueado = store.getters[`auth/obtenerUsuario`]
    if(data.home){
        return next()
    } else{
        next({name:`${logueado.rol_nombre}.pedidos`})
    }
}
