
import vueGlobalMixin from '~/mixins/general'
import Produccion from "~/services/tiendas/produccion";
import formatedores from "~/mixins/Formateadores"
import _ from "lodash"

const contentString = (el, tipo) => {
    if (tipo == 'cedis'){
        return `
            <div style="width:100%;height:100%;border-radius:12px;position:relative;">
                <div class="py-2 px-3 tres-puntos float-right" style="position:absolute;border-radius:12px;color:#fff;font-weight:bold;background-color:#232320;bottom:0px;right:100px;max-width:150px;">
                    ${el.estado_nombre}
                </div>
                <div class="py-2 px-3 tres-puntos float-right" style="position:absolute;border-radius:12px;color:#fff;font-weight:bold;background-color:#232320;bottom:0px;right:0px;max-width:150px;">
                    ${el.calificacion ?? 0}
                </div>
                <img src="${el.imagen}" width="100%" height="100%" style="border-radius:12px;object-fit:cover;" />
            </div>  
        `;
    } else {
        const filtered = [el.direccion, el.direccion_anexo, el.barrio].filter(el => el)
        const direccion = _.join(filtered, ' - ')
        const fecha = formatedores.methods.formatearFecha(el.created_at)
        return `
            <div style="border-radius:12px;position:relative;text-align:center;">
                <img src="${el.imagen}" width="120px" height="120px" style="border-radius:50%;object-fit:cover; border:1px #d1d1d1 solid" />
            </div>  
            <p style="margin-top: 10px;font-size:14px;font-weight: bold;text-align:center;"><b>${el.propietario_nombre}</b></p>
            <div class="text-left mx-3">
                <div class="row mx-0 my-2">
                    <span class="icon-location f-18" /> <span class="f-14">${el.direccion}</span>
                </div>
                <div class="row mx-0 my-2">
                    <span class="icon-warehouse f-18" /> <span class="f-14">Cedis: ${el.cedis_nombre}</span>
                </div>
                <div class="row mx-0 my-2">
                    <span class="icon-calendar-check f-18" /> <span class="f-14">Registrado desde: ${fecha}</span>
                </div>
            </div>
        `;        
    }
}

export const state = {
    cedis: false,
    tiendas: false,
    clientes: false,
    coordenadas: {lat:7.120959508258036,lng: -73.13525244855165 },
    locations: [],
    arrCedis: [],
    arrTiendas: [],
    arrClientes: [],
    refresh: false,
    tiendasNombre: [],
    idTienda: 0,
    idCedis: null,
}

export const getters = {
    getCedis: ({cedis}) => cedis,
    getTiendas: ({tiendas}) => tiendas,
    getTiendasNombre: ({tiendasNombre}) => tiendasNombre,
    getClientes: ({clientes}) => clientes,
    getCoordenadas: ({coordenadas}) => coordenadas,
    getLocations: ({locations}) => locations,
    getRefresh: ({refresh}) => refresh,
    getIdTienda: ({idTienda}) => idTienda,
}

export const mutations = {
    setCedis: (state, payload) => {
        state.cedis = payload
    },
    setTiendas: (state, payload) => {
        state.tiendas = payload
    },
    setClientes: (state, payload) => {
        state.clientes = payload
    },
    setCoordenadas: (state, payload) => {
        state.coordenadas = payload ?? state.coordenadas
    },
    setLocations: (state) => {
        let cedis = state.arrCedis.map(e => ({
            lat: e.latitud,
            lng: e.longitud,
            icon: '/img/icons/cedis_negro.svg',
            info: contentString(e, 'cedis')
        }))
        let tiendas = state.arrTiendas.map(e => ({
            lat: e.latitud,
            lng: e.longitud,
            icon: '/img/icons/leechero_ubicacion.svg',
            info: contentString(e, 'tienda')
        }))
        let clientes = state.arrClientes.map(e => ({ lat: e.latitud,lng: e.longitud}))
        let center = [{...state.coordenadas, icon: '/img/icons/posicion.png'}]
        state.locations = [...center, ...cedis, ...tiendas, ...clientes]
    },
    setArrCedis: (state, payload) => state.arrCedis = payload,
    setArrTiendas: (state, payload) => state.arrTiendas = payload,
    setArrClientes: (state, payload) => state.arrClientes = payload,
    setRefresh: (state, payload) => state.refresh = payload,
    setTiendasNombre: (state, payload) => state.tiendasNombre = payload,
    setIdTienda: (state, payload) => state.idTienda = payload,
    setIdCedis: (state, payload) => state.idCedis = payload,
}

export const actions = {
    async getDatosMapa({commit, state}){
        try {
            let params =  { 
                cedis: state.cedis,
                tiendas: state.tiendas,
                clientes: state.clientes,
                id_cedis: state.idCedis,
                ...state.coordenadas,
                id_tienda: state.idTienda
            }
            const { data } = await Produccion.getDatosMapa(params)
            commit('setArrCedis',data.cedis)
            commit('setArrTiendas',data.tiendas)
            commit('setArrClientes',data.clientes)
            commit('setLocations')
        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
        }
    },
    async getTiendasNombre({ commit }, id_cedis){
        try {
            const { data } = await Produccion.getTiendasNombre({ id_cedis })
            data.unshift({id: 0, propietario_nombre: 'Todos los leecheros'})
            commit('setTiendasNombre', data)
            // commit('setIdTienda', 0)
        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
        }
    }
}
