export default [
    {
        path: '/datos-cuentas',
        name: 'admin.datos-cuentas',
        meta: { nombre: 'Datos cuentas' },
        component: () => import(/* webpackChunkName: "Datos cuentas" */'~/pages/datosCuentas').then(m => m.default || m),
        children: [
            {
                path: 'clientes', 
                name: 'admin.datos-cuentas.clientes',
                meta: { nombre: 'Datos cuentas' },
                component: () => import('~/pages/datosCuentas/clientes').then(m => m.default || m),
            },
            {
                path: 'Leecheros',
                name: 'admin.datos-cuentas.leecheros',
                meta: { nombre: 'Datos cuentas' },
                component: () => import('~/pages/datosCuentas/leecheros').then(m => m.default || m),
            },
            {
                path: 'lanzamientos',
                name: 'admin.datos-cuentas.lanzamientos',
                meta: { nombre: 'Datos cuentas' },
                component: () => import('~/pages/datosCuentas/lanzamientos').then(m => m.default || m),
                children: [
                    {
                        path: 'clientes',
                        name: 'admin.datos-cuentas.lanzamientos.clientes',
                        component: () => import('~/pages/datosCuentas/lanzamientosVer/clientes').then(m => m.default || m),
                    },      
                    {
                        path: 'leecheros',
                        name: 'admin.datos-cuentas.lanzamientos.leecheros',
                        component: () => import('~/pages/datosCuentas/lanzamientosVer/leecheros').then(m => m.default || m),
                    },      
                ]
            },
        ]
    },
]