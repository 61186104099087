<template>
    <modal ref="modalVerCategoria" :titulo="titulo" no-aceptar no-cancelar>
        <div v-for="(data,d) in categorias" :key="d" class="row mx-0 mb-3">
            <div class="col-auto" />
            <div class="col">
                <p class="f-15 text-general mb-2">
                    {{ data.nombre }}
                </p>
                <div class="row mx-0">
                    <div class="col-auto text-general2 pl-0">
                        {{ separadorNumero(data.total) }}
                    </div>
                    <div v-if="pedidos" class="col-auto f-16 text-general">
                        {{ agregarSeparadoresNumero(data.cantidad) }}
                    </div>
                    <div class="col-auto ml-auto f-600 text-general">
                        {{ agregarSeparadoresNumero(data.porcentaje_total, 1) }} %
                    </div>
                </div>
                <div class="row mx-0" style="height:10px;">
                    <div class="h-100 bg-gris br-20" :style="`width:${porcentajesBordes(data.porcentaje_max_total)}%;`" />
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            categorias: [],
            pedidos: false,
            titulo: ''
        }
    },
    methods: {
        toggle({ titulo, categorias, pedidos = false }){
            this.titulo = titulo
            this.pedidos = pedidos
            this.categorias = categorias
            this.$refs.modalVerCategoria.toggle();
        }
    }
}
</script>
