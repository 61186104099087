<template>
    <section class="pt-2" style="height: calc(100% - 60px);">
        <div class="row mx-0">
            <div class="col-12 select-reclamo d-none my-2">
                <div class="row mx-0">
                    <div class="col-5 px-0">
                        <el-select v-model="value" placeholder="Seleccionar reclamo finalizado" class="w-100" @change="activarCar">
                            <el-option
                            v-for="(item, idx) in buscarPedidos"
                            :key="idx"
                            :label="`${item.id} - ${item.nombre}`"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="menu-principal h-100 br-tl-8 bg-white border-r-gris">
                <div v-if="vista == 4 || vista == 5" class="row mx-0 align-items-center justify-center border-b-gris" style="height:56px;">
                    <router-link
                    :to="{name: 'admin.soporte.reclamo'}"
                    class="mr-2"
                    style="text-decoration:none;"
                    :class="$route.name == 'admin.soporte.reclamo' ? '' : 'text-general2'"
                    >
                        <div class="tres-puntos f-14 text-center py-1 br-20 px-3" :class="$route.name == 'admin.soporte.reclamo' ? 'bg-general3 text-white' : ''">
                            Soporte vigente
                        </div>
                    </router-link>
                    <router-link
                    :to="{name: 'admin.soporte.reclamos-terminados'}"
                    style="text-decoration:none;"
                    class="ml-2"
                    :class="$route.name == 'admin.soporte.reclamos-terminados' ? '' : 'text-general2'"
                    >
                        <div class="tres-puntos f-14 text-center py-1 br-20 px-3" :class="$route.name == 'admin.soporte.reclamos-terminados' ? 'bg-general3 text-white' : ''">
                            Soporte Finalizado
                        </div>
                    </router-link>
                </div>
                <div class="row mx-0 border-b-gris" style="height:51px;">
                    <div class="col-12 my-auto">
                        <el-input v-model="buscar" size="small" placeholder="Buscar soporte" class="br-20" />
                    </div>
                </div>
                <div class="overflow-auto custom-scroll content-listar-reclamos">
                    <content-loader v-if="loading" />
                    <div
                    v-for="(info, idx) in buscarPedidos" :key="idx"
                    class="d-middle mx-0 border-bottom cr-pointer"
                    style="min-height:94px;"
                    @click="activarCar(info.id)"
                    >
                        <div :class="`mx-2 px-1 br-5 ${id_pedido === info.id?'bg-general':''}`" style="height:80px" />
                        <img class="border bg-whitesmoke rounded-circle" width="48" height="48" :src="info.imagen_firmada" alt="" />
                        <div class="col pr-0 f-15 ">
                            <div class="row mx-0 f-600 align-items-center">
                                <p class="tres-puntos col-auto px-0">ID {{ info.id }}</p>
                                <p class="tres-puntos ml-auto">
                                    <span v-if="vista==2 || vista==5">
                                        Cierre:
                                    </span>
                                    {{ info.fecha_ult_reclamo }}
                                </p>
                                <estrellas :row="info" />
                            </div>
                            <div v-if="vista==2 || vista==5 || vista==4" class="row mx-0 align-items-center">
                                <el-tooltip v-if="info.ult_mensaje" placement="bottom" :content="info.ult_mensaje" effect="light">
                                    <p class="ult-mensaje col-12 pl-0">{{ info.ult_mensaje }}</p>
                                </el-tooltip>
                                <p v-else>Sin último mensaje</p>
                            </div>
                            <div v-if="vista==1 || vista==6" class="row mx-0 align-items-center">
                                <el-tooltip v-if="info.ult_mensaje_reclamo" placement="bottom" :content="info.ult_mensaje_reclamo" effect="light">
                                    <p class="ult-mensaje col-12 pl-0">{{ info.ult_mensaje_reclamo }}</p>
                                </el-tooltip>
                                <p v-else>Sin último mensaje</p>
                            </div>
                            <div class="row mx-0 justify-content-between">
                                <p class="tres-puntos f-600 col-6 px-1">{{ info.nombre }}</p>
                                <p v-if="vista==1" class="tres-puntos col-6 px-1">{{ info.hora_ult_chat_format }}</p>
                            </div>
                        </div>
                        <div v-if="info.cant_msg > 0" class="br-10 bg-general3 text-white px-2">
                            {{ info.cant_msg }}
                        </div>
                    </div>
                    <div v-if="pedidos.length <= 0" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/question.svg" alt="" />
                            </div>
                            <p>Aún no has recibido un mensaje de soporte</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 content-soporte bg-white br-tr-8 h-100 col menu-prinicipal" style="width: calc(100vw - 560px);">
                <verSoporte v-if="id_pedido!==0" :vista="vista" @actualizar="listarPedidos" />
                <div v-else class="no-datos d-middle-center">
                    <div class="col-4 text-center">
                        <img height="110" src="/img/no-imagen/click.svg" alt="" />
                        <p>Selecciona uno de los soportes abiertos del panel de la izquierda.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
/*
Pors vista

5 => Módulo soporte(Logueado como Admin) Tab Reclamos Terminados
*/
import {mapGetters} from 'vuex'
import Reclamos from '~/services/reclamos/reclamos'
export default {
    metaInfo: () =>({ title: 'Pedidos'}),
    components: {
        verSoporte: () => import('~/pages/soporte/verSoporte'),
    },
    sockets:{
        chats_pedidos(value){
            try {
                const {chat, pedidoChat} = JSON.parse(value)
                if((chat.id_pedido !== this.id_pedido) && chat.momento==2){
                    this.notifiChat(2,chat, pedidoChat)
                    let idx_pedido= this.pedidos.findIndex(e => e.id === pedidoChat.id)
                    if(idx_pedido >= 0){
                        let found = this.pedidos[idx_pedido]
                        found.cant_msg = found.cant_msg +1
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    props:{
        vista: {
            type: Number,
            default: 1
        },
    },
    data(){
        return {
            buscar: null,
            loading: false,
            value: ''
        }
    },
    computed:{
        ...mapGetters({
            pedidos: 'reclamos/reclamos/pedidos',
            id_pedido: 'reclamos/reclamos/id_pedido',
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            cedis:'cedis/cedis',
        }),
        buscarPedidos(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.pedidos.filter(el => el.nombre.toLowerCase().includes(this.buscar.toLowerCase()))

            }else{
                return this.pedidos
            }
        },
    },
    watch: {
        id_cedis(){
            this.$store.commit('reclamos/reclamos/set_id_pedido', 0)
            this.$store.commit('reclamos/reclamos/set_cant_reclamos', 0)
            this.listarPedidos()
        }
    },
    mounted(){
        this.$store.commit('reclamos/reclamos/set_id_pedido', 0)
        this.$store.commit('reclamos/reclamos/set_cant_reclamos', 0)
        this.listarPedidos()
    },
    methods: {
        async listarPedidos(){
            try {
                this.loading = true
                const route = this.$route.name
                var myVista = this.vista
                if(route==='admin.soporte.reclamo'){
                    myVista = 4// soporte
                }
                if(this.vista===2){
                    if(this.$route.name==='tendero.reclamos.terminados'){
                        myVista = 3
                    }
                }

                const enviar = {
                    vista: myVista
                }
                await this.$store.dispatch('reclamos/reclamos/action_listar_pedidos', enviar)
                this.loading = false
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        async activarCar(id){
            this.$store.commit('reclamos/reclamos/set_id_pedido', id)
            this.listarDetalle()
            await this.actualizarUltimaLectura()
            this.listarPedidos()
        },
        async listarDetalle(){
            try {
                const enviar = {
                    id_pedido: this.id_pedido,
                    vista: this.vista
                }
                await this.$store.dispatch('reclamos/reclamos/action_listar_detalle_pedido', enviar)
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarUltimaLectura(){
            try {
                const enviar ={
                    id_pedido: this.id_pedido
                }
                const {data} = await Reclamos.actualizarUltimaLectura(enviar)

            } catch (e){
                this.error_catch(e)
            }
        },
        tipoStar(tipo){
            switch (tipo){
            case 1:
                return 'icon-star-empty'
            case 2:
                return 'text-red'
            case 3:
                return 'text-yellow'
            case 4:
                return 'text-aqua'
            default:
                return 'invisible'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
a:hover{
    color:initial !important;
}
.text-red{
    color: #FF2525;
}
.text-yellow{
    color: #FF9D32;
}
.text-aqua{
    color: #03D6BC;
}
.ult-mensaje{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
.no-datos{
    height: calc(100vh - 165px) !important;
}
/* ----------- Media Queries ----------- */

@media (max-width:1000px){
    .menu-principal{
        display: none !important;
    }
    .select-reclamo{
        display: block !important;
    }
    .no-datos{
        height: calc(100vh - 210px) !important;
    }
}

@media (min-width : 1224px) {
    .content-listar-reclamos{
        width:400px;
        height: calc(100vh - 251px);
    }
}

@media (max-width : 1224px) {
    .content-listar-reclamos{
        width:310px;
        height: calc(100vh - 208px);
    }
}
</style>
