import Pedidos from '~/services/pedidos/pedido-manual'

export const state = {
    productos_agregados:[],
    listado_productos:[],
}

export const getters = {
    productos_agregados: state => state.productos_agregados,
    listado_productos: state => state.listado_productos,
}

export const mutations = {
    set_productos_agregados: (state,payload) => {
        state.productos_agregados = payload
    },
    set_listado_productos: (state,payload) => {
        state.listado_productos = payload
    },

    //Esta Mutacion elimina del array de busqueda el producto que fue agregago al pedido
    alter_listado: (state,payload) => {
        let idx = state.listado_productos.findIndex(p=>p.id === payload)
        state.listado_productos.splice(idx, 1);

    },
    // Esta mutacion actualiza el contenido de un producto
    alter_agregados(state,payload){

        let index = state.productos_agregados.findIndex(p=>p.id === payload.id);

        for (let [key,value] of Object.entries(payload)){
            console.log(index,key,value);
            state.productos_agregados[index][key] = value
        }
    },
    ordenar_productos: (state,payload) => {
        let idx = state.productos_agregados.findIndex(p=>p.id_producto === payload)
        let item = state.productos_agregados[idx];
        state.productos_agregados.splice(idx, 1);
        state.productos_agregados.unshift(item);


    },
    push_producto: (state,payload) => {
        state.productos_agregados.unshift(payload);
    },
    remover_producto: (state,payload) => {
        let idx = state.productos_agregados.findIndex(p=>p.id === payload)
        state.productos_agregados.splice(idx, 1);
    },


}

export const actions = {
    async get_productos({commit},id){
        const {data} = await Pedidos.productos_agregados(id)
        commit('set_productos_agregados',data.productos)
    },
    async buscar_productos({commit},payload){
        const {data} = await Pedidos.busqueda_productos(payload)
        commit('set_listado_productos',data.productos)
    }
}
