<template>
    <div ref="modalgbp" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" :class="tamano ? tamano : ''" role="document">
            <div class="modal-content">
                <cargando v-if="cargadoModal" />
                <div class="modal-header mb-1 bg-header d-flex align-items-center">
                    <div v-show="regresar" class="my-auto d-middle-center bg-white rounded-circle" style="width:20px;height:20px;">
                        <i class="icon-left-circle f-20 cr-pointer text-gr-general" @click="$emit('regresar')" />
                    </div>
                    <!-- <div class="col-auto px-0">
                        <img
                        v-if="icon || user"
                        class="img-tipo"
                        height="35"
                        :src="icon !== '' ? `/img/modales/${icon}.svg` : user " alt="img-producto"
                        />
                    </div> -->
                    <div class="col text-left f-17 f-600 text-general pr-0 pl-2" :class="centerTitle? 'text-center' : 'text-left'">
                        {{ titulo }}
                    </div>
                    <div class="col-auto px-0">
                        <div class="d-middle-center bg-white rounded-circle" style="width:24px;height:24px;">
                            <i class="icon-cancel f-24 cr-pointer text-general" data-dismiss="modal" @click="actionCerrar" />
                        </div>
                    </div>
                    <img v-if="img" class="img-tipo-producto border bg-whitesmoke" height="90" :src="img !== '' ? img : `/img/no-imagen/mercado.svg`" alt="" />
                </div>
                <div v-if="hasHeaderModal" class="row text-general align-items-center  justify-center text-center px-3" :class="altoTitulo">
                    <slot name="header-slot" />
                </div>
                <div
                class="modal-body overflow-auto custom-scroll"
                :class="hasHeaderModal ? 'pt-3' : ''"
                :style="{
                    'height': `${height}vh`,
                    'max-height' : `${fixHeight}vh`
                }"
                >
                    <slot />
                </div>
                <div class="modal-bottom row mx-0 justify-center py-2">
                    <button
                    v-if="!noCancelar"
                    type="button"
                    class="btn mx-2 btn-cancelar"
                    @click="cancelar"
                    v-text="btnCancelar!=''?btnCancelar:textosBotones.cancelar"
                    />
                    <button
                    v-if="cerrar"
                    type="button"
                    class="btn mx-2 btn-cancelar br-8 w-100px"
                    @click="actionCerrar"
                    v-text="textosBotones.cerrar"
                    />
                    <button
                    v-show="!noAceptar"
                    v-loading="cargando"
                    type="button"
                    class="btn mx-2 btn-aceptar text-white br-10 border-0"
                    @click="guardar"
                    v-text="textosBotones.aceptar"
                    />
                    <button
                    v-show="eliminar"
                    v-loading="cargando"
                    type="button"
                    class="btn btn-eliminar mx-2 br-10"
                    @click="actionEliminar"
                    v-text="textosBotones.eliminar"
                    />
                    <slot name="excel" />
                    <button
                    v-show="listo"
                    v-loading="cargando"
                    type="button"
                    class="btn mx-2 btn-general"
                    @click="toggle"
                    v-text="textosBotones.listo"
                    />
                    <button
                    v-show="adicional !== ''"
                    v-loading="cargando"
                    :disabled="desactivarGuardar"
                    type="button"
                    class="btn btn-aceptar mx-2 px-2 text-white w-90px"
                    @click="onAdicional"
                    v-text="adicional"
                    />
                    <div
                    v-for="btn in btns"
                    v-show="btns.length"
                    :key="btn.texto"
                    type="button"
                    class="mx-2 p-2 d-middle-center br-10"
                    :class="btn.color"
                    style="min-width:80px;"
                    @click="$emit('accion', btn.accion)"
                    v-text="btn.texto"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            required: true
        },
        cargadoModal: {
            type: Boolean,
            default: false
        },
        tamano: {
            type: String,
            default: 'modal-md'
        },
        height: {
            type: Number,
            default: null
        },
        noAceptar: {
            type: Boolean,
            default: false
        },
        fixHeight: {
            type: Number,
            default: 60
        },
        centerIcon: {
            type: Boolean,
            default: false
        },
        centerTitle: {
            type: Boolean,
            default: false
        },
        btnCancelar: {
            type: String,
            default: ''
        },
        noCancelar: {
            type: Boolean,
            default: false
        },
        cerrar: {
            type: Boolean,
            default: false
        },
        listo: {
            type: Boolean,
            default: false
        },
        eliminar: {
            type: Boolean,
            default: false
        },
        desactivarGuardar: {
            type: Boolean,
            default: false
        },
        adicional: {
            type: String,
            default: ''
        },
        agregar:{
            type: String,
            default: ''
        },
        btns: {
            type: Array,
            default: () => { return [] }
        },
        cargando: {
            type: Boolean,
            default: false
        },
        regresar: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        },
        img: {
            type: String,
            default: ''
        },
        user: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            textosBotones: {
                listo: 'Listo',
                cerrar: 'Cerrar',
                aceptar: 'Aceptar',
                guardar: 'Guardar',
                eliminar: 'Eliminar',
                cancelar: 'Cancelar',
            }
        }
    },
    computed: {
        altoTitulo(){
            if (this.icon != '' || this.img != ''){
                return 'my-2 mt-3'
            }else { return 'mt-3' }
        },
        hasHeaderModal(){
            return !!this.$slots['header-slot']
        }
    },
    mounted(){
        $(this.$refs.modalgbp).on('hidden.bs.modal', () => {
            this.$emit('close')
        })
    },
    methods: {
        toggle(){
            $(this.$refs.modalgbp).modal('toggle')
        },
        guardar(){
            this.$emit('guardar')
        },
        actionEliminar(){
            this.$emit('eliminar')
        },
        onAdicional(){
            this.$emit('adicional')
        },
        cancelar(){
            this.$emit('cancelar')
            this.toggle()
        },
        actionCerrar(){
            this.$emit('cerrar')
            this.toggle()
        },
        aceptar(){
            this.$emit('aceptar')
        },
        ocultar(){
            $(this.$refs.modalgbp).modal('hide')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/sass/gbp.scss';
.modal-content{
    border: none;
    border-radius: 15px;

    .modal-header{
        height: 60px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: none;
        background-size: cover;

        .close{
            outline: none;
            transition-duration: .85s;
            position: absolute;
            right: 10px;
            top: 1px;
            font-size: 2.5em;

            &:hover{
                transition-duration: .4s;
                transform: rotate(360deg) scale(1.2);
            }
        }
        .logo{
            display: block;
            // height: 70px;
            // width: 70px;

            span{
                font-size: 1.2em;
            }
        }
    }
    .modal-title{
        color: $azul2-primary;
        font-weight: normal;
    }
    .modal-bottom{
        height: 51px;
        align-items: center !important;
    }
}
.img-tipo-producto{
    position: absolute;
    top:8px;
    left: 5%;
    transform: translate(-50%);
}
.bg-header{
    background-color: #F8F9FF;
    background-position: center;
}

</style>
