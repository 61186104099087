export default [
    {
        path: '/delivery',
        name: 'admin.delivery',
        meta: { nombre: 'Deliverys' },
        component: () => import(/* webpackChunkName: "deliverys" */'~/pages/delivery').then(m => m.default || m),
        redirect: {name: 'admin.delivery.lista.mosaico'},
        children: [
            {
                path: 'delivery',
                name: 'admin.delivery.lista',
                component: () => import('~/pages/delivery/listas').then(m => m.default || m),
                redirect: {name: 'admin.delivery.lista.mosaico'},
                children: [
                    {
                        path: 'mosaico',
                        name: 'admin.delivery.lista.mosaico',
                        meta: { nombre: 'Deliverys' },
                        component: () => import(/* webpackChunkName: "deliverys" */'~/pages/delivery/mosaico').then(m => m.default || m),
                    },
                    {
                        path: 'lista',
                        name: 'admin.delivery.lista.lista',
                        meta: { nombre: 'Deliverys' },
                        component: () => import(/* webpackChunkName: "deliverys" */'~/pages/delivery/lista').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'pedidos',
                name: 'admin.delivery.pedidos',
                meta: { nombre: 'Deliverys' },
                component: () => import(/* webpackChunkName: "deliverys" */'~/pages/delivery/listaPedidos').then(m => m.default || m),

            },
            {
                path: 'comparativa-entregas',
                name: 'admin.delivery.comparativa-entregas',
                meta: { nombre: 'Deliverys' },
                component: () => import('~/pages/delivery/comparativasEntregas').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/delivery/ver/:id_delivery',
        name: 'admin.delivery.ver',
        meta: { nombre: 'Deliverys' },
        component: () => import(/* webpackChunkName: "deliverys" */'~/pages/delivery/ver/index').then(m => m.default || m),
        redirect: {name: 'admin.delivery.ver.informacion'},
        children: [
            {
                path: 'informacion',
                name: 'admin.delivery.ver.informacion',
                component: () => import('~/pages/delivery/ver/informacion').then(m => m.default || m),
            },
            {
                path: 'entregas-programadas',
                name: 'admin.delivery.ver.programadas',
                component: () => import('~/pages/delivery/ver/entregasProgramadas').then(m => m.default || m),
            },
            {
                path: 'historial-entregas',
                name: 'admin.delivery.ver.historial',
                component: () => import('~/pages/delivery/ver/historialEntregas').then(m => m.default || m),
            },
            {
                path: 'ubicacion',
                name: 'admin.delivery.ver.ubicacion',
                component: () => import('~/pages/delivery/ver/ubicacion').then(m => m.default || m),
            },

        ]
        /* redirect: {name: 'admin.delivery.lista.mosaico'}, */
    },
]
/* {
    path: 'mapa-entregas-leecheros',
    name: 'admin.entregas.mapa-entregas-leecheros',
    component: () => import('~/pages/entregas/mapaALeecheros').then(m => m.default || m),
},
{
    path: 'mapa-entregas-clientes',
    name: 'admin.entregas.mapa-entregas-clientes',
    component: () => import('~/pages/entregas/MapaEntregasClientes').then(m => m.default || m),
} */
