import Login from '~/pages/auth/login'
import LoginStop from '~/pages/auth/loginStop'
import loginNuevo from '~/pages/auth/loginNuevo';
import cliente from '~/pages/auth/cliente';
import CrearPerfil from '~/pages/auth/crearPerfil';
import prePostulacion from '~/pages/auth/prePostulacion';
import ingresoVendedor from '~/pages/auth/ingresoVendedor';

export default [
    {
        path: "/login",
        name: "login",
        component: Login,
        redirect: { name: "login.form.admin" },
        children: [
            {
                path: "admin",
                name: "login.form.admin",
                component: () =>
                    import("../../pages/auth/partials/form").then(
                        m => m.default || m
                    )
            },
            {
                path: "cliente",
                name: "login.form.cliente",
                component: () =>
                    import("../../pages/auth/partials/form").then(
                        m => m.default || m
                    )
            },
            {
                path: "form",
                name: "login.form",
                component: () =>
                    import("../../pages/auth/partials/form").then(
                        m => m.default || m
                    )
            },
            {
                path: "codigo",
                name: "login.codigo",
                component: () =>
                    import("../../pages/auth/partials/codigoTemporal").then(
                        m => m.default || m
                    )
            }
        ]
    },
    {
        path: "/login/recuperar-contrasena",
        name: "recuperar-contrasena",
        component: () =>
            import("../../pages/auth/recuperarContrasena").then(m => m.default || m)
    },
    {
        path: "/registrado",
        name: "registrado",
        component: () =>
            import("../../pages/auth/registrado").then(m => m.default || m)
    },
    {
        path: "/loginNuevo",
        name: "login.nuevo",
        component: loginNuevo
    },
    {
        path: "/cliente",
        name: "login.cliente",
        component: cliente
    },
    {
        path: "/login/vendedor",
        name: "login.form.leechero",
        component: prePostulacion
    },
    {
        path: "/crear-perfil",
        name: "crear.perfil.cliente",
        component: CrearPerfil
    },
    {
        path: "/login/stop",
        name: "login.stop",
        component: LoginStop
    },
    {
        path: "/ingreso/vendedor",
        name: "ingreso.vendedor",
        component: ingresoVendedor
    },
];
