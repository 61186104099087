import Vue from 'vue'
import Global from '~/mixins/general'
import Formateadores from '~/mixins/Formateadores'
import FlipBook from '~/mixins/FlipBook'
import lodash from 'lodash'
import './permisos'
import './vee-validate'
import './draggable'
import './google-maps'
import './lottie-animations';
import Vue2Filters from "vue2-filters";
import "./slim";
import  './vueperslides';
import './vue-inner-zoom';


import moment from 'moment'
moment.updateLocale('es', {
    week: { dow: 0 }
})

Vue.prototype._ = lodash
Vue.mixin(Global)
Vue.mixin(Formateadores)
Vue.mixin(FlipBook)
Vue.use(Vue2Filters)
