export default [
    {
        path: '/tendero-vip/creditos',
        name: 'tendero-vip.creditos',
        meta: { nombre: 'Creditos' },
        redirect: {name: 'tendero-vip.creditos.dashboard' },
        component: () => import('~/pages/creditos').then(m => m.default || m),
        children: [
            {
                path: 'dashboard',
                name: 'tendero-vip.creditos.dashboard',
                component: () => import('~/pages/creditos/dashboard').then(m => m.default || m),
            },
            {
                path: 'clientes',
                name: 'tendero-vip.creditos.clientes',
                component: () => import('~/pages/creditos/clientes').then(m => m.default || m),
            },
            {
                path: 'clientes/ver/:id_user',
                name: 'tendero-vip.creditos.cliente.ver',
                component: () => import('~/pages/creditos/clienteVer').then(m => m.default || m),
            },
            {
                path: 'cuotas',
                name: 'tendero-vip.creditos.cuotas',
                component: () => import('~/pages/creditos/cuotas').then(m => m.default || m),
            },
        ]

    }
]
