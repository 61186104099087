<template>
    <div class="row mx-0">
        <div class="col px-0">
            <div class="row mx-0 py-2 align-items-center bg-white br-t-12">
                <!-- Cabecera izquierda -->
                <slot name="cabecera-izquierda" />
                <!-- Barra de búsqueda -->
                <div v-if="mostrarBuscador" class="ml-auto col-auto d-middle">
                    <el-input
                    v-model="textoBusqueda"
                    placeholder="Buscar en el listado"
					:autocomplete="autocompleteOption"
                    size="small"
                    prefix-icon="icon-search"
                    class="cabecera-busqueda mx-1 br-20"
                    />
                </div>
                <!-- Cabecera derecha -->
                <slot name="cabecera-derecha" />
            </div>
            <el-table
            id="cont-scroll"
            ref="tablaGeneral"
            v-loading="cargando"
            :height="alto"
            :data="datosDinamicos"
            :class="claseSeleccionable"
            :header-row-class-name="classHeader"
            stripe
            class="w-100 tabla-element"
            @cell-click="eventoSeleccion"
            @selection-change="eventoSeleccionMultiple"
            @row-click="funcionFila"
            @sort-change="ordenarSegunCampo"
            >
                <!-- Columna índice -->
                <el-table-column v-if="mostrarIndice" type="index" min-width="30" align="center" />

                <!-- Columna de selección múltiple -->
                <el-table-column
                v-if="activarSeleccionMultiple"
                :class-name="`${checkDark ? 'check-dark' : 'check-red'}`"
                type="selection"
                min-width="80"
                />
                <!-- Slot para agregar columnas adicionales a la izquierda -->
                <slot name="adicionales-izquierda" />
                <div slot="empty" class="d-middle-center">
                    <div class="text-center" style="width:180px;">
                        <div class="bg-whitesmoke p-3 br-5">
                            <img height="70" src="/img/no-imagen/shop.svg" alt="" />
                        </div>
                        <p>{{ sinDatos }}</p>
                    </div>
                </div>
                <!-- Slot para agregar columnas en el template. Por defecto mostrará las que se le pasen en el array "Columnas" -->
                <slot>
                    <el-table-column v-for="col in columnasDibujables"
                                     :key="col.id"
                                     :prop="col.valor"
                                     :label="col.titulo"
                                     :sortable="col.ordenar || true"
                                     :min-width="col.ancho || 180"
                                     :align="col.alinear"
                                     :fixed="col.fijar || false"
                                     :class-name="col.class"
                                     :formatter="col.formato"
                    >
                        <template slot-scope="{row}">
                            <span v-if="col.modal" class="cr-pointer" @click="$emit('funcionEmit',row)">{{ row[col.valor] }}</span>
                            <span v-else>{{ row[col.valor] }}</span>
                        </template>
                    </el-table-column>
                </slot>

                <!-- Slot para agregar columnas adicionales a la derecha -->
                <slot name="adicionales-derecha" />
            </el-table>
            <!-- Paginación -->
            <div v-if="usarPaginacion" class="d-flex justify-content-center py-3 bg-white br-b-12">
                <el-pagination
                :current-page.sync="paginacion.paginaActual"
                :page-size.sync="configuracion.porPagina"
                :total="paginacion.total"
                class="paginar-general"
                :page-sizes="paginacion.selectorPaginado"
                :layout="paginacion.botones"
                background
                hide-on-single-page
                @current-change="validarPaginacion"
                @size-change="validarPaginacion"
                />
            </div>
        </div>
    </div>
</template>

<script>
var _ = require('lodash');
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        handleCursor:{
            type: Boolean,
            default: false
        },
        checkDark: {
            type:Boolean,
            default: false
        },
        alto: {
            type: String,
            default: 'calc(100vh - 225px)'
        },
        columnas: {
            type: Array,
            default: () => []
        },
        columnasFecha: {
            type: Object,
            default: () => ({})
        },
        classHeader: {
            type: String,
            default: () => ''
        },
        mostrarBuscador: {
            type: Boolean,
            default: true
        },
        mostrarIndice: {
            type: Boolean,
            default: false
        },
        activarSeleccion: {
            type: Boolean,
            default: false
        },
        activarSeleccionMultiple: {
            type: Boolean,
            default: false
        },
        botones: {
            type: Array,
            default: () => []
        },
        configuracionUsuario: {
            type: Object,
            default: () => ({})
        },
        usarPaginacion: {
            type: Boolean,
            default: true
        },
        usarServidor: {
            type: Boolean,
            default: false
        },
        servidorData: {
            type: [Array, Object],
            default: () => {
                return {
                    current_page: 1,
                    per_page: 1,
                    total: 1,
                    data: []
                }
            }
        },
        cargando: {
            type: Boolean,
            default: false
        },
        sinDatos: {
            type: String,
            default: 'No se han encontrado registros'
        }
    },
    data(){
        return {
            datosDinamicos: [],
			autocompleteOption: "off",
            datosFiltrados: [],
            textoBusqueda: '',
            order: null,
            prop: null,
            totalDatos: 0,
            configuracion: {
                paginaActual: 1,
                porPagina: 50,
                selectorPaginado: [5, 10, 25, 50, 100],
                celdasSeleccionables: [0, 1, 2],
                ...this.opciones
            }
        }
    },
    computed: {
        /*
            Array de datos el cual pasará por un proceso de "limpieza", quitando todas las keys innecesarias (watch)
            Este será siempre el array de datos base para ejecutar búsquedas en la tabla.
            Este array es inmutable, a menos que cambien los datos del componente padre.
        */
        datosEstaticos(){
            return this.usarServidor
                ? _.cloneDeep(this.servidorData.data)
                : _.cloneDeep(this.data)
        },
        paginacion(){
            return {
                paginaActual: this.usarServidor
                    ? this.servidorData.current_page
                    : this.configuracion.paginaActual,

                porPagina: this.usarServidor
                    ? this.servidorData.per_page
                    : this.configuracion.porPagina,

                total: this.usarServidor
                    ? this.servidorData.total
                    : this.totalDatos,

                botones: this.usarServidor
                    ? 'prev, pager, next, jumper'
                    : 'sizes, prev, pager, next, jumper',

                selectorPaginado: this.configuracion.selectorPaginado,
            }
        },
        claseSeleccionable(){
            return this.activarSeleccion ? 'cr-pointer' : ''
        },
        columnasDibujables(){
            return this.columnas.filter(item => item.valor)
        },
        columnasBusqueda(){
            return this.columnas.flatMap(item => item.valor ?? item)
        },
        criteriosBusqueda(){
            return this.textoBusqueda.trim().split(' ')
        },
        textoBusquedaSinEspacios(){
            return this.textoBusqueda.trim()
        },
        campoBusquedaEstaVacio(){
            return _.isEmpty(this.textoBusqueda.trim())
        }
    },
    watch: {
        /*
            Array copia de datosEstaticos el cual mostrará los datos filtrados.
            El array estará cambiando a medida que se apliquen filtros.
        */
        datosEstaticos: {
            immediate: true,
            handler(datos){
                if (this.usarServidor){
                    this.datosDinamicos = _.cloneDeep(datos)
                } else {
                    this.paginar(datos)
                }
            }
        },
        /*
            Se aplica un handler al v-model del campo de búsqueda, para así ejecutar a través del debouncer
        */
        textoBusqueda: {
            handler(){
                this.debouncer()
            },
            deep: true

        },
        data(){
            this.$nextTick(()=>{
                let cursor = document.querySelector('#cont-scroll');
                if(this.handleCursor){
                    cursor.classList.add('cursor-pointer');
                }
                let tabla = document.querySelector('#cont-scroll .el-table__body-wrapper');
                this.scrollInx(tabla)
            })
        }
    },
    methods: {
        /*
            Esta función vacía, recibirá el la función _.debounce del mounted, para invocarse cuando se necesite
        */
        debouncer: _.debounce(function(){
            this.validarBusqueda()
        }, 200),
        /*
            Ordena los campos de fecha, según el objeto enviado por props
        */
        ordenarSegunCampo({ prop, order }){
            console.log('prop, order', prop, order);
            this.order = order
            const columna = this.columnasFecha[prop]

            if (!columna) return
            this.prop = columna.valor
            console.log('this.columnasFecha', this.columnasFecha);

            const array = _.isEmpty(this.datosFiltrados) ? this.datosEstaticos : this.datosFiltrados
            // const ordenado = array
            //     .slice()
            //     .sort((a, b) => this.ordernarPorFecha(order, a[columna], b[columna])
            //     )
            const ordenado =
            columna.fecha
                ? _.orderBy(array,columna.valor,order == 'descending' ? ['desc']: ['asc'])
                : _.orderBy(array,columna.valor,order == 'descending' ? ['desc']: ['asc'])
            // console.log({ordenado});
            this.paginar(ordenado)
        },
        funcionFila(row){
            //console.log(row);
            if(!row) return
            this.$emit('filaFuncion', row)
        },
        ordernarPorFecha: (orden, valor1, valor2) => orden === 'descending'
            ? new Date(valor2) - new Date(valor1)
            : new Date(valor1) - new Date(valor2),
        /**
            Evento que se ejecuta si se presiona una fila. Debe estar activada la selección única
            @params = args [row, column, cell, event] (element table)
        */
        eventoSeleccion(...args){
            if (! this.configuracion.celdasSeleccionables
                .includes(args[2].cellIndex))
                return

            this.$emit('seleccion', args[0])
        },

        eventoSeleccionMultiple(seleccionados){
            this.$emit('seleccion', seleccionados)
        },
        /*
            Valida si el campo de búsqueda está vacío o tiene texto. Si está vacío, ejecuta el paginar.
            Si tiene texto, ejecuta la búsqueda.
        */
        validarBusqueda(){
            if (this.usarServidor){
                this.$emit('buscar', this.textoBusquedaSinEspacios)
            } else {
                this.validarPaginacion()
            }
        },
        /*
            Si es ServerSide, emitirá el evento paginar. Si no, invocará la función paginar
        */
        validarPaginacion(pagina){
            if (this.usarServidor)
                this.$emit('paginar', pagina)

            if (! this.usarServidor && ! this.campoBusquedaEstaVacio){
                this.ejecutarBusqueda()
                this.paginar(this.datosFiltrados)
            }

            if (! this.usarServidor && this.campoBusquedaEstaVacio)
                this.paginar(this.datosEstaticos)
        },
        /*
            El paginar tomará la página actual y comparará cuántos registros necesita por página.
            En base a ese cálculo, se toman los datos en el intérvalo necesario (página)
        */
        paginar(datos = []){
            datos = _.orderBy(datos,this.prop,this.order == 'descending' ? ['desc']: ['asc'])
            this.datosDinamicos = []
            this.datosDinamicos = this.usarPaginacion
                ? _.cloneDeep(datos.slice(
                    (this.paginacion.paginaActual - 1) * this.paginacion.porPagina,
                    this.paginacion.paginaActual * this.paginacion.porPagina
                ))
                : _.cloneDeep(datos)

            // console.log({datos, usarPaginacion:this.usarPaginacion,paginacion:this.paginacion , datosDinamicos:this.datosDinamicos});
            this.totalDatos = datos.length
        },
        /*
            Búsqueda en los registros a partir de los criterios de búsqueda dados en el campo buscar.
        */
        ejecutarBusqueda(){
            this.datosFiltrados = []

            this.datosEstaticos.forEach(registro => {
                let coincidencias = []

                // Se comienza a evaluar cada uno de los criterios en el texto de búsqueda
                this.criteriosBusqueda.forEach(criterio => {

                    // Cada item del objecto, se compara contra el criterio
                    Object.entries(registro).forEach(([key, item]) => {
                        if(!item)
                            return

                        if(!_.isEmpty(this.columnasBusqueda) && !this.columnasBusqueda.includes(key))
                            return

                        // Si es array se vuelve String
                        if(Array.isArray(item))
                            item = item.join(' ')

                        // Si es objecto, se vuelve String
                        if(typeof item === 'object' && item !== null)
                            item = JSON.stringify(item)

                        // Se aplica la búsqueda del criterio y si concuerda, se suma +1 al coincidencias de coincidencias
                        if (item.toString().toLowerCase().includes(criterio.toLowerCase()))
                            coincidencias.push(criterio)
                    })
                })

                // Se ordena el array de coincidencias y se toman los valores únicos (no repetidos)
                coincidencias = _.sortBy(_.uniq(coincidencias))

                // Las coincidencias siempre deben concordar con los criterios, entonces será agregada la fila al resultado final
                if (_.isEqual(coincidencias, _.sortBy(this.criteriosBusqueda)))
                    this.datosFiltrados.push(registro)
            })
        },
        scrollInx(element = null){
            if(!element) return
            const slider = element
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;

            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');

            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                //console.log(walk);
            });
        },
        toggleRowSelection(index){
            this.$refs.tablaGeneral.toggleRowSelection(this.datosDinamicos[index])
        },
        clearSelection(){
            this.$refs.tablaGeneral.clearSelection()
        }
    }
}
</script>

<style lang="scss" scoped>
    .tabla-element{
        font-size: 12px;
    }
    .cursor-pointer{
        cursor: grabbing !important; /* fallback if grab cursor is unsupported */
    }
</style>
