import notificaciones from '~/services/publicidad/notificaciones'
import general from '~/mixins/general'

const estadosHash = {
    1: { icon: 'icon-pause-circle-outline', color: 'text-blue', tooltip: 'Pausado' },
    2: { icon: 'icon-clock', color: 'text-general', tooltip: 'Programado' },
    3: { icon: 'icon-ok-circled-outline', color: 'text-green', tooltip: 'Enviado' },
    4: { icon: 'icon-cancel-outline', color: 'text-general-red', tooltip: 'Cancelado' },
}

const graficaModelInitialState = {
    tipo: 1,
    carpeta: '',
    campana: '',
}

export const state = {
    tipo: 1,
    carpeta: {},
    carpetas: [],
    campanas: [],
    estadosHash,
    graficaModel: graficaModelInitialState,
    lanzamientos: {
        creado: 0,
        por_lanzar: 0,
        lanzado: 0,
        cancelado: 0,
    },
    graficaEnvios: {
        semanas: [],
        apertura: [],
        mensaje: [],
        push: [],
    },
    graficaCampanas: {
        nombres: [],
        apertura: [],
        mensaje: [],
        push: [],
    },
    graficaCedis: {
        nombres: [],
        apertura: [],
        mensaje: [],
        push: [],
    },

}

export const getters = {
    tipo: ({ tipo })  => tipo,
    carpeta: ({ carpeta })  => carpeta,
    carpetas: ({ carpetas })  => carpetas,
    campanas: ({ campanas })  => campanas,
    estadosHash: ({ estadosHash })  => estadosHash,
    graficaModel: ({ graficaModel })  => graficaModel,
    graficaEnvios: ({ graficaEnvios })  => graficaEnvios,
    graficaCampanas: ({ graficaCampanas })  => graficaCampanas,
    graficaCedis: ({ graficaCedis })  => graficaCedis,
    lanzamientos: ({ lanzamientos })  => lanzamientos,
    
}

export const mutations = {
    setTipo: (state, payload) => state.tipo = payload,
    setCarpeta: (state, payload) => state.carpeta = payload,
    setCarpetas: (state, payload) => state.carpetas = payload,
    setCampanas: (state, payload) => state.campanas = payload,
    setGraficaModel: (state, payload = graficaModelInitialState) => state.graficaModel = payload,
    setLanzamientos: (state, payload) => state.lanzamientos = payload,
    setGraficaEnvios: (state, payload) => {
        let semanas = [], apertura = [], mensaje = [], push = []
        payload.forEach(el => {
            semanas.push(el.semana)
            apertura.push(el.apertura)
            mensaje.push(el.mensaje)
            push.push(el.push)
        })
        state.graficaEnvios = { semanas, apertura, mensaje, push }
    },
    setGraficaCampanas: (state, payload) => {
        let nombres = [], apertura = [], mensaje = [], push = []
        payload.forEach(el => {
            nombres.push(el.nombre)
            apertura.push(el.apertura ?? 0)
            mensaje.push(el.mensaje)
            push.push(el.push)
        })
        state.graficaCampanas = { nombres, apertura, mensaje, push }
    },
    setGraficaCedis: (state, payload) => {
        let nombres = [], apertura = [], mensaje = [], push = []
        payload.forEach(el => {
            nombres.push(el.nombre)
            apertura.push(el.apertura ?? 0)
            mensaje.push(el.mensaje)
            push.push(el.push)
        })
        state.graficaCedis = { nombres, apertura, mensaje, push }
    },
    limpiarState: (state) => {
        state.tipo = 1
        state.carpeta = {}
        state.carpetas = []
        state.campanas = []
    },
    limpiarGraficaModel: state => state.graficaModel = graficaModelInitialState,

}

export const actions = {
    async listarCarpetas({commit, state}){
        try {
            let params = {
                tipo: state.tipo
            }

            const { data } = await notificaciones.listarCarpetas(params);
            commit('setCarpetas', data.carpetas)
        } catch (error){
            general.methods.error_catch(error)
        }
    },
    async listarCampanas({commit, state}){
        try {
            const { data } = await notificaciones.listarCampana(state.carpeta.id);
            commit('setCampanas', data.campanas)
        } catch (error){
            general.methods.error_catch(error)
        }
    },
    
}
