import Produccion from "~/services/tiendas/produccion";

export const state = {
    parametros: {
        id: null,
        estado: null,
        nombre: null,
        logo: null,
        logo_mini: null,
        cliente_pedido_directo: null,
        cliente_entrega_directa: null,
        permiso_descuento: null,
        manejo_inventario: null,
        cambio_tipo_pedido: null,
        cambio_condiciones: null,
        ventas_solo_app: null,
        cliente_app: null,
        cliente_digitado: null,
        cliente_yo: null,
        descripcion: null,
        idm_ciudad: null,
        barrio: null,
        cobertura: null,
        direccion: null,
        direccion_anexo: null,
        longitud: null,
        latitud: null,
        propietario_nombre: null,
        propietario_genero: null,
        propietario_nacimiento: null,
        nit: null,
        nit_soporte: null,
        created_at: null,
        created_by: null,
        updated_at: null,
        idm_estado: null,
        idm_pais: null,
        ciudad: null,
        departamento: null,
        estrella: null,
        id_estrella: null,
        id_cedis: null,
        id_condicion: null,
        logo_firmado: null,
        logo_mini_firmado: null,
        nit_soporte_firmado: null,
        nombre_cedis: '',
        nombre_condicion: '',
        imagenes:[],
        descuento_lista: null,
        descuento_lista_clientes: null,
        id_categoria_lista: null,
        categoria_lista: null,
        categoria_lista_imagen: null,
    },
    tienda: {
        id: null,
        propietario_nombre: '',
        id_plan: null,
        paz_salvo: '',
        logo_mini: '',
        logo_mini_firmado: '',
    }
};

export const getters = {
    tienda: state => state.tienda,
    parametros: state => state.parametros,
};

export const mutations = {
    set_tienda: (state, payload) => {
        state.tienda = payload;
    },
    setParametros: (state, payload) => {
        state.parametros = payload;
    }
};

export const actions = {
    async action_info_cabecera({ commit }, payload){
        const { data } = await Produccion.info_cabecera(
            payload
        );
        commit("set_tienda", data.info);
    },
};
