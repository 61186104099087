<template>
    <section>
        <el-popover ref="popoverCardProducto" placement="bottom" trigger="hover" :disabled="!info.has_carrito && buscador" popper-class="br-8">
            <div v-if="info.has_carrito" class="row mx-0 align-items-center">
                <i class="icon-cart-outline f-17 text-general mr-2" />
                <span class="text-muted">Actualmente en un carrito</span>
            </div>
            <br v-if="info.has_carrito" />
            <div v-if="!buscador" class="row mx-0 align-items-center">
                <i class="icon-cash f-12 text-general mr-2" />
                <span class="text-muted pl-1">{{ info.cant_vendido }} veces comprado con esta promoción</span>
            </div>
            <!-- Contenido -->
            <div slot="reference" class="card-producto d-middle cr-pointer m-2 p-1" :class="info.borde === 1?border[info.color]:''" :style="ancho?'width: 100%;':'width: 400px;'" @click="detalleProducto">
                <div class="position-relative">
                    <i v-if="info.posicion==1" class="icon-bookmark f-20 position-absolute" :class="text[info.color]" style="top:-6px;right:4px;" />
                    <img v-if="info.foto !== null" class="br-10 obj-cover" height="90" width="90" :src="info.foto" alt="" />
                    <div v-else class="br-5 bg-whitesmoke d-middle-center" style="width:90px;height:90px;">
                        <img height="70" src="/img/no-imagen/mercado.svg" alt="" />
                    </div>
                </div>
                <div class="col px-2">
                    <p class="nombre lh-18 f-600 text-general">{{ info.nombre }}</p>
                    <p class="text-general2">{{ info.presentacion }}</p>
                    <div class="row mx-0 align-items-center pb-1">
                        <span class="f-600 text-general">{{ info.promo_valor=== null ? separadorNumero(info.valor, info.decimales): separadorNumero(info.promo_valor) }}</span>
                        <strike v-if="info.tipo === 2 ||info.tipo === 3 || info.tipo === 4" class="ml-2 f-12">
                            {{ separadorNumero(info.valor, info.decimales) }}
                        </strike>
                        <el-tooltip placement="bottom" content="Stock" effect="light">
                            <p class="col pl-1 text-right px-0 tres-puntos">
                                {{ agregarSeparadoresNumero(info.cant_stock,info.cantidad_tipo == 2 ? 2 : 0) }}
                            </p>
                        </el-tooltip>
                    </div>
                    <div class="row mx-0">
                        <div class="bg-general text-white br-12 px-2 f-12">
                            <span>{{ info.texto }}</span>
                        </div>
                    </div>
                </div>
                <template v-if="!tendero">
                    <div v-if="buscador" class="d-flex flex-column">
                        <i v-if="!info.agregado && $can('botones_cedis_promociones_gestionar')" class="icon-plus-squared f-20" :class="true?'text-gr-red':'text-gris'" @click.stop="agregarProducto(info)" />
                        <i v-if="info.agregado" class="icon-ok-circled f-20" :class="`text-gr-general`" />
                        <i v-if="info.otra_promo " class="icon-ok-circled f-20" :class="`text-red`" />
                    </div>
                    <div v-if="!buscador" class="d-flex flex-column">
                        <i v-show="(info.tipo===2 || info.tipo===4) && $can('botones_cedis_promociones_gestionar')" class="icon-pencil-outline f-22 my-auto cr-pointer text-gr-general" @click.stop="$emit('editar', info)" />
                        <i v-if="$can('botones_cedis_promociones_gestionar')" class="icon-trash-empty f-22 my-auto cr-pointer" :class="true?'text-gr-red':'text-gris'" @click.stop="$emit('eliminar', info.id)" />
                    </div>
                </template>
            </div>
        </el-popover>
        <!-- Partials -->
        <modalDetalleProd ref="modalDetalleProd" :info="info" />
    </section>
</template>

<script>
export default {
    components: {
        modalDetalleProd: () => import('~/pages/promociones/partials/modalDetalleProd'),

    },
    props: {
        info: {
            type: Object,
            default: () => {
                return {
                    nombre: 'Malteada de vainilla Francesa con palitos de chocolate',
                    foto: 'https://img.culturacolectiva.com/content_image/2020/1/21/1579624512515-receta-de-frappe-de-chocolate-con-vino-tinto-en-5-minutos.003.jpeg',
                    presentacion:  '600',
                    sigla: 'ml',
                    valor: 14000,
                    cant_vendido: 51,
                    id_promocion: null,
                    tipo: 1,
                    color: 0,
                    borde: true,
                    posicion: 0,
                    texto: 'MÁS BARATO',
                    descuento: 0,
                    promo_descuento: null,
                    promo_valor: null,
                    id_promo_tienda: null,
                    agregado: false,
                    otra_promo: false,
                    has_carrito: false,
                    descripcion: '',
                    cant_stock

                }
            }
        },
        buscador: {
            type: Boolean,
            default: false
        },
        ancho: {
            type: Boolean,
            default: false
        },
        tendero: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            // agregar: false,
            colores: ['', 'bg-gr-general', 'bg-gr-red', 'bg-gr-purple', 'bg-gr-pink' ],
            text: ['', 'text-gr-general', 'text-gr-red', 'text-gr-purple', 'text-gr-pink' ],
            border: ['', 'border-blue', 'border-red', 'border-purple', 'border-pink' ],
        }
    },
    methods: {
        agregarProducto(producto){
            this.$emit('agregar', producto)
        },
        detalleProducto(){
            this.$refs.popoverCardProducto.doToggle();
            this.$refs.modalDetalleProd.toggle()
        },
    }
}
</script>

<style lang="css" scoped>
.card-producto{
    border-radius: 16px;
}
.nombre{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
