import firebase from "~/library/firebase"

const captachaId = "sign-in-button"

const registroService = {
    async buildRecaptcha(){
        if(!window.recaptchaVerifier){
            window.recaptchaVerifier =  new firebase.auth.RecaptchaVerifier(captachaId, {
                'size': 'invisible',
                'callback': (response) => {
                    // captcha = response
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // onSignInSubmit();
                },
                'expired-callback': () => {
                    // Reset reCAPTCHA?
                }
            });
        } else {
            window.recaptchaVerifier.reset()
        }
    },

    async signInWithPhoneNumber(){
        return new Promise((resolve, reject) => {

        })
    },

    async registrar(phoneNumber){
        return new Promise((resolve, reject) => {

            this.buildRecaptcha()
            const appVerifier = window.recaptchaVerifier
    
            // const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
    
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    resolve(true)
    
                    
                    // ...
                }).catch((error) => {
                    console.log({error});
                    reject(error)
                    // Error; SMS not sent
                    // ...
                });
        })
    },

    async verificarCode(code = null){
        return new Promise((resolve, reject) => {
            if(!code) return reject(new Error('Código no colocado'))
            const confirmationResult = window.confirmationResult
            confirmationResult.confirm(code).then((result) => {
                const user = result.user;
                resolve(user)
            }).catch((error) => {
                reject(error)
            });
        })
    }
}

export default registroService