import axios from 'axios'

const API = 'admin-pedidos'

const Pedidos = {
    generar_pdf_ticket_admin_pedidos(payload){
        return axios(`${API}/${payload.id_pedido}/generar-ticket`,{
            responseType: 'blob',
        })
    },
    generar_pdf_ticket_pedidos_cliente(payload){
        return axios(`pedidos/${payload.id_pedido}/generar-ticket`,{
            responseType: 'blob',
        })
    },

}

export default Pedidos
