import axios from 'axios'

const API = 'promociones-admin/cedis'

const PROMOCIONES_CEDIS = {

    listarPromocionesDelCedis(payload = {}){
        return axios(`${API}/${payload.id_cedis}/lista-promociones`)
    },
    detallePromoDesdeCedis(payload){
        return axios.get(`${API}/${payload.id_promocion}/detalle-promocion-desde-cedis`)
    },
    listarProductosAgregados(payload = {}){
        return axios(`${API}/${payload.id_cedi}/${payload.id_promocion}/productos-agregados-promocion`,{ params: { caso: payload.caso } })
    },
    buscadorProductosPromo(payload = {}){
        return axios(`${API}/${payload.id_promocion}/buscador-productos-promo`,{params: payload})
    },
    agregarProductosAdmin(payload){
        return axios.put(`${API}/actualizar-producto`,payload)
    },
    quitarProducto(payload){
        return axios.put(`${API}/quitar-producto`,payload)
    },
    crearCedisPromociones(payload){
        return axios.post(`${API}/crear-cedi-promociones`,payload)
    },
    editarCedisPromociones(payload){
        return axios.put(`${API}/editar-cedi-promociones`,payload)
    },
    getCategorias(idCedis, idPromocion){
        return axios.get(`${API}/${idCedis}/listar-categorias?id_promocion=${idPromocion}`)
    },
    putDescuentoProductos(id,form){
        return axios.put(`${API}/${id}/actualizar-productos-masivo`,form)
    },
	actualizarPreciosDescuento(idCedis, idPromocion){
        return axios.put(`${API}/${idCedis}/promocion/${idPromocion}/actualizar/precio-productos`)
    },
}

export default PROMOCIONES_CEDIS
