import axios from 'axios'

const API = 'promociones-tendero'

const PROMOCIONES = {
    listarPromociones(payload = {}){
        return axios(`${API}/lista`, { params: payload} )
    },
    detallePromocion(payload = {}){
        return axios(`${API}/${payload.id_promocion}/detalle-promocion`)
    }
}

export default PROMOCIONES
