export const state = {
    cantidad: 0,
};

export const getters = {
    cantidad: state => state.cantidad,
};

export const mutations = {
    setCantidad: (state, payload) => {
        state.cantidad = payload;
    },
};

export const actions = {

};
