

export const state = {
    pedidosFechas: {
        pedidos: [],
        fechas: []
    }
}

export const getters = {
    pedidosFechas: ({ pedidosFechas }) => pedidosFechas,
}

export const mutations = {
    setPedidosFechas: ( state, payload ) => state.pedidosFechas = payload,
}
