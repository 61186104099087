import axios from "axios"

const API = "tienda/produccion";

const produccion = {
    get_datos(params){
        return axios(`${API}/get-datos`, { params })
    },
    getDatosMapa(params){
        return axios(`${API}/get-datos-mapa`, { params })
    },
    getDatosMapaLeechero(id){
        return axios(`${API}/${id}/get-datos-mapa-leechero`)
    },
    getTiendasNombre(params){
        return axios(`${API}/get-tiendas-nombre`, { params })
    },
    get_inactivos(params){
        return axios(`${API}/get-inactivos`, { params })
    },
    info_cabecera(id_tienda){
        return axios(`${API}/${id_tienda}/info-cabecera`)
    },
    historial_estados(id_tienda){
        return axios(`${API}/${id_tienda}/historial-estados`)
    },
    info_tienda(id_tienda){
        return axios(`${API}/${id_tienda}/info-tienda`)
    },
    cambiar_estrella(model){
        return axios.post(`${API}/cambiar-estrella`, model)
    },
    agregar_identificador(model){
        return axios.post(`${API}/agregar-identificador`,model)
    },
    consultarIdentificadores(id_tienda=null){
        return axios(`${API}/${id_tienda}/consultar-identificadores`)
    },
    editar_identificador(model){
        return axios.put(`${API}/editar-identificador`,model)
    },
    eliminar_identificador(id_identificador= null){
        return axios.delete(`${API}/${id_identificador}/eliminar-identificador`)
    },
    getConveniosTienda(idTienda){
        return axios.get(`${API}/${idTienda}/convenios-tienda`)
    },
    getHistorialCupo(idTienda){
        return axios.get(`${API}/${idTienda}/historial/cupo`)
    },
    putCupoLeechero(idTienda, form){
        return axios.put(`${API}/${idTienda}/editar/cupo`,form)
    },
    getClasificacionesTendero(idTienda){
        return axios.get(`${API}/${idTienda}/clasificaciones-tienda`)
    }
}

export default produccion
