export default [
    {
        path: '/admin/rutas',
        meta: { nombre: 'Rutas de Entrega' },
        name: 'admin.rutas',
        component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/index').then(m => m.default || m),
        redirect: {name: 'admin.rutas.turno'},
        children: [
            {
                path: 'turnos',
                meta: { nombre: 'Rutas de Entrega' },
                name: 'admin.rutas.turno',
                component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/turnoRutas').then(m => m.default || m),
            },
            {
                path: 'drivers',
                meta: { nombre: 'Rutas de Entrega' },
                name: 'admin.rutas.drivers',
                component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/drivers/index').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/admin/rutas/turno/:id_turno/ver',
        meta: { nombre: 'Rutas de Entrega' },
        name: 'admin.rutas.turno.ver',
        component: () => import('../../pages/rutas/turnos/verTurno').then(m => m.default || m),
        //redirect: {name: 'admin.rutas.ver.mapa'},
        // children: [
        //     {
        //         path: 'mapa',
        //         meta: { nombre: 'Rutas de Entrega' },
        //         name: 'admin.rutas.ver.mapa',
        //         component: () => import('../../pages/rutas/mapa').then(m => m.default || m),
        //     },
        //     {
        //         path: 'productos',
        //         meta: { nombre: 'Rutas de Entrega' },
        //         name: 'admin.rutas.ver.productos',
        //         component: () => import('../../pages/rutas/productos').then(m => m.default || m),
        //     }
        // ]
    },
    {
        path: '/admin/rutas/:id_ruta/detalle',
        meta: { nombre: 'Rutas de Entrega' },
        name: 'admin.rutas.detalle',
        component: () => import('../../pages/rutas/rutas/verRuta').then(m => m.default || m),
    },
    {
        path: '/admin/rutas/:id_ruta/ruta-entregas',
        meta: { nombre: 'Rutas de Entrega' },
        name: 'admin.rutas.rutas-entregas',
        component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/rutaEntrega/index').then(m => m.default || m),
        children: [
            {
                path: 'mapa',
                meta: { nombre: 'Rutas de Entrega' },
                name: 'admin.rutas.mapa',
                component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/rutaEntrega/mapa').then(m => m.default || m),
            },
            {
                path: 'productos',
                meta: { nombre: 'Rutas de Entrega' },
                name: 'admin.rutas.productos',
                component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/rutaEntrega/productos').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/admin/rutas/drivers/:id_driver/ver',
        meta: { nombre: 'Rutas de Entrega' },
        name: 'admin.rutas.ver-driver',
        component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/drivers/verDriver').then(m => m.default || m),
        children: [
            {
                path: 'mapa',
                meta: { nombre: 'Rutas de Entrega' },
                name: 'admin.rutas.ver-driver.mapa',
                component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/drivers/mapa').then(m => m.default || m),
            },
            {
                path: 'productos',
                meta: { nombre: 'Rutas de Entrega' },
                name: 'admin.rutas.ver-driver.productos',
                component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/drivers/productos').then(m => m.default || m),
            },
            {
                path: 'evidencias',
                meta: { nombre: 'Rutas de Entrega' },
                name: 'admin.rutas.ver-driver.evidencias',
                component: () => import(/* webpackChunkName: "gestion-administrador" */ '../../pages/rutas/drivers/evidencias').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/admin/rutas-entrega-2',
        meta: { nombre: 'Rutas de Entrega 2' },
        name: 'admin.rutas-entrega-2',
        component: () => import(/* webpackChunkName: "gestion-rutas-2" */ '../../pages/rutas2/index').then(m => m.default || m),
        children: [
            {
                path: 'pedidos-proceso',
                meta: { nombre: 'Rutas de Entrega 2' },
                name: 'admin.rutas-entrega-2.pedidos-proceso',
                component: () => import(/* webpackChunkName: "gestion-rutas-2" */ '../../pages/rutas2/pedidosProceso/index').then(m => m.default || m),
                children: [
                    {
                        path: 'asignados',
                        meta: { nombre: 'Rutas de Entrega 2' },
                        name: 'admin.rutas-entrega-2.pedidos-proceso.asignados',
                        component: () => import(/* webpackChunkName: "gestion-rutas-2" */ '../../pages/rutas2/pedidosProceso/asignados').then(m => m.default || m),
                    },
                    {
                        path: 'no-asignados',
                        meta: { nombre: 'Rutas de Entrega 2' },
                        name: 'admin.rutas-entrega-2.pedidos-proceso.no-asignados',
                        component: () => import(/* webpackChunkName: "gestion-rutas-2" */ '../../pages/rutas2/pedidosProceso/noAsignados').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'envios-fallidos',
                meta: { nombre: 'Rutas de Entrega 2' },
                name: 'admin.rutas-entrega-2.envios-fallidos',
                component: () => import(/* webpackChunkName: "gestion-rutas-2" */ '../../pages/rutas2/enviosFallidos').then(m => m.default || m),
            },
            {
                path: 'pendiente-cambio-estado',
                meta: { nombre: 'Rutas de Entrega 2' },
                name: 'admin.rutas-entrega-2.pendiente-cambio-estado',
                component: () => import(/* webpackChunkName: "gestion-rutas-2" */ '../../pages/rutas2/cambioEstado').then(m => m.default || m),
            },
            {
                path: 'historial-pedidos',
                meta: { nombre: 'Rutas de Entrega 2' },
                name: 'admin.rutas-entrega-2.historial-pedidos',
                component: () => import(/* webpackChunkName: "gestion-rutas-2" */ '../../pages/rutas2/historialPedidos').then(m => m.default || m),
            },
            {
                path: 'drivers',
                meta: { nombre: 'Rutas de Entrega 2 ' },
                name: 'admin.rutas-entrega-2.drivers',
                component: () => import(/* webpackChunkName: "gestion-rutas-2" */ '../../pages/rutas2/drivers').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/admin/rutas-entrega-2/driver/ver/:id_driver',
        meta: { nombre: 'Rutas de Entrega 2 ' },
        name: 'admin.rutas-entrega-2.drivers.ver',
        component: () => import(/* webpackChunkName: "gestion-rutas-2" */ '../../pages/rutas2/driverVer').then(m => m.default || m),
    }

]
