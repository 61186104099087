
import Reclamos from '~/services/reclamos/reclamos'

export const state = {
    pedidos: [],
    id_pedido: 0,
    detalle:{
        id: null,
        nombre: '',
        estado: null,
        imagen: null,
        imagen_firmada: null,
        id_estrella: null,
        estrella: null,
        fecha_ult_reclamo:null,
        hora_ult_chat_format: null,
        cant_msg:0,
        distancia: null,
        direccion_show: null,
        cantidad_pedidos: null,
        instrucciones: null,
        billete: null,
        fecha_finalizado_chat: null,
        fecha_finalizado_momento_3: null
    },
    historial:[],
    resumen:{

    },
    productos: [],

    loading_general: false,
    cant_reclamos: 0,
    cant_reclamos_fin:0



}

export const getters = {
    pedidos: state => state.pedidos,
    id_pedido: state => state.id_pedido,
    detalle: state => state.detalle,
    historial: state => state.historial,
    resumen: state => state.resumen,
    productos: state => state.productos,

    loading_general: state => state.loading_general,
    cant_reclamos: state => state.cant_reclamos,
    cant_reclamos_fin: state => state.cant_reclamos_fin,

}

export const mutations = {
    set_pedidos: (state,payload) => {
        state.pedidos = payload
    },
    set_id_pedido: (state,payload) => {
        state.id_pedido = payload
    },
    set_detalle: (state,payload) => {
        state.detalle = payload
    },
    set_historial: (state,payload) => {
        let mensajes = [
            'Un cliente ha solicitado un pedido',
            'Pedido Confirmado',
            'Pedido con cambios',
            'Pedido enviado',
            'Pedido entregado',
            'Reclamo',
        ]
        let iconos = [
            'icon-receipt',
            'icon-ok-circled-outline',
            'icon-account-edit',
            'icon-truck-outline',
            'icon-shopping',
            'icon-attention-outline',
        ]

        let history = []
        for(let idx in payload){
            if(idx == 2 || idx == 5){
                if(payload[idx] !== null){
                    history.push({content: mensajes[idx],hora: payload[idx],activo: payload[idx] !== null,icon: iconos[idx]})
                }
            } else {
                history.push({content: mensajes[idx],hora: payload[idx],activo: payload[idx] !== null,icon: iconos[idx]})
            }
        }
        state.historial = history
    },
    set_resumen: (state,payload) => {
        state.resumen = payload
    },
    set_productos: (state,payload) => {
        state.productos = payload
    },

    set_loading_general: (state,payload) => {
        state.loading_general = payload
    },
    set_cant_reclamos: (state,payload) => {
        state.cant_reclamos = payload
    },
    set_cant_reclamos_fin: (state,payload) => {
        state.cant_reclamos_fin = payload
    },


}

export const actions = {
    async action_listar_pedidos({commit},payload = null){
        const {data} = await Reclamos.listarPedidos(payload)
        commit('set_pedidos',data.pedidos)
        if(payload.vista == 1 || payload.vista == 4){
            commit('set_cant_reclamos',data.cant_msg_total)
        }else{
            commit('set_cant_reclamos_fin',data.cant_msg_total)
        }
    },
    async action_listar_detalle_pedido({commit},payload = null){
        const {data} = await Reclamos.detallePedido(payload)
        commit('set_detalle',data.pedido)
        commit('set_historial',data.historial)
        commit('set_resumen',data.resumen)
        commit('set_productos',data.productos)
    },


}
