import Tabs from "~/services/tiendas/cambio_estados";

export const state = {
    tabs: {
        producciones: 0,
        postulaciones: 0,
        aprobados: 0,
        cambio_datos: 0,
        denegados: 0,
        inactivos: 0,
    }
};

export const getters = {
    tabs: state => state.tabs
};

export const mutations = {
    set_tabs: (state, payload) => {
        state.tabs = payload;
    }
};

export const actions = {
    async action_tabs({ commit }, payload){
        const { data } = await Tabs.contadores_tabs({id_cedis:payload})
        commit("set_tabs", data.tabs);
    },
};
