<template>
    <div class="d-middle w-100 cr-pointer" @click="$emit('accion', {'id':producto.id,'kit':producto.kit})">
        <div :class="`mx-2 px-1 h-80 br-5 ${producto.id==activo?'bg-general':''}`" />
        <div class="card-promocion col p-1 mx-1 my-2 px-0 text-muted2" :class="setStyleBorde(producto.has_promocion,producto.borde,)">
            <div class="row mx-0">
                <div class="col-xl-auto col-lg-12 text-lg-center my-lg-2 px-0">
                    <div class="around-image">
                        <img v-if="producto.imagen" :src="producto.imagen" class="p-1 obj-cover" width="90" height="90" />
                        <div v-else class="br-5 bg-whitesmoke d-middle-center" style="width:90px;height:90px;">
                            <img height="70" src="/img/no-imagen/mercado.svg" alt="" />
                        </div>
                        <p v-show="tipo_impuesto === 2 && producto.porcentaje_impuesto" class="bg-general2 br-10 text-white f-10 text-center">IVA incluido</p>
                        <p v-show="tipo_impuesto === 1 && producto.porcentaje_impuesto" class="bg-general2 br-10 text-white f-10 text-center"> + IVA</p>
                    </div>
                </div>
                <div class="col-xl col-lg-12 pr-0 text-xl-left text-lg-center d-flex flex-column justify-content-around">
                    <div class="row mx-0">
                        <p class="f-17 f-500 col-12 px-0 text-general">
                            {{ producto.nombre }}
                        </p>
                        <p class="text-muted2 f-15 mt-2 mb-1 tres-puntos col-12 px-0">
                            {{ producto.presentacion }}
                        </p>
                        <p class="text-general col-lg-12 col-xl-6 tres-puntos px-0 f-14 f-500">
                            <span v-if="!producto.has_promocion && (caso!==2 && (id_cedis || $usuario.rol == 2 || $usuario.rol == 4)) ">{{ $usuario.rol == 1 || $usuario.rol == 3 ? convertMoney(funValor(producto.precio), producto.idm_moneda) : convertMoneyTendero(funValor(producto.precio), producto.idm_moneda) }}</span>
                            <span v-if="!producto.has_promocion && caso==2 && (id_cedis!==0 || $usuario.rol == 2 || $usuario.rol == 4) ">{{ $usuario.rol == 1 || $usuario.rol == 3 ? convertMoney(funValor(producto.precio), producto.idm_moneda) : convertMoneyTendero(funValor(producto.precio), producto.idm_moneda) }}</span>
                            <span v-if="(caso===2 && !id_cedis)">{{ producto.cant_cedis }} cedis</span>
                            <span v-if="producto.has_promocion" class="text-general f-500">
                                <span v-if="producto.promo_valor=== null">
                                    {{ $usuario.rol == 1 || $usuario.rol == 3 ? convertMoney(funValor(producto.precio), producto.idm_moneda) : convertMoneyTendero(funValor(producto.precio), producto.idm_moneda) }}
                                </span>
                                <span v-else>
                                    {{ $usuario.rol == 1 || $usuario.rol == 3 ? convertMoney(funValor(producto.promo_valor), producto.idm_moneda) : convertMoneyTendero(funValor(producto.promo_valor), producto.idm_moneda) }}
                                </span>
                            </span>
                            <strike v-if="producto.tipo ===2 ||producto.tipo ===3 || producto.tipo ===4 || producto.tipo ===6" class="ml-2 f-12 text-general2">
                                {{ $usuario.rol == 1 || $usuario.rol == 3 ? convertMoney(funValor(producto.precio), producto.idm_moneda) : convertMoneyTendero(funValor(producto.precio), producto.idm_moneda) }}
                            </strike>
                        </p>
                        <el-tooltip placement="bottom" :content="!producto.vencimiento? 'Stock': 'Productos próximos a vencer'" effect="light">
                            <p
                            v-if="producto.stock_actual != null && !producto.vencimiento"
                            class="col-lg-12 col-xl-6 tres-puntos px-0 text-xl-center text-xl-left"
                            :class="producto.stock_bajo ? 'text-general-red' : 'text-general'"
                            >
                                <span class="f-600"> {{ agregarSeparadoresNumero(producto.stock_actual) }} </span> {{ producto.unidad }}
                            </p>
                            <p
                            v-if="producto.vencimiento == true"
                            class="col-lg-13 col-xl-3 tres-puntos px-0 text-xl-center text-xl-left br-10 bg-general text-white"
                            >
                                <span class="f-600"> {{ agregarSeparadoresNumero(producto.stock_actual) }} </span> {{ producto.unidad }}
                            </p>
                        </el-tooltip>
                    </div>
                    <div class="row mx-0">
                        <div v-if="producto.has_promocion" class="bg-general br-12 px-2 tres-puntos text-white f-14">
                            <span>{{ producto.texto }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-auto px-0 ml-auto adicionales text-lg-center px-1 d-xl-flex d-lg-flex justify-content-lg-center flex-xl-column justify-content-xl-around">
                    <div class="row mx-0 justify-center">
                        <i v-show="parseFloat(producto.cant_carrito) > 0" class="icon-cart-outline f-14 text-general-red" />
                    </div>
                    <div class="row mx-0 justify-center">
                        <i
                        v-if="(caso===2 || caso===6) && $can('boton_productos_activar_productos')"
                        class="f-14 cr-pointer" :class="producto.estado ? 'text-general icon-eye' : 'text-muted2 icon-eye-off'"
                        @click.stop="$emit('visible',producto.id)"
                        />
                    </div>
                    <div class="row mx-0 justify-center">
                        <el-tooltip placement="bottom" content="CEDIS que tienen este producto en promoción" effect="light">
                            <div v-if="(caso===2 && !id_cedis &&producto.cant_cedis_productos_activos)">
                                {{ producto.cant_cedis_productos_activos }}
                                <i class="icon-sale f-22 my-auto text-danger" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
/**Casos para el botón cambiar estado
 * 1=  logueado Admin  Menu Cedis->productos ✔
 * 2=  logueado Admin Menu productos ✔
 * 5=  logueado Tendero Menu productos ó  logueado Admin Menu tiendas->productos✔
 * 6=  logueado Admin Menu tiendas->productos Tab stock minimo✔
 * 7=  logueado Tendero Menu productos Tab stock minimo✔
 */
export default {
    props: {
        producto: {
            type: Object,
            default: () => {
                return {}
            }
        },
        activo: {
            type: Number,
            default: 0
        },
        caso: {
            type: Number,
            default: 1
        },
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            agregar: false,
            border: ['', 'border-general' ],
            colores: ['', 'bg-gr-general'],
            text: ['', 'text-gr-general'],
            visible_product: false
        }
    },
    computed:{
        // ...mapGetters({
        //     // id_moneda:'cedis/id_moneda',
        //     id_cedis:'cedis/id_cedis',

        // }),
        id_cedis(){
            if(this.$route.name == 'admin.cedis.productos'){
                return Number(this.$route.params.id_cedis)
            }
            return this.$store.getters['cedis/id_cedis_navbar']
        },
        tipo_impuesto(){
            let rol = this.$usuario.rol
            if(rol === 1 || rol === 3){
                let cedi = this.cedis_calculo.find(o=>o.id === this.id_cedis)

                return cedi.impuestos
            }
            return this.$tienda.tipo_impuesto
        },
        impuesto(){
            const i = this.producto.porcentaje_impuesto
            return i ? i : 0
        },
    },
    methods: {
        funValor(value){
            if(this.tipo_impuesto === 0){
                return value
            }
            if(this.tipo_impuesto === 1){
                return value / (1 + this.impuesto / 100)
            }
            if(this.tipo_impuesto === 2 || this.tipo_impuesto === 3){
                return value
            }
            return 0
        },
        setStyleBorde(promocion, posicion){
            if(promocion == true && posicion === 1 ){
                return this.border[posicion];
            }else{
                return ''
            }
        },
        setMarkColor(promocion, posicion){
            if(promocion == true && posicion === 1 ){
                return this.border[posicion];
            }else{
                return ''
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
.text-red{color:#FF3737;}
.card-promocion{
    min-height: 103px;
    border-radius: 16px !important;
    .around-image{
        text-align: center;
        position: relative;
        i{ position: absolute; right: 0px;top: -0px; }
        img{
            border-radius: 12px 12px 0px 0px;
        }
        .info-promocion-top{
            border-radius: 12px 12px 0px 0px;
        }
        .info-promocion-bottom{
            border-radius: 0px 0px 12px 12px;
        }
    }
}
.adicionales{
    width: 28px;
}
@media (max-width:1327px) {
    .adicionales{
        width: 20%;
    }
}
@media (max-width: 1220px) {
    .adicionales{
        border-left: 0px !important;
        margin-top: 0.5rem;
        width: 100% !important;
    }
}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}

</style>
