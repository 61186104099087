import Cedis from "~/services/cedis";
import CedisCrud from "~/services/cedis/index";
import store from '~/store';

export const state = {
    cedis: [],
    info_cedis: {
        id: null,
        estado: null,
        nombre: null,
        descripcion: null,
        foto: null,
        direccion: null,
        direccion_completa: null,
        longitud: null,
        latitud: null,
        moneda: {
            id: null,
            nombre: null,
            sigla: null,
        },
        responsable: {
            id: null,
            nombre: null,
            imagen: null
        },
        valores: {
            productos: null,
            unidades: null,
            valores: null
        }
    },
    id_cedis: null,
    id_cedis_navbar: 0,
    cedisNavbar: [],
    cargandoTerminado: true,
    monedas: [],
    id_moneda: null,
    moneda:{}
};

export const getters = {
    cedis: state => state.cedis,
    info_cedis: state => state.info_cedis,
    id_cedis: state => state.id_cedis,
    id_cedis_navbar: state => state.id_cedis_navbar,
    monedas: state => state.monedas,
    cedisNavbar: state => state.cedisNavbar,
    id_moneda: state => state.id_moneda,
    cargandoTerminado: state => state.cargandoTerminado,
    moneda: state => state.moneda
};

export const mutations = {
    set_cedis: (state, payload) => {
        state.cedis = payload;
    },
    set_info_cedis: (state, payload) => {
        if(payload === null){
            state.info_cedis = {
                id: 0,
                estado: null,
                nombre: null,
                descripcion: null,
                foto: null,
                direccion: null,
                direccion_completa: null,
                longitud: null,
                latitud: null,
                id_cedis_catalogo:null,
                moneda: {
                    id: null,
                    nombre: null,
                    sigla: null,
                },
                responsable: {
                    id: null,
                    nombre: null,
                    imagen: null
                },
                valores: {
                    productos: null,
                    unidades: null,
                    valores: null
                }
            }
            return
        }
        state.info_cedis = payload;
    },
    set_id_cedis: (state) => {
        state.id_cedis = state.id_cedis == 0 ? 1 : 0;
    },
    set_id_cedis_navbar: (state) => {
        state.id_cedis_navbar = state.cedisNavbar.length != 1 ? 0 : state.cedisNavbar[0].id;
        if(state.id_cedis_navbar != 0){
            const payload = {
                id_cedis: state.id_cedis_navbar
            }
            store.dispatch('cedis/action_info_cedis', payload)
        }else{

            store.commit('cedis/set_info_cedis',null)
        }
    },
    set_monedas: (state, payload) => {
        state.monedas = payload;
    },
    set_cedis_nabvar: (state, payload) => {
        state.cedisNavbar = payload;
    },
    setCargandoTerminado: (state, payload) => {
        state.cargandoTerminado = payload;
    },
    set_id_moneda: (state, payload) => {
        state.id_moneda = payload;
        // commit("set_moneda",payload)
    },
    set_moneda: (state, payload) => {
        const moneda = state.monedas.find(o=>o.id == payload)
        state.moneda = moneda;
    },
};

export const actions = {
    async action_get_cedis({ commit, rootGetters }){
        const { data } = await Cedis.get_cedis();
        if (data.cedis.length != 1){
            data.cedis.unshift({id:0,nombre:'Todos', id_moneda: 0, impuestos:0})
        }
        commit("set_cedis", data.cedis);
        commit("set_cedis_nabvar", data.cedisNavbar);
        commit("set_id_cedis",rootGetters["auth/obtenerUsuario"].id_cedis ?? 0 );
        commit("set_id_cedis_navbar");

    },
    async action_info_cedis({ commit }, payload){
        const { data } = await CedisCrud.info_cedis(payload);
        commit("set_info_cedis", data);
    },
    async action_get_monedas({ commit, rootGetters }){
        const { data } = await Cedis.get_monedas();
        commit("set_monedas", data);
        if (data.length){
            commit("set_id_moneda",rootGetters["auth/obtenerUsuario"].idm_moneda ?? data[0].id);
            commit("set_moneda", rootGetters["auth/obtenerUsuario"].idm_moneda ?? data[0].id);
        }
    },
};
