export default [
    {
        path: '/tendero/almacen',
        name: 'tendero.almacen',
        meta: { nombre: 'Abastecimiento' },
        redirect: { name: 'tendero.almacen.historial-pedidos' },
        component: () => import('../../pages/almacen').then(m => m.default || m),
        children: [
            /* {
                path: 'carrito',
                name: 'tendero.almacen.carrito',
                meta: { nombre: 'Abastecimiento' },
                component: () => import('../../pages/almacen/tendero/carrito').then(m => m.default || m),
            }, */
            {
                path: 'historial-pedidos',
                name: 'tendero.almacen.historial-pedidos',
                meta: { nombre: 'Abastecimiento' },
                component: () => import('../../pages/almacen/tendero/historial/index').then(m => m.default || m),
            },
            {
                path: 'Dashboard',
                name: 'tendero.almacen.dashboard',
                component: () => import('../../pages/almacen/tendero/dashboard/index').then(m => m.default || m),
            },
            {
                path: 'creditos',
                name: 'tendero.almacen.creditos',
                redirect: { name: 'tendero.almacen.creditos.dashboard' },
                component: () => import('../../pages/almacen/tendero/creditos/index').then(m => m.default || m),
                children: [
                    {
                        path: 'Dashboard',
                        name: 'tendero.almacen.creditos.dashboard',
                        component: () => import('../../pages/almacen/tendero/creditos/dashboard').then(m => m.default || m),
                    },
                    {
                        path: 'deuda',
                        name: 'tendero.almacen.creditos.cuotas',
                        component: () => import('../../pages/almacen/tendero/creditos/cuotasPorPagar').then(m => m.default || m),
                    },
                    
                    {
                        path: 'entregas',
                        name: 'tendero.almacen.creditos.entregas',
                        component: () => import('../../pages/almacen/tendero/creditos/entregas').then(m => m.default || m),
                    },
                    {
                        path: 'creditos',
                        name: 'tendero.almacen.creditos.creditos',
                        component: () => import('../../pages/almacen/tendero/creditos/creditos').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'creditos/deuda/ver/:id_pedido',
                name: 'tendero.almacen.creditos.cuotas.ver',
                component: () => import('../../pages/almacen/tendero/creditos/deudaVer').then(m => m.default || m),
            },
            {
                path: 'pedidos-recurrentes',
                name: 'tendero.almacen.pedidos-recurrentes',
                component: () => import('../../pages/almacen/tendero/pedidosRecurrentes/index') .then(m => m.default || m)
            },
            {
                path: 'pedido/:id_pedido_admin',
                name: 'tendero.almacen.creditos.detalle.pedido',
                component: () => import('~/pages/almacen/tendero/creditos/detallePedidoAdmin').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/tendero/carrito',
        name: 'tendero.carrito',
        meta: { nombre: 'Carrito de Compras' },
        component: () => import('../../pages/almacen/tendero/carrito').then(m => m.default || m),
    },
    {
        path: '/tendero/carrito/checkout',
        name: 'tendero.carrito.checkout',
        meta: { nombre: 'Carrito de Compras' },
        component: () => import('../../pages/almacen/tendero/carrito/checkoutCarrito').then(m => m.default || m),
    },
    {
        path: '/tendero/carrito/pedido-creado',
        name: 'tendero.carrito.pedido-creado',
        meta: { nombre: 'Carrito de Compras' },
        component: () => import('../../pages/almacen/tendero/carrito/resumenPedido').then(m => m.default || m),
    }
]
