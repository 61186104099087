export default [
    {
        path: '/tendero/ayuda',
        meta: { nombre: 'información para los usuario' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/ayuda').then(m => m.default || m),
        children: [
            {
                path: 'consultas-frecuentes',
                name: 'tendero.ayuda.frecuentes',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/ayuda/preguntas-frecuentes').then(m => m.default || m),
            },
            {
                path: 'terminos-condiciones',
                name: 'tendero.ayuda.terminos-condiciones',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/ayuda/terminos-condiciones').then(m => m.default || m),
            },
            {
                path: 'contactar',
                name: 'tendero.ayuda.contactar',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/ayuda/contactar').then(m => m.default || m),
            },
            {
                path: 'politica-privacidad',
                name: 'tendero.ayuda.politica-privacidad',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/ayuda/politica-privacidad').then(m => m.default || m),
            }
        ]
    }
]
