
import moment from 'moment';
import vueGlobalMixin from '~/mixins/general'
import geolocalizacion from "~/services/tiendas/geolocalizacion";

export const state = {
    geoTienda: '',
    fecha: '',
    horas: '',
    leecheros: [],
    puntos: [],
}

export const getters = {
    fecha: ({fecha}) => fecha,
    horas: ({horas}) => horas,
    leecheros: ({leecheros}) => leecheros,
    geoTienda: ({geoTienda}) => geoTienda,
    puntos: ({puntos}) => puntos,
}

export const mutations = {
    setFecha: (state, payload) => state.fecha = payload,
    setHoras: (state, payload) => state.horas = payload,
    setLeecheros: (state, payload) => state.leecheros = payload,
    setGeoTienda: (state, payload) => state.geoTienda = payload,
    setPuntos: (state, payload) => state.puntos = payload,
    limpiar: (state) => {
        state.geoTienda = ''
        state.fecha = ''
        state.horas = ''
        state.leecheros = []
        state.puntos = []
    }
}

export const actions = {
    async fetchLeecheros({ commit }){
        try {
            const { data } = await geolocalizacion.leecheros()
            commit('setLeecheros', data.leecheros)
        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
        }
    },
    async generar({ commit, state }){
        try {
            if (
                !state.geoTienda ||
                !state.fecha ||
                !state.horas 
            ){
                return vueGlobalMixin.methods.notificacion('', 'Debe seleccionar todos los filtros', 'error')
            }

            let params = {
                leechero: state.geoTienda,
                fecha: state.fecha,
                horas: state.horas.toString()
            }
            const { data } = await geolocalizacion.puntos(params)
            let puntos = data.puntos.map(e => ({
                lat: +(e.latitud),
                lng: +(e.longitud),
                icon: '/img/icons/leechero_ubicacion.svg',
                info: contentString(e)
            }))
            commit('setPuntos', puntos)
        } catch (error){
            vueGlobalMixin.methods.error_catch(error)
        }
    }
}


const contentString = (el) => {
    let fecha = moment(el.created_at).format('hh:mm a')
    return `
        <div class="row mx-0 my-2">
            <span class="f-14">${fecha}</span>
        </div>
    `;        
}