import axios from "axios"

const API = "tienda/cambio-estados";

const cambioEstados = {
    get_motivos(tipo){
        return axios(`${API}/${tipo}/get-motivos`)
    },
    cambiar_estado(params){
        return axios.post(`${API}/cambiar-estado`, params)
    },
    contadores_tabs(params){
        return axios(`${API}/contadores-tab`, { params });
    },
    leecheroClientes: id => axios.get(`${API}/leechero-clientes/${id}`)
}

export default cambioEstados
