import axios from 'axios'

const direcciones = {
    listar: idUser => axios.get(`home/${idUser}/direcciones`),
    crear: data => axios.post(`home/usuario/direccion`, data),
    actualizar: data => axios.put(`home/usuario/direccion`, data),
    eliminar: idDireccion => axios.delete(`home/direccion/${idDireccion}/eliminar`),
    direccionPrincipal: (idDireccion) => axios.put(`tiendas/${idDireccion}/direccion-principal`),
}

export default direcciones