
import Soporte from '~/services/soporte/tiendas'

export const state = {
    id_user_cliente: 0,
    detalle:{
        id: null,
        id_user: null,
        nombre: null,
        logo_mini_firmado: null,
        estrella: null,
        id_estrella: null,
        chat_admin: null,
    }
}

export const getters = {
    id_user_cliente: state => state.id_user_cliente,
    detalle: state => state.detalle,
}

export const mutations = {
    set_id_user_cliente: (state,payload) => {
        state.id_user_cliente = payload
    },
    set_detalle: (state,payload) => {
        state.detalle = payload
    },

}

export const actions = {

}
