<template>
    <section>
        <el-tooltip class="item" effect="light" content="Chat de soporte" placement="bottom">
            <div class="btn-action border d-middle-center cr-pointer text-general" @click="verChat">
                <i class="icon-message-text-outline f-14" />
            </div>
        </el-tooltip>
        <modalChat ref="modalChatIcono" />
    </section>
</template>
<script>
import {mapGetters} from 'vuex'
import Chat from '~/services/chat/chat'

export default {
    props:{
        idTienda: {
            type: Number,
            default: 0
        }
    },

    data(){
        return{
            cant_msg: 0
        }
    },
    computed:{
        ...mapGetters({
        }),

    },
    mounted(){
        this.cantidadMensajesSinLeer()

    },
    methods: {
        verChat(){
            this.$refs.modalChatIcono.toggle(this.idTienda, 3) // 3 = tipo tienda
        },
        async cantidadMensajesSinLeer(){
            try {
                const params = {
                    id : this.idTienda,
                    tipo: 3// 3= tipo tienda
                }
                const {data} = await Chat.saberCantidadMensajesSinLeer(params)
                this.cant_msg = data.cant_msg
            } catch (e){
                this.error_catch(e)
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
</style>
