import axios from 'axios';

const API_PREFIX = '/auth';

const ENDPOINTS = {
    iniciarSesion: payload => axios.post(`${API_PREFIX}/login`, payload),

    // iniciarSesion(payload){
    //     let instance = axios.create()
    //     let dominio = window.location.origin
    //     instance.defaults.baseURL = `fdfhkjdsfhkjdshfkjhdsjkf/api`
    //     instance.post(`${API_PREFIX}/login`, payload)
    //
    // },

    iniciarSesionSocial: payload => axios.post(`${API_PREFIX}/login-social`, payload),
    iniciarSesionFirebase: payload => axios.post(`${API_PREFIX}/login-firebase-web`, payload),
    cerrarSesion: payload => axios.post(`${API_PREFIX}/logout`, payload),
    obtenerUsuario: () => axios(`${API_PREFIX}/usuario`),
    obtenerRoles: () => axios(`${API_PREFIX}/roles`),
    generarCodigoRecuperacion: (payload) => axios.post(`${API_PREFIX}/codigo-recuperacion`, payload),
    validarCodigoRecuperacion: (payload) => axios.post(`${API_PREFIX}/codigo-validacion`, payload),
    restablecerContrasena: (payload) => axios.post(`${API_PREFIX}/restablecer-contrasena`, payload),
    generarCodigoCliente: (payload) => axios.post(`${API_PREFIX}/generar-codigo-cliente`, payload),
    generarCodigoTienda: (payload) => axios.post(`${API_PREFIX}/generar-codigo-temporal-tienda`, payload),
    generarCodigoTemporalLogin: (payload) => axios.post(`${API_PREFIX}/generar-codigo-temporal-login`, payload),
    checkCodigoTemporal: (payload) => axios.post(`${API_PREFIX}/check-codigo-temporal`, payload),
    checkCodigoTemporalLogin: (payload) => axios.post(`${API_PREFIX}/check-codigo-temporal-login`, payload),
    reenviarCodigoTemporalRegistro: (payload) => axios.post(`${API_PREFIX}/reenviar-codigo-temporal-registro`, payload),
    reenviarCodigoTemporalLogin: (payload) => axios.post(`${API_PREFIX}/reenviar-codigo-temporal-login`, payload),
    completeRegister: (payload) => axios.post(`${API_PREFIX}/terminar-registro`, payload),
    registerWithGoogle: (payload) => axios.post(`${API_PREFIX}/registrar-google`, payload),
    registerWithFacebook: (payload) => axios.post(`${API_PREFIX}/registrar-facebook`, payload),
    noExistsUser: (payload) => axios.post(`tiendas/no-existe-usuario`, payload),
    registrarFirebase: (payload) => axios.post(`${API_PREFIX}/registrar-firebase-web`, payload),

};

export default ENDPOINTS;
