export const state = {
    geocercas: [],
    geocercasGrupo: [],
}

export const getters = {
    geocercas: ({geocercas}) => geocercas,
    geocercasGrupo: ({geocercasGrupo}) => geocercasGrupo,
}

export const mutations = {
    setGeocercas: (state,payload) => {
        state.geocercas = payload
    },
    addGeocerca: (state,payload) => {
        state.geocercas.push(payload)
    },
    removeGeocerca: (state,idGeocerca) => {
        state.geocercas = state.geocercas.filter(g => g.id != idGeocerca)
    },
    setGeocercasGrupo: (state,payload) => {
        state.geocercasGrupo = payload
    },
}
