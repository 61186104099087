
import ReclamosLeecheros from '~/services/reclamos/reclamosLeecheros'

export const state = {
    pedidos: [],
    id_pedido: 0,
    loading_general: false,
    cant_reclamos: 0,
    cant_reclamos_fin:0
}

export const getters = {
    pedidos: state => state.pedidos,
    id_pedido: state => state.id_pedido,
    loading_general: state => state.loading_general,
    cant_reclamos: state => state.cant_reclamos,
    cant_reclamos_fin: state => state.cant_reclamos_fin,
}

export const mutations = {
    set_pedidos: (state,payload) => {
        state.pedidos = payload
    },
    set_id_pedido: (state,payload) => {
        state.id_pedido = payload
    },
    set_loading_general: (state,payload) => {
        state.loading_general = payload
    },
    set_cant_reclamos: (state,payload) => {
        state.cant_reclamos = payload
    },
    set_cant_reclamos_fin: (state,payload) => {
        state.cant_reclamos_fin = payload
    },
}

export const actions = {
    async action_listar_pedidos({commit},payload = null){
        const {data} = await ReclamosLeecheros.listarPedidos(payload)
        commit('set_pedidos',data.pedidos)
        if(payload.vista == 1 || payload.vista == 4){
            commit('set_cant_reclamos',data.cant_msg_total)
        }else{
            commit('set_cant_reclamos_fin',data.cant_msg_total)
        }
    },
}
