<template>
    <div class="position-fixed text-white shadow pt-1 tooltip-container" :class="tooltip ? 'active' : ''" style="right:18px;z-index:9999" @mouseleave="activeClass" @mouseenter="activeClass">
        <p class="text-unfolded text-white text-center text-center f-14">
            <i class="icon-metricas text-white f-18" />
            Graficar por:
        </p>
        <p class="text-folded mt-2 text-center">
            <i class="icon-metricas text-white f-18" />
            <br />
            Entregas
        </p>
        <div class="justify-center align-items-center mx-1 mt-1 switch-butttons">
            <div class="left f-14 text-center w-50 p-1 br-tl-8 cr-pointer" :class="value == 1 ? 'active-switch' : 'switch'" @click="$emit('input', 1)">
                Entregas
            </div>
            <div class="right f-14 text-center w-50 p-1 br-tr-8 cr-pointer" :class="value == 2 ? 'active-switch' : 'switch'" @click="$emit('input', 2)">
                Creación
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TimerComponent',
    props: {
        value : {
            type: [Number, String],
            default: 1
        }
    },
    data(){
        return {
            tooltip: false
        }
    },
    mounted(){
        console.log('se monto el timer component');
    },
    methods: {
        activeClass(){
            this.tooltip = !this.tooltip
        },
    }
}
</script>

<style lang="scss" scoped>
.active-switch{
    background-color: #ffffff;
    color: var(--text-general);
}
.switch{
    background-color: var(--text-general);
    color:  #ffffff;
}
.br-tl-12{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.br-tr-12{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.br-12{
    border-radius: 12px;
}
.tooltip-container{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: var(--text-general);
    width: 76px !important;
    height: 75px;
    transition: all .2s ease-in;

    .text-unfolded{
        display: none;
    }
    .text-folded{
        display: block;
        opacity: 1;
        transition: opacity .2s ease-in-out;
    }
    .switch-butttons{
        border-radius: 8px;
        border: 1px solid #ffffff;
        display: none;
        
        opacity: 1;

        .left{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        .right{
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
    &:hover{
        .switch-butttons{
            animation-name: buttons;
            animation-duration: 2s;
        }
    }
    &.active{
        width: 180px !important;
        .text-unfolded{
            display: block;
        }
        .text-folded{
            display: none;
            opacity: 0;
        }
        .switch-butttons{
            display: flex;
        }

        
    }
}

@keyframes buttons{
    0%{
        display: none;  
        opacity: 0;
    }
    100%{
        display: flex;
        opacity: 1;
    }
}
</style>
