import Almacen from '~/services/almacen/almacen'

export const state = {
    productos: [],
    query:'',
    productos_agregados:[],
    resumen:{},
    formaPago:{},
    parametros: {},
}

export const getters = {
    productos_agregados: state => state.productos_agregados,
    resumen: state => state.resumen,
    productos: state => state.productos,
    query: state => state.query,
    formaPago: state => state.formaPago,
    parametros: state => state.parametros,

}

export const mutations = {
    set_productos_agregados: (state,payload) => {
        state.productos_agregados = payload
    },
    set_resumen: (state,payload) => {
        state.resumen = payload
    },
    set_query: (state,payload) => {
        state.query = payload;
    },
    set_productos: (state,payload) => {
        state.productos.push(...payload);
    },
    vaciar_productos: (state,payload) => {

        state.productos = [];
    },
    alter_producto(state,payload){

        let index = state.productos.findIndex(p=>p.id === payload.id_producto);

        state.productos[index].en_pedido = payload.en_pedido
        state.productos[index].total_final = payload.total_final
        state.productos[index].total_teorico = payload.total_teorico
        state.productos[index].cantidad = payload.cantidad
    },
    alter_lista_productos(state,payload){

        let index = state.productos.findIndex(p=>p.id === payload.id);

        for (let [key,value] of Object.entries(payload)){
            state.productos[index][key] = value
        }

    },

    alter_agregados(state,payload){

        let index = state.productos_agregados.findIndex(p=>p.id === payload.id);

        if(index === -1){
            state.productos_agregados.unshift(payload)
            return
        }

        for (let [key,value] of Object.entries(payload)){
            // console.log(index,key,value);
            state.productos_agregados[index][key] = value
        }
    },
    remover_producto: (state,payload) => {
        let idx = state.productos_agregados.findIndex(p=>p.id_producto === payload)
        state.productos_agregados.splice(idx, 1);
    },
    remover_desconocido(state,payload){
        let idx = state.productos_agregados.findIndex(p=>p.id === payload)
        state.productos_agregados.splice(idx, 1);
    },
    agregar_desconocido(state,payload){
        state.productos_agregados.unshift(payload)
    },

    // Esta mutacion actualiza el contenido de un producto
    ordenar_productos: (state,payload) => {
        let idx = state.productos_agregados.findIndex(p=>p.id_producto === payload)
        let item = state.productos_agregados[idx];
        state.productos_agregados.splice(idx, 1);
        state.productos_agregados.unshift(item);


    },
    push_producto: (state,payload) => {
        state.productos_agregados.unshift(payload);
    },
    setFormaPago:(state,payload) => {
        state.formaPago = payload
    },
    setParametros:(state, payload) => {
        state.parametros = payload
    }
}

export const actions = {
    async get_productos_carrito({commit}){
        const {data} = await Almacen.get_productos_carrito()
        commit('set_productos_agregados',data.productos)
    },
    async get_resumen({commit}){
        const {data} = await Almacen.get_resumen()
        commit('set_resumen',data.resumen)
        commit('setParametros',data.parametros)
    },



}
