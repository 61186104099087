import axios from 'axios'
const api = "tiendas/predefinidas"

const leecherosDefecto = {
    getData: () => axios.get(`${api}/index`),
    fetchTiendas: () => axios.get(`${api}/tiendas`),
    buscarCiudad: ( params ) => axios.get(`${api}/buscar-ciudad`, params),
    buscarPaises: ( params ) => axios.get(`${api}/buscar-paises`, params),
    saveTiendas: ( data ) => axios.post(`${api}/save-tiendas`, data),
    savePais: ( data ) => axios.post(`${api}/save-pais`, data),
    changeDefault: ( data ) => axios.post(`${api}/change-default`, data),
    updateTienda: ( params ) => axios.put(`${api}/update-tienda`, params),
    eliminar: ( id ) => axios.delete(`${api}/${id}/eliminar`),
}
export default leecherosDefecto
