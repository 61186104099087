<template>
    <modal
    ref="modalSiNo"
    :titulo="titulo"
    no-guardar
    no-cancelar
    no-aceptar
    :btns="btns"
    :icon="icon"
    @accion="accion"
    >
        <div class="container">
            <div class="row my-3 text-muted f-14 justify-center text-center px-3">
                {{ mensaje }}
            </div>
            <slot />
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        mensaje: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            btns:[
                {
                    texto: 'No',
                    color: 'btn mx-2 bg-light-f5 br-10 border text-dark',
                    accion: false
                },
                {
                    texto:'Si',
                    color: 'btn mx-2 bg-general text-white br-10 border-0',
                    accion:true
                }
            ]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalSiNo.toggle()
        },
        accion(val){
            this.$emit('accion',val)
        },

    }
}
</script>
