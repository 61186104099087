import axios from 'axios'

const API = 'chat'

const CHAT = {
    listarChats(payload={}){
        return axios(`${API}/listar`,{params: payload })
    },
    enviarChat(payload = {}){
        return axios.post(`${API}/enviar`,payload)
    },
    listarChatsPedidos(payload = {}){
        return axios(`${API}/listar_pedidos`,{params: payload})
    },
    enviarChatPedido(payload = {}){
        return axios.post(`${API}/enviar_pedido`,payload)
    },
    listarChatsTiendas(payload = {}){
        return axios(`${API}/listar-chat-tiendas`,{params: payload})
    },
    enviarChatTienda(payload = {}){
        return axios.post(`${API}/enviar-chat-tienda`,payload)
    },
    actualizarUltimaLecturaChat(payload = {}){
        return axios.post(`${API}/actualizar-ultimas-lectura`,payload)
    },
    obtenerDatosUserCliente(payload = {}){
        return axios(`${API}/${payload.id}/${payload.tipo}/datos-cliente-chat`,payload)
    },
    obtenerInfoTienda(id_tienda= null){
        return axios(`${API}/${id_tienda}/info-tienda`)
    },
    obtenerInfoTiendaCreditoChat(id_user = null){
        return axios(`${API}/${id_user}/info-tienda-chat-creditos`)
    },
    activarInactivarTienda(payload = {}){
        return axios.put(`${API}/activar-inactivar-tienda`, payload)
    },
    saberCantidadMensajesSinLeer(payload = {}){
        return axios(`${API}/cantidad-mensajes-sin-leer`,{params: payload})
    },
    puedoEscribir(payload = {}){
        return axios(`chat-movil/puedo-escribir`,{params: payload})
    },
    // pedidos admin
    listarChatsAdminPedidos(payload = {}){
        return axios(`${API}/mensajes-pedidos-admin`,{params: payload})
    },
    enviarChatPedidoAdmin(payload = {}){
        return axios.post(`${API}/enviar-pedido-admin`,payload)
    },
    // Almacen - pedidos creditos
    listarChatsCreditosPedidosAdmin(payload = {}){
        return axios(`${API}/mensajes-creditos-pedidos-admin`,{params: payload})
    },
    enviarChatCreditosPedidoAdmin(payload = {}){
        return axios.post(`${API}/enviar-creditos-chats-pedido-admin`,payload)
    },
    // Novedades
    listarNovedadesPedidosAdmin(payload = {}){
        return axios(`${API}/mensajes-novedades-pedidos-admin`,{params: payload})
    },
    enviarNovedadesPedidoAdmin(payload = {}){
        return axios.post(`${API}/enviar-novedades-pedido-admin`,payload)
    },
    obtenerDatosUserClienteDesdeCreditos(payload = {}){
        return axios(`${API}/${payload.id}/datos-cliente-chat-creditos`)
    },


}

export default CHAT
