import axios from 'axios'

const API = 'reclamos-leecheros'

const RECLAMOS = {
    listarPedidos(payload={}){
        return axios(`${API}/listar`,{params: payload })
    },
    detallePedido(payload = {}){
        return axios(`${API}/detalle-pedido`,{params: payload})
    },
    finalizarReclamo(idPedido = {}){
        return axios.put(`${API}/${idPedido}/finalizar-reclamo`)
    },
    actualizarUltimaLectura(payload = {}){
        return axios.put(`${API}/actualizar-ultima-lectura`,payload)
    },


}

export default RECLAMOS
