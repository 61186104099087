import axios from "axios";

const API = "accesos-usuario";

const accesosusuario = {    
    guardarAccesoWeb(id_usuario,tipo){
        return axios.get(`${API}/${id_usuario}/${tipo}/actualizar` );
    },
};

export default accesosusuario;
