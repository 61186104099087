export const state = {
    // fecha_inicial: '',
    // fecha_inicial_filtro: '',
    // fecha_final_filtro: '',
    detectarfiltro: false,
    filtros: {
        pendiente: true,
        confirmadas: true,
        incompletas: true,
    },

    rangoFechas:[]
}

export const getters = {
    // fecha_inicial: state => state.fecha_inicial,
    // fecha_inicial_filtro: state => state.fecha_inicial_filtro,
    // fecha_final_filtro: state => state.fecha_final_filtro,
    detectarfiltro: state => state.detectarfiltro,
    filtros: state => state.filtros,

    rangoFechas: state => state.rangoFechas,
}

export const mutations = {
    // set_fecha_inicial: (state,payload) => {
    //     state.fecha_inicial = payload
    // },
    // fecha_inicial_filtro: (state,payload) => {
    //     state.fecha_inicial_filtro = payload
    // },
    // fecha_final_filtro: (state,payload) => {
    //     state.fecha_final_filtro = payload
    // },
    set_detectar_filtro: (state,payload) => {
        state.detectarfiltro = payload
    },
    set_filtros: (state,payload) => {
        state.filtros = payload
    },
    setRangoFechas: (state,payload) => {
        state.rangoFechas = payload
    },
}
