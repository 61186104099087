export default [
    {
        path: '/postulacion/denegada',
        name: 'postulacion.denegada',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/postulaciones/denegada').then(m => m.default || m)
    },
    {
        path: '/postulacion/pendiente',
        name: 'postulacion.pendiente',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/postulaciones/pendiente').then(m => m.default || m)
    },
    {
        path: '/postulacion/inactiva',
        name: 'postulacion.inactiva',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/postulaciones/inactiva').then(m => m.default || m)
    }
]
