<template>
    <div
    ref="modalEliminar"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    >
        <div
        class="modal-dialog modal-dialog-centered"
        :class="tamano ? tamano : ''"
        role="document"
        >
            <div class="modal-content">
                <cargando v-if="cargadoModal" />

                <div class="modal-header mb-1 d-flex align-items-center">
                    <img class="img-tipo" height="35" src="/img/modales/trash.svg" alt="" />
                    <div class="col text-general f-14 f-600 text-left px-3">
                        {{ titulo }}
                    </div>
                    <div class="ml-auto my-auto d-middle-center bg-white rounded-circle" style="width:20px;height:20px;">
                        <i class="icon-cancel f-20 cr-pointer text-gr-general" data-dismiss="modal" />
                    </div>
                </div>
                <div class="modal-body overflow-auto custom-scroll" style="max-height:70vh;">
                    <div class="container">
                        <!-- <img src="" alt="" /> -->

                        <div v-if="mensaje" class="row my-3 text-general f-14 justify-center text-center px-3">
                            {{ mensaje }}
                        </div>
                        <slot />
                        <div class="row mx-0 bg-linea" />
                    </div>
                </div>
                <div class="row mx-0 justify-center pb-3">
                    <button
                    type="button"
                    class="btn-outline"
                    @click="cancelar"
                    v-text="textosBotones.cancelar"
                    />
                    <button
                    v-if="noAceptar"
                    v-loading="cargando"
                    type="button"
                    class="btn mx-2 btn-aceptar text-white br-10"
                    @click="actionEliminar"
                    v-text="textosBotones.eliminar"
                    />
                    <button
                    v-if="eliminar"
                    v-loading="cargando"
                    type="button"
                    class="btn-general"
                    @click="actionEliminar"
                    v-text="textosBotones.eliminar"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        btns: {
            type: Array,
            default: () => { return [] }
        },
        cargando: {
            type: Boolean,
            default: false
        },
        eliminar:{
            type: Boolean,
            default: false
        },
        noAceptar:{
            type: Boolean,
            default: true
        },
        mensaje: {
            type: String,
            default: ''
        },
        tamano: {
            type: String,
            default: ''
        },
        cargadoModal: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            textosBotones: {
                listo: 'Listo',
                aceptar: 'Aceptar',
                guardar: 'Guardar',
                eliminar: 'Eliminar',
                cancelar: 'Cancelar'
            }
        }
    },
    methods: {
        toggle(){
            $(this.$refs.modalEliminar).modal('toggle')
        },
        guardar(){
            this.$emit('guardar')
        },
        aceptar(){
            this.$emit('aceptar')
        },
        actionEliminar(){
            this.$emit('eliminar')
        },
        onAdicional(){
            this.$emit('adicional')
        },
        cancelar(){
            this.$emit('cancelar')
            this.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/sass/gbp.scss';
.bg-linea{
    background: linear-gradient(to right, #fff, #D1D1D1, #fff);
    height: 1px;
}
.modal-content{
    border: none;
    border-radius: 8px;
    .modal-header{
        height: 60px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: none;
        background-size: cover;
        background-color: #F8F9FF;
        background-position: center;
        .close{
            outline: none;
            transition-duration: .85s;
            position: absolute;
            right: 10px;
            top: 1px;
            font-size: 2.5em;
            &:hover{
                transition-duration: .4s;
                transform: rotate(360deg) scale(1.2);
            }
        }
        .logo{
            display: block;
            // height: 70px;
            // width: 70px;
            span{
                font-size: 1.2em;
            }
        }
    }
    .modal-title{
        color: $azul2-primary;
        font-weight: normal;
    }
}
</style>
