<template>
    <el-drawer :visible.sync="drawer" :with-header="false">
        <div class="bg-whitesmoke h-100">
            <div class="row mx-0 align-items-center p-2 bg-general3 text-white shadow-16">
                <i class="icon-cancel mr-2 f-20 cr-pointer" @click="drawer=false" />
                <b>Notificaciones</b>
            </div>
            <div class="bg-white custom-scroll" style="height: calc(100vh - 55px); overflow: auto;">
                <div class="row mx-0 p-2 bg-whitesmoke d-middle justify-content-between">
                    <span>Recientes</span>
                    <div v-show="recientes.length" type="button" class="br-10 p-1 bg-gr-general text-white px-2 text-center" @click="marcar_leidos">
                        Marcar como leidos
                    </div>
                </div>
                <div v-for="(data, idx) in recientes" :key="idx" class="row mx-0 px-3 p-1 border-bottom cr-pointer" style="background:#0892DD10" @click="redirect(data)">
                    <div class="cr-pointer br-10 position-relative my-auto" :class="data.color==1?'bg-gr-general':'bg-gr-red'" style="width:44px;height:44px;padding:1px">
                        <div class="w-100 h-100 br-10 d-middle-center bg-white">
                            <i :class="`icon-${data.icono} f-20 ${data.color==1?'text-gr-general':'text-gr-red'}`" />
                        </div>
                    </div>
                    <div class="col-10 f-14 px-2">
                        <p><b>{{ data.titulo }}</b></p>
                        <p class="mensaje">
                            <!-- <b class="mr-1">{{ data.nombre }}:</b> -->
                            {{ data.texto }}
                        </p>
                        <p>{{ data.created_at | helper-fecha('hh:mm a') }}</p>
                    </div>
                </div>
                <div class="row mx-0 p-2 bg-whitesmoke">
                    Anteriores
                </div>
                <div v-for="(data, idx2) in antiguas" :key="`key2-${idx2}`" class="row mx-0 px-3 p-1 border-bottom cr-pointer" @click="redirect(data)">
                    <div class="cr-pointer br-10 position-relative my-auto" :class="data.color==1?'bg-gr-general':'bg-gr-red'" style="width:30px;height:30px;padding:1px">
                        <div class="w-100 h-100 br-10 d-middle-center bg-white">
                            <i :class="`icon-${data.icono} f-18 ${data.color==1?'text-gr-general':'text-gr-red'}`" />
                        </div>
                    </div>
                    <div class="col-10 px-2">
                        <p><b class="f-12">{{ data.titulo }}</b></p>
                        <p class="mensaje f-12">
                            <!-- <b class="mr-1">{{ data.nombre }}:</b> -->
                            {{ data.texto }}
                        </p>
                        <p class="f-12">{{ data.created_at | helper-fecha('DD-MM-YY hh:mm a') }}</p>
                    </div>
                </div>
                <div v-if="more_data" type="button" class="br-10 p-1 bg-gr-red text-white text-center m-3" @click="listar_notificaciones">
                    Ver Mas...
                </div>
                <div v-else type="button" class="br-10 p-1 bg-white text-center m-3">
                    No hay mas notificaciones
                </div>
            </div>
        </div>
        <slot />
    </el-drawer>
</template>

<script>
import Notificaciones from '~/services/notificaciones'
export default {
    sockets: {
        avisos(obj){
            const {data} = JSON.parse(obj)
            // console.log(data,'procesando sokect');
            if(data.id_user === this.$usuario.id){
                this.lanzar_notificacion(data)
            }

        },
    },
    data(){
        return {
            drawer: false,
            notificaciones: [
                // {
                //     id: '15464',
                //     tipo: 1,
                //     titulo: 'Tienes un nuevo pedido',
                //     icono: 'receipt',
                //     nombre: 'Martina',
                //     mensaje: 'Me gustaría que la fruta la ubique en la parte superior de la bolsa d',
                //     hora: '09:45 am'
                // },
            ],
            more_data:true,
            pagina_actual:0,
            recientes:[],
            antiguas:[],
        }
    },
    computed:{
        rol(){
            return this.$usuario.rol_nombre
        }
    },
    methods:{
        toggle(){
            this.drawer = !this.drawer
            this.more_data = true
            this.pagina_actual = 0
            this.recientes = []
            this.antiguas = []
            this.listar_notificaciones()
        },
        lanzar_notificacion(item){

            this.$notify({
                title: `${item.titulo}`,
                dangerouslyUseHTMLString: true,
                duration:10000,
                onClick:()=>{this.redirect(item)},
                customClass:'my-class',
                iconClass:`icon-${item.icono} ${item.color== 1 ? 'text-gr-general':'text-gr-red'}`,
                message:`<p class="mensaje">${item.texto}</p>`

            });
        },
        redirect(item){
            // console.log(item);
            let path = this.rol+item.ruta

            // console.log(path);
            this.drawer = false
            if('/'+path === this.$route.path || item.ruta === null) return

            this.$router.push({path:`/${path}`})

        },
        async marcar_leidos(){
            try {
                const {data} = await Notificaciones.lectura()
                this.more_data = true
                this.pagina_actual = 0
                this.recientes = []
                this.antiguas = []
                this.listar_notificaciones()
            } catch (e){
                this.error_catch(e)
            }
        },
        async listar_notificaciones(){
            try {
                this.pagina_actual++
                const {data} = await Notificaciones.listar(this.pagina_actual)
                this.recientes = data.recientes
                if(data.antiguas.last_page === this.pagina_actual){
                    this.more_data = false
                }
                this.antiguas = this.antiguas.concat(data.antiguas.data)

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss">
.my-class{
    cursor: pointer;
    i{
        height: 40px !important;
        width: 40px !important;
        font-size: 30px !important;
    }
    h2{
        font-size: 14px;
    }
    .mensaje{
        max-height: 38px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
    }
}
.mensaje{
    max-height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
</style>
