<template>
    <div class="mobile-navbar bg-danger">
        dadwda
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.mobile-navbar{
    height:100vh;
    z-index:88;
    width:30vw;
}
</style>