<template>
    <el-drawer :visible.sync="drawer" :title="titulo" class="bg-lateral" :size="porcentaje" :wrapper-closable="false" :close-on-press-escape="false">
        <template slot="title">
            <slot name="title-edit" />
        </template>
        <slot />
    </el-drawer>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        porcentaje: {
            type: String,
            default: '30%'
        },
    },
    data(){
        return {
            drawer: false,
        };
    },
    methods:{
        toggle(){
            this.drawer = !this.drawer
        }
    }
}
</script>
