import axios from 'axios'

const API = 'pedido'

const Pedidos = {
    pedidos_indicadores(){
        return axios(`${API}/indicadores`)
    },
    listar_pedidos(params){
        return axios(`${API}/lista`,{params:params})
    },
    validar_estado(id_pedido){
        return axios(`${API}/${id_pedido}/validar-estado`)
    },
    pedidos_productos(id_pedido){
        return axios(`${API}/${id_pedido}/productos`)
    },
    get_datos(id_pedido){
        return axios(`${API}/${id_pedido}/ver`)
    },
    select_clientes(){
        return axios(`${API}/get-clientes`)
    },
    get_horarios(){
        return axios(`tiendas/horarios/entregas`)
    },
    get_direcciones(id_user){
        return axios(`${API}/cliente/${id_user}/direcciones`)
    },
    get_distancia(model){
        return axios.put(`${API}/cliente/distancia`,model)
    },
    get_valor_domicilio(distancia){
        return axios(`${API}/cliente/valor-domi`,{params:{distancia}})
    },
    crear_pedido(model){
        return axios.post(`${API}/manual/agregar`,model)
    },
    confirmar(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/confirmar`,model)
    },
    editar_datos(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/update-datos`,model)
    },
    select_motivos(params){
        return axios(`motivos/lista-motivos`,{params:params})
    },
    rechazar_tendero(model){
        return axios.put(`${API}/${model.id_pedido}/rechazar`,model)
    },
    buscar_productos(params){
        return axios(`${API}/${params.id_pedido}/buscar/producto`,{params:params})
    },
    agregar_producto(model){
        return axios.post(`${API}/manual/producto/agregar`,model)
    },
    change_producto(id_pp,model){
        return axios.put(`${API}/manual/producto/${id_pp}/change`,model)
    },
    eliminar_producto_manual(id_pp){
        return axios.delete(`${API}/manual/producto/${id_pp}/delete`)
    },
    descartar_pedido_manual(id_pedido){
        return axios.delete(`${API}/manual/${id_pedido}/delete`)
    },
    alistar_empacar(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/cambio/estado`,model)
    },
    confirmar_envio_pedido(id_pedido,val_direccion){
        let model = {
            otra_direccion:val_direccion
        }
        return axios.put(`${API}/${id_pedido}/enviar`,model)
    },
    finalizar_pedido(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/finalizar`,model)
    },
    alter_producto(id_pp,model){
        return axios.post(`${API}/producto/${id_pp}/change`,model)
    },
    eliminar_recuperar_producto(id_pp){
        return axios.put(`${API}/producto/${id_pp}/change/estado`)
    },
    revertir_cambio(id_pp){
        return axios.put(`${API}/producto/${id_pp}/revertir-cambio`)
    },
    confirmar_cambios_pedido(id_pedido){
        return axios.put(`${API}/${id_pedido}/${1}/enviar/cambios`)
    },
    descartar_cambios_pedido(id_pedido){
        return axios.put(`${API}/${id_pedido}/${2}/descartar/cambios`)
    },
    editar_tendero(id_pedido){
        return axios.put(`${API}/${id_pedido}/proceso-tendero`)
    },
    cancelar_tendero(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/cancelar`,model)
    },
    cambiar_icono(model){
        return axios.put(`${API}/producto/${model.id}/cambio-icono`,model)
    },
    crear_credito(id_pedido,model){
        return axios.post(`${API}/${id_pedido}/add-credito`,model)
    },
    get_credito(id_pedido){
        return axios.get(`${API}/${id_pedido}/credito`)
    },
    cancelar_credito(id_pedido){
        return axios.put(`${API}/${id_pedido}/credito/cancelar`)
    },
    anular_credito(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/credito/anular`,model)
    },
    pasarAdirecto(id_pedido){
        return axios.put(`${API}/${id_pedido}/update/directo`)
    },
    solicitar_cambio_precio(id_pp,model){
        return axios.put(`${API}/producto/${id_pp}/solicitud/cambio-precio`,model)
    },
    verPedido(id_pedido){
        return axios.get(`${API}/${id_pedido}/cambio-pedido`)
    },





    historial_estados(id_pedido){
        return axios(`${API}/${id_pedido}/historial-estados`)
    },
    resumenes(id_pedido){
        return axios(`${API}/${id_pedido}/resumenes`)
    },

    producto_cambiado(id_pp){
        return axios(`${API}/producto/${id_pp}/cambiado`)
    },
    consultar_un_pedido(id_pedido){
        return axios(`${API}/${id_pedido}/consultar`)
    },


}

export default Pedidos
