import axios from 'axios'

const API = 'promociones-admin'

const PROMOCIONES = {
    listarCategorias(id_tienda = null){
        return axios(`${API}/listar-categorias`,{params: {id_tienda: id_tienda}})
    },
    crearPromocion(payload){
        return axios.post(`${API}/crear`,payload)
    },
    listarPromociones(payload = {}){
        return axios(`${API}/promociones-admin`, { params: payload} )
    },
    detallePromocion(payload = {}){
        return axios(`${API}/${payload.id_promocion}/detalle-promocion`)
    },
    editarPromocion(payload){
        return axios.put(`${API}/editar`,payload)
    },
    eliminarPromocion(id_promocion = null){
        return axios.delete(`${API}/${id_promocion}/eliminar`)
    }
}

export default PROMOCIONES
