import store from '~/store'
export default async(to, from, next) =>{
    const data = store.getters[`auth/obtenerUsuario`]

    if(data.tienda.estado === 5){
        return next()

    } else{
        next({name:`${data.rol_nombre}.home`})

    }
}
