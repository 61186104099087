export default [
    {
        path: '/admin/clientes',
        meta: { nombre: 'Clientes estadisticas' },
        name: 'admin.clientes-estadisticas',
        redirect: { name: 'admin.clientes-estadisticas.estadisticas' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/clientesEstadisticas').then(m => m.default || m),
        children: [
            {
                path: 'estadisticas',
                meta: { nombre: 'Clientes estadisticas' },
                name: 'admin.clientes-estadisticas.estadisticas',
                component: () => import(/* webpackChunkName: "pagos" */ '../../pages/clientes').then(m => m.default || m),
            },
            {
                path: 'satisfaccion',
                meta: { nombre: 'Clientes estadisticas' },
                name: 'admin.clientes-estadisticas.satisfaccion',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/satisfaccion').then(m => m.default || m),
            },
            {
                path: 'calificaciones',
                meta: { nombre: 'Clientes estadisticas' },
                name: 'admin.clientes-estadisticas.calificaciones',
                component: () => import(/* webpackChunkName: "productos" */ '../../pages/calificaciones').then(m => m.default || m),
            },
            {
                path: 'clientes',
                meta: { nombre: 'Clientes estadisticas' },
                name: 'admin.clientes-estadisticas.clientes',
                component: () => import(/* webpackChunkName: "productos" */ '../../pages/clientesEstadisticas/clientes').then(m => m.default || m),
            },
            {
                path: 'referidos',
                meta: { nombre: 'Clientes estadisticas' },
                name: 'admin.clientes-estadisticas.referidos',
                component: () => import(/* webpackChunkName: "productos" */ '../../pages/clientesEstadisticas/referidos').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/admin/clientes/ver/:id_cliente',
        meta: { nombre: 'Clientes' },
        name: 'admin.clientes.ver',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/clientesEstadisticas/ver').then(m => m.default || m),
        children: [
            {
                path: 'pedidos-realizados',
                meta: { nombre: 'Clientes' },
                name: 'admin.clientes.ver.pedidos-realizados',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/clientesEstadisticas/pedidosRealizados').then(m => m.default || m),
            },
            {
                path: 'productos-comprados',
                meta: { nombre: 'Clientes' },
                name: 'admin.clientes.ver.productos-comprados',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/clientesEstadisticas/productosComprados').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/admin/clientes/lista',
        meta: { nombre: 'Clientes' },
        name: 'admin.clientes.lista',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/clientesEstadisticas/listaGrupos').then(m => m.default || m),
    }
]

