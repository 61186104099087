export default [
    {
        path: '/admin/satisfaccion',
        meta: { nombre: 'Satisfacción' },
        name: 'satisfaccion',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/satisfaccion').then(m => m.default || m),
    },
    {
        path: '/admin-vip/satisfaccion',
        meta: { nombre: 'Satisfacción' },
        name: 'admin-vip.satisfaccion',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/satisfaccion').then(m => m.default || m),
    }
]
