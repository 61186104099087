export default [
    {
        path: '/admin/estadisticas',
        meta: { nombre: 'Estadisticas' },
        name: 'admin.estadisticas',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/estadisticas').then(m => m.default || m),
        redirect: {name: 'admin.estadisticas.estadisticas'},
        children: [
            {
                path: 'estadisticas',
                meta: { nombre: 'Estadisticas' },
                name: 'admin.estadisticas.estadisticas',
                component: () => import('../../pages/almacen/estadisticas/index').then(m => m.default || m),
            },
            {
                path: 'utilidad',
                meta: { nombre: 'Estadisticas' },
                name: 'admin.estadisticas.utilidad',
                component: () => import(/* webpackChunkName: "promociones" */ '../../pages/estadisticas/utilidad').then(m => m.default || m),
            },
            {
                path: 'etapas',
                meta: { nombre: 'Estadisticas' },
                name: 'admin.estadisticas.etapas',
                component: () => import(/* webpackChunkName: "promociones" */ '../../pages/estadisticas/etapas').then(m => m.default || m),
            },
            {
                path: 'acceso-usuarios',
                meta: { nombre: 'Estadisticas' },
                name: 'admin.estadisticas.acceso-usuarios',
                component: () => import(/* webpackChunkName: "promociones" */ '../../pages/estadisticas/accesoUsuarios').then(m => m.default || m),
                redirect: {name: 'admin.estadisticas.acceso-usuarios.estadisticas-leecheros'},
                children: [
                    {
                        path: 'estadisticas-leecheros',
                        meta: { nombre: 'Estadisticas' },
                        name: 'admin.estadisticas.acceso-usuarios.estadisticas-leecheros',
                        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/estadisticas/accesoUsuarios/estadisticasLeecheros').then(m => m.default || m),
                    },
                    {
                        path: 'estadisticas-clientes',
                        meta: { nombre: 'Estadisticas' },
                        name: 'admin.estadisticas.acceso-usuarios.estadisticas-clientes',
                        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/estadisticas/accesoUsuarios/estadisticasClientes').then(m => m.default || m),
                    },
                    {
                        path: 'comparativa-usuarios',
                        meta: { nombre: 'Estadisticas' },
                        name: 'admin.estadisticas.acceso-usuarios.comparativa-usuarios',
                        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/estadisticas/accesoUsuarios/comparativaUsuarios').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'movimientos-productos',
                meta: { nombre: 'Estadisticas' },
                name: 'admin.estadisticas.movimientos-productos',
                component: () => import( '../../pages/estadisticas/movimientosProductos/index').then(m => m.default || m),
                redirect: {name: 'admin.estadisticas.movimientos-productos.retiros-cedis'},
                children: [
                    {
                        path: 'retiros-cedis',
                        meta: { nombre: 'Estadisticas' },
                        name: 'admin.estadisticas.movimientos-productos.retiros-cedis',
                        component: () => import( '../../pages/estadisticas/movimientosProductos/retirosCedis').then(m => m.default || m),
                    },
                    {
                        path: 'traslados-cedis',
                        meta: { nombre: 'Estadisticas' },
                        name: 'admin.estadisticas.movimientos-productos.traslados-cedis',
                        component: () => import( '../../pages/estadisticas/movimientosProductos/trasladosCedis').then(m => m.default || m),
                    },
                    {
                        path: 'retiros-leecheros',
                        meta: { nombre: 'Estadisticas' },
                        name: 'admin.estadisticas.movimientos-productos.retiros-leecheros',
                        component: () => import( '../../pages/estadisticas/movimientosProductos/retirosLeecheros').then(m => m.default || m),
                    },
                    {
                        path: 'devoluciones-leecheros',
                        meta: { nombre: 'Estadisticas' },
                        name: 'admin.estadisticas.movimientos-productos.devoluciones-leecheros',
                        component: () => import( '../../pages/estadisticas/movimientosProductos/devolucionesLeecheros').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'dashboard-reclamos',
                meta: { nombre: 'Estadisticas' },
                name: 'admin.estadisticas.dashboard-reclamos',
                component: () => import( '../../pages/estadisticas/dashboardReclamos').then(m => m.default || m),
            },
            {
                path: 'movimientos',
                meta: { nombre: 'Estadisticas' },
                name: 'admin.estadisticas.movimientos',
                component: () => import( '../../pages/estadisticas/movimientos').then(m => m.default || m),
            },
            {
                path: 'kpi',
                meta: { nombre: 'Estadisticas' },
                name: 'admin.estadisticas.kpi',
                component: () => import( '../../pages/estadisticas/kpi/index').then(m => m.default || m),
            }
        ]
    },
]
