<template>
    <div class="card-producto d-middle m-2 p-1" :class="diseno.borde?border[diseno.color]:''" @click="$emit('accion')">
        <div class="position-relative">
            <i v-if="diseno.posicion==0" class="icon-bookmark f-20 position-absolute" :class="text[diseno.color]" style="top:-6px;right:4px;" />
            <img v-if="info.foto !== null" class="br-10" height="90" width="90" :src="info.foto" alt="" />
            <div v-else class="br-5 bg-whitesmoke d-middle-center" style="width:90px;height:90px;">
                <img height="70" src="/img/no-imagen/mercado.svg" alt="" />
            </div>
            <div
            class="position-absolute text-white w-100 f-10 text-center"
            :style="diseno.posicion==0?'bottom:0px;border-radius: 0 0 10px 10px;':'top:0px;border-radius: 10px 10px 0 0;'"
            >
                <span v-if="diseno.tipo ===1">2x1</span>
                <span v-else-if="diseno.tipo ===2 || diseno.tipo ===5">{{ diseno.texto }}</span>
                <span v-else-if="diseno.tipo ===3">{{ diseno.descuento }} %</span>
                <span v-else-if="diseno.tipo ===4"> Solo por hoy</span>
            </div>
        </div>
        <div class="col px-2">
            <p class="nombre lh-18 text-general f-600">{{ info.nombre }}</p>
            <p>{{ info.presentacion }}{{ info.sigla }}</p>
            <div v-if="!editar" class="row mx-0 align-items-center pb-1">
                <span class="text-general f-600">{{ convertMoney(info.valor, info.idm_moneda) }}</span>
                <strike v-if="ver" class="ml-2 f-12">
                    15000
                </strike>
                <div v-show="ver" class="cr-pointer text-center ml-auto br-10 bg-gr-red py-1 text-white" style="width:120px;">
                    Añadir
                </div>
                <el-input-number v-show="agregar" v-model="num" :min="1" :max="10" class="ml-auto" style="width:145px;" />
                <el-tooltip class="item" effect="light" content="Número de ventas realizado" placement="bottom">
                    <div v-if="!ver" class="br-10 text-white px-1 f-17 bg-general3 ml-auto">
                        <i class="icon-cash-multiple f-18 mr-2" />
                        <span>{{ info.cant_vendido }}</span>
                    </div>
                </el-tooltip>
            </div>
            <div v-else class="row mx-0 align-items-center pb-1 justify-content-between">
                <span class="text-morado f-600">{{ convertMoney(info.valor) }}</span>
                <i :class="estadoProd(info.estado)" />
            </div>
        </div>
        <div v-if="tendero" class="d-flex flex-column">
            <i v-show="tipo=='libre' || tipo=='rebajado'" class="icon-pencil-outline f-22 my-auto cr-pointer text-gr-general" @click.stop="$emit('editar')" />
            <i class="icon-trash-empty f-22 my-auto cr-pointer" :class="true?'text-gr-red':'text-gris'" @click.stop="$emit('eliminar')" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        diseno: {
            type: Object,
            default: () => {
                return {
                    tipo: 1,
                    color: 0,
                    borde: true,
                    posicion: 0,
                    texto: 'MÁS BARATO',
                    descuento: 0
                }
            }
        },
        info: {
            type: Object,
            default: () => {
                return {
                    nombre: 'Malteada de vainilla Francesa con palitos de chocolate',
                    foto_mini: 'https://img.culturacolectiva.com/content_image/2020/1/21/1579624512515-receta-de-frappe-de-chocolate-con-vino-tinto-en-5-minutos.003.jpeg',
                    presentacion:  '600',
                    sigla: 'ml',
                    valor: 14000,
                    cant_vendido: 51,
                    id_promocion: null,
                    tipo: 1,
                    color: 0,
                    borde: true,
                    posicion: 0,
                    texto: 'MÁS BARATO',
                    descuento: 0,
                    estado: ''


                }
            }
        },
        tendero: {
            type: Boolean,
            default: false
        },
        agregar: {
            type: Boolean,
            default: false
        },
        ver: {
            type: Boolean,
            default: false
        },
        editar: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            // agregar: false,
            colores: [ 'bg-gr-general', 'bg-gr-red', 'bg-gr-purple', 'bg-gr-pink' ],
            text: [ 'text-gr-general', 'text-gr-red', 'text-gr-purple', 'text-gr-pink' ],
            border: [ 'border-blue', 'border-red', 'border-purple', 'border-pink' ],
        }
    },
    mounted(){
        console.log('aqui car producto afuera');
    },
    methods: {
        disenoCard(idx){
            this.diseno.color = this.colores[idx]
            this.borde_activo = this.border[idx]
        },
        estadoProd(est){
            switch (est){
            case 'menos':
                return 'icon-minus text-gr-red'
            case 'mas':
                return 'icon-plus text-gr-red'
            case 'menos':
                return 'icon-swap-horizontal-bold text-gr-general'
            default:
            }
        }
    }
}
</script>

<style lang="css" scoped>
.card-producto{
    width: 400px;
    border-radius: 16px;
}
.nombre{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
