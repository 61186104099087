<template>
    <div>
        <el-select v-model="cedis" placeholder="Select" class="w-100" @change="$emit('cedis_seleccionado',cedis)">
            <el-option
            v-for="item in cedis_lista"
            :key="item.id"
            :label="item.nombre"
            :value="item.id"
            />
        </el-select>
    </div>    
</template>
<script>
import SCedis from '~/services/cedis/index'
export default {
    props:{
        seleccionado:{
            type: [ Number ],
            required: false,
        }
    },
    data(){
        return{
            cedis:null,
            cedis_lista: []
        }
    },
    mounted(){
        this.listarCedis()
        this.cedis = 1
    },
    methods:{
        async listarCedis(){
            try{
                const { data } = await SCedis.get_cedis_disponibles()
                this.cedis_lista = data
            } catch (error){
                this.error_catch(error)
            }
        }      
    }
}
</script>

<style lang="scss" scoped>

</style>
