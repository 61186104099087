<template>
    <section>
        <Modal ref="modalNoDireccion" titulo="No aparece mi dirección" icon="location" cerrar no-cancelar @guardar="onChange()" @cerrar="onClose()">
            <div class="row mx-0 justify-center">
                <div class="col-auto text-center">
                    <img src="/img/ilustraciones/direccion_no_encontrada.svg" style="max-width:1000%;height:345px;" />
                </div>
                <span class="text-general f-16 text-center mt-5">Te sugerimos usar una dirección cercana o un punto de referencia cercano y modificarla en el mapa para tener mayor precisión de tu ubicación</span>
                <ValidationObserver ref="form" class="row w-100">
                    <div class="col mt-5">
                        <ValidationProvider v-slot="{errors}" rules="required" name="dirección">
                            <el-select
                            v-model="direccion"
                            filterable
                            remote
                            class="w-100"
                            reserve-keyword
                            :remote-method="remoteMethod"
                            >
                                <el-option
                                v-for="item in options"
                                :key="item.place_id"
                                :label="item.description"
                                :value="item.description"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </ValidationObserver>
            </div>
        </Modal>
    </section>
</template>

<script>
import localizacion from '~/services/localizacion';
export default {
    data(){
        return {
            options: [],
            direccion: ''
        }
    },

    methods: {
        toggle(){
            this.$refs.modalNoDireccion.toggle()
        },
        async remoteMethod(query){
            try {
                let googleMaps = await this.$iniciarGoogleMaps();
                this.options = await localizacion.getPlaces(googleMaps, query);
            } catch (e){
                this.options = [];
            }
        },
        async onChange(){
            const valid = await this.$refs.form.validate()
            if(!valid) return 

            this.$emit('change', this.direccion)
            this.direccion = ''
            this.options = [];
            this.$refs.form.reset()
            this.toggle()
        },
        onClose(){
            this.direccion = ''
            this.options = [];
            this.$refs.form.reset()

            this.$emit('onClose')
        }
    }

}
</script>

<style>

</style>