<template>
    <section>
        <modal-lateral ref="modalInfoSoporteFinalizado">
            <div class="overflow-auto custom-scroll" style="width:100%;height:calc(100vh - 60px);">
                <div class="row mx-0 justify-center">
                    <div class="col-12">
                        <div class="row justify-center text-general">
                            <h3>Soporte # {{ sop.num }} de</h3>
                        </div>

                        <div class="row justify-center text-muted2 f-20 f-600">
                            <img class="border bg-whitesmoke rounded-circle" width="48" height="48" :src="detalle.imagen_firmada" alt="" />
                        </div>
                        <div class="row justify-center text-general f-20 f-600">
                            {{ detalle.nombre }}
                        </div>
                    </div>
                </div>

                <div class="row mx-0 px-4 my-3">
                    <p class="text-general f-600"> Finalizado: </p>
                    <p class="mx-1"> {{ sop.name }} </p>
                </div>
                <div class="row mx-0 px-4 my-3">
                    <p class="mx-1"> {{ sop.fecha_fin }} </p>
                </div>
                <div class="row mx-0 px-4 my-3">
                    <p class="mx-1"> {{ sop.comentario }} </p>
                </div>
            </div>
        </modal-lateral>
    </section>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    data(){
        return{
            sop:{
                num: 0,
                imagen_firmada: null,
                comentario: null,
                fecha_fin: null
            }
        }
    },
    computed:{
        ...mapGetters({
            detalle: 'soporte/general/detalle',


        }),

    },
    methods: {
        toggle(info){
            // this.limpiar()
            // this.listarInfo(id_tienda)
            this.sop = info
            this.$refs.modalInfoSoporteFinalizado.toggle();
        },
        limpiar(){
            this.tienda={
                singular: '',
                ciudad: '',
                correo: '',
                descripcion: '',
                direccion: '',
                id: null,
                logo_mini: null,
                logo_mini_firmado: null,
                nombre: '',
                plan: '',
                propietario_nombre: '',
                telefono_celular: null,
                cant_cuotas: 0
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
</style>
