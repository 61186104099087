<template>
    <section class="modal-ver-imagenes">
        <Modal ref="modalVerImagenes" :titulo="titulo" tamano="modal-xl">
            <div class="container-fluid">
                <div class="mx-0 position-relative justify-center d-middle-center">
                    <i class="icon-left  f-35 text-general cr-pointer" @click="saltoIzquierda" />
                    <div class="contenedor-imagen row mx-0">
                        <img :src="imagenes[indice].imagen" class="imagen m-auto" />
                    </div>
                    <i class="icon-right f-35 text-general cr-pointer" @click="saltoDerecha" />
                </div>
                <div class="row mx-0 position-relative justify-center my-4">
                    <div v-for="(imagen,i) in imagenes" :key="i" :class="` mb-3 contenedor-imagen-mini ${imagenFull === imagen.archivo ?'actual':''}`">
                        <img :src="imagen.imagen_mini" class="imagen border obj-cover obj-center" @click="cambiarImg(imagen,imagenes,i)" />
                    </div>
                </div>
            </div>
        </Modal>
    </section>
</template>

<script>

export default {
    name: 'ModalGaleriaImagenes',
    props: {
        titulo: {
            type: String,
            default: ''
        },
        imagenes: {
            type: Array,
            default: () => []
        },
        indiceInicial: {
            type: Number,
            default: 0
        }
    },
    data(){
        return {
            imagenFull:'',
            temp:[],
            indice: 0
        }
    },
    methods:{
        toggle(imagenes,imagen,index,titulo){
            // console.log('hola mundo');
            // this.imagenes=imagenes
            // if(this.imagenes.length){
            //     this.temp = this.imagenes
            //     this.imagenFull = imagen.archivo
            //     this.indexTemp = index
            // }else{
            //     this.imagenFull = '',
            //     this.temp = [],
            //     this.indexTemp = null
            // }

            this.$refs.modalVerImagenes.toggle();
        },
        cambiarImg(obj,arreglo,index){
            this.temp = arreglo
            this.indexTemp = index
            this.imagenFull = obj.archivo
        },
        saltoDerecha(){
            --this.indice
        },
        saltoIzquierda(){
            ++this.indice
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-ver-imagenes{
    .contenedor-imagen{
        width: 448px;
        height: 448px;
        border-radius: 4px;
        cursor: pointer;
        .imagen{
            max-height: 100%;
            max-width: 100%;
        }
    }
    .contenedor-imagen-mini{
        width: 103px;
        height: 103px;
        border-radius: 4px;
        margin: 0px 7px;
        // filter: grayscale(1);
        cursor: pointer;
        &.actual{
            filter: grayscale(0);
            border: 2px solid var(--general-color);
        }
        .imagen{
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}
</style>
