export default {
    created(){
        window.option_df_gbp = {
            enableDownload: false,
            hideControls: 'share,outline',
            source: '/api',
            webgl: false
        };
    },
    methods: {
        set_flip_img(arreglo){
            new Promise(resolve => {
                resolve(arreglo[0]);
            }).then(tmp => {
                option_df_gbp.source = tmp;
            })
        },
        set_pdf(file){
            console.log(file);
            option_df_gbp.source = file;
        }
    }
}
