<template>
    <section>
        <div v-if="completed" id="menuWidth" class="border overflow-auto custom-scroll menu-prinicipal br-10">
            <div v-for="(menu, idx) in menus" :key="idx">
                <div
                v-if="$can(menu.can)"
                class="d-flex mx-0 d-middle border-bottom cr-pointer"
                style="height:60px;"
                @click="$router.push({ name: menu.ruta })"
                >
                    <div :class="`mx-2 px-1 h-80 br-5 ${menu.ruta==$route.name?'bg-general':''}`" />
                    <i :class="`${menu.icon} f-22 text-general`" />
                    <div class="name-menu col-xl col-lg col-md-8 col-sm tres-puntos text-general f-15">
                        {{ menu.nombre }}
                    </div>
                    <i :class="`icon-angle-right f-18 ${menu.ruta==$route.name?'text-general':''}`" />
                </div>
            </div>
        </div>
        <template v-else>
            <div v-for="(menu, idx) in menus" :key="idx">
                <div
                v-if="$can(menu.can)"
                class="d-flex mx-0 d-middle border-bottom cr-pointer"
                style="height:60px;"
                @click="$router.push({ name: menu.ruta })"
                >
                    <div :class="`mx-2 px-1 h-80 br-5 ${menu.ruta == $route.name?'bg-general':''}`" />
                    <i :class="`${menu.icon} f-22 text-general`" />
                    <div class="col-9 tres-puntos f-15 text-general">
                        {{ menu.nombre }}
                    </div>
                    <i :class="`icon-angle-right f-18 ${menu.ruta == $route.name?'text-general':''}`" />
                </div>
            </div>
        </template>
    </section>
</template>

<script>
export default {
    props: {
        completed: {
            type: Boolean,
            default: true
        },
        menus: {
            type: Array,
            default: () => {
                return {}
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{
    height: calc(100vh - 58px);
    background: #fff;
}
</style>
