<template>
    <money
    :key="key"
    :value="+value"
    :class="`form-control br-10 ${!border ? '' : 'wt-border'}`"
    v-bind="moneyOptions"
    @input="emitir($event)"
    />
</template>
<script>
import {Money} from 'v-money'
import {mapGetters} from 'vuex'
export default {
    name: 'InputMilesMoneada',
    components:{ Money },
    props: {
        value: {
            type: [Number, String],
            default: 0
        },
        border: {
            type: Boolean,
            default: false
        },
        idMoneda: {
            type: [Number, String],
            default: 0
        }
    },
    data(){
        return {
            valor: this.value,
            moneyOptions: {
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            key:false


        }
    },
    computed:{
        ...mapGetters({
            monedas:'cedis/monedas',
        })
    },
    watch: {
        valor(val){
            this.$emit('change',val)
        },
        idMoneda(val){
            this.cantidadDecimalesMoneda()
        }
    },
    mounted(){
        this.cantidadDecimalesMoneda()
    },
    methods:{
        emitir(value){
            this.valor = value
            this.$emit('input',value)
        },
        cantidadDecimalesMoneda(){
            if(this.monedas){
                const moneda_found = this.monedas.find(e=>e.id==this.idMoneda)
                if(moneda_found){
                    /**
                     * este key es para cuando se cambie la moneda
                     * el input vuelva a renderizar
                     * con la nueva cantidad de decimales
                     */
                    this.key = !this.key
                    this.moneyOptions.precision = moneda_found.decimales
                }
            }
        }
    }

}
</script>
<style lang="scss" scoped>
.wt-border{
    border: 1px solid transparent !important;
    border-radius: 6px !important;
}
</style>
