
import Creditos from '~/services/almacen/creditos_tendero'

export const state = {
    graficaPorPagarMeses: [],
    cuotasPedidos: [],

    datosGenerales: {
        total_deuda:0,
        sumaActivos: 0,
        sumaMorosos: 0
    },
    tablaCuotas: [],
    infoTabCuotas: {
        cuotas_vencidas: 0,
        deuda_por_cobrar: 0,
        deuda_vencidas: 0,
        proxi_cuotas: 0,
        deuda_total: 0
    },
    cant_msg_chat:0

}

export const getters = {
    graficaPorPagarMeses: state => state.graficaPorPagarMeses,
    cuotasPedidos: state => state.cuotasPedidos,
    datosGenerales: state => state.datosGenerales,
    tablaCuotas: state => state.tablaCuotas,
    infoTabCuotas: state => state.infoTabCuotas,
    cant_msg_chat: state => state.cant_msg_chat
}

export const mutations = {
    set_grafica_por_pagar_meses: (state,payload) => {
        state.graficaPorPagarMeses = payload
    },
    set_cuotas_pedidos: (state,payload) => {
        state.cuotasPedidos = payload
    },
    set_datos_generales: (state,payload) => {
        state.datosGenerales = payload
    },
    set_tabla_cuotas: (state,payload) => {
        state.tablaCuotas = payload
    },
    set_info_Tab_cuotas: (state,payload) => {
        state.infoTabCuotas = payload
    },
    set_cant_msg_chat: (state,payload) => {
        state.cant_msg_chat = payload
    },

}

export const actions = {
    async action_listar_creditos_grafica_cuenta_por_pagar({commit}){
        const {data} = await Creditos.listarCreditosGraficaCuentaPorPagar()
        commit('set_grafica_por_pagar_meses',data.grafica)
        commit('set_cuotas_pedidos',data.cuotasPedidos)
        commit('set_datos_generales',data.datosGenerales)
        commit('set_cant_msg_chat',data.cant_msg_chat)
    },
    async action_listar_cuotas({commit}){
        const {data} = await Creditos.verCreditoPorPagarTabla()
        commit('set_tabla_cuotas',data.cuotas)
        commit('set_info_Tab_cuotas',data.info)
    }

}
