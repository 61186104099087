export default [
    {
        path: '/admin/soporte',
        meta: { nombre: 'Soporte' },
        name: 'admin.soporte',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/admin').then(m => m.default || m),
        redirect: {name: 'admin.soporte.tiendas'},
        children: [
            {
                path: 'tiendas',
                meta: { nombre: 'Soporte' },
                name: 'admin.soporte.tiendas',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/tiendas').then(m => m.default || m),
            },
            {
                path: 'soporte-general',
                meta: { nombre: 'Soporte - general' },
                name: 'admin.soporte.general',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/soporteGeneral').then(m => m.default || m),
            },
            {
                path: 'reclamos',
                meta: { nombre: 'Soporte - Reclamos' },
                name: 'admin.soporte.reclamo',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/reclamo').then(m => m.default || m),
            },
            {
                path: 'reclamos/terminados',
                meta: { nombre: 'Soporte - Reclamos terminados' },
                name: 'admin.soporte.reclamos-terminados',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/reclamosTerminados').then(m => m.default || m),
            },
            {
                path: 'reclamos-tienda',
                meta: { nombre: 'Soporte - reclamos' },
                name: 'admin.soporte.reclamos-leecheros',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/reclamosLeecheros').then(m => m.default || m),
            },
            {
                path: 'reclamos-tienda/terminados',
                meta: { nombre: 'Soporte - reclamos' },
                name: 'admin.soporte.reclamos-leecheros-terminados',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/reclamosLeecherosTerminados').then(m => m.default || m),
            },
            {
                path: 'chats-vigentes',
                meta: { nombre: 'Soporte - reclamos' },
                name: 'admin.soporte.chats-vigentes',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/chatsVigentes').then(m => m.default || m),
            },
            {
                path: 'novedades-delivery',
                meta: { nombre: 'Soporte - reclamos' },
                name: 'admin.soporte.novedades-delivery',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/soporte/novedadesDelivery').then(m => m.default || m),
            },
        ]
    }
]
