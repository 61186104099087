<template>
    <section>
        <div class="d-flex aling-center">
            <p class="f-12">{{ fechaInforme }}</p>
            <i class="icon-search cr-pointer f-12" @click="$emit('clicked');createTimer()" />
        </div>
    </section>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
let date

export default {
    name: 'Timer',
    data(){
        return {
            interval: null,
            fechaInforme: 'hace unos segundos'
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        })
    },
    watch: {
        id_cedis(val){
            if(val == null) return
            this.createTimer()
        },
        id_moneda(val){
            if(val == null) return
            this.createTimer()
        },
    },
    beforeDestroy(){
        clearInterval(this.interval)
    },
    mounted(){
        this.createTimer()
    },
    methods: {
        createTimer(){
            date = moment()
            this.interval = setInterval(() => {
                this.fechaInforme = moment(date).locale('es').fromNow();
            }, 1000)
        }
    }
}
</script>
