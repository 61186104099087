import axios from 'axios'

const API = 'pedidos-admin/historial'

const Historial = {

    listar_pedidos(params){
        return axios(`${API}/listado`,{params})
    },
    listar_pedidos_cancelados(params){
        return axios(`pedidos-admin/cancelados/listado`,params)
    },
    getContadores(){
        return axios(`pedidos-admin/contadores`)
    },
    pedidos_productos(id_pedido){
        return axios(`${API}/${id_pedido}/ver`)
    },
    historial_estados(id_pedido){
        return axios(`${API}/${id_pedido}/historial-estados`)
    },
    resumenes(id_pedido){
        return axios(`${API}/${id_pedido}/resumenes`)
    },
    producto_complemento(id_producto){
        return axios(`producto/${id_producto}/complementaria`)
    },
    producto_cambiado(id_pp){
        return axios(`${API}/producto/${id_pp}/cambiado`)
    },

}

export default Historial
