export default [
    {
        path: '/admin/promociones',
        meta: { nombre: 'Promociones' },
        name: 'admin.promociones',
        component: () => import('../../pages/promociones/admin').then(m => m.default || m)
    },
    {
        path: '/admin/promociones/grupos',
        meta: { nombre: 'Promociones' },
        name: 'admin.promociones.grupos',
        component: () => import('../../pages/promociones/grupos').then(m => m.default || m),

    },
    {
        path: '/admin/promociones/grupo/:idGrupo',
        meta: { nombre: 'Promociones' },
        name: 'admin.promociones.grupos.ver',
        component: () => import('../../pages/promociones/grupos/verGrupo').then(m => m.default || m),
        children: [
            {
                path: '/admin/promocion/:idGrupo/detalle/:idPromocion?',
                meta: { nombre: 'Promociones' },
                name: 'admin.promociones.detalle',
                //component: () => import('../../pages/proveedores/lista').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/admin/promociones/grupo/:idGrupo/promocion/crear',
        meta: { nombre: 'Promociones' },
        name: 'admin.promociones.crear',
        component: () => import('../../pages/promociones/grupos/crearPromocion').then(m => m.default || m)
    },
    {
        path: '/admin/promociones/grupo/:idGrupo/promocion/:idPromocion/editar',
        meta: { nombre: 'Promociones' },
        name: 'admin.promociones.editar',
        component: () => import('../../pages/promociones/grupos/editarPromocion').then(m => m.default || m)
    },
    {
        path: '/tendero/promociones',
        meta: { nombre: 'Promociones' },
        name: 'tendero.promociones',
        component: () => import('../../pages/promociones/tendero').then(m => m.default || m)
    },
]
