import axios from 'axios'

const API_AUTH_PREFIJO = 'auth';
const API_PROYECTO_PREFIJO = 'proyecto';
const API_TIENDAS_PREFIJO = 'tiendas';

export default {
    consultarProyecto: () => axios(`${API_PROYECTO_PREFIJO}/datos-dominio`),
    consultarPaises: (payload) => axios(`${API_PROYECTO_PREFIJO}/${payload}/pais`),
    consultarEstados: (payload) => axios(`${API_PROYECTO_PREFIJO}/${payload.proyecto}/pais/${payload.pais}/estados`),
    consultarCiudades: (payload) => axios(`${API_PROYECTO_PREFIJO}/${payload.proyecto}/estado/${payload.estado}/ciudades`),
    consultarTiposTiendas: (params) => axios(`${API_TIENDAS_PREFIJO}/tipos-tiendas`, { params }),
    verificarSiDocumentoExiste: (params) => axios(`${API_AUTH_PREFIJO}/documento-existe`, { params }),
    verificarSiUsuarioExiste: (params) => axios(`${API_AUTH_PREFIJO}/usuario-existe`, { params }),
    postularTienda: (payload) => axios.post(`${API_AUTH_PREFIJO}/postular-tienda`, payload),
}
