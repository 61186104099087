<template>
    <section class="position-relative">
        <!-- Background Image -->
        <navbar-auth />
        <div class="container-fluid px-0">
            <div class="d-middle justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center position-relative h-100" style="background-color:#F5F5F6;">
                <div
                class="position-absolute w-100 h-100 image-background"
                :style="`background-image: url('${ ulrBackground ? ulrBackground : '/img/no-imagen/sin_imagen.png' } ');`"
                />
                <div class="col-auto d-middle mr-xl-3 mr-lg-3 mr-md-0 mr-sm-0 mr-0">
                    <div
                    class="box-login bg-white d-flex flex-column custom-scroll form-signin br-12 position-relative"
                    :style="`box-shadow: 0px 1px 4px #00000014; ${$route.name == 'login.form.admin' ? 'height:368px;' : 'height:518px;'}`"
                    >
                        <router-view @recuperar="abrirModalRecuperarContrasena()" />
                    </div>
                </div>
            </div>
        </div>
        <recuperarContrasena ref="RecuperarContrasena" :usuario="payload.usuario" />
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import firebase from '~/library/firebase';

const VUEX_RUTA = 'auth'

export default {
    layout: 'login',
    name: 'Login',
    components: {
        // MensajeErrorLogin: () => import('./partials/mensajeErrorLogin'),
        recuperarContrasena: () => import('./partials/recuperarContrasena')
    },
    data(){
        return {
            pos_img: '',
            h1_text: '',
            text_msg: '',
            estado: 1,

            url_persona: '',
            url_ilustracion: '',

            payload: {
                usuario: '',
                contrasena: ''
            },
            contrasenaVisible: false,
            cargando: false,
            erroresSesion: ['U001', 'C001'],
            estadoPermitidos: [4, 5],
            errores: {
                sesion: ['usuario', 'contrasena'],
                general: ['proyecto', 'tipo']
            },
            indice: 0,
            //adminPeople: ['admin_delivery', 'admin_inventario', 'admin_marketing', 'admin_señal', 'admin_ventas'],
            //clientePeople: ['cliente_hombre', 'cliente_mujer'],
            leecheroPeople: ['Tend_señal', 'Tend_montaña', 'Tend_casa'],



            adminIlustracion: ['ilu_delivery', 'ilu_inventario', 'ilu_marketing', 'ilu_señal', 'ilu_ventas'],
            adminMessage: ['Gestiona tus rutas', 'Mantente al tanto de ', 'Sorprende a los cliente', 'Ten control de los', 'Controla el inventario'],
            adminSubMessage: ['de entrega de pedidos', 'las ventas y utilidades', 'con descuentos y promociones', 'pedidos de los Vendedores', 'de Centros de distribución'],
            clienteMessage: ['Las mejores promociones', 'Tus productos'],
            clienteSubMessage: ['todos los días', 'de  siempre a un solo toque'],
            leecheroIlustracion: ['ilu_señal', 'ilu_montaña', 'ilu_casa'],
            leecMessage: ['Gana dinero', 'Lidera tu comunidad', 'Desde la comodidad'],
            leecSubMessage: ['cada pedido', 'siendo en centro de los pedidos', 'de tu casa'],
            intervalID:null,
        }
    },
    computed: {
        ...mapGetters({
            error: `${VUEX_RUTA}/obtenerError`,
            imgAdmin:'x_parametros/imgAdmin',
        }),
        /* adminPeople(){
            return this.imgAdmin.login
        }, */
        existenErrores(){
            return !_.isEmpty(this.error)
        },
        existeErrorSesion(){
            return this.existenErrores && this.erroresSesion.includes(this.error.codigo)
        },
        existeErrorServidor(){
            return this.existenErrores && this.error.tipo === 500
        },
        /*
        urlPeople(){
            let img =  this.adminPeople[this.indice];
            //return img;
            return `${img}`
        },
        */
        ulrBackground(){
            //:style="`background-image: url('${ _.isEmpty(ulrBackground) ? '/img/no-imagen/sin_imagen.png' : ulrBackground } ');`"
            // let firstImg = this.imgAdmin.login;
            // return firstImg.shift();
            return this.imgAdmin.login[this.indice];
        },
        spaceWindow(){
            return window.innerWidth <= 1366 ? false : true
        }
    },
    mounted(){
        this.intervalID = setInterval(() => {
            let cantidad = this.imgAdmin.login.length - 1
            this.indice++;
            if(this.indice > cantidad ){
                this.indice = 0;
            }
        }, 5000);

	    this.adminSubMessage[3] = `pedidos de los ${this.$config.vendedor}s`
    },
    destroyed(){
        clearInterval(this.intervalID)
    },
    methods: {
        ...mapActions({
            intentarIniciarSesion: `${VUEX_RUTA}/intentarIniciarSesion`,
            intentarIniciarSesionSocial: `${VUEX_RUTA}/intentarIniciarSesionSocial`
        }),
        ...mapMutations({
            almacenarError: `${VUEX_RUTA}/ALMACENAR_ERROR`,
            removerErrores: `${VUEX_RUTA}/REMOVER_ERRORES`,
        }),
        alternarCargando(){
            this.cargando = !this.cargando
        },
        iniciarSesion(){
            this.alternarCargando()
            this.intentarIniciarSesion(this.payload)
                .catch(this.almacenarError)
                .finally(() => {
                    this.alternarCargando();
                    this.intentarIrAVerificacion();
                });
        },
        async iniciarSesionGoogle(){
            const proveedor = new firebase.auth.GoogleAuthProvider();
            proveedor.addScope('email');
            this.abrirPopoverConexion(proveedor);
        },
        iniciarSesionFacebook(){
            const proveedor = new firebase.auth.FacebookAuthProvider();
            proveedor.addScope('email');
            this.abrirPopoverConexion(proveedor)
        },
        async abrirPopoverConexion(proveedor){
            try {
                this.alternarCargando();
                this.removerErrores();

                const { credential: credenciales, user } = await firebase.auth().signInWithPopup(proveedor);

                if (!credenciales) return;

                const payload = {
                    provider: this.obtenerNombreProveedor(user),
                    token: credenciales.accessToken
                };

                await this.intentarIniciarSesionSocial(payload).catch(this.almacenarError);
                this.intentarIrAVerificacion();
            } catch (error){
                console.error(error);
            } finally {
                this.alternarCargando();
            }
        },
        obtenerNombreProveedor: (obj) => obj.providerData[0].providerId.split('.')[0],
        intentarIrAVerificacion(){
            if (this.existeErrorServidor || this.existeErrorSesion) return

            this.$router.push({ name: 'login.stop' })
        },
        abrirModalRecuperarContrasena(){
            this.$refs.RecuperarContrasena.toggle();
        },
        irALRegistro(){
            this.$router.push({ name: 'registroPostulacion' })
        }
    }
}
</script>
<style lang="scss" scoped>

a{ text-decoration: none !important;}

.box-login{
    width: 423px;
}
.social-divisor{ background: #FFF;opacity: .5; }

.navbar-postulacion{
    top: 0px;
    left: 0px;
    width: calc(100vw);
    height: 70px;
    display: flex;
    align-items: center;
    background-color: var(--color-general) !important;
}
.container-fluid{
    height: calc(100vh - 70px);
}
.none-router{
    color: white;
    outline: none;
    text-decoration: none;
    &:hover{
        color:#523a3a;
    }
}

.social-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 25px;
    border-radius: 50%;
    background: #FFF;
    color: var(--color-general);
    text-align: center;
    cursor: pointer;
}
.img-background{
    background-image: url('/img/auth/onda_postulate.svg');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}
.image-background{
    z-index:0;
    background-size:cover;
    background-position: left center;
    background-attachment: fixed;
    background-repeat:no-repeat;
    transition: all 0.5s ease-out !important;
}

.arrow-oh{
    border-left: 25px solid transparent;
    width: 0px;
    height: 0px;
    border-top: 25px solid transparent;
    border-right: 25px solid white;
    left: -46px;
    top: 85px;
    z-index: 1;
    border-bottom: 25px solid transparent;
}



/* -*-*-*-*- Media Queries -*-*-*-*- */
@media (min-width:320px) and (max-width:480px){
    .navbar-postulacion{
        justify-content: center !important;
        margin: 0px !important;
    }
    .grid-login{
        grid-template-columns: 1fr !important;
    }

}

@media (max-width : 1200px) {
    .faqs-image{
        display: none !important;
    }
    .navbar-postulacion{
        justify-content: center;
    }
    .image-background{
        display: none !important;
    }
    .space-image{
        display: none !important;
    }
    .grid-login{
        grid-template-columns: 1fr 1fr;
    }
}
</style>
