<template>
    <div>
        <el-select v-model="seleccion" :multiple="multiple" placeholder="Seleccionar" class="w-100">
            <el-option
            v-for="item in categorias"
            :key="item.id"
            :label="item.nombre"
            :value="item.id">
            </el-option>
        </el-select>
    </div>
    
</template>
<script>
import SCategorias from '~/services/categorias'
export default {
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        idscategorias: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data(){
        return{
            seleccion: [],
            categorias: []
        }
    },
    mounted(){
        this.getCategorias()
    },
    methods:{
        async getCategorias(){
            try {
                const { data } = await SCategorias.GetCategorias()
                this.categorias = data
                if(this.idscategorias){
                    this.setSeleccionInicial()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        getSeleccion(){
            return this.seleccion
        },
        setSeleccion(categorias){
            this.seleccion = categorias
        },
        setSeleccionInicial(){
            this.seleccion = this.idscategorias            
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
