export default [
    {
        path: '/admin/paginas-inicio',
        name: 'admin.paginas-inicio',
        meta: { nombre: 'Paginas de inicio' },
        component: () => import('../../pages/paginasInicio').then(m => m.default || m)
    },
    {
        path: '/admin/paginas-inicio/editar/',
        name: 'admin.paginas-inicio.editar',
        meta: { nombre: 'Paginas de inicio' },
        component: () => import('../../pages/paginasInicio/editarPagina').then(m => m.default || m),
        redirect: {name: 'admin.paginas-inicio.editar.construccion'},
        children: [
            {
                path: '/admin/paginas-inicio/:idLanding/editar/construccion',
                name: 'admin.paginas-inicio.editar.construccion',
                meta: { nombre: 'Paginas de inicio' },
                component: () => import('../../pages/paginasInicio/construccion').then(m => m.default || m),
            },
            {
                path: '/admin/paginas-inicio/:idLanding/editar/estadisticas',
                name: 'admin.paginas-inicio.editar.estadisticas',
                meta: { nombre: 'Paginas de inicio' },
                component: () => import('../../pages/paginasInicio/estadisticas').then(m => m.default || m),
            },
            {
                path: '/admin/paginas-inicio/editar/clics',
                name: 'admin.paginas-inicio.editar.clics',
                meta: { nombre: 'Paginas de inicio' },
                component: () => import('../../pages/paginasInicio/clics').then(m => m.default || m),
            }
        ]
    },
	{
        path: '/admin/paginas-inicio/footer',
        name: 'admin.paginas.inicio.footer',
        meta: { nombre: 'Paginas de inicio - Footer' },
        component: () => import('../../pages/paginasInicio/footer.vue').then(m => m.default || m)
    },

]
