
import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es'

for (const rule in rules){
    // eslint-disable-next-line no-unused-expressions
    extend(rule, {
        ...rules[rule], // add the rule
        message: es.messages[rule] // add its message
    })
    extend('decimal', {
        validate: (value, { decimals = '*', separator = '.' } = {}) => {
            if (value === null || value === undefined || value === ''){
                return {
                    valid: false
                }
            }
            if (Number(decimals) === 0){
                return {
                    valid: /^-?\d*$/.test(value)
                }
            }
            const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
            const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

            return {
                valid: regex.test(value)
            }
        },
        params: ['decimals', 'separator'],
        message: 'El campo debe tener maximo {decimals} decimales'
    })
    extend("url", {
        validate: value => {
            if (value){
                return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
                    value
                )
            }

            return false;
        },
        message: "El campo debe ser tipo URL"
    });
    extend("greaterThanZero", {
        validate(value){
            return value > 0;
        },
        message: "El {_field_} debe ser mayor a cero."
    });

    extend("promo", {
        validate(value, { valor_pendiente }){
            if (valor_pendiente == "null" || valor_pendiente == null || valor_pendiente == '' || valor_pendiente == 'undefined') return true;
            return +value >= +valor_pendiente;
        },
        params: ["valor_pendiente", "valor_pendiente_formateado"],
        message:
        "El {_field_} debe ser mayor a la promoción ({valor_pendiente_formateado})."
    });
}
Vue.component('ValidationProvider', ValidationProvider)

Vue.component('ValidationObserver', ValidationObserver)
