<template>
    <div id="loading-buscar">
        <el-input v-model="buscar" placeholder="Buscar producto" class="w-100 br-20" @input="buscarProductos"/>
    </div>    
</template>
<script>
import Productos from '~/services/productos/productos'
export default {    
    props: {
       adicional:{
           type: Array,
            default() {
                return {}
            }
       },
       adicional_not:{
           type: Array,
            default() {
                return {}
            }
       }
    },
    data(){
        return{
            buscar:''
        }
    },
    methods:{
        async buscarProductos(){
            try{
                if(this.buscar.length>=3){
                    
                    let loading = this.$loading(
                        {
                            lock: true,
                            target:`#loading-buscar`
                        });

                    let datos = {
                        text : this.buscar,
                        adicional: this.adicional,
                        adicional_not: this.adicional_not
                    }

                    const { data } = await Productos.obtenerFiltrados(datos)
                    this.$emit("productosObtenidos",data)
                    loading.close()
                                       
                }
            } catch (error){
                this.error_catch(error)
            }
        }      
    }
}
</script>

<style lang="scss" scoped>

</style>
